import {
  Box,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import CustomTable from "../../components/table/CustomTableV2";
import LoadingBox from "../../components/common/LoadingBox";
import {
  useDeleteSurvey,
  useFetchSurveys,
  useUpdateSurvey,
} from "../../api/survey/survey";
import { FormattedDate } from "react-intl";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../hooks/useToastMessage";
import { DeleteSomethingModal } from "../../components/modals/DeleteSomethingModal";

const ManageSurveys = () => {
  const { accountId } = useAccountId();
  const {
    data: surveys,
    isLoading: isLoadingSurveys,
    isError,
    error,
  } = useFetchSurveys(accountId);
  const navigate = useCustomDomainNavigate();
  const [showArchived, setShowArchived] = useState(false);

  const surveyColumns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ cell }) => {
          const survey = cell.row.original;
          return (
            <>
              <Link
                onClick={() => {
                  onRowClick(survey.id);
                }}
              >
                {survey.title}
              </Link>
              {survey.isArchived && (
                <Tag ml={4} colorScheme="red">
                  Archived
                </Tag>
              )}
            </>
          );
        },
      },
      {
        Header: "Last Updated",
        accessor: "updatedAt",
        Cell: ({ cell }) => {
          const date = cell.row.original.updatedAt;
          return (
            <>
              <FormattedDate
                value={date}
                year="numeric"
                month="short"
                day="2-digit"
              />
            </>
          );
        },
      },
      {
        accessor: "Actions",
        Header: () => (
          <Flex pr={"100px"} justifyContent={"flex-end"} w={"100%"}>
            Actions
          </Flex>
        ),
        Cell: ({ cell }) => {
          const id = cell.row.original.id;
          return (
            <HStack
              // maxW={"300px"}
              justifyContent={"flex-end"}
              alignContent={"flex-end"}
              // bg={"yellow"}
              spacing={4}
            >
              <Link
                variant={"outline"}
                size={"sm"}
                onClick={() => {
                  navigate(`/${accountId}/surveys/${id}/update?tab=Edit`);
                }}
              >
                Edit
              </Link>
              <Link
                variant={"outline"}
                size={"sm"}
                onClick={() => {
                  navigate(`/${accountId}/surveys/${id}/update?tab=Preview`);
                }}
              >
                Preview
              </Link>
              <Link
                variant={"outline"}
                size={"sm"}
                onClick={() => {
                  navigate(`/${accountId}/surveys/${id}/update?tab=Run`);
                }}
              >
                Manage Runs
              </Link>
              <ThreeDotsMenu survey={cell.row.original} />
            </HStack>
          );
        },
      },
    ],
    [surveys]
  );

  const onRowClick = (id) => {
    navigate(`/${accountId}/surveys/${id}/update`);
  };

  const surveysToShow = useMemo(() => {
    return surveys?.filter((survey) => {
      if (showArchived) {
        return survey.isArchived;
      } else {
        return !survey.isArchived;
      }
    });
  }, [surveys, showArchived]);

  return (
    <Box>
      {isLoadingSurveys && <LoadingBox height={"200px"} />}
      {isError && <Text color={"red.500"}>Error: {error.message}</Text>}
      {surveys && (
        <CustomTable
          columns={surveyColumns}
          data={surveysToShow}
          //onRowClick={onRowClick}
          rightElement={
            <HStack spacing={2}>
              <Switch
                isChecked={showArchived}
                onChange={(e) => {
                  setShowArchived(e.target.checked);
                }}
              />
              <Text>Show Archived</Text>
            </HStack>
          }
          doUsePagination={true}
          selectionType={"link"}
          boxStyle={{
            bg: "white",
            borderRadius: "10px",
            border: "1px",
            borderColor: "gray.200",
            p: 4,
            overflow: "auto",
          }}
          tableStyle={{
            size: "md",
          }}
          tableId={"manageSurveysTable"}
        />
      )}
    </Box>
  );
};

const ThreeDotsMenu = ({ survey }) => {
  const { showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const deleteSurveyMutation = useDeleteSurvey();
  const updateSurveyMutation = useUpdateSurvey();
  const [isDeleteSurveyModalOpen, setIsDeleteSurveyModalOpen] = useState(false);

  const deleteSurvey = async (surveyId) => {
    try {
      await deleteSurveyMutation.mutateAsync({
        accountId,
        surveyId,
      });
      queryClient.invalidateQueries(["fetchSurveys", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting survey" });
    }
  };

  const updateSurvey = async (surveyId, data) => {
    try {
      await updateSurveyMutation.mutateAsync({
        accountId,
        surveyId,
        data,
      });
      queryClient.invalidateQueries(["fetchSurveys", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating survey" });
    }
  };

  return (
    <Box>
      <DeleteSomethingModal
        isOpen={isDeleteSurveyModalOpen}
        isDeleting={deleteSurveyMutation.isLoading}
        setIsOpen={setIsDeleteSurveyModalOpen}
        headerText={"Delete Survey"}
        bodyText={`Are you sure you want to delete this survey?`}
        deleteFunction={async () => {
          await deleteSurvey(survey.id);
        }}
        buttonText={"Delete Survey"}
      />
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              isLoading={
                deleteSurveyMutation.isLoading || updateSurveyMutation.isLoading
              }
              as={IconButton}
              variant={"ghost"}
              icon={<BiDotsVerticalRounded size={22} />}
            ></MenuButton>
            <MenuList
              style={{
                display: !isOpen ? "none" : "", // <-- Without this, draggable items will select entire screen
              }}
            >
              {survey.isArchived ? (
                <MenuItem
                  onClick={() => {
                    updateSurvey(survey.id, { isArchived: false });
                  }}
                >
                  Unarchive
                </MenuItem>
              ) : (
                <MenuItem
                  _hover={{ bg: "red.100" }}
                  onClick={() => {
                    updateSurvey(survey.id, { isArchived: true });
                  }}
                >
                  Archive
                </MenuItem>
              )}
              <MenuItem
                _hover={{ bg: "red.100" }}
                onClick={() => {
                  setIsDeleteSurveyModalOpen(true);
                }}
                isDisabled={!survey._count.runs < 1}
              >
                Delete
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default ManageSurveys;
