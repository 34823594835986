import { SelectField } from "../../../../components/fields/SelectField";
import { formatOkrOptions } from "../../../OKRV2/Objectives/formatOkrOptions";
import { useMemo } from "react";
import useAccountId from "../../../../hooks/customDomainHooks";
import { useFetchObjectives } from "../../../../api/okr/objective";

const OkrWidgetConfig = () => {
  const { accountId } = useAccountId();
  const {
    data: objectiveSets,
    isLoading: isLoadingObjectives,
    isError: isErrorObjectives,
    error: errorObjectives,
  } = useFetchObjectives(accountId, null, "timePeriod.startDate.desc", []);

  const objectiveOptions = useMemo(
    () => formatOkrOptions(objectiveSets),
    [objectiveSets]
  );

  return (
    <>
      <SelectField
        field={{
          id: "data",
          label: "Objective",
          validation: { required: true },
        }}
        isLoading={isLoadingObjectives}
        options={objectiveOptions}
      />
    </>
  );
};

export default OkrWidgetConfig;
