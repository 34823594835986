import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import DrawnArrow from "../../../assets/icons/drawn_arrow.svg?react";
import { SkipButton } from "../components/SkipButton";
import ArrowRight from "../../../assets/icons/arrow_right.svg?react";

const WizardStepRightEnd = ({
  incrementWizardStep,
  wizardName,
  title,
  text,
  isLastStep,
  boxStyles,
}) => {
  return (
    <Box>
      <Box w={"600px"} pl={"20px"} {...boxStyles}>
        <VStack alignItems={"flex-start"} spacing={6}>
          <Box w={"475px"}>
            <Text
              color={"white"}
              fontSize={"40px"}
              fontWeight={800}
              textAlign={"start"}
            >
              {title}
            </Text>
          </Box>
          {isLastStep ? (
            <Button onClick={incrementWizardStep}>Done</Button>
          ) : (
            <HStack spacing={4}>
              <Button
                onClick={incrementWizardStep}
                leftIcon={<ArrowRight fill="#fff" />}
              >
                Continue
              </Button>
              <SkipButton wizardName={wizardName} />
            </HStack>
          )}
          <Text
            color={"white"}
            fontSize={"22px"}
            fontWeight={400}
            textAlign={"start"}
          >
            {text}
          </Text>
        </VStack>
      </Box>
      <Box pl={"30px"} pt={"20px"}>
        <DrawnArrow />
      </Box>
    </Box>
  );
};

export default WizardStepRightEnd;
