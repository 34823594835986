import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import { Navbar } from "./Navbar";
import { AccountContextComponent } from "../context/AccountContextComponent";
import { UserContextComponent } from "../context/UserContextComponent";
import PartnerDashboardSidebar from "./sidebar/PartnerDashboardSidebar";

const DynamicHelmet = () => {
  return (
    <Helmet>
      <title> The Best Answer | Partner</title>
    </Helmet>
  );
};

export const PartnerDashboardLayout = (props) => {
  const { children, hideSidebar, hideCompanySettings } = props;
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <UserContextComponent>
      <AccountContextComponent>
        <DynamicHelmet />
        <Flex
          direction="column"
          h="full"
          id="app-container"
          minHeight="100vh"
          position="relative"
          overflow="hidden"
        >
          <Navbar
            hideSidebar={hideSidebar}
            hideCompanySettings={hideCompanySettings}
            dashboardType="PARTNER"
          />
          {isDesktop && !hideSidebar && <PartnerDashboardSidebar />}
          <Box
            bg={"offWhite"}
            flex="1"
            overflowY={"auto"}
            ml={isDesktop && !hideSidebar ? "64" : "0"}
            pl={isDesktop && !hideSidebar ? "4" : "0"}
            pt="64px"
          >
            {children || <Outlet />}
          </Box>
        </Flex>
      </AccountContextComponent>
    </UserContextComponent>
  );
};
