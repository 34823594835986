import React, { useEffect } from "react";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  updateProfile,
} from "firebase/auth";
import LoadingBox from "../../../components/common/LoadingBox";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../api/config";
import useToastMessage from "../../../hooks/useToastMessage";

const auth = getAuth();

const AcceptInviteHandler = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const email = searchParams.get("email")?.replace(" ", "+");
  const name = searchParams.get("name");
  const navigate = useNavigate();
  const { showErrorToast } = useToastMessage();

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      if (!email) {
        return;
      }

      signInWithEmailLink(auth, email, window.location.href)
        .then(async (result) => {
          //1 update the firebase user's name if it's not already set
          await updateProfile(auth.currentUser, {
            displayName: result.user.displayName || name,
          });
          //2 create the user in the db
          await api.post("/users");
          navigate(
            "/_auth/create-password?email=" +
              searchParams.get("email") +
              "&name=" +
              searchParams.get("name")
          );
        })
        .catch((error) => {
          console.log("error", error);
          showErrorToast({ message: "Failed to sign in" });
        });
    }
  }, []);

  return <LoadingBox />;
};

export default AcceptInviteHandler;
