import { useState, useEffect, useContext, useMemo } from "react";
import useAccountId from "../../../hooks/customDomainHooks";
import { AccountContext } from "../../../context/AccountContextComponent";
import {
  useFetchActiveEmployeesOnDates,
  useFetchTurnoverMetrics,
} from "../../../api/profiles";

const useEmployeeTurnover = (startDate) => {
  const { accountId } = useAccountId();
  const { getRankLabel } = useContext(AccountContext);
  const [
    allEmployeeQuarterlyTurnoverRates,
    setAllEmployeeQuarterlyTurnoverRates,
  ] = useState([]);
  const [departmentsToFilter, setDepartmentsToFilter] = useState([]);
  const [ranksToFilter, setRanksToFilter] = useState([]);
  const [tenuresToFilter, setTenuresToFilter] = useState([]);
  const [terminationTagIdsToFilter, setTerminationTagIdsToFilter] = useState(
    []
  );

  const getQuarterLabel = (date) => {
    const month = new Date(date).getMonth();
    const quarterNumber = Math.floor(month / 3) + 1;

    return `Q${quarterNumber} '${new Date(date)
      .getFullYear()
      .toString()
      .substring(2)}`;
  };

  const today = useMemo(() => {
    return new Date().toISOString();
  }, []);

  const lastNineQuarters = useMemo(() => {
    return getPastQuarterStartDates(new Date(), 9).map((date) => {
      return date.toISOString();
    });
  }, []);

  // Assuming lastSixQuarters are ISO date strings representing the START of each quarter
  const lastSixQuarters = lastNineQuarters.slice(-7, -1);

  function getPastQuarterStartDates(inputDate, numberOfQuarters) {
    if (!(inputDate instanceof Date) || !Number.isInteger(numberOfQuarters)) {
      throw new Error("Invalid input: Expecting a date and an integer number");
    }

    let year = inputDate.getFullYear();
    let month = inputDate.getMonth();
    let currentQuarter = Math.floor(month / 3);
    let dates = [];

    // Calculate the start date of the current quarter
    let startOfCurrentQuarter = new Date(year, currentQuarter * 3, 1);
    for (let i = 0; i < numberOfQuarters; i++) {
      // Adjust the year and quarter accordingly
      if (currentQuarter === 0) {
        year--;
        currentQuarter = 4; // Set it to the 4th quarter of the previous year
      }

      currentQuarter--;
      let startOfQuarter = new Date(year, currentQuarter * 3, 1);

      // Adding the date at the beginning of the array to keep the order from the most recent to the oldest
      dates.unshift(startOfQuarter);
    }

    dates.push(startOfCurrentQuarter);

    return dates;
  }

  const {
    data: turnoverMetrics,
    isLoading: isLoadingTurnoverMetrics,
    isError: isErrorTurnoverMetrics,
    error: errorTurnoverMetrics,
    fetchStatus,
  } = useFetchTurnoverMetrics(
    accountId,
    startDate || lastNineQuarters[0],
    today,
    tenuresToFilter,
    departmentsToFilter,
    ranksToFilter,
    terminationTagIdsToFilter
  );

  const {
    data: activeEmployeesData,
    isLoading: isLoadingActiveEmployeesData,
    isError: isErrorActiveEmployeesData,
    error: errorActiveEmployeesData,
    fetchStatus: fetchStatusActiveEmployeesData,
  } = useFetchActiveEmployeesOnDates(
    accountId,
    lastNineQuarters,
    departmentsToFilter,
    ranksToFilter,
    tenuresToFilter
  );

  const getTurnoverByRank = () => {
    if (!turnoverMetrics) return [];

    // Create a mapping from the actual dates to the respective quarters
    const dateToQuarterMap = {};
    lastSixQuarters.forEach((quarterStartDate, index) => {
      // The label is derived from the start date of the quarter
      const quarterLabel = getQuarterLabel(quarterStartDate);

      // If we have the next quarter's start date, we can establish the range for this quarter
      if (lastSixQuarters[index + 1]) {
        const currentQuarterStart = new Date(quarterStartDate);
        const nextQuarterStart = new Date(lastSixQuarters[index + 1]);

        // Any date in turnoverMetrics that is between the currentQuarterStart and the nextQuarterStart belongs to this quarter
        turnoverMetrics.forEach((metric) => {
          const endDate = new Date(metric.endDate);

          if (endDate >= currentQuarterStart && endDate < nextQuarterStart) {
            dateToQuarterMap[metric.endDate] = quarterLabel;
          }
        });
      } else {
        // If we don't have the next quarter's start date, we can assume that the last quarter is the current quarter
        turnoverMetrics.forEach((metric) => {
          const endDate = new Date(metric.endDate);
          const oneQuarterInFuture = new Date(quarterStartDate);
          oneQuarterInFuture.setMonth(oneQuarterInFuture.getMonth() + 3);
          if (
            endDate >= new Date(quarterStartDate) &&
            endDate < oneQuarterInFuture
          ) {
            dateToQuarterMap[metric.endDate] = quarterLabel;
          }
        });
      }
    });

    // Initialize an object to hold the collated data, with proper structure
    const turnoverByRank = {};
    lastSixQuarters.forEach((quarterStartDate) => {
      const quarterLabel = getQuarterLabel(quarterStartDate);
      turnoverByRank[quarterLabel] = {};
    });

    // Now, populate turnoverByRank using the mapped quarter labels
    turnoverMetrics.forEach((metric) => {
      const quarterLabel = dateToQuarterMap[metric.endDate];
      if (quarterLabel) {
        const rank = getRankLabel(metric.ranking || "Unranked");

        if (!turnoverByRank[quarterLabel][rank]) {
          turnoverByRank[quarterLabel][rank] = 0;
        }
        turnoverByRank[quarterLabel][rank]++;
      }
    });

    // Convert the turnoverByRank object into the desired array format, maintaining order
    const resultArray = lastSixQuarters.map((quarterStartDate) => {
      const quarterLabel = getQuarterLabel(quarterStartDate);
      return { label: quarterLabel, ...turnoverByRank[quarterLabel] };
    });

    return resultArray;
  };

  const getTurnoverByDepartment = () => {
    if (!turnoverMetrics) return [];

    // Get the last six quarters from your last nine quarters
    const lastSixQuarters = lastNineQuarters.slice(-7, -1);

    // Map for linking dates to their respective quarters
    const dateToQuarterMap = {};
    lastSixQuarters.forEach((quarterStartDate, index) => {
      const quarterLabel = getQuarterLabel(quarterStartDate);

      if (lastSixQuarters[index + 1]) {
        const currentQuarterStart = new Date(quarterStartDate);
        const nextQuarterStart = new Date(lastSixQuarters[index + 1]);

        turnoverMetrics.forEach((metric) => {
          const endDate = new Date(metric.endDate);

          if (endDate >= currentQuarterStart && endDate < nextQuarterStart) {
            dateToQuarterMap[metric.endDate] = quarterLabel;
          }
        });
      } else {
        // If we don't have the next quarter's start date, we can assume that the last quarter is the current quarter
        turnoverMetrics.forEach((metric) => {
          const endDate = new Date(metric.endDate);
          const oneQuarterInFuture = new Date(quarterStartDate);
          oneQuarterInFuture.setMonth(oneQuarterInFuture.getMonth() + 3);
          if (
            endDate >= new Date(quarterStartDate) &&
            endDate < oneQuarterInFuture
          ) {
            dateToQuarterMap[metric.endDate] = quarterLabel;
          }
        });
      }
    });

    // Structure to hold the turnover by department
    const turnoverByDepartment = {};
    lastSixQuarters.forEach((quarterStartDate) => {
      const quarterLabel = getQuarterLabel(quarterStartDate);
      turnoverByDepartment[quarterLabel] = {};
    });

    // Populate the turnoverByDepartment with data
    turnoverMetrics.forEach((metric) => {
      const quarterLabel = dateToQuarterMap[metric.endDate];
      if (quarterLabel) {
        // Assuming the department's name is accessible as metric.department.name
        const departmentName = metric.department?.name;

        if (!turnoverByDepartment[quarterLabel][departmentName]) {
          turnoverByDepartment[quarterLabel][departmentName] = 0;
        }
        turnoverByDepartment[quarterLabel][departmentName]++;
      }
    });

    // Convert to array format as per the previous function's behavior
    const resultArray = lastSixQuarters.map((quarterStartDate) => {
      const quarterLabel = getQuarterLabel(quarterStartDate);
      return { label: quarterLabel, ...turnoverByDepartment[quarterLabel] };
    });

    return resultArray;
  };

  useEffect(() => {
    if (!turnoverMetrics || !activeEmployeesData) return;

    const datesAsList = Object.keys(activeEmployeesData)
      .map((date) => date)
      .slice(-7);
    const quarterlyTurnoverRates = {};
    for (let i = 0; i < datesAsList.length - 1; i++) {
      const date = datesAsList[i];
      const nextDate = datesAsList[i + 1];
      const previousYearDate = new Date(nextDate);
      previousYearDate.setFullYear(new Date(nextDate).getFullYear() - 1);

      const numEmployeesLeftInDateRange = turnoverMetrics.reduce(
        (acc, metric) => {
          if (
            new Date(metric.endDate) >= new Date(date) &&
            new Date(metric.endDate) < new Date(nextDate)
          ) {
            return acc + 1;
          }
          return acc;
        },
        0
      );

      const averageNumEmployeesDuringDateRangeQuarter =
        (activeEmployeesData[date]?.total +
          activeEmployeesData[nextDate]?.total) /
        2;

      // Protect against divide by zero
      let quarterlyTurnoverRate = 0;
      if (averageNumEmployeesDuringDateRangeQuarter !== 0) {
        quarterlyTurnoverRate = (
          (numEmployeesLeftInDateRange /
            averageNumEmployeesDuringDateRangeQuarter) *
          100
        ).toFixed(1);
      }

      const numEmployeesLeftInDateRangeLtm = turnoverMetrics.reduce(
        (acc, metric) => {
          if (
            new Date(metric.endDate) >= new Date(previousYearDate) &&
            new Date(metric.endDate) < new Date(nextDate)
          ) {
            return acc + 1;
          }
          return acc;
        },
        0
      );

      const averageNumEmployeesDuringDateRangeLtm =
        (activeEmployeesData[previousYearDate.toISOString()]?.total +
          activeEmployeesData[nextDate]?.total) /
        2;

      // Protect against divide by zero
      let ltmTurnoverRate = 0;
      if (averageNumEmployeesDuringDateRangeLtm !== 0) {
        ltmTurnoverRate = (
          (numEmployeesLeftInDateRangeLtm /
            averageNumEmployeesDuringDateRangeLtm) *
          100
        ).toFixed(1);
      }

      quarterlyTurnoverRates[getQuarterLabel(date)] = {
        average: quarterlyTurnoverRate,
        ltm: ltmTurnoverRate,
        startDate: date,
        endDate: nextDate,
      };
    }

    setAllEmployeeQuarterlyTurnoverRates(quarterlyTurnoverRates);
  }, [turnoverMetrics, activeEmployeesData]);

  const turnoverByRank = getTurnoverByRank();
  const turnoverByDepartment = getTurnoverByDepartment();

  return {
    turnoverMetrics,
    turnoverByRank,
    turnoverByDepartment,
    isLoadingTurnoverMetrics,
    isErrorTurnoverMetrics,
    errorTurnoverMetrics,
    fetchStatus,
    activeEmployeesData,
    isLoadingActiveEmployeesData,
    isErrorActiveEmployeesData,
    errorActiveEmployeesData,
    fetchStatusActiveEmployeesData,
    allEmployeeQuarterlyTurnoverRates,
    lastSixQuarters,
    lastNineQuarters,
    setAllEmployeeQuarterlyTurnoverRates,
    departmentsToFilter,
    setDepartmentsToFilter,
    ranksToFilter,
    setRanksToFilter,
    tenuresToFilter,
    setTenuresToFilter,
    terminationTagIdsToFilter,
    setTerminationTagIdsToFilter,
    getQuarterLabel,
  };
};

export default useEmployeeTurnover;
