import { Box, HStack, Icon, Square, Text } from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";

export const MessageBox = ({ message, ...props }) => {
  return (
    <Box
      borderRadius={"md"}
      border={"1px"}
      borderColor={"gray.200"}
      bg={"white"}
      overflow={"auto"}
      w={"100%"}
      pr={1}
      {...props}
    >
      <HStack>
        <Square size="12" bg="green.500" p={7}>
          <Icon as={FiInfo} boxSize="7" color={"white"} />
        </Square>
        <Text>{message}</Text>
      </HStack>
    </Box>
  );
};
