import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

// This endpoint will return the SurveyRunResponse and the SurveyRun data
export const useFetchMySurveyRunResponse = (
  accountId,
  surveyRunId,
  profileId
) => {
  return useQuery({
    queryKey: ["fetchMySurveyRunResponse", accountId, surveyRunId, profileId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/surveyRun/${surveyRunId}/response`
      );
      return response.data;
    },
  });
};

export const useUpdateSurveyRunResponse = (props) =>
  useMutation({
    mutationKey: ["updateSurveyRunResponse"],
    mutationFn: async ({ accountId, surveyRunResponseId, data }) => {
      const response = await api.put(
        `/${accountId}/surveyRunResponse/${surveyRunResponseId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useGetAllSurveyRunResponsesAsCsv = () =>
  useMutation({
    mutationKey: ["fetchAllSurveyRunResponsesAsCsv"],
    mutationFn: async ({ contentType, accountId, surveyRunId }) => {
      console.log("surveyRunId", surveyRunId);

      const response = await api.get(
        `/${accountId}/surveyRun/${surveyRunId}/all-responses`,
        {
          headers: {
            Accept: contentType || "text/csv",
          },
        }
      );
      // Assuming the response is the CSV text directly,
      // you might want to return it as is or process it depending on your needs.
      return response.data;
    },
  });

export const useGetAllSurveyRunResponses = (
  contentType,
  accountId,
  surveyRunId,
  departments,
  tenuresToFilter,
  ranksToFilter
) =>
  useQuery({
    queryKey: [
      "fetchAllSurveyRunResponses",
      contentType,
      accountId,
      surveyRunId,
      departments,
      tenuresToFilter,
      ranksToFilter,
    ],
    enabled: !!surveyRunId,
    queryFn: async () => {
      const departmentQuery = departments
        ?.map((department) => `&department=${department.label}`)
        .join("&");

      const tenureQuery = tenuresToFilter
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const rankQuery = ranksToFilter
        ?.map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/surveyRun/${surveyRunId}/all-responses?${departmentQuery}${tenureQuery}${rankQuery}`,
        {
          headers: {
            Accept: contentType || "text/csv",
          },
        }
      );
      return response.data;
    },
  });

export const useGetEnpsResponses = (
  accountId,
  departments,
  tenuresToFilter,
  ranksToFilter
) =>
  useQuery({
    queryKey: [
      "fetchEnpsResponses",
      accountId,
      departments,
      tenuresToFilter,
      ranksToFilter,
    ],
    queryFn: async () => {
      const departmentQuery = departments
        ?.map((department) => `&department=${department.label}`)
        .join("&");

      const tenureQuery = tenuresToFilter
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const rankQuery = ranksToFilter
        ?.map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/enps?${departmentQuery}${tenureQuery}${rankQuery}`
      );
      return response.data;
    },
  });
