import { Box, Text } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import CustomTable from "../../../components/table/CustomTableV2";
import { AccountContext } from "../../../context/AccountContextComponent";

export const TurnoverFullDataTable = ({ profileMetrics, showAfterDate }) => {
  const { aRankLabel, bRankLabel, cRankLabel, accountData } =
    useContext(AccountContext);
  const dataColumns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Tenure Group",
        accessor: "tenureBucket",
      },
      {
        Header: "Tenure",
        accessor: (row) => {
          const endDate = new Date(row.endDate);
          const startDate = new Date(row.startDate);
          const years = endDate.getFullYear() - startDate.getFullYear();
          const months = endDate.getMonth() - startDate.getMonth();
          const adjustedYears = months < 0 ? years - 1 : years;
          const adjustedMonths = months < 0 ? months + 12 : months;
          return adjustedYears * 12 + adjustedMonths; // returns total months for sorting
        },
        Cell: ({ value }) => {
          const years = Math.floor(value / 12);
          const months = value % 12;
          return `${years} years, ${months} months`;
        },
      },
      {
        Header: "End Date",
        accessor: "endDate",
        Cell: ({ value }) => {
          return (
            <Text textAlign={"end"}>
              {value ? new Date(value).toLocaleDateString() : "N/A"}
            </Text>
          );
        },
      },
      {
        Header: "Department",
        accessor: "department.name",
      },
      {
        Header: "Termination Reason",
        accessor: "terminationTag.label",
      },
      {
        Header: "Ranking",
        accessor: "ranking",
      },
    ];
  }, [profileMetrics]);

  const formattedData = useMemo(() => {
    if (!profileMetrics) return [];
    const rankingLabels = {
      A: aRankLabel,
      B: bRankLabel,
      C: cRankLabel,
      Unranked: "Unranked",
    };
    return profileMetrics
      ?.filter((profile) => {
        return (
          profile.endDate &&
          new Date(profile.endDate) >= new Date(showAfterDate)
        );
      })
      .map((profile) => {
        return {
          ...profile,
          ranking: rankingLabels[profile.ranking] || "Unranked",
        };
      });
  }, [profileMetrics]);

  return (
    <>
      <Box w={"100%"}>
        <CustomTable
          columns={dataColumns}
          data={formattedData || []}
          initialState={{
            sortBy: [
              { id: "tenureBucket", desc: false },
              { id: "name", desc: false },
            ],
          }}
          doUsePagination={true}
          showSearchBar={true}
          showDownloadLink={true}
          csvFileName={`employee_tenure`.replaceAll(" ", "_").toLowerCase()}
          csvMapping={{
            Name: "name",
            Email: "email",
            Tenure: "tenureBucket",
            Department: "department.name",
            "Termination Reason": "terminationTag.label",
            Manager: "manager",
            "Start Date": "startDate",
            "End Date": "endDate",
            Ranking: "ranking",
          }}
          selectionType={"none"}
          boxStyle={{
            bg: "white",
            borderRadius: "10px",
            border: "1px",
            borderColor: "gray.200",
            p: 4,
            overflow: "auto",
          }}
          tableId={"turnoverFullDataTable"}
        />
      </Box>
    </>
  );
};
