import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CustomTable from "./CustomTableV2";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";

export const DualTables = ({
  columns,
  initialLeftData,
  initialRightData,
  onRightRowsChange,
}) => {
  const [leftData, setLeftData] = useState(initialLeftData);
  const [rightData, setRightData] = useState(initialRightData);
  const [selectedLeftRows, setSelectedLeftRows] = useState(new Set([]));
  const [selectedRightRows, setSelectedRightRows] = useState(new Set([]));

  useEffect(() => {
    onRightRowsChange && onRightRowsChange(rightData);
  }, [rightData]);

  const handleAdd = () => {
    const newData = leftData.filter(
      (row) => !Array.from(selectedLeftRows).includes(row.id)
    );
    setLeftData(newData);

    const movedData = leftData.filter((row) =>
      Array.from(selectedLeftRows).includes(row.id)
    );
    setRightData([...rightData, ...movedData]);
    setSelectedLeftRows(new Set([]));
  };

  const handleAddAll = () => {
    setRightData([...rightData, ...leftData]);
    setLeftData([]);
    setSelectedLeftRows(new Set([]));
  };

  const handleRemove = () => {
    const newData = rightData.filter(
      (row) => !Array.from(selectedRightRows).includes(row.id)
    );
    setRightData(newData);

    const movedData = rightData.filter((row) =>
      Array.from(selectedRightRows).includes(row.id)
    );
    setLeftData([...leftData, ...movedData]);
    setSelectedRightRows(new Set([]));
  };

  const handleRemoveAll = () => {
    setLeftData([...leftData, ...rightData]);
    setRightData([]);
    setSelectedRightRows(new Set([]));
  };

  return (
    <HStack align={"flex-start"} w={"100%"}>
      <Box w={"500px"} h={"600px"}>
        <VStack alignItems={"flex-start"} spacing={0}>
          <Text fontWeight={"bold"}>Available</Text>
          <CustomTable
            columns={columns}
            data={leftData}
            selectedRows={selectedLeftRows}
            setSelectedRows={setSelectedLeftRows}
            boxStyle={{ height: "450px" }}
          />
        </VStack>
      </Box>
      <Box w={"200px"} pt={14}>
        <VStack p={4}>
          <Button
            w={"100%"}
            onClick={() => handleAddAll()}
            rightIcon={<BiSolidRightArrow />}
            isDisabled={leftData.length === 0}
          >
            Add All
          </Button>
          <Button
            w={"100%"}
            onClick={() => handleAdd()}
            rightIcon={<BiSolidRightArrow />}
            isDisabled={selectedLeftRows.size === 0}
          >
            Add
          </Button>
          <Button
            bg={null}
            variant="red"
            w={"100%"}
            onClick={() => handleRemove()}
            leftIcon={<BiSolidLeftArrow />}
            isDisabled={selectedRightRows.size === 0}
          >
            Remove
          </Button>
          <Button
            variant="red"
            w={"100%"}
            onClick={() => handleRemoveAll()}
            leftIcon={<BiSolidLeftArrow />}
            isDisabled={rightData.length === 0}
          >
            Remove all
          </Button>
        </VStack>
      </Box>
      <Box w={"500px"} h={"600px"}>
        <VStack alignItems={"flex-start"} spacing={0}>
          <Text fontWeight={"bold"}>Selected</Text>
          <CustomTable
            columns={columns}
            data={rightData}
            selectedRows={selectedRightRows}
            setSelectedRows={setSelectedRightRows}
            boxStyle={{ height: "450px" }}
          />
        </VStack>
      </Box>
    </HStack>
  );
};
