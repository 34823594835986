import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateOneOnOneSchedule = (props) =>
  useMutation({
    mutationKey: ["createOneOnOneSchedule"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/oneOnOneSchedule`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteOneOnOneSchedule = (props) =>
  useMutation({
    mutationKey: ["deleteOneOnOneSchedule"],
    mutationFn: async ({ accountId, oneOnOneScheduleId }) => {
      const response = await api.delete(
        `/${accountId}/oneOnOneSchedule/${oneOnOneScheduleId}`
      );
      return response.data;
    },
    ...props,
  });

export const useFetchOneOnOneSchedules = (accountId) => {
  return useQuery({
    queryKey: ["fetchOneOnOneSchedules"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/oneOnOneSchedule`);
      return response.data;
    },
  });
};

export const useUpdateOneOnOneSchedule = (props) =>
  useMutation({
    mutationKey: ["updateOneOnOneSchedule"],
    mutationFn: async ({
      accountId,
      oneOnOneScheduleId,
      data,
      fromThisMeetingId,
    }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneSchedule/${oneOnOneScheduleId}/${fromThisMeetingId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
