import PageShell from "../../layout/PageShell";
import {
  useFetchPartner,
  useFetchPartnerAccounts,
  usePartnerCancelSubscription,
} from "../../api/partner/partner";
import { Box, Link, Text } from "@chakra-ui/react";
import CustomTable from "../../components/table/CustomTableV2";
import { useMemo, useState } from "react";
import { FormattedDate } from "react-intl";
import LoadingBox from "../../components/common/LoadingBox";
import { useCustomDomainNavigate } from "../../hooks/customDomainHooks";
import { SetPlanModal } from "./modals/SetPlanModal";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../hooks/useToastMessage";
import { AreYouSureModal } from "../../components/common/AreYouSureModal";

const EnterprisePlansPage = () => {
  const {
    data: partnerData,
    isLoading: isLoadingPartnerData,
    isError: isErrorPartnerData,
    error: errorPartnerData,
  } = useFetchPartner();

  const {
    data: partnerAccounts,
    isLoading: isLoadingPartnerAccounts,
    isError: isErrorPartnerAccounts,
    error: errorPartnerAccounts,
  } = useFetchPartnerAccounts();
  const cancelSubscriptionMutation = usePartnerCancelSubscription();
  const navigate = useCustomDomainNavigate();
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [partnerAccountToEdit, setPartnerAccountToEdit] = useState(undefined);
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [partnerAccountToCancel, setPartnerAccountToCancel] =
    useState(undefined);

  const cancelSubscription = async (data) => {
    try {
      await cancelSubscriptionMutation.mutateAsync({
        data,
      });
      queryClient.invalidateQueries(["partnerCancelSubscription"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error cancelling subscription" });
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "Company",
        accessor: "name",
        Cell: ({ cell }) => {
          const account = cell.row.original;
          return (
            <Link
              onClick={() => {
                navigate(`/${account.id}/home`);
              }}
            >
              {account.name}
            </Link>
          );
        },
      },
      {
        Header: "Plan",
        accessor: "accountSubscription.pricePlan.description",
        Cell: ({ cell }) => {
          const pricePlan = cell.row.original.accountSubscription?.pricePlan;
          const nextInvoiceDate =
            cell.row.original.accountSubscription?.nextInvoiceDate;

          if (!pricePlan) {
            return (
              <Link
                onClick={() => {
                  setPartnerAccountToEdit(cell.row.original);
                  setShowPlanModal(true);
                }}
              >
                Set Enterprise Plan
              </Link>
            );
          }
          return (
            <Text>
              {pricePlan?.description}{" "}
              {nextInvoiceDate && (
                <Link
                  onClick={() => {
                    setPartnerAccountToCancel(cell.row.original);
                    setIsAreYouSureModalOpen(true);
                  }}
                >
                  cancel
                </Link>
              )}
              {!nextInvoiceDate && (
                <Link
                  onClick={() => {
                    setPartnerAccountToEdit(cell.row.original);
                    setShowPlanModal(true);
                  }}
                >
                  change
                </Link>
              )}
            </Text>
          );
        },
      },
      {
        Header: "Next Invoice",
        accessor: "accountSubscription.nextInvoiceDate",
        Cell: ({ cell }) => {
          const date = cell.row.original.accountSubscription?.nextInvoiceDate;
          if (!date) return null;
          return <FormattedDate value={date} />;
        },
      },
      {
        Header: "Trial End",
        accessor: "accountSubscription.trialEndDate",
        Cell: ({ cell }) => {
          const date = cell.row.original.accountSubscription?.trialEndDate;
          if (!date) return null;
          return <FormattedDate value={date} />;
        },
      },
    ],
    []
  );

  if (isErrorPartnerData || errorPartnerData) {
     navigate("/");
  }

  if (isLoadingPartnerData || isLoadingPartnerAccounts) {
    return <LoadingBox />;
  }

  return (
    <PageShell breadcrumbs={[]} menuItems={[]}>
      <SetPlanModal
        partnerAccount={partnerAccountToEdit}
        partnerData={partnerData}
        isOpen={showPlanModal}
        setIsOpen={setShowPlanModal}
      />
      <AreYouSureModal
        isOpen={isAreYouSureModalOpen}
        isDeleting={cancelSubscriptionMutation.isLoading}
        setIsOpen={setIsAreYouSureModalOpen}
        headerText={"Cancel Subscription?"}
        bodyText={`Are you sure you want to cancel the subscription for ${partnerAccountToCancel?.name}? Employees will lose access to The Best Answer at the end of the current billing period.`}
        actionFunction={async () => {
          await cancelSubscription({
            accountId: partnerAccountToCancel.id,
          });
        }}
        buttonText={"Cancel Subscription"}
      />
      <Box>
        <CustomTable
          selectionType={"link"}
          doUsePagination={true}
          columns={tableColumns}
          data={partnerAccounts}
          boxStyle={{
            bg: "white",
            borderRadius: "10px",
            border: "1px",
            borderColor: "gray.200",
            p: 4,
            overflow: "auto",
          }}
          tableStyle={{
            size: "md",
          }}
          initialState={{
            sortBy: [
              {
                id: "accountSubscription.pricePlan.description",
                desc: false,
              },
            ],
          }}
        />
      </Box>
    </PageShell>
  );
};

export default EnterprisePlansPage;
