import { useState } from "react";
import PageShell from "../../layout/PageShell";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import AllOkrs from "./AllOkrs";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";
import PersonalOkrs from "./PersonalOkrs";
import CompanyOkrs from "./CompanyOkrs";
import useTabNavigation from "../../hooks/useTabNavigation";
import { useSearchParams } from "react-router-dom";

const OkrsPage = () => {
  const { accountId } = useAccountId();
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const allTabs = [
    {
      name: "Company OKRs",
      content: CompanyOkrs, // component reference, not instance
    },
    {
      name: "My OKRs",
      content: PersonalOkrs, // component reference, not instance
    },
    {
      name: "All OKRs",
      content: AllOkrs, // component reference, not instance
    },
  ];
  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "OKRs" },
  ]);

  const { tabIndex, handleTabsChange } = useTabNavigation(allTabs, "OKRs");

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "OKRS",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
    >
      <InfoBox
        pageInfoType={"OKRS"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />
      <Tabs
        index={tabIndex}
        onChange={(e) => {
          // if (searchParams.has("objectiveId")) {
          //   searchParams.delete("objectiveId");
          //   setSearchParams(searchParams);
          // }
          handleTabsChange(e);
        }}
      >
        <TabList>
          {allTabs.map((tab) => (
            <Tab key={tab.name}>{tab.name}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {allTabs.map((tab, idx) => (
            <TabPanel key={tab.name}>
              {tabIndex === idx && <tab.content />}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </PageShell>
  );
};

export default OkrsPage;
