import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useDeleteApiKey } from "../../../../api/integrations/apiKeys";
import useAccountId from "../../../../hooks/customDomainHooks";
import useToastMessage from "../../../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { DeleteSomethingModal } from "../../../../components/modals/DeleteSomethingModal";
import { useState } from "react";

export const ApiKeyMenu = ({ apiKeyId }) => {
  const { showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const deleteApiKeyMutation = useDeleteApiKey();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const deleteApiKey = async () => {
    try {
      await deleteApiKeyMutation.mutateAsync({
        accountId,
        apiKeyId,
      });
      queryClient.invalidateQueries(["getApiKeys", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting API Key" });
    }
  };

  return (
    <Box>
      <DeleteSomethingModal
        isOpen={isDeleteModalOpen}
        isDeleting={deleteApiKeyMutation.isLoading}
        setIsOpen={setIsDeleteModalOpen}
        headerText={"Delete API Key"}
        bodyText={`Are you sure you want to delete this API Key?`}
        deleteFunction={async () => {
          await deleteApiKey();
        }}
        buttonText={"Delete API Key"}
      />
      <Menu>
        {({}) => (
          <>
            <MenuButton
              isLoading={deleteApiKeyMutation.isLoading}
              as={IconButton}
              variant={"ghost"}
              icon={<BiDotsVerticalRounded size={22} />}
            ></MenuButton>
            <MenuList>
              <MenuItem
                _hover={{ bg: "red.100" }}
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};
