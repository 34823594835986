import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const defaultColors = [
  "#FF6384",
  "#48BB78",
  "#FFCE56",
  "#FF9F40",
  "#36A2EB",
  "#4BC0C0",
  "#9966FF",
  "#FF3D67",
  "#70D4B4",
  "#B2912F",
  "#B778FF",
];

const HorizontalBarChart = ({
  data,
  colors,
  width = 350,
  height = 250,
  displayPercentages,
}) => {
  // Normalize data to reflect percentages if displayPercentages is true
  let formattedData = data?.map((item) => {
    if (displayPercentages) {
      const { label, ...rest } = item;
      const total = Object.values(rest).reduce((a, b) => a + b, 0);
      const normalizedData = Object.fromEntries(
        Object.entries(rest).map(([key, value]) => [
          key,
          total ? value / total : 0,
        ])
      );
      return {
        label,
        ...normalizedData,
      };
    } else {
      return item;
    }
  });

  if (!displayPercentages) {
    // Sort data from highest to lowest count
    formattedData = [...formattedData].sort((a, b) => b.value - a.value);
  }
  const [minDomain, maxDomain] = displayPercentages ? [0, 1] : [0, "auto"];

  // Define a formatter for Y-axis labels that truncates labels that are too long
  const labelFormatter = (label) => {
    if (label.length > 9) {
      return `${label.substring(0, 9)}...`;
    }
    return label;
  };

  const tooltipFormatter = (value) => {
    return displayPercentages ? `${(value * 100).toFixed(0)}%` : value;
  };

  // TODO Look for a function to get the width of a string given a font + font size
  // Calculate width for Y-axis based on the length of the longest label
  const yAxisWidth = Math.max(
    data?.reduce(
      (max, curr) => Math.max(max, Math.min(curr.label.length, 9)),
      0
    ) * 4,
    90
  );

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        // width={"100%"}
        height={"100%"}
        data={formattedData}
        layout="vertical"
        margin={{ top: 10, right: 40, left: 0, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          domain={[minDomain, maxDomain]}
          type="number"
          tickFormatter={(tick) =>
            displayPercentages ? `${(tick * 100).toFixed(0)}%` : tick
          }
          allowDecimals={displayPercentages ? true : false}
        />
        <YAxis
          dataKey="label"
          type="category"
          width={yAxisWidth}
          tickFormatter={labelFormatter}
          fontSize={12}
        />
        <Tooltip formatter={tooltipFormatter} isAnimationActive={false} />
        {Object.keys(formattedData[0] ? formattedData[0] : {})?.map(
          (key, index) => {
            if (key !== "label") {
              return (
                <Bar
                  key={key}
                  maxBarSize={40}
                  dataKey={key}
                  fill={
                    colors && colors[key]
                      ? colors[key]
                      : defaultColors[index % defaultColors.length]
                  } // Using color from the colors prop if defined, otherwise using a default color
                  stackId="a"
                />
              );
            }
            return null;
          }
        )}
        {/* Only show the legend if there is more than 1 value */}
        {Object.keys(formattedData[0] ? formattedData[0] : {}).length > 2 && (
          <Legend />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBarChart;
