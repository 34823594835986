import { useNavigate, useLocation } from "react-router-dom";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  updateProfile,
} from "firebase/auth";
import { useEffect, useState } from "react";
import api from "../../../api/config";

const auth = getAuth();

const EmailActionHandler = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [accountIdFromUrl, setAccountIdFromUrl] = useState(null);

  function extractEmailFromContinueUrl() {
    try {
      // Parse the main URL
      let mainUrl = new URL(window.location.href);

      // Extract the continueUrl parameter
      let continueUrlParam = mainUrl.searchParams.get("continueUrl");
      if (!continueUrlParam) {
        setEmail(searchParams.get("email")?.replace(" ", "+"));
        setName(searchParams.get("name"));
        setAccountIdFromUrl(searchParams.get("accountId"));
        return null;
      }

      // Decode the continueUrl parameter (since it's URL encoded)
      let decodedContinueUrl = decodeURIComponent(continueUrlParam);

      // Parse the continueUrl
      let continueUrl = new URL(decodedContinueUrl);

      // Extract the email parameter from the continueUrl
      setEmail(continueUrl.searchParams.get("email")?.replace(" ", "+"));
      setName(continueUrl.searchParams.get("name"));
    } catch (e) {
      console.error("Error while extracting email:", e);
      return null;
    }
  }

  useEffect(() => {
    extractEmailFromContinueUrl();
  }, []);

  const acceptInvite = () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      if (!email || !name) {
        return;
      }

      signInWithEmailLink(auth, email, window.location.href)
        .then(async (result) => {
          //1 update the firebase user's name if it's not already set
          await updateProfile(auth.currentUser, {
            displayName: result.user.displayName || name,
          });
          //2 create the user in the db
          await api.post("/users");
          const isNewUser =
            result.user.metadata.creationTime ===
            result.user.metadata.lastSignInTime;
          // If the user is new, then redirect to create password page
          if (isNewUser) {
            navigate("/_auth/create-password?email=" + email + "&name=" + name);
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("error", error);
          navigate(
            "/_auth/invite-expired?email=" +
              email +
              "&name=" +
              name +
              "&accountId=" +
              accountIdFromUrl
          );
        });
    }
  };

  useEffect(() => {
    if (!mode || !actionCode) {
      navigate("/");
      return;
    }
    switch (mode.trim()) {
      case "resetPassword":
        navigate("/_auth/reset-password-handler?oobCode=" + actionCode);
        break;
      case "verifyEmail":
        navigate("/_auth/verify-email-handler?oobCode=" + actionCode);
        break;
      case "recoverEmail":
        break;
      case "signIn":
        acceptInvite();
        break;
      case "revertSecondFactorAddition":
        navigate("/_auth/revert-second-factor-addition?oobCode=" + actionCode);
        break;
      default:
        console.log("invalid mode:", mode);
      // Error: invalid mode.
    }
  }, [mode, navigate, email, name, actionCode]);
};

export default EmailActionHandler;
