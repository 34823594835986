import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useFetchCultureHubLayout = (accountId) => {
  return useQuery({
    queryKey: ["fetchCultureHubLayout"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/cultureHubLayout`);
      return response.data;
    },
  });
};

export const useUpdateCultureHubLayout = (props) =>
  useMutation({
    mutationKey: ["updateCultureHubLayout"],
    mutationFn: async ({ accountId, cultureHubLayoutId, data }) => {
      const response = await api.put(
        `/${accountId}/cultureHubLayout/${cultureHubLayoutId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
