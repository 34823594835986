import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Tag,
  Text,
} from "@chakra-ui/react";
import CustomTable from "../../components/table/CustomTableV2";
import { useContext, useMemo, useState } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import { useFetchTeams } from "../../api/team";
import LoadingBox from "../../components/common/LoadingBox";
import { CreateTeamModal } from "./modals/CreateTeamModal";
import { IoAddOutline } from "react-icons/io5";

const Teams = () => {
  const { accountId } = useAccountId();
  const {
    data: teams,
    isLoading: isLoadingTeams,
    isError,
    error,
  } = useFetchTeams(accountId);
  const navigate = useCustomDomainNavigate();
  const [showArchived, setShowArchived] = useState(false);
  const { currentProfile } = useContext(AccountContext);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);

  const canAddTeam = useMemo(() => {
    if (!currentProfile) return false;
    if (["ADMIN", "OWNER", "PARTNER"].includes(currentProfile.role))
      return true;
    return false;
  }, [currentProfile]);

  const teamsColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell }) => {
          const team = cell.row.original;
          return (
            <>
              {team.name}
              {team.isArchived && (
                <Tag ml={4} colorScheme="red">
                  Archived
                </Tag>
              )}
            </>
          );
        },
      },
      {
        Header: "Members",
        accessor: (row) => row._count.members,
        Cell: ({ cell }) => {
          const numMembers = cell.row.original._count.members;
          const members = cell.row.original.members;
          return (
            <HStack>
              <Text w={"40px"} textAlign={"right"} pr={2}>
                {numMembers}
              </Text>
              <AvatarGroup
                size="md"
                max={4}
                // spacing={"-7px"}
              >
                {members.map((member) => {
                  return (
                    <Avatar
                      name={member.profile.name}
                      src={member.profile?.user?.photoUrl}
                      key={member.id}
                    />
                  );
                })}
              </AvatarGroup>
            </HStack>
          );
        },
      },
    ],
    [teams]
  );

  const onRowClick = (row) => {
    navigate(`/${accountId}/teams/${row.original.id}`);
  };

  const teamsToShow = useMemo(() => {
    return teams?.filter((team) => {
      if (showArchived) {
        return team.isArchived;
      } else {
        return !team.isArchived;
      }
    });
  }, [teams, showArchived]);

  return (
    <Box>
      <CreateTeamModal
        isOpen={showCreateTeamModal}
        setIsOpen={setShowCreateTeamModal}
      />
      {isLoadingTeams && <LoadingBox height={"200px"} />}
      {isError && <Text color={"red.500"}>Error: {error.message}</Text>}
      {teams && (
        <CustomTable
          columns={teamsColumns}
          data={teamsToShow}
          onRowClick={onRowClick}
          rightElement={
            <HStack spacing={4}>
              <FormControl
                display="flex"
                alignItems="center"
                gap={2}
                justifyContent={"flex-end"}
              >
                <Switch
                  isChecked={showArchived}
                  onChange={(e) => {
                    setShowArchived(e.target.checked);
                  }}
                  id="showArchived"
                ></Switch>
                <FormLabel htmlFor="showArchived" mb={0} fontWeight={400}>
                  Show Archived
                </FormLabel>
              </FormControl>
              {canAddTeam && (
                <Button
                  onClick={() => setShowCreateTeamModal(true)}
                  leftIcon={<IoAddOutline />}
                  minW={"130px"}
                >
                  Add Team
                </Button>
              )}
            </HStack>
          }
          doUsePagination={true}
          selectionType={"row"}
          boxStyle={{
            bg: "white",
            borderRadius: "10px",
            border: "1px",
            borderColor: "gray.200",
            p: 4,
            overflow: "auto",
          }}
          tableStyle={{
            size: "md",
          }}
          tableId={"viewTeamsTable"}
        />
      )}
    </Box>
  );
};

export default Teams;
