import {
  Box,
  Button,
  HStack,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../../hooks/useToastMessage";
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import { DeleteSomethingModal } from "../../../components/modals/DeleteSomethingModal";
import useAccountId from "../../../hooks/customDomainHooks";
import { SwitchItem } from "./Modules";
import {
  useCreateTerminationTag,
  useDeleteTerminationTag,
} from "../../../api/terminationTag/terminationTag";

export const EditTerminationTags = () => {
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const createTerminationTagMutation = useCreateTerminationTag();
  const removeTerminationTagMutation = useDeleteTerminationTag();
  const { accountData } = useContext(AccountContext);
  const [isDeleteTerminationTagModalOpen, setIsDeleteTerminationTagModalOpen] =
    useState(false);
  const [terminationTags, setTerminationTags] = useState(
    accountData?.terminationTags || []
  );
  const [newTerminationTag, setNewTerminationTag] = useState("");
  const [terminationTagToDelete, setTerminationTagToDelete] = useState(null);

  const removeTerminationTag = async () => {
    if (!terminationTagToDelete.id) return;
    try {
      await removeTerminationTagMutation.mutateAsync({
        accountId,
        valueTagId: terminationTagToDelete.id,
      });
      setTerminationTags(
        terminationTags.filter(
          (valueTag) => valueTag.id !== terminationTagToDelete.id
        )
      );
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      showErrorToast({ message: `Error removing tag` });
    }
  };

  const addTerminationTag = async () => {
    // Avoid duplicate tags
    if (terminationTags.find((e) => e.label === newTerminationTag)) {
      return;
    }
    try {
      await createTerminationTagMutation.mutateAsync({
        accountId,
        data: {
          label: newTerminationTag,
        },
      });
      setNewTerminationTag("");
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      showErrorToast({ message: `Error creating tag` });
    }
  };

  useEffect(() => {
    setTerminationTags(accountData?.terminationTags || []);
  }, [accountData]);

  return (
    <>
      <DeleteSomethingModal
        isOpen={isDeleteTerminationTagModalOpen}
        isDeleting={removeTerminationTagMutation.isLoading}
        setIsOpen={setIsDeleteTerminationTagModalOpen}
        headerText={"Delete Tag"}
        bodyText={`Are you sure you want to delete this termination reason? Removing a termination reason will remove it from any terminated employee that uses it. This means reporting metrics may be affected.`}
        deleteFunction={async () => {
          await removeTerminationTag();
        }}
        buttonText={"Delete Termination Reason"}
      />
      <Text>Termination Reasons</Text>
      <Wrap>
        {terminationTags.map((terminationTag) => (
          <WrapItem key={terminationTag.id}>
            <HStack spacing={4}>
              <Tag
                size={"lg"}
                borderRadius="full"
                variant="outline"
                colorScheme={"green"}
              >
                <TagLabel>{terminationTag?.label}</TagLabel>
                <TagCloseButton
                  onClick={() => {
                    setTerminationTagToDelete(terminationTag);
                    setIsDeleteTerminationTagModalOpen(true);
                  }}
                />
              </Tag>
            </HStack>
          </WrapItem>
        ))}
      </Wrap>
      <HStack>
        <Input
          placeholder={"Termination Reason"}
          onChange={(e) => {
            setNewTerminationTag(e.target.value);
          }}
          value={newTerminationTag}
        />
        <Button
          w={170}
          onClick={addTerminationTag}
          isDisabled={
            !newTerminationTag ||
            terminationTags.find((e) => e.label === newTerminationTag)
          }
          variant={"outline"}
        >
          Add reason
        </Button>
      </HStack>
    </>
  );
};
