import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Accordion, StackDivider, VStack } from "@chakra-ui/react";
import {
  DndContext,
  useSensor,
  PointerSensor,
  useSensors,
  DragOverlay,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { memo, useCallback, useEffect, useState } from "react";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { ObjectiveAccordion } from "./ObjectiveAccordion";
import { useUpdateObjectiveSet } from "../../../api/okr/objective";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../utils/localStorageHelper";

export const ObjectiveSet = ({
  objectiveSet,
  objectiveOwnerType,
  tabName,
  newlyCreatedObjectiveId,
  setNewlyCreatedObjectiveId,
  createObjective,
  expandAll,
}) => {
  const sensors = useSensors(useSensor(PointerSensor));
  const [objectives, setObjectives] = useState(objectiveSet?.objectives);
  const [objectiveIds, setObjectiveIds] = useState(
    objectiveSet?.objectives.map((obj) => obj.id)
  );
  const [activeId, setActiveId] = useState(null);
  const { accountId } = useAccountId();
  const updateObjectiveSetMutation = useUpdateObjectiveSet();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const objectiveIdQueryParam = searchParams.get("objectiveId");

  const getInitialObjectiveSetAccordionIds = () => {
    // Check local storage for owners to filter for this tab
    // If null for this tab, use an empty array
    const localStorageObjectiveSetAccordion = getLocalStorageItem(
      `OKR.${tabName}.${objectiveSet.id}.ObjectiveSetAccordionIds` // TODO change all to a variable
    );
    if (localStorageObjectiveSetAccordion) {
      return localStorageObjectiveSetAccordion;
    }
    return [];
  };

  const [objectiveSetAccordionIds, setObjectiveSetAccordionIds] = useState(
    getInitialObjectiveSetAccordionIds()
  );
  const [objectiveSetAccordionIndexes, setObjectiveSetAccordionIndexes] =
    useState([]);

  const updateObjective = async (data) => {
    try {
      await updateObjectiveSetMutation.mutateAsync({
        accountId,
        objectiveSetId: objectiveSet.id,
        data,
      });
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating Objectives" });
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id && over?.id) {
      const activeIndex = objectiveIds.indexOf(active.id);
      const overIndex = objectiveIds.indexOf(over.id);
      if (activeIndex !== overIndex) {
        const newObjectives = [...objectives];

        // Use splice to remove and insert objective in the correct position
        const [movedObjective] = newObjectives.splice(activeIndex, 1);
        newObjectives.splice(overIndex, 0, movedObjective);

        setObjectives(newObjectives);
        updateObjective({ displayOrder: newObjectives.map((obj) => obj.id) });
      }
    }

    setActiveId(null);
  };

  const DragPreview = ({ item }) => {
    return (
      <Accordion allowToggle w={"100%"} index={[-1]}>
        <ObjectiveAccordion
          objective={item}
          index={null}
          objectiveOwnerType={"PROFILE"}
          accordionIndex={[-1]}
        />
      </Accordion>
    );
  };

  useEffect(() => {
    setObjectives(objectiveSet?.objectives);
  }, [objectiveSet]);

  const focusOnObjectiveTitle = (objectiveId) => {
    const inputElement = document.getElementById(
      `objective-title-${objectiveId}`
    );
    if (inputElement) {
      inputElement.focus();
    }
  };

  // Open the accordion of the newly created objective
  useEffect(() => {
    if (newlyCreatedObjectiveId) {
      setObjectiveSetAccordionIds([
        ...objectiveSetAccordionIds,
        newlyCreatedObjectiveId,
      ]);
      // focus on the title of the newly created objective
      focusOnObjectiveTitle(newlyCreatedObjectiveId);
      setNewlyCreatedObjectiveId(null);
    }
  }, [newlyCreatedObjectiveId, objectives]);

  // Open the accordion of the objectiveId in the url
  useEffect(() => {
    if (objectiveIdQueryParam && objectiveIds.includes(objectiveIdQueryParam)) {
      if (objectiveSetAccordionIds.includes(objectiveIdQueryParam)) {
        return;
      }
      setObjectiveSetAccordionIds([objectiveIdQueryParam]);
      setNewlyCreatedObjectiveId(null);
    }
  }, [objectiveIdQueryParam, objectives]);

  // When expanded objectives change, (objectiveSetAccordionIds) update the objectiveSetAccordionIndexes
  useEffect(() => {
    // save in local storage
    setLocalStorageItem(
      `OKR.${tabName}.${objectiveSet.id}.ObjectiveSetAccordionIds`,
      objectiveSetAccordionIds
    );

    if (!objectiveSetAccordionIds) {
      setObjectiveSetAccordionIndexes([]);
      return;
    }

    // Turn list of ids into list of indices
    const newIndices = objectiveSetAccordionIds.map((id) => {
      return objectives.findIndex((obj) => obj.id === id);
    });

    setObjectiveSetAccordionIndexes(newIndices);
  }, [objectiveSetAccordionIds, objectives]);

  // when expandAll changes, handle expanding or collapsing all objectives
  useEffect(() => {
    if (expandAll === true) {
      // expand all objectives
      setObjectiveSetAccordionIds(objectiveIds);
    } else if (expandAll === false) {
      // collapse all objectives
      setObjectiveSetAccordionIds([]);
    }
  }, [expandAll]);

  useEffect(() => {
    setObjectiveIds(objectives.map((obj) => obj.id));
  }, [objectives]);

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
    >
      <Accordion allowToggle w={"100%"} index={objectiveSetAccordionIndexes}>
        <SortableContext
          items={objectiveIds}
          strategy={verticalListSortingStrategy}
        >
          <VStack
            alignItems={"flex-start"}
            spacing={0}
            w={"100%"}
            divider={<StackDivider />}
          >
            {objectives?.map((objective, index) => (
              <div
                key={objective.id}
                style={{
                  visibility: activeId === objective.id ? "hidden" : "visible",
                  width: "100%",
                }}
              >
                <SortableObjectiveAccordion
                  key={objective.id}
                  index={index}
                  objective={objective}
                  objectiveOwnerType={objectiveOwnerType}
                  handleAccordionChange={(objectiveId) => {
                    // add objective id to objectiveSetAccordionIds if not already in array
                    setObjectiveSetAccordionIds(
                      objectiveSetAccordionIds.includes(objectiveId)
                        ? objectiveSetAccordionIds.filter(
                            (i) => i !== objectiveId
                          )
                        : [...objectiveSetAccordionIds, objectiveId]
                    );
                  }}
                  setObjectiveSetAccordionIds={setObjectiveSetAccordionIds}
                  createObjective={(timePeriod, ownerType, index) => {
                    // updateObjectiveSetAccordionIndex([index]);
                    createObjective(timePeriod, ownerType, index);
                  }}
                  setNewlyCreatedObjectiveId={setNewlyCreatedObjectiveId}
                />
              </div>
            ))}
          </VStack>
        </SortableContext>
      </Accordion>
      <DragOverlay>
        {activeId ? (
          <DragPreview item={objectives.find((kr) => kr.id === activeId)} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export const SortableObjectiveAccordion = memo(
  ({
    objective,
    index,
    objectiveOwnerType,
    setObjectiveSetAccordionIds,
    handleAccordionChange,
    createObjective,
    setNewlyCreatedObjectiveId,
  }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: objective.id });

    const handleDrag = useCallback(() => {
      if (isDragging) {
        setObjectiveSetAccordionIds([]);
      }
    }, [isDragging]);

    useEffect(handleDrag, [isDragging]);

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style}>
        <ObjectiveAccordion
          objective={objective}
          index={index}
          objectiveOwnerType={objectiveOwnerType}
          key={objective.id}
          dragAttributes={attributes}
          dragListeners={listeners}
          handleAccordionChange={handleAccordionChange}
          createObjective={createObjective}
          setNewlyCreatedObjectiveId={setNewlyCreatedObjectiveId}
        />
      </div>
    );
  }
);
