import {
  TagLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tag,
  Box,
} from "@chakra-ui/react";

export const FilterOneSelect = ({ options, value, onChange, buttonProps }) => {
  if (!options || !options.length) return null;
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Box}
            px={0}
            py={0}
            cursor="pointer"
            w={"110px"}
            {...buttonProps}
          >
            <Tag
              borderRadius="full"
              variant="outline"
              colorScheme="blue"
              bg="#4285F414"
            >
              <TagLabel>
                {options.find((option) => option.value === value)?.label}
              </TagLabel>
            </Tag>
          </MenuButton>
          <MenuList shadow="lg" color="gray.600" py="4" px="3">
            {options.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => onChange(option.value)}
                bg={option.value === value && "gray.200"}
                _hover={{ bg: "gray.100" }}
                rounded={"md"}
              >
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
