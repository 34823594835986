import { useQuery } from "@tanstack/react-query";
import api from "../config";

export const useFetchInvoice = (accountId, invoiceId) => {
  return useQuery({
    queryKey: ["fetchInvoice", accountId, invoiceId],
    enabled: !!invoiceId,
    queryFn: async () => {
      const response = await api.get(`/${accountId}/invoice/${invoiceId}`);
      return response.data;
    },
  });
};

export const useFetchInvoices = (accountId) => {
  return useQuery({
    queryKey: ["fetchInvoices", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/invoice`);
      return response.data;
    },
  });
};
