import { Stack, VStack } from "@chakra-ui/react";
import { InputField } from "../../../../components/fields/InputField";
import LayoutTypeSelector from "../LayoutTypeSelector";

const HeaderLayout = ({ question, questionIndex, onChange }) => {
  return (
    <VStack w={"100%"} spacing={6}>
      <Stack
        w={"100%"}
        gap={4}
        spacing={0}
        flexDirection={{ base: "column", md: "row" }}
      >
        <InputField
          field={{
            id: `prompt`,
            placeholder: "Add a heading",
            validation: {
              required: { value: true, message: "Header is required" },
            },
          }}
          inputStyle={{
            size: "lg",
            fontWeight: "semibold",
            variant: "flushed",
          }}
        />
        <LayoutTypeSelector questionIndex={questionIndex} onChange={onChange} />
      </Stack>
    </VStack>
  );
};

export default HeaderLayout;
