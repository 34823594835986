import { Box, Center, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { createRef, useRef, useState } from "react";
import { BsIncognito } from "react-icons/bs";
import SurveyQuestion from "../TakeSurvey/SurveyQuestion";
import WhiteBox from "../../../components/common/WhiteBox";
import SurveyLayout from "../TakeSurvey/SurveyLayout";
import { MessageBox } from "../../../components/common/MessageBox";

const PreviewSurvey = ({ survey }) => {
  const [formErrors, setFormErrors] = useState({});
  // Create a refs array for each SurveyQuestion
  const questionRefs = useRef(survey.questions.map(() => createRef()));
  let questionIndex = 0;

  return (
    <Center w={"100%"}>
      <Box maxW={"1000px"} w={"100%"}>
        <VStack alignItems={"flex-start"} spacing={4}>
          <MessageBox
            message={"This is how the survey will appear to respondents."}
          />
          <WhiteBox>
            <VStack>
              <Text fontWeight={"bold"} fontSize={"xl"}>
                {survey.title}
              </Text>
              {survey.isAnonymous && (
                <HStack>
                  <Text color={"textSecondary"} fontSize={"xs"}>
                    Responses are anonymous
                  </Text>
                  <Icon as={BsIncognito} color={"textSecondary"} />
                </HStack>
              )}
              <Text>{survey.overview}</Text>
            </VStack>
          </WhiteBox>
          {survey.questions.map((question) => {
            if (!["HEADER", "PARAGRAPH"].includes(question.element.type)) {
              const currentQuestionIndex = questionIndex;
              questionIndex++;
              return (
                <Box key={question.id} w={"100%"}>
                  <SurveyQuestion
                    question={question}
                    questionIndex={currentQuestionIndex}
                    isReadOnly={true}
                    response={{}}
                    key={question.id}
                    surveyRunResponseId={undefined}
                    surveyRunId={survey.id}
                    setFormErrors={setFormErrors}
                    ref={questionRefs.current[currentQuestionIndex]}
                  />
                </Box>
              );
            } else {
              return (
                <Box key={question.id} w={"100%"}>
                  <SurveyLayout question={question} />
                </Box>
              );
            }
          })}

          {/* {survey.questions.map((question, index) => {
            <Box key={question.id} w={"100%"}>
              {!["HEADER", "PARAGRAPH"].includes(question.element.type) ? (
                <SurveyQuestion
                  question={question}
                  questionIndex={index}
                  isReadOnly={true}
                  response={{}}
                  key={question.id}
                  surveyRunResponseId={undefined}
                  surveyRunId={survey.id}
                  setFormErrors={setFormErrors}
                  ref={questionRefs.current[index]} // Pass the ref to each SurveyQuestion
                />
              ) : (
                <SurveyLayout question={question} />
              )}
            </Box>;
          })} */}
        </VStack>
      </Box>
    </Center>
  );
};

export default PreviewSurvey;
