import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateAdminWidget = (props) =>
  useMutation({
    mutationKey: ["createAdminWidget"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/adminWidget`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteAdminWidget = (props) =>
  useMutation({
    mutationKey: ["deleteAdminWidget"],
    mutationFn: async ({ accountId, adminWidgetId }) => {
      const response = await api.delete(
        `/${accountId}/adminWidget/${adminWidgetId}`
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateAdminWidget = (props) =>
  useMutation({
    mutationKey: ["updateAdminWidget"],
    mutationFn: async ({ accountId, adminWidgetId, data }) => {
      const response = await api.put(
        `/${accountId}/adminWidget/${adminWidgetId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
