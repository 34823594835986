import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateEvent = (props) =>
  useMutation({
    mutationKey: ["createEvent"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/event`, data);
      return response.data;
    },
    ...props,
  });

export const useCloneEvent = (props) =>
  useMutation({
    mutationKey: ["cloneEvent"],
    mutationFn: async ({ accountId, eventId }) => {
      const response = await api.post(`/${accountId}/event/${eventId}/clone`);
      return response.data;
    },
    ...props,
  });

export const useUpdateEvent = (props) =>
  useMutation({
    mutationKey: ["updateEvent"],
    mutationFn: async ({ accountId, eventId, data }) => {
      const response = await api.put(`/${accountId}/event/${eventId}`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteEvent = (props) =>
  useMutation({
    mutationKey: ["deleteEvent"],
    mutationFn: async ({ accountId, eventId }) => {
      const response = await api.delete(`/${accountId}/event/${eventId}`);
      return response.data;
    },
    ...props,
  });

export const useFetchUpcomingEvents = (accountId) => {
  return useQuery({
    queryKey: ["fetchUpcomingEvents"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/event/upcoming`);
      return response.data;
    },
  });
};

export const useFetchPastEvents = (accountId) => {
  return useQuery({
    queryKey: ["fetchPastEvents"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/event/past`);
      return response.data;
    },
  });
};

export const useFetchMyEvents = (accountId) => {
  return useQuery({
    queryKey: ["fetchMyEvents"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/event/signedup`);
      return response.data;
    },
  });
};

export const useFetchEvent = (accountId, eventId) => {
  return useQuery({
    queryKey: ["fetchEvent", eventId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/event/${eventId}`);
      return response.data;
    },
  });
};
