import {
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { MdCalendarMonth, MdLocationOn } from "react-icons/md";
import { FormattedDate, FormattedTime } from "react-intl";
import EventMenu from "./EventMenu";
import { useContext } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import { getEventImage } from "./getEventImage";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";

const CardBadge = (props) => {
  const { children, ...flexProps } = props;
  return (
    <Flex
      bg={"green.600"}
      position="absolute"
      right={-20}
      top={6}
      width="240px"
      transform="rotate(45deg)"
      py={2}
      justifyContent="center"
      alignItems="center"
      {...flexProps}
    >
      <Text
        fontSize="xs"
        textTransform="uppercase"
        fontWeight="bold"
        letterSpacing="wider"
        color={"white"}
      >
        {children}
      </Text>
    </Flex>
  );
};

const EventCard = ({ event, eventMenuItems }) => {
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();
  const { currentProfile } = useContext(AccountContext);

  const mySignup = event.signups.find(
    (signup) => signup.profileId === currentProfile.id
  );

  return (
    <>
      <Card maxW="sm" w={"100%"} overflow="hidden" position="relative">
        <CardBody w={"100%"}>
          <Stack spacing="4">
            {mySignup && <CardBadge>Signed Up</CardBadge>}
            <Image
              src={event.imageUrl ? event.imageUrl : getEventImage(event.id)}
              alt="Event Image"
              borderRadius="lg"
              maxW={"700px"}
              w={"100%"}
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/${accountId}/events/${event.id}/view`);
              }}
            />
            <HStack justifyContent={"space-between"} h={"100%"} w={"100%"}>
              <Heading
                size="md"
                onClick={() => {
                  navigate(`/${accountId}/events/${event.id}/view`);
                }}
                _hover={{ cursor: "pointer" }}
              >
                {event.name}
              </Heading>
              <HStack>
                {!event.isPublished && (
                  <Tag colorScheme="gray" variant={"outline"}>
                    DRAFT
                  </Tag>
                )}
                <EventMenu event={event} eventMenuItems={eventMenuItems} />
              </HStack>
            </HStack>
            <HStack>
              <Icon as={MdCalendarMonth} fontSize={"xl"} />
              <Text color={"green.600"} fontWeight={"semibold"}>
                {event.startTime ? (
                  <>
                    <FormattedDate
                      value={event.startTime}
                      year="numeric"
                      month="short"
                      day="2-digit"
                    />
                    {` `}
                    <FormattedTime
                      value={event.startTime}
                      timeZoneName="short"
                    />
                  </>
                ) : (
                  "TBD"
                )}
              </Text>
            </HStack>
            <HStack>
              <Icon as={MdLocationOn} fontSize={"xl"} />
              <Text color={"textSecondary"}>
                {event.location ? event.location : "TBD"}
              </Text>
            </HStack>
          </Stack>
        </CardBody>
      </Card>
    </>
  );
};

export default EventCard;
