import {
  useCreateRankingPeriod,
  useFetchAbcRankingPeriods,
  useUpdateAbcRankingPeriod,
} from "../../api/abcRankings/abcRankingPeriod";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { IoAddOutline } from "react-icons/io5";
import { useContext, useEffect, useMemo, useState } from "react";
import { DateField } from "../../components/fields/DateField";
import useToastMessage from "../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import LoadingBox from "../../components/common/LoadingBox";
import { SwitchField } from "../../components/fields/SwitchField";
import { CreateRankingPeriodModal } from "./modals/CreateRankingPeriodModal";
import useAccountId from "../../hooks/customDomainHooks";
import CustomTable from "../../components/table/CustomTableV2";
import { AccountContext } from "../../context/AccountContextComponent";

const AbcRankingPeriods = (props) => {
  const { accountId } = useAccountId();
  const [isCreateRankingPeriodModalOpen, setIsCreateRankingPeriodModalOpen] =
    useState(false);
  const { currentTimePeriod, nextTimePeriod } = useContext(AccountContext);
  const {
    data: rankingPeriods,
    isLoading: isLoadingAbcRankingPeriods,
    isError: isErrorAbcRankingPeriods,
    error: errorAbcRankingPeriods,
  } = useFetchAbcRankingPeriods(accountId);
  const [accordionIndex, setAccordionIndex] = useState([-1]);
  const createRankingPeriodMutation = useCreateRankingPeriod();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  const findRankingPeriod = (rankingPeriods, timePeriodId) => {
    return rankingPeriods?.find((period) => period.period.id === timePeriodId);
  };

  const createRankingPeriod = async (data) => {
    try {
      const respData = await createRankingPeriodMutation.mutateAsync({
        accountId,
        data,
      });
      queryClient.invalidateQueries(["fetchAbcRankingPeriods"]);
      queryClient.invalidateQueries(["fetchAbcRankingReports"]);
      showSuccessToast(
        "Team Alignment Period Created",
        "The Team Alignment Period has been successfully created."
      );
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating Team Alignment Period." });
    }
  };

  const renderCreateButton = (timePeriod) => {
    return (
      <Button
        onClick={() =>
          createRankingPeriod({
            isOpen: true,
            timePeriodId: timePeriod.id,
            dueDate: timePeriod.endDate,
          })
        }
        isLoading={createRankingPeriodMutation.isLoading}
      >
        Create Report Period
      </Button>
    );
  };

  const currentRankingPeriod = findRankingPeriod(
    rankingPeriods,
    currentTimePeriod.id
  );
  const nextRankingPeriod = findRankingPeriod(
    rankingPeriods,
    nextTimePeriod.id
  );

  const addPeriodPlaceholder = (timePeriod, dataList) => {
    dataList.push({
      period: {
        label: timePeriod.label,
        id: timePeriod.id,
        startDate: timePeriod.startDate,
      },
      dueDate: timePeriod.endDate,
      isDisabled: true,
      createButton: renderCreateButton(timePeriod),
    });
  };

  const collapsedViewData = [];
  const expandedViewData = [...(rankingPeriods || [])];

  if (currentRankingPeriod) {
    collapsedViewData.push(currentRankingPeriod);
  } else {
    addPeriodPlaceholder(currentTimePeriod, collapsedViewData);
    addPeriodPlaceholder(currentTimePeriod, expandedViewData);
  }
  if (nextTimePeriod.id !== currentTimePeriod.id) {
    if (nextRankingPeriod) {
      collapsedViewData.push(nextRankingPeriod);
    } else {
      addPeriodPlaceholder(nextTimePeriod, collapsedViewData);
      addPeriodPlaceholder(nextTimePeriod, expandedViewData);
    }
  }

  const rankingPeriodsColumns = useMemo(
    () => [
      {
        Header: "Period",
        accessor: "period.label",
        Cell: ({ row }) => {
          return <Text>{row.original.period.label}</Text>;
        },
      },
      {
        Header: "Due",
        accessor: "dueDate",
        Cell: ({ row }) => {
          if (row.original.isDisabled) {
            return <Box h={"57px"}></Box>;
          }
          return (
            <RankingPeriodForm rankingPeriod={row.original}>
              {(submitForm) => <RankingPeriodDueDate submitForm={submitForm} />}
            </RankingPeriodForm>
          );
        },
      },
      {
        Header: "Open",
        accessor: "isOpen",
        Cell: ({ row }) => {
          return (
            <RankingPeriodForm rankingPeriod={row.original}>
              {(submitForm) => (
                <RankingPeriodOpenSwitch
                  submitForm={submitForm}
                  isDisabled={row.original.isDisabled}
                />
              )}
            </RankingPeriodForm>
          );
        },
      },
      {
        Header: "Action",
        accessor: "createButton",
        Cell: ({ value }) => value || null, // Render the button from the row data, if it exists.
      },
    ],
    [rankingPeriods]
  );

  const handleModalClose = (wasPeriodCreated) => {
    setIsCreateRankingPeriodModalOpen(false);
    if (wasPeriodCreated) setAccordionIndex([0]); // If a new period was created, expand the accordion
  };

  // Sort the rankingPeriods by closest rankingPeriod.period.startData

  const sortedRankingPeriods = useMemo(() => {
    const today = new Date();
    if (!expandedViewData) return;

    // Sorting rankingPeriods based on the closeness of period.startDate to today
    const sortedData = [...expandedViewData].sort((a, b) => {
      const dateA = new Date(a.period.startDate);
      const dateB = new Date(b.period.startDate);

      // Calculating absolute difference between startDate and today for each element
      const diffA = Math.abs(today - dateA);
      const diffB = Math.abs(today - dateB);

      // Sorting based on the absolute difference
      return diffA - diffB;
    });

    return sortedData;
  }, [expandedViewData]);

  if (isLoadingAbcRankingPeriods) {
    return <LoadingBox />;
  }

  return (
    <>
      <CreateRankingPeriodModal
        isOpen={isCreateRankingPeriodModalOpen}
        handleClose={handleModalClose}
        usedTimePeriods={rankingPeriods.map(
          (rankingPeriod) => rankingPeriod?.period?.id
        )}
      />
      <Box
        bg={"white"}
        borderRadius={"md"}
        border={"1px"}
        borderColor={"gray.200"}
        overflow={"auto"}
        maxW={"1200px"}
      >
        <Accordion
          allowToggle
          index={accordionIndex}
          key={accordionIndex}
          // maxW={"1180px"}
        >
          <AccordionItem border={"0px"}>
            {({ isExpanded }) => {
              return (
                <>
                  <AccordionButton h={0} p={0} />
                  <HStack justifyContent={"space-between"} px={4} py={1}>
                    <Text fontSize={"lg"} fontWeight={700}>
                      Manage Reporting Periods
                    </Text>
                    <Button
                      my={2}
                      size={"sm"}
                      onClick={() => {
                        setIsCreateRankingPeriodModalOpen(true);
                      }}
                      leftIcon={<IoAddOutline />}
                    >
                      New Period
                    </Button>
                  </HStack>
                  <>
                    {!isExpanded && (
                      <Box px={4} maxW={"1180px"}>
                        <CustomTable
                          columns={rankingPeriodsColumns}
                          data={collapsedViewData} 
                          initialState={{
                            sortBy: [],
                          }}
                          doUsePagination={false}
                          showSearchBar={false}
                        />
                      </Box>
                    )}
                    <AccordionPanel>
                      <Box maxW={"1180px"}>
                        <CustomTable
                          columns={rankingPeriodsColumns}
                          data={sortedRankingPeriods}
                          initialState={{
                            sortBy: [
                              {
                                id: "dueDate",
                                desc: true,
                              },
                            ],
                          }}
                          doUsePagination
                        />
                      </Box>
                    </AccordionPanel>
                    {sortedRankingPeriods && sortedRankingPeriods.length > 1 ? (
                      <Box
                        flex="1"
                        textAlign="center"
                        _hover={{ bg: "gray.100" }}
                        p={2}
                        cursor={"pointer"}
                        onClick={() => {
                          setAccordionIndex(isExpanded ? [-1] : [0]);
                        }}
                      >
                        <HStack
                          w={"100%"}
                          justifyContent={"center"}
                          cursor={"pointer"}
                        >
                          <Text>{isExpanded ? "Hide" : "View more"}</Text>
                          <AccordionIcon />
                        </HStack>
                      </Box>
                    ) : (
                      <Box p={4} />
                    )}
                  </>
                </>
              );
            }}
          </AccordionItem>
        </Accordion>
      </Box>
    </>
  );
};

const RankingPeriodForm = ({ rankingPeriod, children }) => {
  const updateRankingPeriodMutation = useUpdateAbcRankingPeriod();
  const { showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const form = useForm({
    mode: "onChange",
    defaultValues: rankingPeriod,
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = form;

  useEffect(() => {
    if (rankingPeriod && !isDirty) {
      reset(rankingPeriod);
    }
  }, [rankingPeriod, reset]);

  const updateAbcRankingPeriod = async (data) => {
    try {
      await updateRankingPeriodMutation.mutateAsync({
        accountId,
        rankingPeriodId: rankingPeriod.id,
        data,
      });
      queryClient.invalidateQueries(["fetchAbcRankingPeriods"]);
    } catch (error) {
      console.log(error);
      reset(rankingPeriod);
      showErrorToast({ message: "Error updating Team Alignment Period" });
    }
  };

  // Only submit if there are no errors
  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateAbcRankingPeriod)(event);
    } else {
      console.log("errors", errors);
    }
  };

  return (
    <>
      <FormProvider {...form} key={rankingPeriod.id}>
        <form>
          <HStack py={2}>
            {children(submitForm)}

            {isSubmitting ? (
              <Spinner size="sm" mr={2} alignSelf={"center"} />
            ) : (
              <Box mr={2} w={"16px"} />
            )}
          </HStack>
        </form>
      </FormProvider>
    </>
  );
};

const RankingPeriodDueDate = ({ submitForm }) => {
  return (
    <Box maxW={"200px"}>
      <DateField
        field={{
          id: `dueDate`,
          validation: { required: true },
        }}
        onDateChange={submitForm}
      />
    </Box>
  );
};

const RankingPeriodOpenSwitch = ({ submitForm, isDisabled }) => {
  return (
    <Box maxW={"200px"}>
      <SwitchField
        field={{
          id: `isOpen`,
          validation: { required: true },
          isDisabled: isDisabled,
        }}
        onSwitchChange={submitForm}
      />
    </Box>
  );
};

export default AbcRankingPeriods;
