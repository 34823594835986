import { Box, Link, Text } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import CustomTable from "../../components/table/CustomTableV2";
import { AvatarWithName } from "../../components/common/AvatarWithName";
import { useFetchMyTeam } from "../../api/team";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import LoadingBox from "../../components/common/LoadingBox";
import { FormattedDate } from "react-intl";
import { UpdateOneOnOneScheduleModal } from "./modals/UpdateOneOnOneScheduleModal";
import { CreateOneOnOneScheduleModal } from "./modals/CreateOneOnOneScheduleModal";
import InviteeOneOnOnes from "./InviteeOneOnOnes";
import CreateOneOnOneButton from "./Buttons/CreateOneOnOneButton";
import { RRule } from "rrule";
import { OneOnOneCalendarLinks } from "./OneOnOneCalendarLinks";

const ViewMyTeam = () => {
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();
  const [showEditModal, setShowEditModal] = useState(false);
  const [meetingData, setMeetingData] = useState(null);
  const [showCreateScheduleModal, setShowCreateScheduleModal] = useState(false);

  const {
    data: myTeamMembers,
    isLoading: isLoadingMyTeam,
    isError,
    error,
  } = useFetchMyTeam(accountId);

  const myTeamColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell }) => {
          const member = cell.row.original;
          return <AvatarWithName profile={member} />;
        },
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Frequency",
        accessor: "recurrence",
        Cell: ({ cell }) => {
          const oneOnOneSchedules = cell.row.original.oneOnOneInvitedSchedules;
          const allRecurrences = oneOnOneSchedules
            .map((schedule) => {
              if (!schedule.recurrence || schedule.isArchived) return "";
              const rule = RRule.fromString(schedule.recurrence.rrule);
              return (
                rule.toText().charAt(0).toUpperCase() + rule.toText().slice(1)
              );
            })
            .filter((recurrence) => recurrence !== "");

          if (!allRecurrences || allRecurrences.length === 0) return <></>;
          return <>{allRecurrences.join(", ")}</>;
        },
      },
      {
        Header: "Add to Calendar",
        accessor: "id",
        Cell: ({ cell }) => {
          const oneOnOneSchedules = cell.row.original.oneOnOneInvitedSchedules;
          if (!oneOnOneSchedules || oneOnOneSchedules.length === 0)
            return <></>;
          const oneOnOneSchedule = oneOnOneSchedules[0];
          const startDate =
            cell.row.original.nextMeeting?.startDate ||
            oneOnOneSchedule.startDate;
          return (
            <OneOnOneCalendarLinks
              oneOnOneSchedule={oneOnOneSchedule}
              startDate={startDate}
            />
          );
        },
      },
      {
        Header: "Next 1-on-1",
        accessor: "nextMeeting",
        Cell: ({ cell }) => {
          const nextMeetingDate = cell.row.original.nextMeeting?.startDate;
          return (
            <>
              {nextMeetingDate ? (
                <Link
                  fontSize="sm"
                  onClick={() =>
                    navigate(
                      `/${accountId}/one-on-ones/${cell.row.original.nextMeeting?.id}`
                    )
                  }
                >
                  <FormattedDate
                    value={nextMeetingDate}
                    year="numeric"
                    month="short"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                    weekday="long"
                  />
                </Link>
              ) : (
                <Text fontSize="sm">---</Text>
              )}
            </>
          );
        },
      },
      {
        Header: "Most Recent 1-on-1",
        accessor: "previousMeeting",
        Cell: ({ cell }) => {
          const previousMeetingDate =
            cell.row.original.previousMeeting?.startDate;
          return (
            <>
              {previousMeetingDate ? (
                <Link
                  fontSize="sm"
                  onClick={() =>
                    navigate(
                      `/${accountId}/one-on-ones/${cell.row.original.previousMeeting?.id}`
                    )
                  }
                >
                  <FormattedDate
                    value={previousMeetingDate}
                    year="numeric"
                    month="short"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                    weekday="long"
                  />
                </Link>
              ) : (
                <Text fontSize="sm">---</Text>
              )}
            </>
          );
        },
      },
      // {
      //   Header: "",
      //   accessor: "id",
      //   Cell: ({ cell }) => {
      //     const nextMeetingDate = cell.row.original.nextMeeting?.startDate;

      //     return (
      //       <>
      //         {!nextMeetingDate && (
      //           <Link
      //             onClick={() => {
      //               setSelectedProfileId(cell.row.original.id);
      //               setShowCreateScheduleModal(true);
      //             }}
      //           >
      //             schedule
      //           </Link>
      //         )}
      //       </>
      //     );
      //   },
      // },
    ],
    [myTeamMembers]
  );

  if (isLoadingMyTeam) {
    return <LoadingBox />;
  }

  return (
    <Box maxW={1300}>
      <UpdateOneOnOneScheduleModal
        isOpen={showEditModal}
        setIsOpen={setShowEditModal}
        meetingData={meetingData}
      />
      <CreateOneOnOneScheduleModal
        isOpen={showCreateScheduleModal}
        setIsOpen={setShowCreateScheduleModal}
      />
      <Text fontSize={"2xl"} fontWeight={"bold"}>
        My Team
      </Text>
      <CustomTable
        selectionType={"row"}
        doUsePagination={true}
        columns={myTeamColumns}
        data={myTeamMembers}
        boxStyle={{
          bg: "white",
          borderRadius: "10px",
          border: "1px",
          borderColor: "gray.200",
          p: 4,
          overflow: "auto",
        }}
        tableStyle={{
          size: "sm",
        }}
        tableId={"viewMyTeamTable"}
        rightElement={<CreateOneOnOneButton />}
        renderRowSubComponent={(row) => {
          return (
            <Box p={4}>
              <InviteeOneOnOnes
                inviteeId={row.original.id}
                inviteeName={row.original.name}
              />
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default ViewMyTeam;
