import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Text,
  VStack,
  VisuallyHidden,
} from "@chakra-ui/react";
import { MicrosoftIcon, GoogleIcon } from "./ProviderIcons";
import { useState } from "react";

const providers = [
  { name: "Google", authType: "google", icon: <GoogleIcon boxSize="5" /> },
  {
    name: "Microsoft",
    authType: "microsoft",
    icon: <MicrosoftIcon boxSize="5" />,
  },
  // { name: "Twitter", icon: <TwitterIcon boxSize="5" /> },
  // { name: "GitHub", icon: <GitHubIcon boxSize="5" /> },
];

export const OAuthButtonGroup = ({ login }) => (
  <VStack>
    {providers.map(({ name, icon, authType }) => (
      <AuthButton
        key={name}
        name={name}
        icon={icon}
        authType={authType}
        login={login}
      />
    ))}
  </VStack>
);

const AuthButton = ({ name, icon, authType, login }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Button
      key={name}
      width="full"
      onClick={async () => {
        setIsLoading(true);
        await login(authType);
        setIsLoading(false);
      }}
      isLoading={isLoading}
      variant={"outline"}
      borderColor={"#D3DBE6"}
      bg={"#ECF2FA80"}
    >
      <VisuallyHidden>Sign in with {name}</VisuallyHidden>
      <HStack spacing={2}>
        <Box width="20px">{icon}</Box>
        <Text width="150px">Continue with {name}</Text>
      </HStack>
    </Button>
  );
};
