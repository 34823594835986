import { VStack } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import { DualTables } from "../../../components/table/DualTables";

const EditPlanningTeam = ({ event, setRightRowsSelected }) => {
  const { accountData } = useContext(AccountContext);

  const profileColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Department",
        accessor: "department.name",
      },
    ],
    [accountData]
  );

  const profilesNotOnPlanningTeam = useMemo(() => {
    if (!accountData) return [];
    const profileIdsOnTeam = event.planningTeam.map((row) => row.id);
    return accountData.profiles.filter((member) => {
      return (
        member.isActive &&
        member.id !== event.creator.id &&
        !profileIdsOnTeam.includes(member.id)
      );
    });
  }, [event, accountData.profiles]);

  const profilesOnPlanningTeam = useMemo(() => {
    if (!accountData) return [];
    const currentInvitedIds = event.planningTeam.map((row) => row.id);
    return accountData.profiles.filter((member) => {
      return (
        member.isActive &&
        member.id !== event.creator.id &&
        currentInvitedIds.includes(member.id)
      );
    });
  }, [event, accountData.profiles]);

  return (
    <VStack spacing={4}>
      {accountData && (
        <DualTables
          columns={profileColumns}
          initialLeftData={profilesNotOnPlanningTeam}
          initialRightData={profilesOnPlanningTeam}
          onRightRowsChange={setRightRowsSelected}
        />
        // <CustomTable
        //   columns={profileColumns}
        //   data={profilesToShow}
        //   preSelectedRowIds={event.planningTeam.map((row) => row.id)}
        //   bulkActions={[
        //     {
        //       label: "Update Planning Team",
        //       minSelected: 0,
        //       action: (selectedRows) => {
        //         addToPlanningTeam(selectedRows);
        //       },
        //       isLoading: updateEventMutation.isLoading,
        //     },
        //   ]}
        // />
      )}
    </VStack>
  );
};

export default EditPlanningTeam;
