import { OrgChart } from "d3-org-chart";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactDOMServer from "react-dom/server";
import { useBreakpointValue } from "@chakra-ui/react";
import { KrLabel, ObjectiveLabelLong } from "../OKRV2/common";
import { select } from "d3";
import LoadingBox from "../../components/common/LoadingBox";
import { NodeButton } from "./NodeButton";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import { useFetchObjectiveTree } from "../../api/okr/objective";
import { AccountContext } from "../../context/AccountContextComponent";
import ZoomButtons from "./ZoomButtons";

export const OkrTree = (props) => {
  const { objectiveId, onSuccess } = props;
  const d3Container = useRef(null);
  const [data, setData] = useState(null);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { accountId } = useAccountId();
  const { data: okrTreeData, isLoading } = useFetchObjectiveTree(
    accountId,
    objectiveId
  );
  const [chart, setChart] = useState(null);

  const navigate = useCustomDomainNavigate();
  const { currentProfile } = useContext(AccountContext);

  useEffect(() => {
    if (!okrTreeData) return;

    const formattedData = [];

    // Group and sort keyResults by objectiveId and createdAt
    const keyResultsByObjective = okrTreeData.reduce((grouped, node) => {
      if (!("parentKeyResultId" in node)) {
        // Node is a key result
        if (!grouped[node.objectiveId]) {
          grouped[node.objectiveId] = [];
        }
        grouped[node.objectiveId].push(node);
      }
      return grouped;
    }, {});

    Object.keys(keyResultsByObjective).forEach((objectiveId) => {
      keyResultsByObjective[objectiveId].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    });

    okrTreeData.forEach((node) => {
      let formattedNode = {};
      if ("parentKeyResultId" in node) {
        // Node is an objective
        formattedNode = {
          ...node,
          type: "objective",
          _expanded: node.id === objectiveId,
          _highlighted: node.id === objectiveId,
          parentId: node.parentKeyResultId || null,
        };
      } else {
        // Node is a key result
        formattedNode = {
          ...node,
          type: "keyResult",
          parentId: node.objectiveId || null,
        };
      }

      formattedData.push(formattedNode);
    });

    // sort by index
    formattedData.sort((a, b) => a.index - b.index);
    // Look for any missing nodes due to permissions blocking access
    formattedData.forEach((node) => {
      if (node.parentId) {
        const parent = formattedData.find((n) => n.id === node.parentId);
        if (!parent) {
          console.log(
            `Missing parent node for ${node.id} with parent id ${node.parentId}`
          );
          formattedData.push({
            id: node.parentId,
            type: "",
            title:
              "Not Found. You may not have access to this OKR or it has been archived.",
            parentId: null,
          });
        }
      }
    });

    setData([...formattedData]);
  }, [okrTreeData]);

  useEffect(() => {
    if (data && data.length > 0 && d3Container.current && !chart) {
      const newChart = new OrgChart();
      setChart(newChart); // Initialize chart once data and container are ready
    }
  }, [data, d3Container.current]);

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (chart && data && data.length && d3Container.current) {
      chart
        .container(d3Container.current)
        .data(data)
        .nodeWidth((d) => (d.data.type === "objective" ? 400 : 300))
        .nodeHeight((d) => (d.data.type === "objective" ? 200 : 200))
        .childrenMargin((d) => 90)
        .compactMarginBetween((d) => 65)
        .compactMarginPair((d) => 80)
        .neighbourMargin((a, b) => 50)
        .siblingsMargin((d) => 100)
        .compact(!isDesktop)
        // .onNodeClick((d, i, arr) => {
        //   console.log(d, "Id of clicked node ");
        //   // props.onNodeClick(d);
        // })
        .buttonContent(({ node, state }) => {
          return ReactDOMServer.renderToStaticMarkup(
            <NodeButton node={node} />
          );
        })
        .nodeContent(function (d, i, arr) {
          return ReactDOMServer.renderToStaticMarkup(
            <NodeContent
              d={d}
              onTitleClick={(nodeId) =>
                console.log(`Node ${nodeId} title clicked`)
              }
            />
          );
        })
        .nodeUpdate(function (e, d) {
          select(this).on("click.node-update", (event, data) => {
            if ([...event.srcElement.classList].includes("node-title")) {
              const objectiveId =
                data.data.type === "objective"
                  ? data.data.id
                  : data.parent.data.id;
              let isOwner = false;
              if (data.data.type === "objective") {
                isOwner =
                  data.data.objectiveSet?.owner?.id === currentProfile.id;
              } else {
                isOwner =
                  data.parent.data.objectiveSet?.owner?.id ===
                  currentProfile.id;
              }
              if (!isOwner) {
                navigate(
                  `/${accountId}/okrs/?tab=All+OKRs&objectiveId=${objectiveId}`
                );
              } else {
                navigate(
                  `/${accountId}/okrs/?tab=My+OKRs&objectiveId=${objectiveId}`
                );
              }

              onSuccess();
            }
          });
        })
        .render()
        .fit();
    }
  }, [data, chart, d3Container.current, isDesktop]);

  function filterOkrTree(e) {
    // Get input value
    const value = e.target.value?.toLowerCase();

    // Clear previous higlighting
    chart.clearHighlighting();

    // Get chart nodes
    const data = chart.data();

    // Mark all previously expanded nodes for collapse
    data.forEach((d) => (d._expanded = false));

    // Loop over data and check if input value matches any name
    data.forEach((d) => {
      if (
        value != "" &&
        (d.title?.toLowerCase().includes(value) ||
          (d.index + 1).toString() === value ||
          `kr${(d.index + 1).toString()}` === value)
      ) {
        d._highlighted = true;
        d._expanded = true;
      }
    });

    // Update data and rerender graph
    chart.data(data).render().fit();
  }

  return (
    <div
      style={{
        backgroundImage: "radial-gradient(#E2E8F0 1px, transparent 0)",
        backgroundSize: "12px 12px",
        backgroundPosition: "-19px -19px",
      }}
    >
      <ZoomButtons chart={chart} filter={filterOkrTree} />

      <div ref={d3Container} />
      {isLoading && <LoadingBox />}
      {!isLoading && !data?.length && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
          }}
        >
          <p>No OKRs to show</p>
        </div>
      )}
    </div>
  );
};

const NodeContent = (props) => {
  const { d } = props;

  return (
    <div
      style={{
        overflow: "hidden", // Prevents content from spilling out
        boxSizing: "border-box", // Makes sure padding and border are included in the total width and height
        paddingTop: "19px",
        paddingBottom: "6px",
        paddingRight: "19px",
        paddingLeft: "19px",
        height: d.height + 20,
        width: d.width,
      }}
    >
      <style>
        {`
          .node-title:hover {
            text-decoration: underline;
          }
        `}
      </style>
      <div
        style={{
          overflow: "hidden",
          boxSizing: "border-box",
          backgroundColor: "white",
          cursor: "default",
          borderRadius: "8px",
          height: d.height - 22,
          border: "1px solid lightgray",
          boxShadow: d.data._highlighted
            ? "0px 0px 14px 6px rgba(47,133,90,0.85)"
            : "0px 0px 4px 0px #00000052",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1,
            padding: "10px",
            textAlign: "center",
          }}
        >
          {/* KRLabel and ObjectiveLabel */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px", // adjust as needed
              marginBottom: "10px",
            }}
          >
            {d.data.type === "objective" && <ObjectiveLabelLong />}
            {d.data.type === "keyResult" && <KrLabel index={d.data.index} />}
          </div>

          {/* Objective Team / Owner / Department Name */}
          {d.data.type === "objective" && (
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <p
                style={{
                  color: "#2F855A",
                  fontSize: "20px",
                  fontWeight: "800",
                }}
              >
                {d.data.objectiveSet?.team?.name}
                {d.data.objectiveSet?.owner?.name}
                {d.data.objectiveSet?.department?.name}
              </p>
            </div>
          )}

          {/* Title */}
          <div style={{ textAlign: "center", marginBottom: "5px" }}>
            <p
              className="node-title"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "#404040",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              {d.data.title || "No title"}
            </p>
          </div>
        </div>
        {/* Total Children and Objectives/KRs */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "10px",
            fontWeight: "700",
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              borderRight: "1px solid #404040",
              paddingRight: "10px",
              marginRight: "10px",
            }}
          >
            Total Children: {d.data._totalSubordinates}
          </div>
          {d.data.type === "objective" ? (
            <div>KRs: {d.data._directSubordinates}</div>
          ) : (
            <div>Objectives: {d.data._directSubordinates}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OkrTree;
