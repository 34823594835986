import { extendTheme } from "@chakra-ui/react";
import { avatarTheme } from "./styles/avatar";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fonts: {
    heading: "Inter,sans-serif",
    body: "Inter,sans-serif",
  },
  semanticTokens: {
    colors: {
      error: "red.500",
      primary: "#2F855A",
      textPrimary: "#1A202C",
      textSecondary: "gray.500",
      offWhite: "#F6F6F6",
    },
  },
  colors: {
    // TODO update these for the shades of primary color
    primary: {
      50: "#BEE3F8",
      100: "#BEE3F8",
      200: "#90CDF4",
      300: "#90CDF4",
      400: "#90CDF4",
      500: "#3182ce",
      600: "#3182ce",
      700: "#3182ce",
      800: "#3182ce",
      900: "#1A365D",
    },
  },
  styles: {
    global: () => ({
      body: {
        bg: "offWhite",
      },
      // h1: {
      //   fontSize: "2xl",
      //   fontWeight: "bold",
      // },
      // h2: {
      //   fontSize: "xl",
      // },
      // h3: {
      //   fontSize: "lg",
      // },
      // p: {
      //   fontSize: "md",
      // },
      // blockquote: {
      //   borderLeft: "3px solid #E2E8F0",
      //   paddingLeft: "1rem",
      // },
      // a: {
      //   color: "blue.500",
      //   _hover: {
      //     textDecoration: "underline",
      //     cursor: "pointer",
      //   },
      // },
    }),
  },
  components: {
    // https://stackoverflow.com/questions/69577974/overriding-the-theme-css-of-box
    Accordion: {
      baseStyle: {
        button: {
          _focus: { boxShadow: "none" },
        },
      },
    },
    Avatar: avatarTheme,
    Button: {
      baseStyle: {
        borderRadius: "10px",
        borderColor: "gray.500",
        p: "14px 20px 14px 20px",
        _focus: { boxShadow: "none" },
      },
      variants: {
        solid: ({ colorMode }) => ({
          bg: "primary",
          color: "white",
          border: "1px",
          borderColor: "#1A202C59",
          _hover: { bg: "green.700", _disabled: { bg: "green.700" } },
          //_active: { bg: "blue.700" },
        }),
        red: () => ({
          bg: "red.600",
          color: "white",
          border: "1px",
          borderColor: "#1A202C59",
          _hover: { bg: "red.700", _disabled: { bg: "red.700" } },
        }),
      },
    },
    Checkbox: {
      // make the checkbox border color gray.500
      baseStyle: {
        control: {
          _focus: { boxShadow: "none" },
        },
      },
      defaultProps: {
        colorScheme: "green",
      },
    },
    // https://github.com/chakra-ui/chakra-ui/issues/2347#issuecomment-997215492
    Input: {
      baseStyle: {
        field: {
          borderRadius: "10px",
          height: "54px",
        },
      },
      variants: {
        outline: () => ({
          field: {
            background: "#ECF2FA80",
            borderColor: "#D3DBE6",
            // background: colorMode === "dark" ? "rgba(0,0,0,0)" : "white",
            // borderColor: "gray.500",
            _hover: {
              borderColor: "gray.700",
            },
            _focus: {
              borderColor: "black",
              boxShadow: "none",
            },
          },
        }),
        flushed: () => ({
          field: {
            _focus: {
              borderColor: "black",
              boxShadow: "none",
            },
            borderRadius: "0px",
          },
        }),
        unstyled: () => ({
          field: {
            borderRadius: "0px",
          },
        }),
      },
    },
    Link: {
      baseStyle: {
        color: "green.600",
        textDecoration: "underline",
      },
    },
    Select: {
      baseStyle: ({ colorMode }) => ({
        field: {
          background: colorMode === "dark" ? "rgba(0,0,0,0)" : "white",
        },
      }),
    },
    Switch: {
      baseStyle: {
        _focus: { boxShadow: "none" },
        track: {
          bg: "white",
          boxShadow: "0 0 0 1px gray",
          _hover: {
            boxShadow: "0 0 0 1px green",
          },
          _checked: {
            bg: "green.500",
            boxShadow: "0 0 0 0px",
          },
        },
        thumb: {
          bg: "gray.400",
          _checked: {
            bg: "white",
            _hover: {
              bg: "white",
            },
          },
          _hover: {
            bg: "gray.300",
          },
        },
      },
    },

    Text: {
      baseStyle: {
        color: "textPrimary",
      },
    },
    Tabs: {
      variants: {
        line: {
          tab: {
            _selected: {
              color: "green.600",
            },
          },
        },
      },
    },
  },
});

export default theme;
