import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useFetchAbcRankingPeriods = (accountId) => {
  return useQuery({
    queryKey: ["fetchAbcRankingPeriods"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/abcRankingPeriods`);
      return response.data;
    },
  });
};

export const useUpdateAbcRankingPeriod = (props) =>
  useMutation({
    mutationKey: ["updateAbcRankingPeriod"],
    mutationFn: async ({ accountId, rankingPeriodId, data }) => {
      const response = await api.put(
        `/${accountId}/abcRankingPeriods/${rankingPeriodId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useCreateRankingPeriod = (props) =>
  useMutation({
    mutationKey: ["createRankingPeriod"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/abcRankingPeriods`, data);
      return response.data;
    },
    ...props,
  });

export const useFetchLatestAbcRankingPeriodMetrics = (accountId) => {
  return useQuery({
    queryKey: ["fetchLatestAbcRankingPeriodMetrics"],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/metrics/abcRankingPeriods/latest`
      );
      return response.data;
    },
  });
};

export const useFetchAllAbcRankingPeriodMetrics = (
  accountId,
  departments,
  tenures
) => {
  return useQuery({
    queryKey: ["fetchAllAbcRankingPeriodMetrics", departments, tenures],
    queryFn: async () => {
      const departmentQuery = departments
        ?.map((department) => `&department=${department.label}`)
        .join("&");

      const tenureQuery = tenures
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/abcRankingPeriods/all?${departmentQuery}${tenureQuery}`
      );
      return response.data;
    },
  });
};

export const useFetchAbcRankingPeriodMetrics = (
  accountId,
  timePeriodId,
  departments,
  tenures
) => {
  return useQuery({
    queryKey: [
      "fetchAbcRankingPeriodMetrics",
      accountId,
      timePeriodId,
      departments,
      tenures,
    ],
    enabled: !!timePeriodId && timePeriodId !== "LAST_6_REPORTS",
    queryFn: async () => {
      const departmentQuery = departments
        .map((department) => `&department=${department.label}`)
        ?.join("&");

      const tenureQuery = tenures
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/abcRankingPeriods/${timePeriodId}?${departmentQuery}${tenureQuery}`
      );
      return response.data;
    },
  });
};
