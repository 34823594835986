import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export const DeleteSomethingModal = (props) => {
  const {
    isOpen,
    setIsOpen,
    deleteFunction,
    isDeleting,
    headerText,
    bodyText,
    buttonText,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{headerText || "Are you sure?"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{bodyText}</ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={() => {
              setIsOpen(false);
            }}
            variant="outline"
          >
            Close
          </Button>
          <Button
            _hover={{
              bg: "red.500",
              color: "white",
            }}
            color={"red.500"}
            variant="outline"
            isLoading={isDeleting}
            onClick={async () => {
              await deleteFunction();
              setIsOpen(false);
            }}
          >
            {buttonText || "Delete"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
