import PageShell from "../../layout/PageShell";
import { Box, Tag, Text, useMediaQuery } from "@chakra-ui/react";
import { useContext } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import LoadingBox from "../../components/common/LoadingBox";
import { useFetchAdminDashboardLayout } from "../../api/adminDashboard/adminDashboardLayout";
import CultureDashboardLayout from "./CultureDashboardLayout";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";

const ZoomOutWrapper = ({ scale, children }) => {
  const style = {
    // transform: `scale(${scale})`,
    // transformOrigin: "top left",
    // width: `${100 / scale}%`,
    // height: `${100 / scale}%`,
  };

  return <div style={style}>{children}</div>;
};

const CultureDashboardPage = () => {
  const { accountId } = useAccountId();
  const { accountData } = useContext(AccountContext);
  const [isLargerThan1800] = useMediaQuery("(min-width: 1890px)");

  const {
    data: adminDashboardLayout,
    isLoading: isLoadingAdminDashboardLayout,
    isError: isErrorAdminDashboardLayout,
    error: errorAdminDashboardLayout,
  } = useFetchAdminDashboardLayout(accountId);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Admin Dashboard" },
  ]);

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponent={
        <Box alignSelf={"flex-end"} pl={4}>
          <Tag size={"md"} colorScheme={"yellow"}>
            Viewable by Admins
          </Tag>
        </Box>
      }
    >
      {(isLoadingAdminDashboardLayout || !accountData) && (
        <LoadingBox height={"100px"} />
      )}
      {isErrorAdminDashboardLayout && (
        <Text color={"red"}>Error loading dashboard</Text>
      )}
      {!isLoadingAdminDashboardLayout && !adminDashboardLayout && (
        <Text color={"red"}>Dashboard not found</Text>
      )}
      <ZoomOutWrapper scale={isLargerThan1800 ? 1 : 0.75}>
        {adminDashboardLayout && accountData && (
          <CultureDashboardLayout adminDashboardLayout={adminDashboardLayout} />
        )}
      </ZoomOutWrapper>
    </PageShell>
  );
};

export default CultureDashboardPage;
