import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useFetchSurveys = (accountId) => {
  return useQuery({
    queryKey: ["fetchSurveys", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/survey`);
      return response.data;
    },
  });
};

export const useFetchSurvey = (accountId, surveyId) => {
  return useQuery({
    queryKey: ["fetchSurvey", surveyId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/survey/${surveyId}`);
      return response.data;
    },
  });
};

export const useCreateSurvey = (props) =>
  useMutation({
    mutationKey: ["createSurvey"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/survey`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateSurvey = (props) =>
  useMutation({
    mutationKey: ["updateSurvey"],
    mutationFn: async ({ accountId, surveyId, data }) => {
      const response = await api.put(`/${accountId}/survey/${surveyId}`, data);
      return response.data;
    },
    ...props,
  });

export const useFetchLatestSurveyRunMetrics = (accountId, surveyId) => {
  return useQuery({
    queryKey: ["fetchLatestSurveyrunMetrics", surveyId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/survey/${surveyId}/latest-survey-run-metrics`
      );
      return response.data;
    },
  });
};

export const useFetchSurveyRunMetrics = (accountId, surveyId) => {
  return useQuery({
    enabled: !!surveyId,
    queryKey: ["fetchSurveyrunMetrics", surveyId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/survey/${surveyId}/survey-run-metrics`
      );
      return response.data;
    },
  });
};

export const useDeleteSurvey = (props) =>
  useMutation({
    mutationKey: ["deleteSurvey"],
    mutationFn: async ({ accountId, surveyId }) => {
      const response = await api.delete(`/${accountId}/survey/${surveyId}`);
      return response.data;
    },
    ...props,
  });
