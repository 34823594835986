import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

export const PhoneInputField = (props) => {
  const { field, leftAddon, inputStyle } = props;
  const form = useFormContext();
  const {
    formState: { errors },
    register,
    control,
  } = form;

  return (
    <Controller
      name={field.id}
      control={control}
      defaultValue={field.defaultValue ? field.defaultValue : ""}
      rules={{
        validate: (value) =>
          isValidPhoneNumber(value || "") || "Invalid phone number",
      }}
      render={({ field: { onChange, value, onBlur } }) => (
        <FormControl
          isInvalid={get(errors, field.id)}
          isRequired={field.validation?.required}
        >
          {field.label && <FormLabel>{field.label}</FormLabel>}
          <InputGroup>
            {leftAddon}
            <PhoneInput
              id={field.id}
              name={field.id}
              key={field.id}
              placeholder={field.placeholder}
              // {...register(field.id, field.validation)}
              value={field.initialValue || ""}
              onChange={onChange}
              inputComponent={Input}
              defaultCountry="US"
              {...inputStyle}
            />
          </InputGroup>
          <FormErrorMessage>
            {get(errors, field.id) && get(errors, `${field.id}.message`)}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
