import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateKbPage = (props) =>
  useMutation({
    mutationKey: ["createKbPage"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/kbPage`, data);
      return response.data;
    },
    ...props,
  });

export const useCloneKbPage = (props) =>
  useMutation({
    mutationKey: ["cloneKbPage"],
    mutationFn: async ({ accountId, pageId }) => {
      const response = await api.post(`/${accountId}/kbPage/${pageId}/clone`);
      return response.data;
    },
    ...props,
  });

export const useUpdateKbPage = (props) =>
  useMutation({
    mutationKey: ["updateKbPage"],
    mutationFn: async ({ accountId, pageId, data }) => {
      const response = await api.put(`/${accountId}/kbPage/${pageId}`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteKbPage = (props) =>
  useMutation({
    mutationKey: ["deleteKbPage"],
    mutationFn: async ({ accountId, kbPageId }) => {
      const response = await api.delete(`/${accountId}/kbPage/${kbPageId}`);
      return response.data;
    },
    ...props,
  });

export const useFetchKbPages = (accountId) => {
  return useQuery({
    queryKey: ["fetchKbPages"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/kbPage`);
      return response.data;
    },
  });
};

export const useFetchKbPage = (accountId, pageId) => {
  return useQuery({
    queryKey: ["fetchKbPage", pageId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/kbPage/${pageId}`);
      return response.data;
    },
  });
};
