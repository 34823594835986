import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import BillingLayout from "./BillingLayout";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import useToastMessage from "../../hooks/useToastMessage";
import { useLocation } from "react-router-dom";
import { BillingForm } from "./BillingForm";

const NewBillingPage = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const planName = searchParams.get("planName");
  const billingPeriod = searchParams.get("billingPeriod");
  return (
    <BillingLayout>
      {() => (
        <Box py={4}>
          <Text fontSize={"2xl"} fontWeight={600} pb={4}>
            Payment Method
          </Text>
          <Text fontSize={"md"} pb={4}>
            Please enter your billing information. You won't be charged until
            the end of your free trial.
          </Text>
          <NewBillingForm planName={planName} billingPeriod={billingPeriod} />
        </Box>
      )}
    </BillingLayout>
  );
};

const NewBillingForm = ({ planName, billingPeriod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorToast } = useToastMessage();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const port = window.location.port;
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const path = window.location.pathname.split("/billing")[0];
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url:
            protocol +
            "//" +
            hostname +
            ":" +
            port +
            path +
            `/billing-redirect?planName=${planName}&billingPeriod=${billingPeriod}`,
        },
      });
      setIsLoading(false);
      // TODO test the case where 3DS auth fails
      if (error) {
        throw error;
      }
    } catch (error) {
      // Test for invalid_request_error, there wont be a error.message
      showErrorToast({
        message:
          error.message ||
          "Something went wrong. Please contact support@thebestanswer.com",
      });
      setIsLoading(false);
      console.log("error", error);
    }
  };

  return (
    <BillingForm
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      buttonLabel={"Start Free Trial"}
    />
  );
};

export default NewBillingPage;
