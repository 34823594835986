import { IconButton, Link, Tag, Text, VStack } from "@chakra-ui/react";
import { useContext, useMemo, useState } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import LoadingBox from "../../../components/common/LoadingBox";
import DepartmentMembers from "./DepartmentMembers";
import { EditDepartmentModal } from "./modals/EditDepartmentModal";
import { useFetchDepartment } from "../../../api/departments/department";
import { MdOutlineEdit } from "react-icons/md";
import useAccountId from "../../../hooks/customDomainHooks";

const Department = ({ departmentId, setDepartmentId }) => {
  const { accountId } = useAccountId();
  const { currentProfile, accountData, isLoading } = useContext(AccountContext);
  const [isEditDepartmentModalOpen, setIsEditDepartmentModalOpen] =
    useState(false);

  const { data: department, isLoading: isLoadingDepartment } =
    useFetchDepartment(accountId, departmentId);

  const canEdit = useMemo(() => {
    if (!currentProfile) return false;
    if (["ADMIN", "OWNER", "PARTNER"].includes(currentProfile.role)) return true;
    return false;
  }, [currentProfile]);

  if (isLoadingDepartment || !accountData) {
    return <LoadingBox height={"100px"} />;
  }
  if (!isLoadingDepartment && !department) {
    return <Text color={"red"}>Department not found</Text>;
  }

  return (
    <>
      {department && accountData && (
        <>
          <VStack alignItems={"flex-start"}>
            <VStack alignItems={"flex-start"}>
              <Link
                fontSize={"md"}
                color={"textPrimary"}
                onClick={() => setDepartmentId(null)}
              >{`< All Departments`}</Link>
              <Text fontSize={"2xl"} fontWeight={"bold"}>
                {department.name}{" "}
                {canEdit && (
                  <IconButton
                    icon={<MdOutlineEdit />}
                    variant="ghost"
                    fontSize={"md"}
                    size={"sm"}
                    onClick={() => {
                      setIsEditDepartmentModalOpen(true);
                    }}
                  />
                )}
                {department.isArchived && <Tag colorScheme="red">Archived</Tag>}
              </Text>
            </VStack>
            <DepartmentMembers
              members={department.profiles}
              allowEdit={canEdit}
              departmentId={departmentId}
            />
          </VStack>

          <EditDepartmentModal
            isOpen={isEditDepartmentModalOpen}
            setIsOpen={setIsEditDepartmentModalOpen}
            department={department}
          />
        </>
      )}
    </>
  );
};

export default Department;
