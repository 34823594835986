import React, { useContext, useEffect, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {
  Box,
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
} from "@chakra-ui/react";
import { BsEmojiSmile } from "react-icons/bs";
import { AccountContext } from "../../../context/AccountContextComponent";
import { getEmojiDataFromNative } from "emoji-mart";
import { init } from "emoji-mart";

const EmojiPicker = ({ onEmojiSelect, buttonLabel }) => {
  const { accountData, isLoading } = useContext(AccountContext);
  const [bannedEmojiIds, setBannedEmojiIds] = useState(undefined);
  const [hasSetBannedEmojiIds, setHasSetBannedEmojiIds] = useState(false);

  useEffect(() => {
    const initEmojiData = async () => {
      if (!data || !accountData) return;
      await init({ data });
      const bannedEmojis = accountData?.bannedReactionEmojis ?? [];
      const allBannedPromises = bannedEmojis.map(async (emoji) => {
        const emojiData = await getEmojiDataFromNative(emoji, data);
        return emojiData.id;
      });
      const allBanned = await Promise.all(allBannedPromises);
      setBannedEmojiIds(allBanned);
      setHasSetBannedEmojiIds(true);
    };

    initEmojiData();
  }, [data, accountData]);

  return (
    <Popover trigger={"hover"}>
      <PopoverTrigger>
        {buttonLabel ? (
          <Button size="sm" variant="outline" leftIcon={<BsEmojiSmile />}>
            {buttonLabel}
          </Button>
        ) : (
          <IconButton
            size="sm"
            variant="ghost"
            icon={<BsEmojiSmile size={"19px"} />}
          />
        )}
      </PopoverTrigger>
      <PopoverContent width="auto" maxWidth="none">
        <PopoverArrow />
        <PopoverBody>
          {isLoading && <Spinner />}
          {!isLoading && data && hasSetBannedEmojiIds && (
            <Picker
              onEmojiSelect={onEmojiSelect}
              data={data}
              theme={"light"}
              // there's a bug in emoji-mart where searching for
              // an emoji still shows the banned emoji
              exceptEmojis={bannedEmojiIds}
            />
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default EmojiPicker;
