import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../config";

export const useFetchOneOnOneMeetings = (accountId, inviteeId) => {
  return useQuery({
    queryKey: ["fetchOneOnOneMeetings", inviteeId],
    queryFn: async () => {
      const inviteeQuery = inviteeId ? `inviteeId=${inviteeId}` : "";
      const response = await api.get(
        `/${accountId}/oneOnOneMeeting?${inviteeQuery}`
      );
      return response.data;
    },
  });
};

export const useFetchOneOnOneMeeting = (accountId, meetingId) => {
  return useQuery({
    queryKey: ["fetchOneOnOneMeeting", meetingId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/oneOnOneMeeting/${meetingId}`
      );
      return response.data;
    },
    // enabled: !!meetingId,
  });
};

export const useUpdateOneOnOneMeeting = (props) =>
  useMutation({
    mutationKey: ["updateOneOnOneMeeting"],
    mutationFn: async ({ accountId, data, meetingId }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneMeeting/${meetingId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateSharedNote = (props) =>
  useMutation({
    mutationKey: ["updateSharedNote"],
    mutationFn: async ({ accountId, data, meetingId }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneMeeting/${meetingId}/sharedNote`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdatePrivateNote = (props) =>
  useMutation({
    mutationKey: ["updatePrivateNote"],
    mutationFn: async ({ accountId, data, meetingId }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneMeeting/${meetingId}/privateNote`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteOneOnOneMeeting = (props) =>
  useMutation({
    mutationKey: ["deleteOneOnOneMeeting"],
    mutationFn: async ({
      accountId,
      meetingId,
      allMeetings = false,
      andFollowingMeetings = false,
    }) => {
      const response = await api.delete(
        `/${accountId}/oneOnOneMeeting/${meetingId}?allMeetings=${allMeetings}&andFollowingMeetings=${andFollowingMeetings}`
      );
      return response.data;
    },
    ...props,
  });

export const useUnarchiveOneOnOneMeeting = (props) =>
  useMutation({
    mutationKey: ["unarchiveOneOnOneMeeting"],
    mutationFn: async ({ accountId, meetingId }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneMeeting/${meetingId}/unarchive`
      );
      return response.data;
    },
    ...props,
  });
