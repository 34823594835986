import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useFetchAbcRankingReports = (accountId) => {
  return useQuery({
    queryKey: ["fetchAbcRankingReports"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/abcRankingReports`);
      return response.data;
    },
  });
};

export const useFetchAbcRankingReport = (accountId, reportId) => {
  return useQuery({
    queryKey: ["fetchAbcRankingReport", reportId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/abcRankingReports/${reportId}`
      );
      return response.data;
    },
  });
};

export const useFetchAllAbcRankingReportMetrics = (
  accountId,
  departments,
  tenures,
  timePeriod
) => {
  return useQuery({
    queryKey: [
      "fetchAllAbcRankingReportMetrics",
      departments,
      tenures,
      timePeriod,
    ],
    queryFn: async () => {
      const departmentQuery = departments
        ?.map((department) => `&department=${department.label}`)
        .join("&");

      const tenureQuery = tenures
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const timePeriodQuery =
        timePeriod && timePeriod !== "LAST_6_REPORTS"
          ? `&timePeriod=${timePeriod}`
          : "";

      const response = await api.get(
        `/${accountId}/metrics/employeeAbcRankings/all?${departmentQuery}${tenureQuery}${timePeriodQuery}`
      );
      return response.data;
    },
  });
};
