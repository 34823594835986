import ProtectedRoute from "./auth/components/ProtectedRoute";
import { DashboardLayout } from "./layout/DashboardLayout";
import { Login } from "./auth/pages/Login";
import Register from "./auth/pages/Register";
import ResetPassword from "./auth/pages/ResetPassword";
import { Navigate, Outlet } from "react-router-dom";
import OrgChartPage from "./pages/OrgChart/OrgChartPage";
import CreateAccount from "./pages/Account/CreateAccount";
import AccountPage from "./pages/Account/AccountPage";
import PeoplePage from "./pages/People/PeoplePage";
import OkrsPage from "./pages/OKRV2/OkrsPage";
import TeamsPage from "./pages/Teams/TeamsPage";
import TeamPage from "./pages/Teams/TeamPage";
import AbcRankingsPage from "./pages/ABCRankings/AbcRankingsPage";
import AbcRankingsReportPage from "./pages/ABCRankings/AbcRankingsReportPage";
import SurveysPage from "./pages/Surveys/SurveysPage";
import ManageSurveyPage from "./pages/Surveys/ManageSurveyPage";
import TakeSurveyPage from "./pages/Surveys/TakeSurvey/TakeSurveyPage";
import EventsPage from "./pages/Events/EventsPage";
import ViewEventPage from "./pages/Events/ViewEventPage";
import EditEventPage from "./pages/Events/EditEvent/EditEventPage";
import CultureDashboardPage from "./pages/CultureDashboard/CultureDashboardPage";
import CultureHubPage from "./pages/CultureHub/CultureHubPage";
import KnowledgeBasePage from "./pages/KnowledgeBase/KnowledgeBasePage";
import EditKbPage from "./pages/KnowledgeBase/EditKbPage/EditKbPage";
import ViewKbPage from "./pages/KnowledgeBase/ViewKbPage/ViewKbPage";
import CultureScorePage from "./pages/CultureScore/CultureScorePage";
import CultureBudgetPage from "./pages/CultureBudget/CultureBudgetPage";
import ShoutoutsPage from "./pages/Shoutouts/ShoutoutsPage";
import ReportsPage from "./pages/Reports/ReportsPage";
import ProfileNotVerified from "./auth/pages/ProfileNotVerified";
import EmailActionHandler from "./auth/pages/handlers/EmailActionHandler";
import ResetPasswordHandler from "./auth/pages/handlers/ResetPasswordHandler";
import VerifyEmailHandler from "./auth/pages/handlers/VerifyEmailHandler";
import AcceptInviteHandler from "./auth/pages/handlers/AcceptInviteHandler";
import CreatePassword from "./auth/pages/handlers/CreatePassword";
import ErrorOnAcceptingInvite from "./auth/pages/handlers/ErrorOnAcceptingInvite";
import RevertSecondFactorHandler from "./auth/pages/handlers/RevertSecondFactorHandler";
import NewBillingPage from "./pages/Billing/NewBillingPage";
import BillingRedirect from "./pages/Billing/BillingRedirect";
import SubscriptionsPage from "./pages/Billing/SubscriptionsPage";
import BillingRedirectCompanyInfo from "./pages/Billing/BillingRedirectCompanyInfo";
import { PartnerDashboardLayout } from "./layout/PartnerDashboardLayout";
import EnterprisePlansPage from "./pages/PartnerPages/EnterprisePlansPage";
import AcceptTos from "./pages/Billing/AcceptTos";
import SurveysReportPage from "./pages/Reports/Surveys/SurveysReportPage";
import TeamAlignmentReportPage from "./pages/Reports/TeamAlignment/TeamAlignmentReportPage";
import EmployeeTenureReportPage from "./pages/Reports/EmployeeTenure/EmployeeTenureReportPage";
import EnpsReportPage from "./pages/Reports/Enps/EnpsReportPage";
import EmployeeTurnoverReportPage from "./pages/Reports/EmployeeTurnover/EmployeeTenureReportPage";
import ContactAdmin from "./pages/Account/ContactAdmin";
import OneOnOnePage from "./pages/OneOnOnes/OneOnOnePage";
import ViewMeetingPage from "./pages/OneOnOnes/Meeting/ViewMeetingPage";

const routes = [
  {
    path: "*",
    element: (
      <ProtectedRoute>
        <DashboardLayout hideSidebar />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/",
    element: (
      <>
        <ProtectedRoute>
          <DashboardLayout hideSidebar />
        </ProtectedRoute>
      </>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "new",
        element: <CreateAccount />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: "/_auth/",
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/_auth/signin" replace />,
      },
      {
        path: "signin",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "not-verified",
        element: <ProfileNotVerified />,
      },
      // Email action handlers
      {
        path: "email-handler",
        element: <EmailActionHandler />,
      },
      {
        path: "reset-password-handler",
        element: <ResetPasswordHandler />,
      },
      {
        path: "verify-email-handler",
        element: <VerifyEmailHandler />,
      },
      {
        path: "accept-invite-handler",
        element: <AcceptInviteHandler />,
      },
      {
        path: "create-password",
        element: <CreatePassword />,
      },
      {
        path: "invite-expired",
        element: <ErrorOnAcceptingInvite />,
      },
      {
        path: "revert-second-factor-addition",
        element: <RevertSecondFactorHandler />,
      },
      {
        path: "*",
        element: <Navigate to="/_auth/signin" replace />,
      },
    ],
  },
  {
    path: "/partner",
    element: (
      <>
        <ProtectedRoute>
          <PartnerDashboardLayout />
        </ProtectedRoute>
      </>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "enterprise-plans",
        element: <EnterprisePlansPage />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: "/:accountId/setup",
    element: (
      <>
        <ProtectedRoute>
          <DashboardLayout hideSidebar hideCompanySettings />
        </ProtectedRoute>
      </>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="billing" replace />,
      },
      {
        path: "billing",
        element: <NewBillingPage />,
      },
      {
        path: "billing-redirect",
        element: <BillingRedirect />,
      },
      {
        path: "billing-redirect-company",
        element: <BillingRedirectCompanyInfo />,
      },
      {
        path: "subscriptions",
        element: <SubscriptionsPage />,
      },
      {
        path: "tos",
        element: <AcceptTos />,
      },
      {
        path: "contact-admin",
        element: <ContactAdmin />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: "/:accountId",
    element: (
      <>
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      </>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "home",
        element: <CultureHubPage />,
      },
      {
        path: "company-information",
        element: <AccountPage />,
      },
      {
        path: "okrs",
        element: <OkrsPage />,
      },
      {
        path: "org-chart",
        element: <OrgChartPage />,
      },
      {
        path: "people",
        element: <PeoplePage />,
      },
      {
        path: "teams",
        element: <TeamsPage />,
      },
      {
        path: "teams/:teamId",
        element: <TeamPage />,
      },
      {
        path: "ta",
        element: <AbcRankingsPage />,
      },
      {
        path: "ta/:reportId",
        element: <AbcRankingsReportPage />,
      },
      {
        path: "surveys",
        element: <SurveysPage />,
      },
      {
        path: "surveys/:surveyId/update",
        element: <ManageSurveyPage />,
      },
      {
        path: "surveys/take-survey/:surveyRunId",
        element: <TakeSurveyPage />,
      },
      {
        path: "events",
        element: <EventsPage />,
      },
      {
        path: "events/:eventId/view",
        element: <ViewEventPage />,
      },
      {
        path: "events/:eventId/edit",
        element: <EditEventPage />,
      },
      {
        path: "admin-dashboard",
        element: <CultureDashboardPage />,
      },
      {
        path: "culture-score",
        element: <CultureScorePage />,
      },
      {
        path: "culture-budget",
        element: <CultureBudgetPage />,
      },
      {
        path: "knowledge-base",
        element: <KnowledgeBasePage />,
      },
      {
        path: "knowledge-base/:pageId/edit",
        element: <EditKbPage />,
      },
      {
        path: "knowledge-base/:pageId/view",
        element: <ViewKbPage />,
      },
      {
        path: "shoutouts",
        element: <ShoutoutsPage />,
      },
      {
        path: "reports",
        element: <ReportsPage />,
      },
      {
        path: "reports/surveys",
        element: <SurveysReportPage />,
      },
      {
        path: "reports/ta",
        element: <TeamAlignmentReportPage />,
      },
      {
        path: "reports/employee-tenure",
        element: <EmployeeTenureReportPage />,
      },
      {
        path: "reports/employee-turnover",
        element: <EmployeeTurnoverReportPage />,
      },
      {
        path: "reports/enps",
        element: <EnpsReportPage />,
      },
      {
        path: "one-on-ones",
        element: <OneOnOnePage />,
      },
      {
        path: "one-on-ones/:meetingId",
        element: <ViewMeetingPage />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
];

/**
 * A helper function for the customer subdomain feature to prevent
 * the need to duplicate routes that don't have the accountId param
 *
 * @param {RouteObject[]} routes
 * @returns {RouteObject[]}
 */
function customDomainRoutes(routes) {
  return routes.map((route) => {
    if (route.path === "/:accountId") {
      return {
        ...route,
        path: "/",
      };
    }
    return route;
  });
}

export default routes;
export { customDomainRoutes };
