import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";
import { useRef } from "react";

// To do search on nested options use labelToSearch
// options: [{
//   "label": "Searchable",
//   "value": "searchable",
//   "labelToSearch": "Searchable",
// }]
export const SelectField = (props) => {
  const {
    field,
    options,
    isLoading,
    onSelectionChange,
    isClearable,
    selectStyles,
    selectProps,
  } = props;
  const form = useFormContext();
  const {
    formState: { errors },
    register,
    getValues,
    control,
  } = form;
  const formValuesRef = useRef();

  const findOption = (currentValue) => {
    if (!currentValue || !options) return undefined;
    for (const option of options) {
      if (option.options) {
        const foundOption = option.options.find(
          (o) => o.value === currentValue
        );
        if (foundOption) {
          return foundOption;
        }
      } else if (option.value == currentValue) {
        return option;
      }
    }
    return undefined;
  };

  // This is for the search functionality
  const filterOption = (option, inputValue) => {
    if (option.data?.labelToSearch) {
      return String(option.data?.labelToSearch)
        .toLowerCase()
        .includes(inputValue.toLowerCase());
    }
    return (
      option.label &&
      String(option.label).toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const defaultSelectStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      minW: "250px",
    }),
    option: (provided, state) => ({
      ...provided,
      pl: 7,
    }),
    ...selectStyles,
  };

  return (
    <FormControl
      isInvalid={get(errors, field.id)}
      isRequired={field.validation?.required}
    >
      {field.label && <FormLabel>{field.label}</FormLabel>}
      <Controller
        control={control}
        name={field.id}
        id={field.id}
        rules={field.validation}
        // {...register(field.id, field.validation)}
        {...register(field.id, {
          validation: field.validation,
          valueAsNumber: false,
          //validate: () => {},
        })}
        ref={null}
        render={(props) => {
          const formValues = getValues();
          const valueFromForm = get(formValues, field.id);
          const selectedOption = findOption(valueFromForm);
          formValuesRef.current = getValues();
          return (
            <Select
              isLoading={isLoading}
              key={formValuesRef.current[field.id]}
              inputRef={props.field.ref}
              options={options}
              value={selectedOption}
              defaultValue={() => {
                const formValues = getValues();
                const valueFromInitial = get(formValues, field.id);
                if (field.isMulti) {
                  let listOptions = [];
                  for (
                    let index = 0;
                    index < valueFromInitial?.length;
                    index++
                  ) {
                    const val = valueFromInitial[index];
                    const option = findOption(val);
                    listOptions.push(option);
                  }
                  if (listOptions) return listOptions;
                } else {
                  const option = findOption(valueFromInitial);
                  if (option) return option;
                }
              }}
              onChange={(val) => {
                if (Array.isArray(val)) {
                  const vals = val.map((v) => {
                    return v.value;
                  });
                  props.field.onChange(vals);
                } else {
                  props.field.onChange(val?.value ? val.value : null);
                }
                if (onSelectionChange) {
                  onSelectionChange(val);
                }
              }}
              placeholder={field.placeholder}
              closeMenuOnSelect={true}
              openMenuOnClick={true}
              formatCreateLabel={(inputLabel) => {
                return `Use "${inputLabel}"`;
              }}
              //isValidNewOption={() => { return !customValueNotAllowed }}
              createOptionPosition={"last"}
              isMulti={field.isMulti}
              menuPosition="fixed"
              isReadOnly={field.isReadOnly}
              // formatGroupLabel={(data) => (
              //   <Box>
              //     <strong>{data.label}</strong>
              //     <Box as="span" color="gray.500">
              //       {data.label}
              //     </Box>
              //   </Box>
              // )}
              filterOption={filterOption}
              isClearable={isClearable}
              chakraStyles={defaultSelectStyles}
              {...selectProps}
            />
          );
        }}
      />
      <FormErrorMessage>
        {get(errors, field.id) && get(errors, `${field.id}.message`)}
      </FormErrorMessage>
    </FormControl>
  );
};
