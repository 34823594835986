import { Box, Center, Flex, Image, Stack, Text } from "@chakra-ui/react";
import Div100vh from "react-div-100vh";
import { HelpButton } from "../../components/common/Buttons/HelpButton";
import tbaFrame from "../../assets/tba-frame.png";
import { useContext, useMemo } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import GridIcon from "../../assets/icons/grid.svg?react";
import CalIcon from "../../assets/icons/cal.svg?react";
import NewsIcon from "../../assets/icons/newsfeed.svg?react";
import InboxIcon from "../../assets/icons/inbox.svg?react";
import ProfileIcon from "../../assets/icons/profile_square.svg?react";
import TeamIcon from "../../assets/icons/team.svg?react";
import ArrowLeftRightIcon from "../../assets/icons/arrow_left_right.svg?react";
import DashboardIcon from "../../assets/icons/dashboard.svg?react";
import SmileyIcon from "../../assets/icons/smiley.svg?react";
import ChatBubblesIcon from "../../assets/icons/chatbubbles.svg?react";
import ChartIcon from "../../assets/icons/chart_3.svg?react";
import HeartIcon from "../../assets/icons/heart.svg?react";
import LightningIcon from "../../assets/icons/lightning.svg?react";
import TeamAlignmentIcon from "../../assets/icons/team-alignment.svg?react";
import { SidebarSection } from "./SidebarSection";

const DashboardSidebar = () => {
  const { currentProfile, accountData, isLoading, hasModuleTurnedOn } =
    useContext(AccountContext);

  const getModule = (moduleName) => {
    return accountData?.modules?.find((m) => m.moduleName === moduleName);
  };

  const sidebarSections = useMemo(() => {
    return [
      {
        id: "company-section",
        items: [
          {
            type: "header",
            label: "Company",
            show: true,
          },
          {
            type: "nav",
            label: "Culture Hub",
            href: "home",
            startElement: <GridIcon />,
            show: true, //hasModuleTurnedOn("CULTURE_HUB"),
            id: "culture-hub",
          },
          {
            type: "nav",
            label: "Events",
            href: "events",
            startElement: <CalIcon fill="#17181A" />,
            show: hasModuleTurnedOn("EVENTS"),
          },
          {
            type: "nav",
            label: "OKRs",
            href: "okrs",
            startElement: <NewsIcon />,
            show: hasModuleTurnedOn("OKR"),
          },
          // {
          //   type: "divider",
          //   show: true,
          // },
          {
            type: "nav",
            label: "Surveys",
            href: ["OWNER", "ADMIN", "PARTNER"].includes(currentProfile?.role)
              ? "surveys?tab=Manage+Surveys"
              : "surveys",
            startElement: <InboxIcon />,
            show: hasModuleTurnedOn("SURVEY"),
          },
          {
            type: "nav",
            label: "1-on-1s",
            href: "one-on-ones",
            startElement: (
              <ChatBubblesIcon style={{ width: "24px", height: "20px" }} />
            ),
            show: hasModuleTurnedOn("ONE_ON_ONE"),
          },
          {
            type: "nav",
            label: "Shoutouts",
            href: "shoutouts",
            startElement: (
              <HeartIcon width={"20px"} height={"20px"} stroke={"#292D32"} />
            ),
            show: hasModuleTurnedOn("SHOUTOUTS"),
          },
          {
            type: getModule("KNOWLEDGE_BASE")?.url ? "externalLink" : "nav",
            label: "Knowledge Base",
            href: getModule("KNOWLEDGE_BASE")?.url || "knowledge-base",
            startElement: <LightningIcon fontSize={"20px"} />,
            show: hasModuleTurnedOn("KNOWLEDGE_BASE"),
          },
        ],
      },
      {
        id: "people-section",
        items: [
          {
            type: "header",
            label: "People",
            show: true,
          },
          {
            type: "nav",
            label: "People",
            href: "people",
            startElement: <ProfileIcon />,
            show: true,
          },
          {
            type: "nav",
            label: "Teams",
            href: "teams",
            startElement: <TeamIcon />,
            show: true,
          },
          {
            type: "nav",
            label: "Org Chart",
            href: "org-chart",
            startElement: <ArrowLeftRightIcon />,
            show: hasModuleTurnedOn("ORG_CHART"),
          },
        ],
      },
      {
        id: "culture-management-section",
        items: [
          {
            type: "header",
            label: "Culture Management",
            show:
              currentProfile?._count?.reports > 0 ||
              ["OWNER", "ADMIN", "PARTNER"].includes(currentProfile?.role),
          },
          {
            type: "nav",
            label: "Admin Dashboard",
            href: "admin-dashboard",
            startElement: <DashboardIcon />,
            show: ["OWNER", "ADMIN", "PARTNER"].includes(currentProfile?.role),
            id: "admin-dashboard",
          },
          {
            type: "nav",
            label: "Culture Success Score",
            href: "culture-score",
            startElement: <SmileyIcon />,
            show:
              hasModuleTurnedOn("CULTURE_SCORE") &&
              ["OWNER", "ADMIN", "PARTNER"].includes(currentProfile?.role),
          },
          {
            type: "nav",
            label: "Team Alignment",
            href: "ta",
            startElement: <TeamAlignmentIcon />,
            show:
              hasModuleTurnedOn("ABC_RANKING") &&
              (currentProfile?._count?.reports > 0 ||
                ["OWNER", "ADMIN", "PARTNER"].includes(currentProfile?.role)),
          },
          // {
          //   type: "nav",
          //   label: "Culture Budget",
          //   href: "culture-budget",
          //   startElement: <WalletCheckIcon />,
          //   show:
          //     hasModuleTurnedOn("CULTURE_BUDGET") &&
          //     ["OWNER", "ADMIN", "PARTNER"].includes(currentProfile?.role),
          // },
          {
            type: "nav",
            label: "Reports",
            href: "reports",
            startElement: <ChartIcon />,
            show:
              hasModuleTurnedOn("REPORTS") &&
              ["OWNER", "ADMIN", "PARTNER"].includes(currentProfile?.role),
          },
        ],
      },
    ];
  }, [currentProfile, accountData]);

  return (
    <Box
      w="64"
      bg={"offWhite"}
      borderRight={"1px solid"}
      borderColor={"gray.200"}
      fontSize="sm"
      minH="100vh"
      maxH="100vh"
      position="fixed"
      height="100vh"
      // zIndex="docked" // Give it a high z-index so it stays on top
    >
      <Div100vh>
        <Flex h="full" direction="column">
          <Center py={"8px"}>
            {isLoading ? (
              <Box h={"40px"}></Box>
            ) : (
              <Image
                src={
                  accountData?.logoImageUrl
                    ? accountData.logoImageUrl
                    : tbaFrame
                }
                alt="TBA Frame"
                h={"50px"}
              />
            )}
          </Center>
          <Stack flex="1" overflow="auto" p="10px" mt={"5px"}>
            {accountData &&
              sidebarSections.map((sidebarSection, index) => {
                return (
                  <SidebarSection
                    sidebarSection={sidebarSection}
                    index={index}
                    key={index}
                  />
                );
              })}
          </Stack>
          <Stack
            spacing="2"
            p="10px"
            borderTop={"1px"}
            borderColor={"gray.100"}
          >
            <HelpButton />
            <Text fontSize="xs" color="textSecondary">
              Powered by The Best Answer
            </Text>
          </Stack>
        </Flex>
      </Div100vh>
    </Box>
  );
};
export default DashboardSidebar;
