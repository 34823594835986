import { Box } from "@chakra-ui/react";
import { AccountContext } from "../../../context/AccountContextComponent";
import { useContext, useEffect, useRef, useState } from "react";
import LoadingBox from "../LoadingBox";
import { ViewPageInfo } from "./ViewPageInfo";
import { EditPageInfo } from "./EditPageInfo";

const InfoBox = ({ pageInfoType, setPageInfoEditMode, pageInfoEditMode }) => {
  const { accountData, isLoading: isAccountDataLoading } =
    useContext(AccountContext);
  const pageInfoRef = useRef();

  const [pageInfo, setPageInfo] = useState(
    accountData?.pageInfos?.find(
      (pageInfo) => pageInfo.pageType === pageInfoType
    )
  );

  useEffect(() => {
    const newPageInfo = accountData?.pageInfos?.find(
      (pageInfo) => pageInfo.pageType === pageInfoType
    );
    setPageInfo(newPageInfo);
    pageInfoRef.current = newPageInfo;
  }, [accountData, pageInfoType]);

  if (isAccountDataLoading) {
    return <LoadingBox />;
  }
  if (!pageInfo) {
    return;
  }

  return (
    <Box pl={0} pb={2} mb={4}>
      {pageInfoEditMode ? (
        <EditPageInfo
          pageInfo={pageInfo}
          setPageInfoEditMode={setPageInfoEditMode}
          pageInfoRef={pageInfoRef}
        />
      ) : (
        <ViewPageInfo
          pageInfo={pageInfo}
          setPageInfoEditMode={setPageInfoEditMode}
        />
      )}
    </Box>
  );
};

export default InfoBox;
