import React, { createContext, useContext, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { AccountContext } from "./AccountContextComponent";
import { useBindSlackUser } from "../api/integrations/slack";
import useToastMessage from "../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import useAccountId from "../hooks/customDomainHooks";

export const BindSlackContext = createContext();

export const BindSlackContextComponent = ({ children }) => {
  const { currentProfile } = useContext(AccountContext);
  const { accountId } = useAccountId();
  const urlParams = new URLSearchParams(window.location.search);
  const slackNonce = urlParams.get("slackNonce");
  const bindSlackUserMutation = useBindSlackUser();
  const { showSuccessToast } = useToastMessage();
  const didRun = useRef(false);
  const queryClient = useQueryClient();

  const clearSlackNonce = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  const handleBindSlackUser = async () => {
    try {
      if (slackNonce && currentProfile) {
        const response = await bindSlackUserMutation.mutateAsync({
          accountId: currentProfile.account.id,
          data: { slackNonce },
        });
        console.log("Bind slack user response", response);
        clearSlackNonce();
        showSuccessToast("Slack account linked successfully");
        queryClient.invalidateQueries("fetchAccount", accountId);
      }
    } catch (e) {
      console.error("Error binding slack user", e);
      clearSlackNonce();
    }
  };

  // Look for a slackNonce query parameter, if it exists, bind the slack account

  useEffect(() => {
    if (slackNonce && currentProfile) {
      console.log("Binding slack account", slackNonce);
      if (!didRun.current) {
        handleBindSlackUser();
        didRun.current = true;
      }
    }
  }, [slackNonce, currentProfile]);

  return (
    <BindSlackContext.Provider value={{}}>
      {children || <Outlet />}
    </BindSlackContext.Provider>
  );
};
