import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../config";

export const useUpdateCultureScoreConfiguration = (props) =>
  useMutation({
    mutationKey: ["updateCultureScoreConfiguration"],
    mutationFn: async ({ accountId, cultureScoreConfigId, data }) => {
      const response = await api.put(
        `/${accountId}/cultureScoreConfiguration/${cultureScoreConfigId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useFetchCultureScoreFactors = (accountId) => {
  return useQuery({
    queryKey: ["fetchCultureScoreFactors"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/cultureScoreFactor`);
      return response.data;
    },
  });
};
