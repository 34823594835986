import { Box, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import useAccountId from "../../../hooks/customDomainHooks";
import { useContext, useState } from "react";
import LoadingBox from "../../../components/common/LoadingBox";
import { AccountContext } from "../../../context/AccountContextComponent";
import { useGetEnpsResponses } from "../../../api/survey/surveyRunResponse";
import { FormProvider, useForm } from "react-hook-form";
import { SelectField } from "../../../components/fields/SelectField";
import { EnpsReportFilters } from "./EnpsReportFilters";
import { EnpsFullDataTable } from "./EnpsFullDataTable";
import CustomLineChart from "../../../components/common/charts/CustomLineChart";
import { reduceQuestionResponsesToEnpsScore } from "../../../utils/enps";
import { useIntl } from "react-intl";
import { allTenures, teamAlignment } from "../../../constants";

const EnpsReport = () => {
  const { accountId } = useAccountId();
  const { accountData, hasModuleTurnedOn } = useContext(AccountContext);
  const [departmentsToFilter, setDepartmentsToFilter] = useState([]);
  const [ranksToFilter, setRanksToFilter] = useState([]);
  const [tenuresToFilter, setTenuresToFilter] = useState([]);
  const intl = useIntl();

  const {
    data: enpsMetrics,
    isLoading: isLoadingEnpsMetrics,
    isError: isErrorEnpsMetrics,
    error: errorEnpsMetrics,
    fetchStatus,
  } = useGetEnpsResponses(
    accountId,
    departmentsToFilter,
    tenuresToFilter,
    ranksToFilter
  );

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      graphType: "all",
    },
  });

  const watchGraphType = form.watch("graphType");

  const formatData = (data) => {
    if (!data) return [];
    const departments = accountData.departments;
    const formattedData = data?.map((surveyRun) => {
      const departmentScores = {};
      const tenureScores = {};

      departments.forEach((department) => {
        departmentScores[department.name] = reduceQuestionResponsesToEnpsScore(
          surveyRun.enpsQuestion?.questionResponses.filter(
            (questionResponse) => {
              return (
                questionResponse.surveyRunResponse?.respondent?.department
                  ?.name === department.name
              );
            }
          )
        );
      });

      allTenures.forEach((tenure) => {
        tenureScores[tenure.label] = reduceQuestionResponsesToEnpsScore(
          surveyRun.enpsQuestion?.questionResponses.filter(
            (questionResponse) => {
              return (
                questionResponse.surveyRunResponse?.respondent?.tenure ===
                tenure.label
              );
            }
          )
        );
      });

      return {
        label: intl.formatDate(surveyRun.createdAt, {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }),
        survey: surveyRun.survey,
        surveyRun: {
          label: surveyRun.label,
        },
        departmentScores: {
          ...departmentScores,
        },
        tenureScores: {
          ...tenureScores,
        },
        rankingScores: {
          A: reduceQuestionResponsesToEnpsScore(
            surveyRun.enpsQuestion?.questionResponses.filter(
              (questionResponse) => {
                return (
                  questionResponse.surveyRunResponse?.respondent
                    ?.employeeABCRanking?.ranking === "A"
                );
              }
            )
          ),
          B: reduceQuestionResponsesToEnpsScore(
            surveyRun.enpsQuestion?.questionResponses.filter(
              (questionResponse) => {
                return (
                  questionResponse.surveyRunResponse?.respondent
                    ?.employeeABCRanking?.ranking === "B"
                );
              }
            )
          ),
          C: reduceQuestionResponsesToEnpsScore(
            surveyRun.enpsQuestion?.questionResponses.filter(
              (questionResponse) => {
                return (
                  questionResponse.surveyRunResponse?.respondent
                    ?.employeeABCRanking?.ranking === "C"
                );
              }
            )
          ),
        },
        All: reduceQuestionResponsesToEnpsScore(
          surveyRun.enpsQuestion?.questionResponses
        ),
      };
    });
    return formattedData;
  };

  const formattedData = formatData(enpsMetrics);

  const getViewByOptions = () => {
    const options = [
      {
        label: "All Employees",
        value: "all",
      },
      {
        label: "By Department",
        value: "byDepartment",
      },
      {
        label: "By Tenure",
        value: "byTenure",
      },
    ];

    if (hasModuleTurnedOn("ABC_RANKING")) {
      options.push({
        label: "By Rank",
        value: "byRank",
      });
    }

    return options;
  };

  return (
    <VStack spacing={8} alignItems={"flex-start"}>
      <VStack alignItems={"flex-start"} spacing={4}>
        <VStack alignItems={"flex-start"} w={"100%"}>
          <Text fontWeight={800} fontSize={"xl"}>
            eNPS Report
          </Text>
          <Box alignSelf={"flex-start"}>
            <FormProvider {...form}>
              <form onSubmit={() => {}}>
                <Wrap>
                  <WrapItem alignItems={"center"} fontWeight={700}>
                    <Text>View:</Text>
                  </WrapItem>
                  <WrapItem alignItems={"center"}>
                    <SelectField
                      field={{
                        id: "graphType",
                        validation: { required: true },
                      }}
                      options={getViewByOptions()}
                      selectStyles={{
                        input: (provided, state) => ({
                          ...provided,
                          color: "blue.500",
                          fontWeight: 700,
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          color: "blue.500",
                          fontWeight: 700,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }),
                      }}
                      selectProps={{
                        variant: "unstyled",
                      }}
                    />
                  </WrapItem>
                </Wrap>
              </form>
            </FormProvider>
          </Box>
          <EnpsReportFilters
            ranksToFilter={ranksToFilter}
            setRanksToFilter={setRanksToFilter}
            departmentsToFilter={departmentsToFilter}
            setDepartmentsToFilter={setDepartmentsToFilter}
            tenuresToFilter={tenuresToFilter}
            setTenuresToFilter={setTenuresToFilter}
            watchGraphType={watchGraphType}
          />
        </VStack>
        {isLoadingEnpsMetrics && fetchStatus !== "idle" && (
          <Box alignSelf={"center"}>
            <LoadingBox height={"600px"} />
          </Box>
        )}
        {watchGraphType === "byTenure" && (
          <EnpsChartByTenure data={formattedData} />
        )}
        {watchGraphType === "byDepartment" && (
          <EnpsChartByDepartment data={formattedData} />
        )}
        {watchGraphType === "byRank" && (
          <EnpsChartByRanking data={formattedData} />
        )}
        {watchGraphType === "all" && <EnpsChartAll data={formattedData} />}
        <EnpsFullDataTable
          enpsMetrics={formattedData}
          graphType={watchGraphType}
        />
      </VStack>
    </VStack>
  );
};

const EnpsChartAll = ({ data }) => {
  const formattedData = data?.map((surveyRun) => {
    return {
      label: surveyRun.label,
      Score: surveyRun.All,
    };
  });

  return (
    <Box
      overflow={"auto"}
      bg={"white"}
      w={"100%"}
      p={4}
      borderRadius={"md"}
      border={"1px "}
      borderColor={"gray.200"}
      maxW={"900px"}
      minW={"900px"}
    >
      <Box justifyContent={"center"}>
        <Text textAlign={"center"}>All Employees eNPS</Text>
      </Box>
      <CustomLineChart
        data={formattedData || []}
        yAxisDomain={[-100, 100]}
        lineProps={[
          {
            dataKey: "Score",
            stroke: "#36A2EB",
          },
        ]}
      />
    </Box>
  );
};

const EnpsChartByRanking = ({ data }) => {
  const { aRankLabel, bRankLabel, cRankLabel } = useContext(AccountContext);

  const formattedData = data?.map((surveyRun) => {
    return {
      label: surveyRun.label,
      [aRankLabel]: surveyRun.rankingScores.A,
      [bRankLabel]: surveyRun.rankingScores.B,
      [cRankLabel]: surveyRun.rankingScores.C,
      All: surveyRun.All,
    };
  });

  return (
    <Box
      overflow={"auto"}
      bg={"white"}
      w={"100%"}
      p={4}
      borderRadius={"md"}
      border={"1px "}
      borderColor={"gray.200"}
      maxW={"900px"}
      minW={"900px"}
    >
      <Box justifyContent={"center"}>
        <Text textAlign={"center"}>eNPS by Rank</Text>
      </Box>
      <CustomLineChart
        key={formattedData?.length}
        data={formattedData || []}
        yAxisDomain={[-100, 100]}
        lineProps={[
          {
            dataKey: "All",
            stroke: "#CBD5E0",
          },
          {
            dataKey: aRankLabel,
            stroke: teamAlignment.A.color,
          },
          {
            dataKey: bRankLabel,
            stroke: teamAlignment.B.color,
          },
          {
            dataKey: cRankLabel,
            stroke: teamAlignment.C.color,
          },
        ]}
      />
    </Box>
  );
};

const EnpsChartByDepartment = ({ data }) => {
  const { accountData } = useContext(AccountContext);
  const { departments } = accountData;

  const formattedData = data?.map((surveyRun) => ({
    label: surveyRun.label,
    ...surveyRun.departmentScores,
  }));

  const baseLineProp = { dataKey: "All", stroke: "#CBD5E0" };

  const colorOptions = [
    "#48BB78",
    "#36A2EB",
    "#F56565",
    "#FF9F40",
    "#FFCE56",
    "#4C51BF",
    "#ED64A6",
    "#38B2AC",
    "#ECC94B",
    "#9F7AEA",
    "#F687B3",
  ];

  const departmentLineProps = departments.map((department, index) => ({
    dataKey: department.name,
    stroke: colorOptions[index % colorOptions.length], // Safe cycling through color options
  }));

  departmentLineProps.unshift(baseLineProp);

  return (
    <Box
      overflow="auto"
      bg="white"
      w="100%"
      p={4}
      borderRadius="md"
      border="1px"
      borderColor="gray.200"
      maxW="900px"
      minW="900px"
    >
      <Box justifyContent={"center"}>
        <Text textAlign={"center"}>eNPS by Department</Text>
      </Box>
      <CustomLineChart
        data={formattedData || []}
        yAxisDomain={[-100, 100]}
        lineProps={departmentLineProps}
      />
    </Box>
  );
};

const EnpsChartByTenure = ({ data }) => {
  const formattedData = data?.map((surveyRun) => ({
    label: surveyRun.label,
    ...surveyRun.tenureScores,
  }));

  const baseLineProp = { dataKey: "All", stroke: "#CBD5E0" };

  const tenureLineProps = allTenures.map((tenure) => ({
    dataKey: tenure.label,
    stroke: tenure.color,
  }));

  tenureLineProps.unshift(baseLineProp);

  return (
    <Box
      overflow="auto"
      bg="white"
      w="100%"
      p={4}
      borderRadius="md"
      border="1px"
      borderColor="gray.200"
      maxW="900px"
      minW="900px"
    >
      <Box justifyContent={"center"}>
        <Text textAlign={"center"}>eNPS by Tenure</Text>
      </Box>
      <CustomLineChart
        data={formattedData || []}
        yAxisDomain={[-100, 100]}
        lineProps={tenureLineProps}
      />
    </Box>
  );
};

export default EnpsReport;
