import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";

export const CheckboxField = (props) => {
  const { field } = props;
  const form = useFormContext();
  const {
    formState: { errors },
    control,
    getValues,
  } = form;

  return (
    <Controller
      name={field.id}
      control={control}
      rules={field.validation}
      render={({ field: { value, onChange } }) => (
        <FormControl
          isInvalid={get(errors, field.id)}
          isRequired={field.validation?.required}
        >
          <Box key={field.value}>
            <Checkbox
              isDisabled={field.disabled}
              key={field.value}
              isChecked={value}
              colorScheme="blue"
              onChange={(val) => {
                onChange(val);
              }}
            >
              {field.label}
            </Checkbox>
          </Box>
          <FormErrorMessage>
            {get(errors, field.id) && get(errors, `${field.id}.message`)}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
