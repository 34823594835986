import { HStack, Link, Text, VStack } from "@chakra-ui/react";

const getDomain = (url) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  return anchor.hostname;
};

const LinksList = ({ links }) => {
  return (
    <>
      <VStack w={"100%"} alignItems={"flex-start"}>
        {links?.map((link, index) => {
          if (!link.label) return null;

          let cleanedUrl = link.url;
          if (cleanedUrl) {
            cleanedUrl = link.url.includes("://")
              ? link.url
              : `https://${link.url}`;
          }
          const isExternal = getDomain(cleanedUrl) !== window.location.hostname;

          return (
            <HStack fontSize={"sm"} key={index} fontWeight={700}>
              <Text color={"primary"}>•</Text>
              {cleanedUrl ? (
                <Link
                  href={cleanedUrl}
                  isExternal={isExternal}
                  target={isExternal ? "_blank" : "_self"}
                >
                  {link.label}
                </Link>
              ) : (
                <Text>{link.label}</Text>
              )}
            </HStack>
          );
        })}
      </VStack>
    </>
  );
};

export default LinksList;
