import {
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useMenuButton,
} from "@chakra-ui/react";
import * as React from "react";
import { BiBuoy, BiChat } from "react-icons/bi";
import { Crisp } from "crisp-sdk-web";
import GearIcon from "../../../assets/icons/settings.svg?react";

const activeBgColor = "#2F855A1F";

const HelpMenuButton = () => {
  const buttonProps = useMenuButton();

  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: activeBgColor }}
      _hover={{ bg: activeBgColor }}
      color="fontPrimary"
    >
      <HStack flex="1" spacing="3">
        <Icon as={GearIcon} fontSize="2xl" />
        <Text fontSize={"13px"}>Help & Support</Text>
      </HStack>
    </Flex>
  );
};

export const HelpButton = (props) => {
  return (
    <Menu>
      <HelpMenuButton />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue("gray.600", "gray.200")}
        px="3"
        maxH={"50vh"}
        overflow="auto"
      >
        <MenuItem
          rounded="md"
          as={Link}
          isExternal
          style={{ textDecoration: "none" }}
          _hover={{ bg: activeBgColor }}
          href="https://fallforward.notion.site/36d0be8ab5b542b79c4fe7a93ddf21fa?v=b2b9e82c145442e0b8fc0f44d71f3ae5"
          icon={<Icon as={BiBuoy} fontSize="xl" />}
        >
          Help center
        </MenuItem>
        <MenuItem
          rounded="md"
          icon={<Icon as={BiChat} fontSize="xl" />}
          _hover={{ bg: activeBgColor }}
          onClick={() => {
            Crisp.chat.open();
          }}
        >
          Support
        </MenuItem>

        <HStack spacing={2} pt={2}>
          <Link
            onClick={() => {
              window.open(
                "https://thebestanswer.com/privacy-policy/",
                "_blank"
              );
            }}
            variant={"outline"}
            fontSize={"xs"}
            textDecor={"none"}
          >
            Privacy Policy
          </Link>
          <Text fontSize={"xs"} color={"primary"}>
            •
          </Text>
          <Link
            onClick={() => {
              window.open(
                "https://thebestanswer.com/terms-of-service/",
                "_blank"
              );
            }}
            variant={"outline"}
            fontSize={"xs"}
            textDecor={"none"}
          >
            Terms of Service
          </Link>
        </HStack>
      </MenuList>
    </Menu>
  );
};
