import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../config";

export const useFetchOneOnOneTemplates = (accountId) => {
  return useQuery({
    queryKey: ["fetchOneOnOneTemplates"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/oneOnOneTemplate`);
      return response.data;
    },
  });
};

export const useUpdateTemplate = (props) =>
  useMutation({
    mutationKey: ["updateTemplate"],
    mutationFn: async ({ accountId, data, templateId }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneTemplate/${templateId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useCreateTemplate = (props) =>
  useMutation({
    mutationKey: ["createTemplate"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/oneOnOneTemplate`, data);
      return response.data;
    },
    ...props,
  });

export const useArchiveTemplate = (props) =>
  useMutation({
    mutationKey: ["archiveTemplate"],
    mutationFn: async ({ accountId, templateId }) => {
      const response = await api.delete(
        `/${accountId}/oneOnOneTemplate/${templateId}`
      );
      return response.data;
    },
    ...props,
  });
