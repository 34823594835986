import { Box } from "@chakra-ui/react";
import { SelectField } from "../../../components/fields/SelectField";
import { useFormContext } from "react-hook-form";

const getDefaultLayoutData = (type) => {
  const layoutData = {
    HEADER: {
      prompt: "My Header",
      element: {
        type: "HEADER",
      },
    },
    PARAGRAPH: {
      prompt: "My Paragraph",
      element: {
        type: "PARAGRAPH",
      },
    },
  };
  return layoutData[type];
};

const LayoutTypeSelector = ({ questionIndex, onChange }) => {
  const form = useFormContext();

  return (
    <Box w={"270px"}>
      <SelectField
        field={{
          id: `element.type`,
        }}
        options={[
          { label: "Header", value: "HEADER" },
          { label: "Paragraph", value: "PARAGRAPH" },
        ]}
        onSelectionChange={(value) => {
          // Clear out the old layout element
          const layoutType = value.value;
          const newQuestionData = getDefaultLayoutData(layoutType);

          form.setValue(`element`, { ...newQuestionData.element });
          form.setValue(`prompt`, newQuestionData.prompt);
          form.clearErrors(`element`);
          onChange();
        }}
      />
    </Box>
  );
};

export default LayoutTypeSelector;
