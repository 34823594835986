import { Stack } from "@chakra-ui/react";
import { SidebarItem } from "./SidebarItem";

export const SidebarSection = ({ sidebarSection, index }) => {
  return (
    <Stack pb={2} spacing={0} id={sidebarSection.id}>
      {sidebarSection.items.map((sidebarItem, itemIndex) => (
        <SidebarItem sidebarItem={sidebarItem} key={itemIndex} />
      ))}
    </Stack>
  );
};
