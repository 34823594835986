import React from "react";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Tooltip,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { useIntl } from "react-intl";

export const DateField = (props) => {
  const {
    field,
    leftAddon,
    onDateChange,
    showTimeSelect,
    dateFormat,
    isFakeRequired,
    datePickerProps
  } = props;
  const form = useFormContext();
  const {
    formState: { errors },
    register,
    control,
  } = form;
  const intl = useIntl();

  // Get the user's timezone
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneShort = Intl.DateTimeFormat("en", { timeZoneName: "short" })
    .formatToParts()
    .find((p) => p.type === "timeZoneName").value;

  const isValidDate = (d) => {
    const date = new Date(d);
    return date instanceof Date && !isNaN(date.getTime());
  };

  return (
    <Controller
      name={field.id}
      control={control}
      defaultValue={null}
      rules={field.validation || {}}
      render={({ field: { onChange, value } }) => (
        <FormControl
          isInvalid={get(errors, field.id)}
          isRequired={field.label && field.validation?.required}
        >
          {(field.label || showTimeSelect) && (
            <Tooltip
              label={
                field.tooltipId &&
                intl.formatMessage({
                  id: field.tooltipId,
                })
              }
            >
              <FormLabel>
                {field.label} {showTimeSelect && `(${timezoneShort})`}{" "}
                {isFakeRequired && <span style={{ color: "#E53E3E" }}>*</span>}
              </FormLabel>
            </Tooltip>
          )}
          <InputGroup>
            {leftAddon}
            <DatePicker
              {...register(field.id, {
                //validate: () => {},
                validation: field.validation,
                valueAsNumber: false,
              })}
              wrapperClassName="datePicker"
              autoComplete="off"
              portalId="my-portal"
              selected={
                value && isValidDate(value)
                  ? utcToZonedTime(new Date(value), timezone)
                  : null
              }
              onChange={async (date) => {
                if (!date || !isValidDate(date)) {
                  console.log("Invalid date");
                  onChange(null);
                  return;
                }
                // Convert selected date back to UTC
                const utcDate = zonedTimeToUtc(date, timezone);
                onChange(utcDate);
                // Need to wait for errors to be updated
                await form.trigger(field.id);
                if (!get(errors, field.id) && onDateChange) {
                  onDateChange(utcDate);
                }
              }}
              onBlur={() => {
                form.trigger(field.id);
              }}
              dateFormat={dateFormat || (showTimeSelect ? "Pp" : "P")}
              showTimeSelect={showTimeSelect}
              customInput={<Input placeholder="yyyy-MM-dd" />}
              {...datePickerProps}
            />
          </InputGroup>
          <FormErrorMessage>
            {get(errors, field.id) && get(errors, `${field.id}.message`)}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
