import {
  Button,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useToastMessage } from "../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { SelectField } from "../../components/fields/SelectField";
import { useContext, useMemo } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import { useCreateShoutout } from "../../api/shoutouts/shoutout";
import { CreatableSelectField } from "../../components/fields/CreatableSelectField";
import useAccountId from "../../hooks/customDomainHooks";
import { useSearchParams } from "react-router-dom";
import HeartIcon from "../../assets/icons/heart.svg?react";

const CreateShoutout = ({ onSuccess }) => {
  const { accountId } = useAccountId();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const createShoutoutMutation = useCreateShoutout();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const { accountData, isLoading, hasModuleTurnedOn, currentProfile } =
    useContext(AccountContext);

  const recipientFromSearchParam = useMemo(
    () => searchParams.get("recipient"),
    [searchParams]
  );

  const defaultValues = {
    text: "",
    valueTags: [],
    recipients: recipientFromSearchParam ? [recipientFromSearchParam] : [],
  };

  const form = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = form;

  const recipients = watch("recipients");

  const createShoutout = async (data) => {
    try {
      await createShoutoutMutation.mutateAsync({ accountId, data });
      showSuccessToast("Shouotut created!");
      onSuccess();
      reset(defaultValues);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating shoutout" });
    }
  };

  const onSubmit = async (data) => {
    await createShoutout(data);
    queryClient.invalidateQueries(["fetchShoutouts"]);
    queryClient.invalidateQueries(["fetchAccount", accountId]);
  };

  const canCreateCustomTag = hasModuleTurnedOn(
    "SHOUTOUTS_ALLOW_USER_CREATION_OF_TAGS"
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Give a shoutout to...</FormLabel>
            <SelectField
              field={{
                id: "recipients",
                validation: {
                  required: true,
                },
                isMulti: true,
              }}
              options={accountData?.profiles
                .filter((profile) => profile.id !== currentProfile.id)
                .map((profile) => ({
                  value: profile.id,
                  label: profile.name,
                }))}
              onSelectionChange={(selected) => {
                console.log(selected);
              }}
              isLoading={isLoading}
              isClearable={true}
            />
          </FormControl>
          {recipients && recipients.length > 0 && (
            <>
              <FormControl isRequired>
                <FormLabel>Message</FormLabel>
                <Textarea
                  type="text"
                  {...register("text", { required: "Message is required" })}
                  noOfLines={4}
                  style={{ backgroundColor: "white" }}
                />
                {errors.name && (
                  <Text color="red.500">{errors.name.message}</Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Tags</FormLabel>
                {canCreateCustomTag ? (
                  <CreatableSelectField
                    field={{
                      id: "valueTags",
                      validation: {},
                    }}
                    options={accountData?.valueTags.map((valueTag) => ({
                      value: valueTag.label,
                      label: valueTag.label,
                    }))}
                    isLoading={isLoading}
                    isMulti={true}
                    isClearable={true}
                  />
                ) : (
                  <SelectField
                    field={{
                      id: "valueTags",
                      validation: {},
                      isMulti: true,
                    }}
                    options={accountData?.valueTags.map((valueTag) => ({
                      value: valueTag.label,
                      label: valueTag.label,
                    }))}
                    isLoading={isLoading}
                    isClearable={true}
                  />
                )}
              </FormControl>
              <Button
                type="submit"
                isLoading={isSubmitting}
                leftIcon={<HeartIcon stroke={"white"} fill={"white"} />}
              >
                Post Shoutout
              </Button>
            </>
          )}
        </VStack>
      </form>
    </FormProvider>
  );
};

export default CreateShoutout;
