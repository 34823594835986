import { useState, useEffect } from "react";
import { auth } from "./firebase";

const useAuthState = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    // Cleanup on unmount
    return () => {
      unsubscribe();
    };
  }, []);

  return { user, setUser, loading };
};

export default useAuthState;
