import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useToastMessage } from "../../hooks/useToastMessage";
import {
  useCreateProfile,
  useSendInviteEmail,
  useUpdateProfile,
} from "../../api/profiles";
import { useQueryClient } from "@tanstack/react-query";
import { SelectField } from "../../components/fields/SelectField";
import { CreatableSelectField } from "../../components/fields/CreatableSelectField";
import { useContext, useEffect, useMemo, useState } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import { MdClose } from "react-icons/md";
import { PhoneInputField } from "../../components/fields/PhoneInputField";
import useAccountId from "../../hooks/customDomainHooks";
import { DateField } from "../../components/fields/DateField";
import { AreYouSureModal } from "../../components/common/AreYouSureModal";
import UsageChangeNotification from "../../components/common/UsageChangeNotification";
import { CheckboxField } from "../../components/fields/CheckBoxField";

const EditEmployee = ({ mode, onSuccess, member }) => {
  const { accountId } = useAccountId();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const createProfileMutation = useCreateProfile();
  const updateProfileMutation = useUpdateProfile();
  const sendInviteEmailMutation = useSendInviteEmail();
  const queryClient = useQueryClient();
  const { accountData, currentProfile, isLoading } = useContext(AccountContext);
  const [doReset, setDoReset] = useState(false);
  const [doSendInviteEmail, setDoSendInviteEmail] = useState(false);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [formData, setFormData] = useState({});

  if (mode === "edit" && !member) {
    return;
  }
  const defaultValues =
    mode === "edit"
      ? {
          email: member.email,
          name: member.name,
          role: member.role,
          hasBillingAccess: member.hasBillingAccess,
          title: member.title,
          levelName: member.level?.name,
          departmentName: member.department?.name,
          reportsToId: member.reportsToId,
          employeeId: member.employeeId,
          // officeLocationId: member.officeLocation?.id,
          phoneNumbers: member.phoneNumbers,
          startDate: member.startDate,
          endDate: member.endDate,
          terminationTagId: member.terminationTagId,
        }
      : {
          email: "",
          name: "",
          role: "EMPLOYEE",
          hasBillingAccess: false,
          title: "",
          levelName: "",
          departmentName: "",
          reportsToId: "",
          employeeId: "",
          officeLocationId: "",
          phoneNumbers: [],
          startDate: "",
        };

  const form = useForm({
    mode: "onChange",
    defaultValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    watch,
    setValue,
    reset,
  } = form;

  const watchRole = watch("role");

  // if watchRole is EMPLOYEE, clear out hasBillingAccess
  useEffect(() => {
    if (watchRole === "EMPLOYEE") {
      setValue("hasBillingAccess", false);
    } else if (watchRole === "OWNER") {
      setValue("hasBillingAccess", true);
    } else if (watchRole === "ADMIN") {
      setValue("hasBillingAccess", member?.hasBillingAccess || false);
    }
  }, [watchRole, reset, defaultValues]);

  // There's a bug in react-hook-form where reset() doeesn't work when
  // called async with useFieldArray. So this is a workaround.
  useEffect(() => {
    if (doReset) {
      reset(defaultValues);
      setDoReset(false);
    }
  }, [doReset, reset, defaultValues]);

  const createProfile = async (data) => {
    try {
      await createProfileMutation.mutateAsync({ accountId, data });
      showSuccessToast(
        "Member created",
        "The member has been successfully created."
      );
      reset(defaultValues);
      setDoReset(true);
      onSuccess();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating member" });
    }
  };

  const updateProfile = async (profileId, data) => {
    try {
      await updateProfileMutation.mutateAsync({ accountId, data, profileId });
      showSuccessToast(
        "Member updated",
        "The member has been successfully updated."
      );
      onSuccess();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating member" });
    }
  };

  const sendInviteEmail = async (email) => {
    try {
      await sendInviteEmailMutation.mutateAsync({
        accountId,
        data: { email },
      });
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error sending invite email" });
    }
  };

  const updateOrCreate = async (data) => {
    if (mode === "edit") {
      await updateProfile(member.id, data);
    }
    if (mode === "create") {
      await createProfile(data);
      if (doSendInviteEmail) {
        await sendInviteEmail(data.email);
      }
    }
    queryClient.invalidateQueries(["fetchAccount", accountId]);
    queryClient.invalidateQueries(["getMe"]);
  };

  const onSubmit = async (data) => {
    setFormData(data);
    // If the user is an owner and setting the role to owner and not editing themself, we need to
    // notify them what they are doing.
    if (
      currentProfile.role === "OWNER" &&
      data.role === "OWNER" && member &&
      currentProfile.id !== member.id
    ) {
      setIsAreYouSureModalOpen(true);
      return;
    }
    updateOrCreate(data);
  };

  const getRoleOptions = () => {
    if (
      mode === "edit" &&
      member.role === "OWNER" &&
      currentProfile.role === "ADMIN"
    ) {
      return [{ value: "OWNER", label: "Owner" }];
    }
    // Can only assign OWNER role to an employee if you are an owner
    // and the member is active not newly created or invited
    // to prevent losing the OWNER role accidentally
    if (currentProfile.role === "OWNER" && mode === "edit" && profileIsActive(member)) {
      return [
        { value: "EMPLOYEE", label: "Employee" },
        { value: "ADMIN", label: "Admin" },
        { value: "OWNER", label: "Owner" },
      ];
    }
    if (currentProfile.role === "OWNER") {
      return [
        { value: "EMPLOYEE", label: "Employee" },
        { value: "ADMIN", label: "Admin" },
      ];
    }
    if (currentProfile.role === "ADMIN") {
      return [
        { value: "EMPLOYEE", label: "Employee" },
        { value: "ADMIN", label: "Admin" },
      ];
    }
    if (currentProfile.role === "PARTNER") {
      return [
        { value: "EMPLOYEE", label: "Employee" },
        { value: "ADMIN", label: "Admin" },
        { value: "OWNER", label: "Owner" },
      ];
    }
    return [{ value: "EMPLOYEE", label: "Employee" }];
  };

  const terminationTagOptions = useMemo(() => {
    return accountData?.terminationTags?.map((reason) => ({
      label: reason.label,
      value: reason.id,
    }));
  }, [accountData]);

  return (
    <>
      <AreYouSureModal
        isOpen={isAreYouSureModalOpen}
        isDeleting={updateProfileMutation.isLoading}
        setIsOpen={setIsAreYouSureModalOpen}
        headerText={"Are you sure?"}
        bodyText={`Are you sure you want to re-assign the Owner role to another user? Your role will be changed to Admin.`}
        actionFunction={async () => {
          await updateOrCreate(formData);
        }}
        buttonText={"Change owner"}
      />
      <FormProvider {...form}>
        <form>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                autoComplete="off"
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && (
                <Text color="red.500">{errors.name.message}</Text>
              )}
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                {...register("email", { required: "Email is required" })}
              />
              {errors.email && (
                <Text color="red.500">{errors.email.message}</Text>
              )}
            </FormControl>
            <HStack
              w={"100%"}
              spacing={"30px"}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
            >
              <FormControl>
                <FormLabel>Role</FormLabel>
                <SelectField
                  field={{
                    id: "role",
                    validation: {},
                  }}
                  options={getRoleOptions()}
                  isLoading={isLoading}
                />
              </FormControl>
              <Box w={"300px"}>
                <FormControl>
                  <FormLabel>Has billing access?</FormLabel>
                  <Box pt={"12px"}>
                    <CheckboxField
                      field={{
                        id: `hasBillingAccess`,
                        label: "Yes",
                        disabled: watchRole !== "ADMIN",
                      }}
                    />
                  </Box>
                </FormControl>
              </Box>
            </HStack>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input type="text" {...register("title")} />
            </FormControl>
            <FormControl>
            <FormLabel>Level</FormLabel>
              <CreatableSelectField
                field={{
                  id: "levelName",
                  validation: {},
                }}
                options={accountData?.levels?.map((level) => ({
                  value: level.name,
                  label: level.name,
                })) || []}
                isLoading={isLoading}
                isClearable={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Department</FormLabel>
              <CreatableSelectField
                field={{
                  id: "departmentName",
                  validation: {},
                }}
                options={accountData?.departments.map((department) => ({
                  value: department.name,
                  label: department.name,
                }))}
                isLoading={isLoading}
                isClearable={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Reports To</FormLabel>
              <SelectField
                field={{
                  id: "reportsToId",
                  validation: {},
                }}
                options={accountData?.profiles
                  ?.filter((profile) => profile.id !== member?.id)
                  .map((profile) => ({
                    value: profile.id,
                    label: profile.name,
                  }))}
                isLoading={isLoading}
                isClearable={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Hired on</FormLabel>
              <DateField
                field={{
                  id: `startDate`,
                }}
              />
            </FormControl>
            {member && !member.isActive && (
              <HStack
                w={"100%"}
                spacing={"30px"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
              >
                <FormControl>
                  <FormLabel>End date</FormLabel>
                  <DateField
                    field={{
                      id: `endDate`,
                      validation: {
                        required: "End date is required for archived members",
                      },
                    }}
                  />
                </FormControl>
                <SelectField
                  field={{
                    id: "terminationTagId",
                    label: "Termination Reason",
                  }}
                  options={terminationTagOptions}
                />
              </HStack>
            )}

            <FormControl>
              <FormLabel>Employee ID</FormLabel>
              <Input
                type="text"
                autoComplete="off"
                {...register("employeeId")}
              />
            </FormControl>
            {/* <FormControl>
            <FormLabel>Office Location</FormLabel>
            <Input type="text" {...register("officeLocationId")} />
          </FormControl> */}

            <PhoneNumbersList control={form.control} />
            <UsageChangeNotification
              subscriptionPlan={
                accountData.accountSubscription?.subscriptionPlan
              }
              currentNumEmployees={
                accountData?.profiles?.filter(
                  (profile) => profile.isActive && profile.role === "EMPLOYEE"
                )?.length
              }
              currentNumAdmins={
                accountData?.profiles?.filter(
                  (profile) =>
                    profile.isActive &&
                    (profile.role === "OWNER" || profile.role === "ADMIN")
                )?.length
              }
              newUsageType={
                watchRole === "EMPLOYEE" ? "ADD_EMPLOYEE" : "ADD_ADMIN"
              }
            />
            <Box w={"100%"}>
              {mode === "edit" && (
                <HStack w={"100%"} justifyContent={"space-between"}>
                  <Box w={"10px"} />
                  <Button
                    isLoading={updateProfileMutation.isLoading}
                    onClick={handleSubmit((data) => onSubmit(data))}
                    isDisabled={
                      isValid === false ||
                      (member.role === "OWNER" &&
                        currentProfile.role === "ADMIN")
                    }
                  >
                    Update Employee
                  </Button>
                </HStack>
              )}

              {mode === "create" && (
                <VStack alignItems={"flex-end"}>
                  <Checkbox
                    isChecked={doSendInviteEmail}
                    onChange={(e) => {
                      setDoSendInviteEmail(e.target.checked);
                    }}
                  >
                    Send invite email
                  </Checkbox>
                  <HStack w={"100%"} justifyContent={"space-between"}>
                    <Box w={"10px"} />
                    <HStack>
                      <Button
                        isLoading={isSubmitting}
                        onClick={onSuccess}
                        variant={"ghost"}
                      >
                        Cancel
                      </Button>
                      <Button
                        isLoading={createProfileMutation.isLoading}
                        onClick={handleSubmit((data) => onSubmit(data))}
                      >
                        Add Employee
                      </Button>
                    </HStack>
                  </HStack>
                </VStack>
              )}
            </Box>
          </VStack>
        </form>
      </FormProvider>
    </>
  );
};

const profileIsActive = (member) => {
  // this helper function lets us know if the profile
  // has not been archived, if they've been invted and created a user
  return member.isActive && member.userId && member.invitedAt;
}

const PhoneNumbersList = ({ control }) => {
  const {
    fields: phoneNumbers,
    append: appendPhoneNumber,
    remove: removePhoneNumber,
  } = useFieldArray({
    control: control,
    name: "phoneNumbers",
  });

  return (
    <FormControl>
      <FormLabel>Phone Numbers</FormLabel>
      <VStack>
        {phoneNumbers.map((phoneNumber, index) => (
          <HStack w={"100%"} key={phoneNumber.id}>
            <PhoneInputField
              field={{
                id: `phoneNumbers.${index}.number`,
                initialValue: phoneNumber.number,
              }}
            />
            <CreatableSelectField
              field={{
                id: `phoneNumbers.${index}.label`,
                validation: {},
              }}
              options={[
                { value: "WORK", label: "Work" },
                { value: "HOME", label: "Home" },
                { value: "MOBILE", label: "Mobile" },
                { value: "OTHER", label: "Other" },
              ]}
            />
            <IconButton
              icon={<MdClose />}
              variant="ghost"
              p={0}
              onClick={() => {
                removePhoneNumber(index);
              }}
            />
          </HStack>
        ))}
        <Button
          onClick={() => {
            appendPhoneNumber({
              number: "",
              label: "WORK",
            });
          }}
          alignSelf={"flex-start"}
          variant="outline"
        >
          Add Phone Number
        </Button>
      </VStack>
    </FormControl>
  );
};
export default EditEmployee;
