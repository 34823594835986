import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useFetchDepartments = (accountId) => {
  return useQuery({
    queryKey: ["fetchDepartments", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/department`);
      return response.data;
    },
  });
};

export const useFetchDepartment = (accountId, departmentId) => {
  return useQuery({
    queryKey: ["fetchDepartment", departmentId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/department/${departmentId}`
      );
      return response.data;
    },
  });
};

export const useCreateDepartment = (props) =>
  useMutation({
    mutationKey: ["createDepartment"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/department`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateDepartment = (props) =>
  useMutation({
    mutationKey: ["updateDepartment"],
    mutationFn: async ({ accountId, departmentId, data }) => {
      const response = await api.put(
        `/${accountId}/department/${departmentId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useAddDepartmentMembers = (props) =>
  useMutation({
    mutationKey: ["addDepartmentMembers"],
    mutationFn: async ({ accountId, departmentId, data }) => {
      const response = await api.put(
        `/${accountId}/department/${departmentId}/addMembers`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useRemoveDepartmentMembers = (props) =>
  useMutation({
    mutationKey: ["removeDepartmentMembers"],
    mutationFn: async ({ accountId, departmentId, data }) => {
      const response = await api.put(
        `/${accountId}/department/${departmentId}/removeMembers`,
        data
      );
      return response.data;
    },
    ...props,
  });
