import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  VStack,
} from "@chakra-ui/react";
import {
  getAuth,
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from "firebase/auth";
import useToastMessage from "../../../hooks/useToastMessage";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export const TwoFactorAuthModal = (props) => {
  const { isOpen, setIsOpen, onSuccess } = props;
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const [isSendingVerificationCode, setIsSendingVerificationCode] =
    useState(false);
  const [hasSentVerificationCode, setHasSentVerificationCode] = useState(false);
  const auth = getAuth();

  const getEnrolledPhoneFactors = () => {
    const enrolledPhoneFactors = [];
    multiFactor(auth.currentUser).enrolledFactors.forEach(async (factor) => {
      if (factor.factorId == "phone") {
        enrolledPhoneFactors.push(factor);
      }
    });
    return enrolledPhoneFactors;
  };

  const sendSmsWithCode = async (e) => {
    e.preventDefault();
    setIsSendingVerificationCode(true);
    const auth = getAuth();
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "add-2fa-recaptcha-container",
      { size: "invisible" }
    );
    multiFactor(auth.currentUser)
      .getSession()
      .then(function (multiFactorSession) {
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);

        // Send SMS verification code.
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
          .then(function (verificationId) {
            // Save the verification ID.
            setVerificationId(verificationId);
            setIsSendingVerificationCode(false);
            setHasSentVerificationCode(true);
            // recaptchaVerifier.clear();
          })
          .catch(function (error) {
            // Error occurred.
            console.log("error", error);
            if (error.code === "auth/second-factor-already-in-use") {
              showErrorToast({
                message: "Phone number already in use",
              });
            }
            if (error.code === "auth/invalid-phone-number") {
              showErrorToast({
                message: "Invalid phone number",
              });
            }
            setIsSendingVerificationCode(false);
            //recaptchaVerifier.clear();
          });
      });
  };

  const submitVerificationCode = async (e) => {
    e.preventDefault();
    const verificationCode = e.target.verificationCode.value;
    setIsVerifyingCode(true);
    const auth = getAuth();
    try {
      const currentEnrolledPhoneFactors = getEnrolledPhoneFactors();
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      // Complete enrollment.
      await multiFactor(auth.currentUser).enroll(
        multiFactorAssertion,
        "SMS_MFA"
      );
      for (const factor of currentEnrolledPhoneFactors) {
        await multiFactor(auth.currentUser).unenroll(factor.uid);
      }
      showSuccessToast("2FA successfully added");
      setIsVerifyingCode(false);
      setHasSentVerificationCode(false);
      setIsOpen(false);
      onSuccess();
    } catch (error) {
      if (error.code === "auth/invalid-verification-code") {
        showErrorToast({
          message: "Invalid verification code",
        });
        setIsVerifyingCode(false);
        return;
      }
      console.log("error", error);
      showErrorToast({
        message: "Error adding 2FA",
      });
      setIsVerifyingCode(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setIsSendingVerificationCode(false);
        setIsVerifyingCode(false);
        setHasSentVerificationCode(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{"Set your phone number"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={{ base: 2, md: 5 }}>
            <form onSubmit={sendSmsWithCode}>
              <VStack>
                <FormControl isRequired>
                  <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    defaultCountry="US"
                    inputComponent={Input}
                  />
                </FormControl>
                <Button
                  variant="solid"
                  isLoading={isSendingVerificationCode}
                  type="submit"
                >
                  Send Verification Code
                </Button>
              </VStack>
            </form>
            {hasSentVerificationCode && (
              <form onSubmit={submitVerificationCode}>
                <VStack>
                  <FormControl isRequired>
                    <FormLabel htmlFor="verificationCode">
                      Verification Code
                    </FormLabel>
                    <Input id="verificationCode" type="text" />
                  </FormControl>
                  <Button
                    variant="solid"
                    isLoading={isVerifyingCode}
                    type="submit"
                  >
                    Verify Code
                  </Button>
                </VStack>
              </form>
            )}
            <div
              id="add-2fa-recaptcha-container"
              style={{ display: "flex", justifyContent: "center" }}
            ></div>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={() => {
              setIsOpen(false);
              setIsSendingVerificationCode(false);
              setIsVerifyingCode(false);
              setHasSentVerificationCode(false);
            }}
            variant="outline"
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
