import PageShell from "../../layout/PageShell";
import { Text } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import LoadingBox from "../../components/common/LoadingBox";
import { useFetchCultureHubLayout } from "../../api/cultureHub/cultureHubLayout";
import CultureHubLayout from "./CultureHubLayout";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";

const CultureHubPage = () => {
  const { accountId } = useAccountId();
  const { currentProfile, accountData, isLoading } = useContext(AccountContext);
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);

  const {
    data: cultureHubLayout,
    isLoading: isLoadingCultureHubLayout,
    isError: isErrorCultureHubLayout,
    error: errorCultureHubLayout,
  } = useFetchCultureHubLayout(accountId);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Culture Hub" },
  ]);

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "CULTURE_HUB",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
      id={"culture-hub-page"}
    >
      <InfoBox
        pageInfoType={"CULTURE_HUB"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />

      {(isLoadingCultureHubLayout || !accountData) && (
        <LoadingBox height={"100px"} />
      )}
      {isErrorCultureHubLayout && (
        <Text color={"red"}>Error loading dashboard</Text>
      )}
      {!isLoadingCultureHubLayout && !cultureHubLayout && (
        <Text color={"red"}>Dashboard not found</Text>
      )}
      {cultureHubLayout && accountData && (
        <CultureHubLayout cultureHubLayout={cultureHubLayout} />
      )}
    </PageShell>
  );
};

export default CultureHubPage;
