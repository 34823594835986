import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Stack,
  Switch,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";
import { useIntl } from "react-intl";

export const SwitchField = (props) => {
  const { field, onSwitchChange, leftLabel, rightLabel } = props;
  const form = useFormContext();
  const {
    formState: { errors },
    control,
  } = form;
  const intl = useIntl();

  return (
    <Controller
      name={field.id}
      control={control}
      defaultValue={field.defaultValue || false}
      rules={field.validation?.required}
      render={({ field: { onChange, value } }) => (
        <FormControl
          isInvalid={get(errors, field.id)}
          isRequired={field.label && field.validation?.required}
        >
          <VStack alignItems={"flex-start"}>
            {field.label && (
              <Tooltip
                label={
                  field.tooltipId &&
                  intl.formatMessage({
                    id: field.tooltipId,
                  })
                }
              >
                <FormLabel htmlFor={field.id}>{field.label}</FormLabel>
              </Tooltip>
            )}
            <HStack>
              {leftLabel && <Text>{leftLabel}</Text>}
              <Switch
                id={field.id}
                name={field.id}
                key={field.id}
                isChecked={value}
                isDisabled={field.isDisabled}
                onChange={(val) => {
                  onChange(val);
                  if (onSwitchChange) {
                    onSwitchChange(val);
                  }
                }}
              />
              {rightLabel && <Text>{rightLabel}</Text>}
            </HStack>
          </VStack>
          <FormErrorMessage>
            {get(errors, field.id) && get(errors, `${field.id}.message`)}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
