import { Box, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import useAccountId from "../../../hooks/customDomainHooks";
import { useContext, useEffect, useMemo, useState } from "react";
import LoadingBox from "../../../components/common/LoadingBox";
import { AccountContext } from "../../../context/AccountContextComponent";
import {
  useFetchAbcRankingPeriodMetrics,
  useFetchAbcRankingPeriods,
} from "../../../api/abcRankings/abcRankingPeriod";
import { SelectField } from "../../../components/fields/SelectField";
import { FormProvider, useForm } from "react-hook-form";
import { TeamAlignmentReportFilters } from "./TeamAlignmentReportFilters";
import { AllEmployeesChart } from "./AllEmployeesChart";
import { AllEmployeesChartLastSix } from "./AllEmployeesChartLastSix";
import { ByTenureChart } from "./ByTenureChart";
import { ByDepartmentChart } from "./ByDepartmentChart";
import { TeamAlignmentFullDataTable } from "./TeamAlignmentFullDataTable";
import { allTenures, teamAlignment } from "../../../constants";

const TeamAlignmentReport = () => {
  const { accountId } = useAccountId();
  const { accountData } = useContext(AccountContext);
  const [tenuresToFilter, setTenuresToFilter] = useState([]);
  const [departmentsToFilter, setDepartmentsToFilter] = useState([]);

  const {
    data: rankingPeriods,
    isLoading: isLoadingAbcRankingPeriods,
    isError: isErrorAbcRankingPeriods,
    error: errorAbcRankingPeriods,
  } = useFetchAbcRankingPeriods(accountId);

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      graphType: "all",
      timePeriod: "LAST_6_REPORTS", // timePeriodOptions?.[0]?.value,
    },
  });

  const watchGraphType = form.watch("graphType");
  const watchTimePeriod = form.watch("timePeriod");

  const timePeriodOptions = useMemo(() => {
    const formattedTimePeriods =
      rankingPeriods?.map((rankingPeriod) => ({
        label: rankingPeriod?.period?.label,
        value: rankingPeriod?.period?.id,
      })) || [];
    if (watchGraphType === "all") {
      formattedTimePeriods.unshift({
        label: "Last 6 Reports",
        value: "LAST_6_REPORTS",
      });
    }
    return formattedTimePeriods;
  }, [accountData, rankingPeriods, watchGraphType]);

  useEffect(() => {
    if (watchGraphType !== "all") {
      const defaultValue = rankingPeriods[0]?.period?.id;
      if (defaultValue) {
        form.setValue("timePeriod", defaultValue);
      }
    }
    setTenuresToFilter([]);
    setDepartmentsToFilter([]);
  }, [watchGraphType, form.setValue]);

  const {
    data: abcRankingPeriodMetrics,
    isLoading: isLoadingAbcRankingPeriodMetrics,
    isError: isErrorAbcRankingPeriodMetrics,
    error: errorAbcRankingPeriodMetrics,
    fetchStatus,
  } = useFetchAbcRankingPeriodMetrics(
    accountId,
    watchTimePeriod,
    departmentsToFilter,
    tenuresToFilter
  );

  const aCount = abcRankingPeriodMetrics?.reduce((acc, curr) => {
    return acc + (curr.ranking === "A" ? 1 : 0);
  }, 0);
  const bCount = abcRankingPeriodMetrics?.reduce((acc, curr) => {
    return acc + (curr.ranking === "B" ? 1 : 0);
  }, 0);
  const cCount = abcRankingPeriodMetrics?.reduce((acc, curr) => {
    return acc + (curr.ranking === "C" ? 1 : 0);
  }, 0);
  const unrankedCount = abcRankingPeriodMetrics?.reduce((acc, curr) => {
    return acc + (!curr.ranking ? 1 : 0);
  }, 0);

  const allEmployeeRankingData = {
    A: { count: aCount, color: teamAlignment.A.color },
    B: { count: bCount, color: teamAlignment.B.color },
    C: { count: cCount, color: teamAlignment.C.color },
    Unranked: {
      count: unrankedCount,
      color: teamAlignment.Unranked.color,
    },
  };

  const getAbcRankingPeriodMetricsByTenure = () => {
    if (!abcRankingPeriodMetrics) return null;

    const tenureTemplate = { A: 0, B: 0, C: 0, Unranked: 0 };

    // Dynamically create allEmployeeTenureData using allTenures
    const allEmployeeTenureData = allTenures.reduce((acc, tenure) => {
      acc[tenure.label] = { ...tenureTemplate };
      return acc;
    }, {});

    abcRankingPeriodMetrics.forEach(({ ranking, employee }) => {
      const { tenureDays } = employee;
      const tenure = allTenures.find(
        (t) => t.value.minDays <= tenureDays && tenureDays <= t.value.maxDays
      );

      if (tenure) {
        allEmployeeTenureData[tenure.label][ranking || "Unranked"]++;
      }
    });

    return Object.entries(allEmployeeTenureData).map(([tenure, rankings]) => ({
      label: tenure,
      ...rankings,
    }));
  };

  const getAbcRankingPeriodMetricsByDepartment = () => {
    if (!abcRankingPeriodMetrics) return null;

    const departmentTemplate = { A: 0, B: 0, C: 0, Unranked: 0 };

    // Initializing the data structure using a dynamic approach
    let allEmployeeDepartmentData = {};

    abcRankingPeriodMetrics.forEach(({ ranking, employee }) => {
      const departmentName = employee.department?.name || "Unassigned";

      // If the department hasn't been added to our data structure, add it
      if (!allEmployeeDepartmentData[departmentName]) {
        allEmployeeDepartmentData[departmentName] = { ...departmentTemplate };
      }

      allEmployeeDepartmentData[departmentName][ranking || "Unranked"]++;
    });

    return Object.entries(allEmployeeDepartmentData).map(
      ([department, rankings]) => ({
        label: department,
        ...rankings,
      })
    );
  };
  const allEmployeeTenureData = getAbcRankingPeriodMetricsByTenure();
  const allEmployeeDepartmentData = getAbcRankingPeriodMetricsByDepartment();

  return (
    <VStack alignItems={"flex-start"} spacing={4}>
      <VStack alignItems={"flex-start"} w={"100%"}>
        <Text fontWeight={800} fontSize={"xl"}>
          Team Alignment Report
        </Text>
        <Box alignSelf={"flex-start"}>
          <FormProvider {...form}>
            <form onSubmit={() => {}}>
              <Wrap>
                <WrapItem alignItems={"center"} fontWeight={700}>
                  <Text>View:</Text>
                </WrapItem>
                <WrapItem alignItems={"center"}>
                  <SelectField
                    field={{
                      id: "graphType",
                      validation: { required: true },
                    }}
                    options={[
                      {
                        label: "All Employees",
                        value: "all",
                      },
                      {
                        label: "By Tenure",
                        value: "byTenure",
                      },
                      {
                        label: "By Department",
                        value: "byDepartment",
                      },
                    ]}
                    selectStyles={{
                      input: (provided, state) => ({
                        ...provided,
                        color: "blue.500",
                        fontWeight: 700,
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        color: "blue.500",
                        fontWeight: 700,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }),
                    }}
                    selectProps={{
                      variant: "unstyled",
                    }}
                  />
                </WrapItem>
                <WrapItem alignItems={"center"} fontWeight={700}>
                  <Text>Period:</Text>
                </WrapItem>
                <WrapItem alignItems={"center"}>
                  <SelectField
                    field={{
                      id: "timePeriod",
                      validation: { required: true },
                    }}
                    isLoading={isLoadingAbcRankingPeriods}
                    options={timePeriodOptions}
                    selectStyles={{
                      input: (provided, state) => ({
                        ...provided,
                        color: "blue.500",
                        fontWeight: 700,
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        color: "blue.500",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: 700,
                      }),
                    }}
                    selectProps={{
                      variant: "unstyled",
                    }}
                  />
                </WrapItem>
              </Wrap>
            </form>
          </FormProvider>
        </Box>
        <TeamAlignmentReportFilters
          tenuresToFilter={tenuresToFilter}
          setTenuresToFilter={setTenuresToFilter}
          departmentsToFilter={departmentsToFilter}
          setDepartmentsToFilter={setDepartmentsToFilter}
          watchGraphType={watchGraphType}
        />
      </VStack>
      {isLoadingAbcRankingPeriodMetrics && fetchStatus !== "idle" && (
        <Box alignSelf={"center"}>
          <LoadingBox height={"600px"} />
        </Box>
      )}
      {!isLoadingAbcRankingPeriodMetrics && watchGraphType === "all" && (
        <>
          <AllEmployeesChart
            allEmployeeRankingData={allEmployeeRankingData}
            timePeriod={timePeriodOptions.find(
              (timePeriodOption) => timePeriodOption.value === watchTimePeriod
            )}
          />
        </>
      )}

      {watchGraphType === "all" && watchTimePeriod === "LAST_6_REPORTS" && (
        <AllEmployeesChartLastSix
          timePeriod={timePeriodOptions.find(
            (timePeriodOption) => timePeriodOption.value === watchTimePeriod
          )}
          departmentsToFilter={departmentsToFilter}
          tenuresToFilter={tenuresToFilter}
        />
      )}

      {!isLoadingAbcRankingPeriodMetrics && watchGraphType === "byTenure" && (
        <ByTenureChart
          allEmployeeTenureData={allEmployeeTenureData}
          timePeriod={timePeriodOptions.find(
            (timePeriodOption) => timePeriodOption.value === watchTimePeriod
          )}
        />
      )}

      {!isLoadingAbcRankingPeriodMetrics &&
        watchGraphType === "byDepartment" && (
          <ByDepartmentChart
            allEmployeeDepartmentData={allEmployeeDepartmentData}
            timePeriod={timePeriodOptions.find(
              (timePeriodOption) => timePeriodOption.value === watchTimePeriod
            )}
          />
        )}
      <TeamAlignmentFullDataTable
        departmentsToFilter={departmentsToFilter}
        tenuresToFilter={tenuresToFilter}
        timePeriod={watchTimePeriod}
      />
    </VStack>
  );
};

export default TeamAlignmentReport;
