import { useState } from "react";
import Joyride, { STATUS, EVENTS } from "react-joyride";
import WizardStepRightEnd from "../Steps/WizardStepRightEnd";
import WelcomeWizardScreen from "./WelcomeWizardScreen";
import WizardStepBottomRight from "../Steps/WizardStepBottomRight";
import WizardStepBottomLeft from "../Steps/WizardStepBottomLeft";
import WizardStepRightStart from "../Steps/WizardStepRightStart";
import WizardStep from "../Steps/WizardStep";
import WizardStepLeftStart from "../Steps/WizardStepLeftStart";

const defaultStepProps = {
  disableBeacon: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  hideFooter: true,
};

const createWizardSteps = (incrementWizardStep, wizardName) => [
  {
    ...defaultStepProps,
    disableScrolling: true,
    target: "#culture-hub",
    content: (
      <WizardStepRightStart
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Your Culture Hub - a place to learn, share, and engage."}
        text={
          "See what's happening around the company then click in to get involved."
        }
        boxStyles={{ width: "380px" }}
      />
    ),
    spotlightPadding: "6",
    floaterProps: {
      disableAnimation: true,
      placement: "right-start",
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "500px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#SHOUTOUTS",
    content: (
      <WizardStepRightStart
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Give a Shoutout"}
        text={
          "Your co-workers can be pretty awesome sometimes! Let them know you appreciate their efforts."
        }
        boxStyles={{ width: "500px" }}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "right-start",
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "550px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#UPCOMING_EVENTS",
    content: (
      <WizardStepLeftStart
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Sign up"}
        text={"See events that are coming up, then sign-up to get involved!"}
        boxStyles={{ width: "380px" }}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "left-start",
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "500px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#OPEN_SURVEYS",
    content: (
      <WizardStepBottomLeft
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Be Heard"}
        text={
          "Participate in company surveys to help drive change and success!"
        }
        boxStyles={{ width: "380px" }}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "bottom-end",
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "550px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#COMPANY_OKRS",
    content: (
      <WizardStepLeftStart
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Get Aligned"}
        text={
          "Objectives and Key Results (OKRs) drive collaboration, transparency and alignment, and encourage stretch goals!"
        }
        boxStyles={{ width: "380px" }}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "left-start",
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "500px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#MY_OKRS",
    content: (
      <WizardStepRightStart
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Get Focused"}
        text={
          "Create your OKRs to align your priorities with company objectives!"
        }
        boxStyles={{ width: "400px" }}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "right-start",
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "450px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#USEFUL_LINKS",
    content: (
      <WizardStepBottomLeft
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Learn More"}
        text={
          "Useful links are shortcuts to Knowledge Base pages and external web pages or platforms."
        }
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "bottom-end",
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "650px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#company-section",
    disableScrolling: true,
    content: (
      <WizardStepRightEnd
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Dig Deeper"}
        text={"Explore these modules in greater detail here."}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "right-end",
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "550px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#people-section",
    content: (
      <WizardStepRightEnd
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Know your Team"}
        text={
          "View and search for fellow employees, teams and departments, and browse the company org chart."
        }
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "right-end",
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "600px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#culture-hub-page",
    content: (
      <WizardStep
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Let's build a culture of success together!"}
        isLastStep={true}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "center",
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "520px",
      },
    },
  },
];

export const CultureHubWizard = ({ wizardName, completeWizard }) => {
  const [runWizard, setRunWizard] = useState(false);
  const [wizardStep, setWizardStep] = useState(0);

  const incrementWizardStep = () => {
    setWizardStep(wizardStep + 1);
  };

  const startWizard = (event) => {
    event.preventDefault();
    setRunWizard(true);
  };

  const handleJoyrideCallback = async (data) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      await completeWizard({ wizardName });
      setRunWizard(false);
    }
    // if the target is not found, increment the step
    else if (type === EVENTS.TARGET_NOT_FOUND) {
      incrementWizardStep();
    }
  };

  const wizardSteps = createWizardSteps(incrementWizardStep, wizardName);

  return (
    <>
      {!runWizard && (
        <WelcomeWizardScreen
          wizardName={wizardName}
          startWizard={startWizard}
        />
      )}
      <Joyride
        steps={wizardSteps}
        callback={handleJoyrideCallback}
        run={runWizard}
        stepIndex={wizardStep}
      />
    </>
  );
};
