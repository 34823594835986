import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { RadioGroupField } from "../../../components/fields/RadioGroupField";

export const DeleteMeetingsModal = (props) => {
  const { isOpen, setIsOpen, onSubmit } = props;

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      updateType: "thisMeeting", // thisMeeting or thisAndFollowingMeetings
    },
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = form;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"sm"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete meeting</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <RadioGroupField
                field={{
                  id: `updateType`,
                  options: [
                    { label: "This meeting", value: "thisMeeting" },
                    {
                      label: "This and following meetings",
                      value: "thisAndFollowingMeetings",
                    },
                  ],
                }}
              />
              <Button
                isLoading={isSubmitting}
                isDisabled={!isValid}
                type={"submit"}
                mt={4}
                _hover={{
                  bg: "red.500",
                  color: "white",
                }}
                color={"red.500"}
                variant="outline"
              >
                Delete
              </Button>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
