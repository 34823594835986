import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  VStack,
  Link,
  usePopoverContext,
} from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const MEETING_STATUSES = {
  Held: {
    color: "#33D182",
    label: "Completed",
  },
  Skipped: {
    color: "#F3B500",
    label: "Skipped",
  },
  NoStatus: {
    color: "gray",
    label: "Scheduled",
  },
};

export const MeetingStatusField = ({ submit }) => {
  const [isSaving, setIsSaving] = useState(false);

  const form = useFormContext();

  const watchStatus = form.watch("status");

  const MeetingStatuses = () => {
    const { onClose } = usePopoverContext();

    const handleLinkClick = async (status) => {
      form.setValue("status", status, true);
      submit();
      onClose();
    };

    return (
      <VStack alignItems={"center"} textAlign={"center"} fontWeight={700}>
        {watchStatus !== "Held" && (
          <Link
            onClick={() => handleLinkClick("Held")}
            color={MEETING_STATUSES["Held"].color}
          >
            {MEETING_STATUSES["Held"].label}
          </Link>
        )}
        {watchStatus !== "Skipped" && (
          <Link
            onClick={() => handleLinkClick("Skipped")}
            color={MEETING_STATUSES["Skipped"].color}
          >
            {MEETING_STATUSES["Skipped"].label}
          </Link>
        )}
        {watchStatus !== "NoStatus" && (
          <Link
            onClick={() => handleLinkClick("NoStatus")}
            color={MEETING_STATUSES["NoStatus"].color}
          >
            {MEETING_STATUSES["NoStatus"].label}
          </Link>
        )}
      </VStack>
    );
  };

  return (
    <Box>
      <Popover isOpen={undefined}>
        <PopoverTrigger>
          <Button
            isLoading={isSaving}
            variant="ghost"
            _hover={{ textDecoration: "underline" }}
            cursor={"pointer"}
            _active={{ bg: "none" }}
            p={0}
            m={0}
            color={MEETING_STATUSES[watchStatus].color}
            fontSize={16}
            fontWeight={700}
            textAlign={"left"}
            justifyContent={"flex-start"}
          >
            {MEETING_STATUSES[watchStatus].label}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
          borderRadius={"10px"}
          w={"120px"}
          p={3}
        >
          <PopoverArrow />
          <PopoverBody>
            <MeetingStatuses />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
