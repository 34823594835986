import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../config";

export const useFetchPartnerAccounts = () => {
  return useQuery({
    queryKey: ["fetchPartnerAccounts"],
    queryFn: async () => {
      const response = await api.get(`/partner/partnerAccounts`);
      return response.data;
    },
  });
};

export const useFetchPartner = () => {
  return useQuery({
    queryKey: ["fetchPartner"],
    queryFn: async () => {
      const response = await api.get(`/partner`);
      return response.data;
    },
  });
};

export const useSetEnterprisePlan = (props) =>
  useMutation({
    mutationKey: ["setEnterprisePlan"],
    mutationFn: async ({ data }) => {
      const response = await api.post(
        `/partner/putOnEnterpriseSubscriptionPlan`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const usePartnerCancelSubscription = (props) =>
  useMutation({
    mutationKey: ["partnerCancelSubscription"],
    mutationFn: async ({ data }) => {
      const response = await api.post(`/partner/cancelSubscription`, data);
      return response.data;
    },
    ...props,
  });
