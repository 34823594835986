import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateObjective = (props) =>
  useMutation({
    mutationKey: ["createObjective"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/objective`, data);
      return response.data;
    },
    ...props,
  });

export const useFetchObjectives = (
  accountId,
  ownerType,
  orderBy,
  timePeriodIds,
  profileOwnersToFilter = [],
  teamOwnersToFilter = [],
  departmentOwnersToFilter = []
) => {
  return useQuery({
    queryKey: [
      "fetchObjectives",
      accountId,
      ownerType,
      orderBy,
      timePeriodIds,
      profileOwnersToFilter,
      teamOwnersToFilter,
      departmentOwnersToFilter,
    ],
    queryFn: async () => {
      // If currentTimePeriod.id is not set yet, pass in null for timePeriodIds, else pass in the [timePeriodIds] or []
      if (timePeriodIds === null || timePeriodIds === undefined) {
        return null;
      }
      const timePeriodQuery = timePeriodIds
        .map((timePeriodId) => `&timePeriodId=${timePeriodId}`)
        .join("&");
      const orderByQuery = orderBy ? `&orderBy=${orderBy}` : "";
      const profileOwnerQuery = profileOwnersToFilter
        .map((ownerId) => `&ownerId=${ownerId}`)
        .join("&");
      const teamOwnerQuery = teamOwnersToFilter
        .map((teamId) => `&teamId=${teamId}`)
        .join("&");
      const departmentOwnerQuery = departmentOwnersToFilter
        .map((departmentId) => `&departmentId=${departmentId}`)
        .join("&");
      const response = await api.get(
        `/${accountId}/objective?ownerType=${ownerType}${orderByQuery}${timePeriodQuery}${profileOwnerQuery}${teamOwnerQuery}${departmentOwnerQuery}`
      );
      return response.data;
    },
  });
};

export const useFetchObjectiveTree = (accountId, objectiveId) => {
  return useQuery({
    queryKey: ["fetchObjectivetree", accountId, objectiveId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/objective/tree?objectiveId=${objectiveId}`
      );
      return response.data;
    },
  });
};

export const useUpdateObjective = (props) =>
  useMutation({
    mutationKey: ["updateObjective"],
    mutationFn: async ({ accountId, objectiveId, data }) => {
      const response = await api.put(
        `/${accountId}/objective/${objectiveId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateObjectiveSet = (props) =>
  useMutation({
    mutationKey: ["updateObjectiveSet"],
    mutationFn: async ({ accountId, objectiveSetId, data }) => {
      const response = await api.put(
        `/${accountId}/objectiveSet/${objectiveSetId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useFetchObjective = (accountId, objectiveId) => {
  return useQuery({
    queryKey: ["fetchObjective", objectiveId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/objective/${objectiveId}`);
      return response.data;
    },
  });
};

export const useDeleteObjective = (props) =>
  useMutation({
    mutationKey: ["deleteObjective"],
    mutationFn: async ({ accountId, objectiveId }) => {
      const response = await api.delete(
        `/${accountId}/objective/${objectiveId}`
      );
      return response.data;
    },
    ...props,
  });
