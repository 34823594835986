import { Button, VStack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useToastMessage } from "../../hooks/useToastMessage";
import { useParams } from "react-router-dom";
import { useUpdateTeam } from "../../api/team";
import { InputField } from "../../components/fields/InputField";
import { useQueryClient } from "@tanstack/react-query";
import useAccountId from "../../hooks/customDomainHooks";

const EditTeam = ({ onSuccess, team }) => {
  const { teamId } = useParams();
  const { accountId } = useAccountId();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const updateTeamMutation = useUpdateTeam();
  const queryClient = useQueryClient();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      name: team.name,
    },
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;

  const updateTeam = async (data) => {
    try {
      const respData = await updateTeamMutation.mutateAsync({
        accountId,
        teamId,
        data,
      });
      queryClient.invalidateQueries(["fetchTeam", teamId]);
      showSuccessToast("Team updated");
      onSuccess();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating team." });
    }
  };

  const onSubmit = async (data) => {
    await updateTeam(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <InputField
            field={{
              id: "name",
              placeholder: "Team Name",
              label: "Team Name",
              validation: {
                required: { value: true, message: "Name is required" },
              },
            }}
          />
          <Button type="submit" isLoading={isSubmitting}>
            Save
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default EditTeam;
