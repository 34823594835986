import { Box, Center, HStack, Spinner, Text } from "@chakra-ui/react";
import { useFetchAllAbcRankingPeriodMetrics } from "../../../../api/abcRankings/abcRankingPeriod";
import BarGraph from "../../../../components/common/charts/BarGraph";
import PercentageSwitch from "./PercentageSwitch";
import useAccountId from "../../../../hooks/customDomainHooks";
import SampleDataWidget from "./SampleDataWidget";
import { teamAlignment } from "../../../../constants";

const TimeSeriesAbcRankingsWidget = ({ widgetData, updateAdminWidget }) => {
  const { accountId } = useAccountId();
  const {
    data: abcRankingPeriodMetrics,
    isLoading: isLoadingAbcRankingPeriodMetrics,
    isError: isErrorAbcRankingPeriodMetrics,
    error: errorAbcRankingPeriodMetrics,
  } = useFetchAllAbcRankingPeriodMetrics(accountId);

  if (isLoadingAbcRankingPeriodMetrics) return <Spinner />;
  if (isErrorAbcRankingPeriodMetrics) {
    console.error(errorAbcRankingPeriodMetrics);
    return <Text>Error loading Team Alignment Period metrics!</Text>;
  }
  if (!abcRankingPeriodMetrics || abcRankingPeriodMetrics.length === 0) {
    return (
      <SampleDataWidget
        imageUrl={"team-alignment-ts-widget.png"}
        text={
          "This widget will show live data after your first report submission."
        }
        width={736}
        height={306}
      />
    );
  }

  const graphData = abcRankingPeriodMetrics.map((metric) => {
    const { aCount, bCount, cCount, unrankedCount } = metric;
    return {
      label: metric.period.label,
      Unranked: unrankedCount,
      A: aCount,
      B: bCount,
      C: cCount,
    };
  });

  const colors = {
    A: teamAlignment.A.color,
    B: teamAlignment.B.color,
    C: teamAlignment.C.color,
    Unranked: teamAlignment.Unranked.color,
  };

  return (
    <Box w={"100%"} h={"200px"}>
      <HStack w={"100%"} justifyContent={"space-between"}>
        <Box />
        <PercentageSwitch
          widgetData={widgetData}
          updateAdminWidget={updateAdminWidget}
        />
      </HStack>
      <BarGraph
        data={graphData}
        colors={colors}
        displayPercentages={widgetData.data?.displayPercentages}
      />
    </Box>
  );
};

export default TimeSeriesAbcRankingsWidget;
