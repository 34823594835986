import {
  Box,
  Grid,
  GridItem,
  InputRightAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { NumberField } from "../../components/fields/NumberField";
import TrendingIcon from "./TrendingIcon";
import { useCultureScoreData } from "./useCultureScoreData";

const Factors = ({ cultureScoreSetting }) => {
  const { currentMonthFactorWeightedScoreAverages } = useCultureScoreData();

  return (
    <Box
      borderRadius={"md"}
      border={"1px"}
      borderColor={"gray.200"}
      my={4}
      mx={{ base: 0, xl: 12 }}
      overflow={"auto"}
    >
      <Grid
        templateColumns="repeat(24, 1fr)"
        minWidth={"400px"}
        bg={"gray.100"}
        fontSize={"sm"}
      >
        <GridItem colSpan={5} py={2}>
          <Box pl={4}>
            <Text fontWeight={"bold"}>Measured Attribute</Text>
          </Box>
        </GridItem>

        <GridItem colSpan={3} py={2}>
          <Text fontWeight={"bold"}>Current value</Text>
        </GridItem>

        <GridItem colSpan={4} py={2}>
          <Text fontWeight={"bold"}>Target Min</Text>
        </GridItem>

        <GridItem colSpan={4} py={2}>
          <Text fontWeight={"bold"}>
            Target {cultureScoreSetting.module !== "RETENTION" && "Max"}
          </Text>
        </GridItem>

        <GridItem colSpan={3} py={2}>
          <Text fontWeight={"bold"}>Score</Text>
        </GridItem>

        <GridItem colSpan={3} py={2}>
          <Text fontWeight={"bold"}>Weight</Text>
        </GridItem>

        <GridItem colSpan={2} py={2}>
          <Text fontWeight={"bold"}>Trending</Text>
        </GridItem>

        {cultureScoreSetting.factors.map((factorSettings, index) => (
          <GridItem colSpan={24} key={factorSettings.id} bg={"gray.50"}>
            <FactorRow
              moduleName={cultureScoreSetting.module}
              factorSettings={factorSettings}
              index={index}
              factor30DayAverage={
                currentMonthFactorWeightedScoreAverages &&
                currentMonthFactorWeightedScoreAverages[factorSettings.id]
              }
            />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

const FactorRow = ({ factorSettings, moduleName, factor30DayAverage }) => {
  const {
    cultureScoreFactors,
    currentMonthFactorWeightedScoreAverages,
    lastMonthFactorWeightedScoreAverages,
  } = useCultureScoreData();
  const form = useFormContext();
  const { getValues } = form;

  const currentMonthModuleWeightedScoreAverage =
    currentMonthFactorWeightedScoreAverages &&
    currentMonthFactorWeightedScoreAverages[factorSettings.id] * 100;
  const lastMonthFactorWeightedScoreAverage =
    lastMonthFactorWeightedScoreAverages &&
    lastMonthFactorWeightedScoreAverages[factorSettings.id] * 100;

  const verifyWeights = () => {
    const values = getValues(`${moduleName}.factors`);
    const totalWeight = Object.keys(values).reduce((acc, key) => {
      return acc + values[key].weight;
    }, 0);

    if (totalWeight !== 100) {
      return false; //"Weights for factors must add up to 100%";
    } else {
      return true;
    }
  };

  const verifyTargetMinMax = () => {
    const values = getValues(`${moduleName}.factors.${factorSettings.id}`);
    if (factorSettings.includeTargetMin && factorSettings.includeTargetMax) {
      return values.targetMax >= values.targetMin;
    } else {
      return true;
    }
  };

  const currentFactor =
    cultureScoreFactors &&
    cultureScoreFactors[0]?.modules
      ?.find((module) => {
        return module.id === moduleName;
      })
      ?.factors?.find((factorData) => {
        return factorData.id === factorSettings.id;
      });

  const formatScore = (value, displayAs) => {
    if (value === undefined) {
      value = 0;
    }
    if (displayAs === "PERCENT") {
      return `${(value * 100).toFixed(0)}`;
    } else if (displayAs === "FLOAT") {
      return value.toFixed(2);
    } else if (displayAs === "NUMBER") {
      return value.toFixed(0);
    }
  };

  return (
    <Box flex="1" textAlign="left" borderTop={"1px"} borderColor={"gray.200"}>
      <Grid templateColumns="repeat(24, 1fr)" gap={4} alignItems="center" p={2}>
        <GridItem colSpan={5}>
          <VStack alignItems={"flex-start"}>
            <Text fontWeight={"bold"} fontSize={"sm"}>
              {factorSettings.label}
            </Text>
            <Text fontSize={"xs"}>{factorSettings.units}</Text>
          </VStack>
        </GridItem>

        <GridItem colSpan={3}>
          <Text>
            {formatScore(currentFactor?.currentValue, factorSettings.displayAs)}
            {factorSettings.displayAs === "PERCENT" && "%"}
          </Text>
        </GridItem>

        <GridItem colSpan={4}>
          {factorSettings.includeTargetMin ? (
            <NumberField
              field={{
                id: `${moduleName}.factors.${factorSettings.id}.targetMin`,
                min: -100,
                max: 100,
                validation: {
                  required: {
                    value: true,
                    message: "Target is required",
                  },
                  validate: {
                    verifyTargetMinMax: verifyTargetMinMax,
                  },
                },
              }}
              styleProps={{ w: "60px", bg: "white" }}
              rightAddon={
                factorSettings.displayAs === "PERCENT" && (
                  <InputRightAddon children="%" px={2} bg={"gray.50"} />
                )
              }
            />
          ) : (
            <Text>N/A</Text>
          )}
        </GridItem>

        <GridItem colSpan={4}>
          {factorSettings.includeTargetMax && (
            <NumberField
              field={{
                id: `${moduleName}.factors.${factorSettings.id}.targetMax`,
                validation: {
                  min: -100,
                  max: 100,
                  required: {
                    value: true,
                    message: "Target is required",
                  },
                  validate: {
                    verifyTargetMinMax: verifyTargetMinMax,
                  },
                },
              }}
              styleProps={{ w: "60px", bg: "white" }}
              rightAddon={
                factorSettings.displayAs === "PERCENT" && (
                  <InputRightAddon children="%" px={2} bg={"gray.50"} />
                )
              }
            />
          )}
          {factorSettings.includeTarget && (
            <NumberField
              field={{
                id: `${moduleName}.factors.${factorSettings.id}.target`,
                validation: {
                  min: -100,
                  max: 100,
                  required: {
                    value: true,
                    message: "Target is required",
                  },
                  // validate: {
                  //   verifyTargetMinMax: verifyTargetMinMax,
                  // },
                },
              }}
              styleProps={{ w: "60px", bg: "white" }}
              rightAddon={
                factorSettings.displayAs === "PERCENT" && (
                  <InputRightAddon children="%" px={2} bg={"gray.50"} />
                )
              }
            />
          )}
          {!factorSettings.includeTargetMax &&
            !factorSettings.includeTarget && <Text>N/A</Text>}
        </GridItem>

        <GridItem colSpan={3}>
          <Text>
            {(currentFactor ? currentFactor.score * 100 : 0).toFixed(0)}
          </Text>
        </GridItem>

        <GridItem colSpan={3}>
          <NumberField
            field={{
              id: `${moduleName}.factors.${factorSettings.id}.weight`,
              max: 100,
              min: 0,
              validation: {
                required: {
                  value: true,
                  message: "Weight is required",
                },
                validate: {
                  verifyWeights: verifyWeights,
                },
              },
            }}
            styleProps={{ w: "50px", bg: "white" }}
            inputProps={{
              onClick: (e) => {
                e.stopPropagation();
              },
            }}
            rightAddon={<InputRightAddon children="%" px={2} bg={"gray.50"} />}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <TrendingIcon
            current={currentMonthModuleWeightedScoreAverage}
            previous={lastMonthFactorWeightedScoreAverage}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Factors;
