import { useMutation } from "@tanstack/react-query";
import api from "../config";

export const useUpdateQuestion = (props) =>
  useMutation({
    mutationKey: ["updateQuestion"],
    mutationFn: async ({ accountId, questionId, data }) => {
      const response = await api.put(
        `/${accountId}/question/${questionId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useCreateQuestion = (props) =>
  useMutation({
    mutationKey: ["createQuestion"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/question`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteQuestion = (props) =>
  useMutation({
    mutationKey: ["deleteQuestion"],
    mutationFn: async ({ accountId, questionId }) => {
      const response = await api.delete(`/${accountId}/question/${questionId}`);
      return response.data;
    },
    ...props,
  });
