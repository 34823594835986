import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  BiDotsVerticalRounded,
  BiDuplicate,
  BiPencil,
  BiSend,
  BiShow,
  BiTrashAlt,
} from "react-icons/bi";
import { DeleteSomethingModal } from "../../components/modals/DeleteSomethingModal";
import { useUpdateEvent } from "../../api/events/event";
import useToastMessage from "../../hooks/useToastMessage";
import React, { useContext, useState } from "react";
import { useCloneEvent } from "../../api/events/event";
import { AccountContext } from "../../context/AccountContextComponent";
import { useUpdateEventSignup } from "../../api/events/eventSignup";
import { MdRemoveCircleOutline } from "react-icons/md";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";

const EventMenu = ({ event, eventMenuItems }) => {
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const updateEventMutation = useUpdateEvent();
  const cloneEventMutation = useCloneEvent();
  const updateEventSignup = useUpdateEventSignup();
  const { currentProfile } = useContext(AccountContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const updateEvent = async (data) => {
    try {
      await updateEventMutation.mutateAsync({
        accountId,
        data,
        eventId: event.id,
      });
      queryClient.invalidateQueries(["fetchUpcomingEvents"]);
      queryClient.invalidateQueries(["fetchPastEvents"]);
      queryClient.invalidateQueries(["fetchMyEvents"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating event" });
    }
  };

  const cloneEvent = async (data) => {
    try {
      const eventResp = await cloneEventMutation.mutateAsync({
        accountId,
        eventId: event.id,
      });
      navigate(`/${accountId}/events/${eventResp.id}/edit`);
      queryClient.invalidateQueries(["fetchUpcomingEvents"]);
      queryClient.invalidateQueries(["fetchPastEvents"]);
      queryClient.invalidateQueries(["fetchMyEvents"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error cloning event" });
    }
  };

  const hideEventSignup = async (eventSignupId) => {
    try {
      await updateEventSignup.mutateAsync({
        accountId,
        eventSignupId,
        data: {
          isViewable: false,
        },
      });
      queryClient.invalidateQueries(["fetchMyEvents"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error hiding event" });
    }
  };

  const publishEvent = async () => {
    const { invitees, signups, planningTeam, ...rest } = event;
    await updateEvent({ ...rest, isPublished: true });
    queryClient.invalidateQueries(["fetchEvent", event.id]);
  };

  const archiveEvent = async () => {
    const { invitees, signups, planningTeam, ...rest } = event;
    await updateEvent({ ...rest, isArchived: true });
    queryClient.invalidateQueries(["fetchEvent", event.id]);
    navigate(`/${accountId}/events`);
  };

  const menuItemsComponents = {
    EDIT: (
      <MenuItem
        _hover={{ bg: "gray.100" }}
        onClick={() => {
          navigate(`/${accountId}/events/${event.id}/edit`);
        }}
        icon={<BiPencil size={18} />}
      >
        Edit
      </MenuItem>
    ),
    VIEW: (
      <MenuItem
        _hover={{ bg: "gray.100" }}
        onClick={() => {
          navigate(`/${accountId}/events/${event.id}/view`);
        }}
        icon={<BiShow size={18} />}
      >
        View
      </MenuItem>
    ),
    COPY: (
      <MenuItem
        _hover={{ bg: "gray.100" }}
        onClick={cloneEvent}
        icon={<BiDuplicate size={18} />}
      >
        Copy
      </MenuItem>
    ),
    PUBLISH: (
      <MenuItem
        _hover={{ bg: "gray.100" }}
        onClick={publishEvent}
        icon={<BiSend size={18} />}
        isDisabled={
          event.isPublished ||
          !event.startTime ||
          !event.endTime ||
          !event.location
        }
      >
        Publish
      </MenuItem>
    ),
    REMOVE: (
      <>
        {
          <MenuItem
            _hover={{ bg: "gray.100" }}
            onClick={() => {
              hideEventSignup(event.myEventSignup.id);
            }}
            icon={<MdRemoveCircleOutline size={18} />}
          >
            Remove Me
          </MenuItem>
        }
      </>
    ),
    DELETE: (
      <>
        <MenuDivider />
        <MenuItem
          _hover={{
            bg: "red.100",
          }}
          color={"red.500"}
          onClick={() => {
            setIsDeleteModalOpen(true);
          }}
          icon={<BiTrashAlt size={18} />}
        >
          Delete
        </MenuItem>
      </>
    ),
  };

  const canEditEvent =
    ["ADMIN", "OWNER", "PARTNER"].includes(currentProfile?.role) ||
    event.creator?.id === currentProfile?.id ||
    event.planningTeam?.some((member) => member.id === currentProfile?.id);
  const canCreateEvent = ["ADMIN", "OWNER", "PARTNER"].includes(
    currentProfile?.role
  );

  const renderMenuItems = () => {
    let menuItemsToShow = [];
    if (
      eventMenuItems.includes("REMOVE") &&
      event.myEventSignup &&
      new Date(event.endTime) < new Date()
    ) {
      menuItemsToShow.push(
        React.cloneElement(menuItemsComponents.REMOVE, { key: event.id })
      );
    }
    if (canEditEvent) {
      menuItemsToShow.push(
        eventMenuItems.map((item, index) => {
          if (item === "COPY" && !canCreateEvent) return;
          if (item === "REMOVE") return; // Remove button is handled above
          return React.cloneElement(menuItemsComponents[item], { key: index });
        })
      );
    }
    return menuItemsToShow;
  };

  if (!canEditEvent && !event.myEventSignup) {
    return null;
  }

  if (renderMenuItems().length === 0) {
    return null;
  }

  return (
    <>
      <Box>
        <Menu>
          <MenuButton
            as={IconButton}
            variant={"ghost"}
            icon={<BiDotsVerticalRounded size={22} />}
            isLoading={
              updateEventMutation.isLoading ||
              cloneEventMutation.isLoading ||
              updateEventSignup.isLoading
            }
          ></MenuButton>
          <Portal>
            <MenuList>{renderMenuItems()}</MenuList>
          </Portal>
        </Menu>
      </Box>
      <DeleteSomethingModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        deleteFunction={archiveEvent}
        isDeleting={updateEventMutation.isLoading}
        bodyText={
          "Deleting an event cannot be undone. This will delete the event for all participants."
        }
      />
    </>
  );
};

export default EventMenu;
