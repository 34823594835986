import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateCultureHubWidget = (props) =>
  useMutation({
    mutationKey: ["createCultureHubWidget"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/cultureHubWidget`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteCultureHubWidget = (props) =>
  useMutation({
    mutationKey: ["deleteCultureHubWidget"],
    mutationFn: async ({ accountId, cultureHubWidgetId }) => {
      const response = await api.delete(
        `/${accountId}/cultureHubWidget/${cultureHubWidgetId}`
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateCultureHubWidget = (props) =>
  useMutation({
    mutationKey: ["updateCultureHubWidget"],
    mutationFn: async ({ accountId, cultureHubWidgetId, data }) => {
      const response = await api.put(
        `/${accountId}/cultureHubWidget/${cultureHubWidgetId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
