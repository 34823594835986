import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateValueTag = (props) =>
  useMutation({
    mutationKey: ["createValueTag"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/valueTag`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteValueTag = (props) =>
  useMutation({
    mutationKey: ["deleteValueTag"],
    mutationFn: async ({ accountId, valueTagId }) => {
      const response = await api.delete(
        `/${accountId}/valueTag/${valueTagId}`
      );
      return response.data;
    },
    ...props,
  });

export const useFetchValueTags = (accountId) => {
  return useQuery({
    queryKey: ["fetchValueTags"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/valueTag`);
      return response.data;
    },
  });
};
