import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import theme from "./theme";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IntlProvider } from "react-intl";
import StackdriverErrorReporter from "stackdriver-errors-js";
import { en } from "./lang/en";
import { es } from "./lang/es";
import TagManager from "react-gtm-module/dist/TagManager.js";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // Don't retry if the error is a 403 or 404
        if (error.response?.status === 403 || error.response?.status === 404) {
          return false;
        }
        return failureCount < 3;
      },
      refetchOnWindowFocus: true,
      // staleTime: 15 * 60 * 1000 // 15 minutes
    },
  },
});

// Only use stackdriver error reporting in hosted environments
// local should still just output to browser dev tools for easier debugging
if (import.meta.env.VITE_ERROR_REPORTING_ENABLED === "true") {
  const errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: import.meta.env.VITE_REACT_APP_FIREBASE_API_KEY,
    projectId: import.meta.env.VITE_REACT_APP_FIREBASE_PROJECT_ID,
    service: "tba-web",
    version: import.meta.env.VITE_BUILD_NUM || "unknown",
  });
}

// Set up react-intl
const messages = {
  en,
  es,
};
// convert en-US to en
const locale = navigator.language.split("-")[0];

// Setup Google Tag Manager
const tagManagerArgs = {
  gtmId: import.meta.env.VITE_REACT_APP_GTM_TAG || "GT-WF3LW5KT",
  dataLayer: {
    env: import.meta.env.VITE_GTM_ENV || "unknown",
  }
}

TagManager.initialize(tagManagerArgs)


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <IntlProvider locale={locale} messages={messages[locale] || messages["en"]}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </HelmetProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </IntlProvider>
  </React.StrictMode>
);
