import { useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreatePageInfo = (props) =>
  useMutation({
    mutationKey: ["createPageInfo"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/pageInfo`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdatePageInfo = (props) =>
  useMutation({
    mutationKey: ["updatePageInfo"],
    mutationFn: async ({ accountId, pageInfoId, data }) => {
      const response = await api.put(
        `/${accountId}/pageInfo/${pageInfoId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeletePageInfo = (props) =>
  useMutation({
    mutationKey: ["deletePageInfo"],
    mutationFn: async ({ accountId, pageInfoId }) => {
      const response = await api.delete(`/${accountId}/pageInfo/${pageInfoId}`);
      return response.data;
    },
    ...props,
  });
