import { useParams } from "react-router-dom";
import { Box, HStack, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { useDeleteQuestion } from "../../../api/survey/question";
import { useUpdateQuestion } from "../../../api/survey/question";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { debounce } from "lodash";
import useToastMessage from "../../../hooks/useToastMessage";
import QuestionMenu from "./QuestionMenu";
import { useQueryClient } from "@tanstack/react-query";
import NPSQuestion from "./Questions/NPSQuestion";
import HeaderLayout from "./Layouts/HeaderLayout";
import ParagraphLayout from "./Layouts/ParagraphLayout";
import useAccountId from "../../../hooks/customDomainHooks";

const LayoutForm = ({
  question,
  index,
  moveQuestion,
  questionsLength,
  questionTags,
}) => {
  const { surveyId } = useParams();
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const updateQuestionMutation = useUpdateQuestion();
  const deleteQuestionMutation = useDeleteQuestion();
  const queryClient = useQueryClient();

  const defaultValues = {
    ...question,
    tags: question.tags.map((tag) => tag.label),
  };
  const form = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
  } = form;

  const updateQuestion = async (data) => {
    try {
      await updateQuestionMutation.mutateAsync({
        accountId,
        questionId: data.id,
        data,
      });
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating survey" });
    }
  };

  const deleteQuestion = async (questionId) => {
    try {
      await deleteQuestionMutation.mutateAsync({ accountId, questionId });
      queryClient.invalidateQueries(["fetchSurvey", surveyId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting question" });
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => submitForm(event), 1000),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = async (event) => {
    if (Object.keys(errors).length === 0) {
      await handleSubmit(updateQuestion)(event);
    } else {
      console.log("errors", errors);
    }
  };

  const renderLayoutType = (layoutType, index) => {
    switch (layoutType) {
      case "HEADER":
        return (
          <HeaderLayout
            question={question}
            questionIndex={index}
            onChange={debouncedSubmit}
            questionTags={questionTags}
          />
        );
      case "PARAGRAPH":
        return (
          <ParagraphLayout
            question={question}
            questionIndex={index}
            onChange={debouncedSubmit}
          />
        );
      default:
        return <Text>Unknown Layout Type</Text>;
    }
  };

  const watchLayoutType = useWatch({
    control,
    name: `element.type`,
  });

  return (
    <Box
      bg={"white"}
      borderRadius={"md"}
      border={"2px"}
      borderColor={"gray.200"}
      p={4}
      mb={4}
      w={"100%"}
    >
      <FormProvider {...form}>
        <form
          onChange={debouncedSubmit}
          // Prevent form submission on enter key
          onKeyDown={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
        >
          <HStack justifyContent={"space-between"}>
            {renderLayoutType(watchLayoutType, index)}
            <QuestionMenu
              moveQuestion={moveQuestion}
              removeQuestion={() => deleteQuestion(question.id)}
              questionIndex={index}
              questionsLength={questionsLength}
              isLoading={
                updateQuestionMutation.isLoading ||
                deleteQuestionMutation.isLoading
              }
            />
          </HStack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default LayoutForm;
