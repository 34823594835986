import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import EditEmployee from "../EditEmployee";

export const EditEmployeeModal = (props) => {
  const { isOpen, setIsOpen, member } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EditEmployee
            mode={"edit"}
            onSuccess={() => {
              setIsOpen(false);
            }}
            member={member}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
