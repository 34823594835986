import { Button, VStack } from "@chakra-ui/react";
import { SelectField } from "../../components/fields/SelectField";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import { IoAddOutline } from "react-icons/io5";
import useToastMessage from "../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { useCreateCultureHubWidget } from "../../api/cultureHub/cultureHubWidget";
import useAccountId from "../../hooks/customDomainHooks";

const AddCultureHubWidgetForm = ({ onSuccess, columnIndex }) => {
  const createCultureHubWidgetMutation = useCreateCultureHubWidget();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const { accountId } = useAccountId();
  const form = useForm({
    mode: "onChange",
    defaultValues: { type: "COMPANY_OKRS", data: {} },
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = form;

  const widgetTypes = [
    {
      label: "OKRS",
      options: [
        { label: "Company OKRs", value: "COMPANY_OKRS" },
        { label: "My OKRs", value: "MY_OKRS" },
      ],
    },
    {
      label: "Events",
      options: [{ label: "Upcoming Events", value: "UPCOMING_EVENTS" }],
    },
    {
      label: "Shoutouts",
      options: [{ label: "Shoutouts", value: "SHOUTOUTS" }],
    },
    {
      label: "Surveys",
      options: [{ label: "Open Surveys", value: "OPEN_SURVEYS" }],
    },
    {
      label: "Useful Links",
      options: [{ label: "Useful Links", value: "USEFUL_LINKS" }],
    },
  ];

  const widgetType = watch("type");

  useEffect(() => {
    // If widgetType changes, reset the data field
    setValue("data", {});
  }, [widgetType, setValue]);

  const createCultureHubWidget = async (data) => {
    try {
      await createCultureHubWidgetMutation.mutateAsync({
        accountId,
        data: {
          ...data,
          columnIndex,
        },
      });
      queryClient.invalidateQueries(["fetchCultureHubLayout"]);
      onSuccess();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating widget" });
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    createCultureHubWidget(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack alignItems={"flex-start"} spacing={4}>
          <SelectField
            field={{
              id: "type",
              label: "Widget Type",
              validation: { required: true },
            }}
            options={widgetTypes}
          />
          <Button
            mt={4}
            isLoading={createCultureHubWidgetMutation.isLoading}
            type="submit"
            isDisabled={!isDirty || isSubmitting}
            leftIcon={<IoAddOutline />}
          >
            Add Widget
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default AddCultureHubWidgetForm;
