import {
  Box,
  Button,
  HStack,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../../hooks/useToastMessage";
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import {
  useCreateValueTag,
  useDeleteValueTag,
} from "../../../api/valueTag/valueTag";
import { DeleteSomethingModal } from "../../../components/modals/DeleteSomethingModal";
import useAccountId from "../../../hooks/customDomainHooks";
import { SwitchItem } from "./Modules";

export const EditValueTags = () => {
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const createValueTagMutation = useCreateValueTag();
  const removeValueTagMutation = useDeleteValueTag();
  const { accountData, isLoading } = useContext(AccountContext);
  const [isDeleteValueTagModalOpen, setIsDeleteValueTagModalOpen] =
    useState(false);
  const [valueTags, setValueTags] = useState(accountData?.valueTags || []);
  const [newValueTag, setNewValueTag] = useState("");
  const [valueTagToDelete, setValueTagToDelete] = useState(null);

  const removeValueTag = async () => {
    if (!valueTagToDelete.id) return;
    try {
      await removeValueTagMutation.mutateAsync({
        accountId,
        valueTagId: valueTagToDelete.id,
      });
      setValueTags(
        valueTags.filter((valueTag) => valueTag.id !== valueTagToDelete.id)
      );
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      showErrorToast({ message: `Error removing tag` });
    }
  };

  const addValueTag = async () => {
    // Avoid duplicate tags
    if (valueTags.find((e) => e.label === newValueTag)) {
      return;
    }
    try {
      await createValueTagMutation.mutateAsync({
        accountId,
        data: {
          label: newValueTag,
        },
      });
      setNewValueTag("");
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      showErrorToast({ message: `Error creating tag` });
    }
  };

  useEffect(() => {
    setValueTags(accountData?.valueTags || []);
  }, [accountData]);

  return (
    <>
      <DeleteSomethingModal
        isOpen={isDeleteValueTagModalOpen}
        isDeleting={removeValueTagMutation.isLoading}
        setIsOpen={setIsDeleteValueTagModalOpen}
        headerText={"Delete Tag"}
        bodyText={`Are you sure you want to delete this tag? Removing a tag will remove it from any shoutout that uses it.`}
        deleteFunction={async () => {
          await removeValueTag();
        }}
        buttonText={"Delete Tag"}
      />
      <Text>Tags</Text>
      <Wrap>
        {valueTags.map((valueTag) => (
          <WrapItem key={valueTag.id}>
            <HStack spacing={4}>
              <Tag
                size={"lg"}
                borderRadius="full"
                variant="outline"
                colorScheme={"green"}
              >
                <TagLabel>{valueTag?.label}</TagLabel>
                <TagCloseButton
                  onClick={() => {
                    setValueTagToDelete(valueTag);
                    setIsDeleteValueTagModalOpen(true);
                  }}
                />
              </Tag>
            </HStack>
          </WrapItem>
        ))}
      </Wrap>
      <HStack>
        <Input
          placeholder={"Tag"}
          onChange={(e) => {
            setNewValueTag(e.target.value);
          }}
          value={newValueTag}
        />
        <Button
          w={170}
          onClick={addValueTag}
          isDisabled={
            !newValueTag || valueTags.find((e) => e.label === newValueTag)
          }
          variant={"outline"}
        >
          Add tag
        </Button>
      </HStack>
      <Box w={200}>
        <SwitchItem
          label={"Allow user creation of tags"}
          id={"SHOUTOUTS_ALLOW_USER_CREATION_OF_TAGS.status"}
          size={"sm"}
        />
      </Box>
    </>
  );
};
