import { useUpdateObjective } from "../../../api/okr/objective";
import useAccountId from "../../../hooks/customDomainHooks";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { InputField } from "../../../components/fields/InputField";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { AccordionButton, Box, HStack, Spinner } from "@chakra-ui/react";
import ArrowsOutIcon from "../../../assets/icons/arrows_out.svg?react";
import ArrowsInIcon from "../../../assets/icons/arrows_in.svg?react";
import ObjectiveVisibility from "./ObjectiveVisibility";

export const ObjectiveTitle = ({
  objective,
  isExpanded,
  setAccordionIndex,
  accordionIndex,
  canEditObjective,
  setNewlyCreatedObjectiveId,
}) => {
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateObjectiveMutation = useUpdateObjective();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      title: objective?.title,
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = form;

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const submitForm = (data) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateObjective)(data);
    }
  };

  const updateObjective = async (data) => {
    try {
      await updateObjectiveMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        data: {
          title: data.title,
        },
      });
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
      queryClient.invalidateQueries(["fetchKeyResults"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating objective" });
    }
  };

  useEffect(() => {
    if (objective && !isDirty) {
      reset(objective);
    }
  }, [objective, reset, isDirty]);

  // Submit the form when form values change
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!name) return;
      debouncedSubmit();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, debouncedSubmit]);

  return (
    <HStack spacing={1} w={"100%"} align="baseline">
      <AccordionButton
        w="22px"
        h="22px"
        alignItems="center"
        justifyContent="center"
        borderRadius={"100%"}
        p={1}
        onClick={() => {
          setAccordionIndex(accordionIndex[0] === 0 ? [-1] : [0]);
        }}
      >
        {isExpanded ? (
          <ArrowsInIcon fill={"black"} />
        ) : (
          <ArrowsOutIcon fill={"black"} />
        )}
      </AccordionButton>
      {/* Width is dynamic based on text in order to put Avatar at the end */}
      <HStack
        spacing={1}
        // width={Math.max(inputWidth + 100, 180) + "px"}
        align="baseline"
      >
        <FormProvider {...form}>
          <form
            style={{ width: "100%" }}
            // Prevent form submission on enter key
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          >
            <InputField
              field={{
                id: "title",
                aiPlaceholder: 'Start writing an Objective or type "/" for AI',
                placeholder: "Add an Objective ",
                isReadOnly: !canEditObjective,
              }}
              useAi={true}
              aiContext={{
                context:
                  "We are writing an OKR objective title. Give me an example of a good objective title, return only the title.",
                metaData: {
                  objectiveId: objective.id,
                  objectiveSetId: objective.objectiveSet?.id,
                  setNewlyCreatedObjectiveId: setNewlyCreatedObjectiveId,
                },
              }}
              aiContextTask={"OKR_OBJECTIVE_TITLE"}
              aiMultipleLines={true}
              inputProps={{
                variant: "unstyled",
                onClick: () => {
                  // setAccordionIndex(accordionIndex[0] === 0 ? [-1] : [0]);
                  setAccordionIndex([0]);
                },
                width: "100%",
                id: `objective-title-${objective.id}`,
              }}
              inputStyle={{
                color: "blue.500",
                fontWeight: 800,
                maxW: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: canEditObjective ? "text" : "pointer",
              }}
            />
          </form>
        </FormProvider>
        <Box w={"10px"}>
          {updateObjectiveMutation.isLoading && <Spinner size={"xs"} />}
        </Box>
        <Box w={"75px"}>
          <ObjectiveVisibility
            objective={objective}
            canEditObjective={canEditObjective}
          />
        </Box>
      </HStack>
    </HStack>
  );
};
