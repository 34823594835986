import {
  useFetchObjectives,
  useUpdateObjective,
} from "../../../api/okr/objective";
import useAccountId from "../../../hooks/customDomainHooks";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import { debounce } from "lodash";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { SelectField } from "../../../components/fields/SelectField";
import { formatParentKrOptions } from "./formatOkrOptions";

export const ObjectiveKRParent = ({ objective, canEditObjective }) => {
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateObjectiveMutation = useUpdateObjective();

  const { data: objectiveSets } = useFetchObjectives(
    accountId,
    null,
    "timePeriod.startDate.desc",
    []
  );

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      parentKeyResultId: objective?.parentKeyResultId,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const submitForm = (data) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateObjective)(data);
    }
  };

  const updateObjective = async (data) => {
    try {
      await updateObjectiveMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        data: {
          parentKeyResultId: data.parentKeyResultId,
        },
      });
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating objective" });
    }
  };

  const krOptions = useMemo(
    () => formatParentKrOptions(objectiveSets, objective),
    [objective, objectiveSets]
  );

  return (
    <FormProvider {...form}>
      <form
        onChange={debouncedSubmit}
        // Prevent form submission on enter key
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <SelectField
          field={{
            id: `parentKeyResultId`,
            placeholder: canEditObjective ? "Parent KR" : "",
            isReadOnly: !canEditObjective,
          }}
          options={krOptions}
          isLoading={!objectiveSets}
          isClearable={true}
          onSelectionChange={submitForm}
          selectStyles={{
            input: (provided, state) => ({
              ...provided,
              color: "blue.500",
              fontWeight: 800,
            }),
            control: (provided, state) => ({
              ...provided,
              color: "blue.500",
              fontWeight: 800,
              textDecoration: canEditObjective ? "underline" : "none",
              cursor: canEditObjective ? "pointer" : "default",
            }),
          }}
          selectProps={{
            variant: "unstyled",
            components: !canEditObjective
              ? {
                  DropdownIndicator: () => null,
                }
              : {},
          }}
        />
      </form>
    </FormProvider>
  );
};
