import { Box } from "@chakra-ui/react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";
import Indent from "./extensions/indent";
// import "./TipTapStyle.css";
import Color from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import FontFamily from "@tiptap/extension-font-family";
import ResizableImageExtension from "./extensions/ResizableImageExtension";

const HtmlViewer = ({ html, minHeight }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Link,
      Indent.configure({
        types: ["listItem", "paragraph"],
        minLevel: 0,
        maxLevel: 8,
      }),
      Color,
      TextStyle,
      Highlight.configure({ multicolor: true }),
      FontFamily.configure({
        types: ["textStyle"],
      }),
      ResizableImageExtension.configure({
        isViewOnly: true,
      }),
    ],
    content: html,
    editable: false,
  });

  return (
    <Box>
      {editor && (
        <Box
          style={{
            "--editor-min-height": minHeight ? `${minHeight}px` : null,
          }}
        >
          <EditorContent editor={editor} className="editor" />
        </Box>
      )}
    </Box>
  );
};

export default HtmlViewer;
