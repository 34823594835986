import { useState, useMemo, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "../utils/localStorageHelper";

// pageName is a string that represents the name of the page.
// This is used to store the tab name for the page in local storage
function useTabNavigation(tabs, pageName = "") {
  const [searchParams, setSearchParams] = useSearchParams();
  const userInitiatedRef = useRef(false); // Keep track of user-initiated changes

  const setQueryParamWithoutNavigation = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    setSearchParams((params) => {
      params.set(key, value);
      return params;
    });
    window.history.replaceState({}, "", url);
  };

  // Get the default tab name from the URL or local storage
  // Prefer the tab name from the URL, then from local storage, then the first tab
  const defaultTabName = useMemo(() => {
    const tabFromUrl = searchParams.get("tab");
    if (tabFromUrl) {
      return tabFromUrl;
    }

    const tabFromStorage = getLocalStorageItem(`Tabs.${pageName}`);
    if (tabFromStorage) {
      setQueryParamWithoutNavigation("tab", tabFromStorage);
      return tabFromStorage;
    }
    return tabs[0]?.name;
  }, [searchParams, tabs, pageName]);

  const findTabIndex = (tabName) => {
    const foundIndex = tabs.findIndex((tab) => tab.name === tabName);
    return foundIndex === -1 ? 0 : foundIndex;
  };

  const initialTabIndex = useMemo(() => {
    return findTabIndex(defaultTabName);
  }, [defaultTabName, tabs]);

  const [tabIndex, setTabIndex] = useState(initialTabIndex);
  const [tabName, setTabName] = useState(searchParams.get("tab"));

  const handleTabsChange = (index) => {
    userInitiatedRef.current = true; // Mark as user-initiated change
    setQueryParamWithoutNavigation("tab", tabs[index].name);
    setTabIndex(index);
    setTabName(tabs[index].name);
  };

  useEffect(() => {
    if (userInitiatedRef.current) {
      // If the change was initiated by the user, skip this effect
      userInitiatedRef.current = false;
      return;
    }
    const newTabIndex = findTabIndex(tabName);
    // Only update tabIndex if it has changed
    if (newTabIndex !== tabIndex) {
      setTabIndex(newTabIndex);
    }
  }, [tabs, tabIndex, tabName]);

  useEffect(() => {
    const newTabName = searchParams.get("tab");
    const newTabIndex = findTabIndex(newTabName);
    if (newTabName !== tabName) {
      setTabName(newTabName);
      setTabIndex(newTabIndex);
    }
  }, [searchParams.get("tab")]);

  // When tabName changes, update the local storage for Tabs.[pageName]
  useEffect(() => {
    if (pageName) {
      setLocalStorageItem(`Tabs.${pageName}`, tabName);
    }
  }, [tabName, pageName]);

  // The problem is that updating the query param is not retriggering the useEffect
  return {
    tabIndex,
    handleTabsChange,
  };
}

export default useTabNavigation;
