const MAX_SIZE = 800;

// Returns a Promise that resolves with a Blob
export default function getCroppedImg(imageSrc, cropArea) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      /* setting canvas width & height */
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      let scaledWidth = cropArea.width;
      let scaledHeight = cropArea.height;

      if (cropArea.width > MAX_SIZE || cropArea.height > MAX_SIZE) {
        const scale = Math.min(
          MAX_SIZE / cropArea.width,
          MAX_SIZE / cropArea.height
        );
        scaledWidth = cropArea.width * scale;
        scaledHeight = cropArea.height * scale;
      }

      canvas.width = scaledWidth;
      canvas.height = scaledHeight;

      /* drawing image */
      ctx.drawImage(
        image,
        cropArea.x * scaleX,
        cropArea.y * scaleY,
        cropArea.width * scaleX,
        cropArea.height * scaleY,
        0,
        0,
        scaledWidth,
        scaledHeight
      );

      /* creating blob */
      canvas.toBlob(resolve, "image/png");
    };
  });
}
