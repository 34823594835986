import { Box, HStack, Image, Spinner, Text, VStack } from "@chakra-ui/react";
import ImageUpload from "../../components/common/UploadFile/ImageUpload";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateAccount } from "../../api/accounts";
import useToastMessage from "../../hooks/useToastMessage";
import { useCallback, useContext } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId from "../../hooks/customDomainHooks";
import { FormProvider, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { InputField } from "../../components/fields/InputField";

const ViewAccount = ({ account }) => {
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const updateAccountMutation = useUpdateAccount();
  const { currentProfile } = useContext(AccountContext);
  const { showErrorToast } = useToastMessage();

  const updateAccount = async (data) => {
    try {
      await updateAccountMutation.mutateAsync({
        accountId,
        accountData: {
          ...data,
        },
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating Account" });
    }
  };

  const removeImageFromAccount = async () => {
    try {
      await updateAccount({ logoImagePath: null });
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error removing image from Account" });
    }
  };

  const canEditAccount = ["OWNER", "ADMIN", "PARTNER"].includes(
    currentProfile?.role
  );

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      name: account.name,
    },
  });

  const {
    formState: { errors },
  } = form;

  const submitForm = async (data) => {
    if (Object.keys(errors).length === 0) {
      await updateAccount(data);
    }
  };

  const debouncedSubmit = debounce(submitForm, 1000);

  const handleFormChange = useCallback(
    (e) => {
      debouncedSubmit(form.getValues());
    },
    [debouncedSubmit]
  );

  return (
    <Box>
      <VStack alignItems={"flex-start"} w={"100%"} spacing={6}>
        <FormProvider {...form}>
          {canEditAccount ? (
            <form onChange={handleFormChange}>
              <HStack align={"center"} w={"300px"}>
                <InputField
                  field={{
                    id: "name",
                    placeholder: "Company Name",
                    label: "Company Name",
                    validation: {
                      required: {
                        value: true,
                        message: "Company Name is required",
                      },
                    },
                  }}
                />
                <Box>{updateAccountMutation.isLoading && <Spinner />}</Box>
              </HStack>
            </form>
          ) : (
            <>
              <Text fontWeight={"bold"}>Company Name</Text>
              <h1>{account.name}</h1>
            </>
          )}
        </FormProvider>
        {canEditAccount && (
          <>
            <Text fontWeight={"bold"}>Company Logo</Text>
            <ImageUpload
              imageUrl={account.logoImageUrl}
              removeImage={removeImageFromAccount}
              addImage={async (resp) => {
                await updateAccount({ logoImagePath: resp.fileName });
              }}
              imageStyle={{ maxWidth: "400px" }}
              allowCrop={false}
            />
          </>
        )}
        {!canEditAccount && account.logoImageUrl && (
          <Image
            maxW={"400px"}
            w={"100%"}
            alt="preview image"
            src={account.logoImageUrl}
            borderRadius="lg"
          />
        )}
      </VStack>
    </Box>
  );
};

export default ViewAccount;
