import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useToastMessage } from "../../hooks/useToastMessage";
import { useArchiveProfile } from "../../api/profiles";
import { SelectField } from "../../components/fields/SelectField";
import useAccountId from "../../hooks/customDomainHooks";
import { DateField } from "../../components/fields/DateField";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import { AccountContext } from "../../context/AccountContextComponent";

const ArchiveEmployee = ({ onSuccess, member }) => {
  const { accountId } = useAccountId();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const archiveProfileMutation = useArchiveProfile();
  const queryClient = useQueryClient();
  const { accountData } = useContext(AccountContext);

  const defaultValues = {
    terminationTagId:
      accountData?.terminationTags?.length > 0
        ? accountData.terminationTags[0].id
        : "",
    endDate: new Date(),
  };

  const form = useForm({
    mode: "onChange",
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;

  const terminationTagOptions = useMemo(() => {
    return accountData?.terminationTags?.map((reason) => ({
      label: reason.label,
      value: reason.id,
    }));
  }, [accountData]);

  const archiveMember = async (data) => {
    try {
      await archiveProfileMutation.mutateAsync({
        accountId,
        data,
        profileId: member.id,
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
      showSuccessToast(
        "Member archived",
        "The member has been successfully archived."
      );
      onSuccess();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error archiving member" });
    }
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(archiveMember)}>
          <VStack>
            <Box maxW={"200px"} alignSelf={"flex-start"}>
              <DateField
                field={{
                  id: `endDate`,
                  label: "End Date",
                  validation: { required: true },
                }}
              />
            </Box>
            <Box maxW={"200px"} alignSelf={"flex-start"}>
              <SelectField
                field={{
                  label: "Termination Reason",
                  id: "terminationTagId",
                  validation: {
                    required: { value: true },
                    message: "Termination reason is required",
                  },
                }}
                options={terminationTagOptions}
              />
            </Box>
            <HStack w={"100%"} justifyContent={"space-between"}>
              <Box />
              <HStack>
                <Button
                  isLoading={isSubmitting}
                  onClick={onSuccess}
                  variant={"ghost"}
                >
                  Cancel
                </Button>
                <Button
                  isLoading={archiveProfileMutation.isLoading}
                  type="submit"
                  variant={"red"}
                >
                  Archive
                </Button>
              </HStack>
            </HStack>
          </VStack>
        </form>
      </FormProvider>
    </>
  );
};

export default ArchiveEmployee;
