import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { createRef, useEffect, useRef, useState } from "react";
import { BsIncognito } from "react-icons/bs";
import SurveyQuestion from "./SurveyQuestion";
import WhiteBox from "../../../components/common/WhiteBox";
import { useUpdateSurveyRunResponse } from "../../../api/survey/surveyRunResponse";
import useToastMessage from "../../../hooks/useToastMessage";
import SurveyLayout from "./SurveyLayout";
import { FormattedDate } from "react-intl";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";

const TakeSurvey = ({ surveyRunResponse }) => {
  const { surveyRun } = surveyRunResponse;
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const updateSurveyRunResponseMutation = useUpdateSurveyRunResponse();
  const navigate = useCustomDomainNavigate();
  let questionIndex = 0;
  useEffect(() => {
    const newIsValid = Object.values(formErrors).every(
      (questionErrors) => Object.keys(questionErrors).length === 0
    );
    setIsFormValid(newIsValid);
  }, [formErrors]);

  const submitSurveyRunResponse = async (data) => {
    try {
      await updateSurveyRunResponseMutation.mutateAsync({
        accountId,
        surveyRunResponseId: surveyRunResponse.id,
        data: {
          isSubmitted: true,
        },
      });
      navigate(`/${accountId}/surveys`);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error submitting survey response" });
    }
  };

  // Create a refs array for each SurveyQuestion
  const questionRefs = useRef(
    surveyRun.questions
      .filter(
        (question) => !["PARAGRAPH", "HEADER"].includes(question.element.type)
      )
      .map(() => createRef())
  );

  const validateAllQuestions = async () => {
    // Create an array to store updated formErrors
    const updatedFormErrors = { ...formErrors };

    // Create an array of promises to store all validation triggers
    const validationPromises = questionRefs.current.map(
      async (questionRef, index) => {
        // Return the promise from trigger() on each question form
        const isValid = await questionRef.current.trigger();
        const questionId = surveyRun.questions[index].id;

        if (isValid) {
          updatedFormErrors[questionId] = {};
        } else {
          updatedFormErrors[questionId] = { value: { message: "Error" } };
        }
      }
    );

    // Wait for all validations to finish
    await Promise.all(validationPromises);

    return updatedFormErrors;
  };

  const onSubmit = async () => {
    try {
      const updatedFormErrors = await validateAllQuestions();
      const newIsValid = Object.values(updatedFormErrors).every(
        (questionErrors) => Object.keys(questionErrors).length === 0
      );

      if (newIsValid) {
        submitSurveyRunResponse();
      } else {
        console.log("Some questions have errors");
      }
    } catch (error) {
      console.error("Error validating questions:", error);
    }
  };

  return (
    <Center w={"100%"}>
      <Box p={4} maxW={"1000px"} w={"100%"}>
        <VStack alignItems={"flex-start"} spacing={4}>
          <WhiteBox>
            <VStack>
              <Text fontWeight={"bold"} fontSize={"xl"}>
                {surveyRun.title}
              </Text>
              <HStack>
                {surveyRun.isAnonymous && (
                  <>
                    <HStack>
                      <Text color={"textSecondary"} fontSize={"xs"}>
                        Responses are anonymous
                      </Text>
                      <Icon as={BsIncognito} color={"textSecondary"} />
                    </HStack>
                    <Divider
                      orientation="vertical"
                      borderColor={"gray.500"}
                      h={"20px"}
                    />
                  </>
                )}
                <Text fontSize={"xs"} color={"textSecondary"}>
                  Due: {` `}
                  <FormattedDate
                    value={surveyRun.dueDate}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                </Text>
              </HStack>
              <Text>{surveyRun.overview}</Text>
            </VStack>
          </WhiteBox>
          {surveyRun.questions.map((question) => {
            if (!["HEADER", "PARAGRAPH"].includes(question.element.type)) {
              const currentQuestionIndex = questionIndex;
              questionIndex++;
              return (
                <Box key={question.id} w={"100%"}>
                  <SurveyQuestion
                    question={question}
                    questionIndex={currentQuestionIndex}
                    response={
                      surveyRunResponse.responses?.find((questionResponse) => {
                        return questionResponse.questionId === question.id;
                      })?.response || {}
                    }
                    key={question.id}
                    surveyRunResponseId={surveyRunResponse.id}
                    surveyRunId={surveyRun.id}
                    setFormErrors={setFormErrors}
                    ref={questionRefs.current[currentQuestionIndex]}
                  />
                </Box>
              );
            } else {
              return (
                <Box key={question.id} w={"100%"}>
                  <SurveyLayout question={question} />
                </Box>
              );
            }
          })}
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Box />
            <Button
              size={"lg"}
              isDisabled={!isFormValid}
              onClick={onSubmit}
              isLoading={updateSurveyRunResponseMutation.isLoading}
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      </Box>
    </Center>
  );
};

export default TakeSurvey;
