import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../config";

export const useFetchKeyResults = (accountId) => {
  return useQuery({
    queryKey: ["fetchKeyResults"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/objective/keyresult`);
      return response.data;
    },
  });
};

export const useCreateKeyResult = (props) =>
  useMutation({
    mutationKey: ["createKeyResult"],
    mutationFn: async ({ accountId, objectiveId, data }) => {
      const response = await api.post(
        `/${accountId}/objective/${objectiveId}/keyresult`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateKeyResult = (props) =>
  useMutation({
    mutationKey: ["updateKeyResult"],
    mutationFn: async ({ accountId, objectiveId, keyResultId, data }) => {
      const response = await api.put(
        `/${accountId}/objective/${objectiveId}/keyresult/${keyResultId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteKeyResult = (props) =>
  useMutation({
    mutationKey: ["deleteKeyResult"],
    mutationFn: async ({ accountId, objectiveId, keyResultId }) => {
      const response = await api.delete(
        `/${accountId}/objective/${objectiveId}/keyresult/${keyResultId}`
      );
      return response.data;
    },
    ...props,
  });
