import {
  Center,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useState } from "react";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../../hooks/useToastMessage";
import { BiArchive, BiDotsVerticalRounded } from "react-icons/bi";
import {
  useDeleteOneOnOneMeeting,
  useUnarchiveOneOnOneMeeting,
} from "../../../api/oneOnOne/oneOnOneMeeting";
import { DeleteMeetingsModal } from "../modals/DeleteMeetingModal";

export const MeetingMenu = ({ meetingId, canDelete, isArchived }) => {
  const { accountId } = useAccountId();
  const deleteMeetingMutation = useDeleteOneOnOneMeeting();
  const unarchiveMeetingMutation = useUnarchiveOneOnOneMeeting();
  const queryClient = useQueryClient();
  const [isThisOrAllMeetingsModalOpen, setIsThisOrAllMeetingsModalOpen] =
    useState(false);
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const navigate = useCustomDomainNavigate();

  const archiveMeeting = async (meetingId, updateType) => {
    try {
      await deleteMeetingMutation.mutateAsync({
        accountId,
        meetingId,
        andFollowingMeetings: updateType === "thisAndFollowingMeetings",
        allMeetings: updateType === "allMeetings",
      });
      queryClient.invalidateQueries(["fetchOneOnOneMeetings"]);
      queryClient.invalidateQueries(["fetchMyTeam"]);
      queryClient.invalidateQueries(["fetchOneOnOneMeeting", meetingId]);
      showSuccessToast("Meeting deleted");
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting meeting" });
    }
  };

  const unarchiveMeeting = async (meetingId) => {
    try {
      await unarchiveMeetingMutation.mutateAsync({
        accountId,
        meetingId,
        unarchive: true,
      });
      queryClient.invalidateQueries(["fetchOneOnOneMeetings"]);
      queryClient.invalidateQueries(["fetchMyTeam"]);
      queryClient.invalidateQueries(["fetchOneOnOneMeeting", meetingId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error unarchiving meeting" });
    }
  };

  return (
    <>
      <DeleteMeetingsModal
        isOpen={isThisOrAllMeetingsModalOpen}
        setIsOpen={setIsThisOrAllMeetingsModalOpen}
        onSubmit={async (data) => {
          await archiveMeeting(meetingId, data.updateType);
          setIsThisOrAllMeetingsModalOpen(false);
          navigate(`/${accountId}/one-on-ones`);
        }}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          variant="outline"
          icon={<BiDotsVerticalRounded size={22} />}
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></MenuButton>
        <MenuList>
          {canDelete && !isArchived && (
            <MenuItem
              onClick={(e) => {
                setIsThisOrAllMeetingsModalOpen(true);
              }}
              icon={
                <Center>
                  <Icon as={BiArchive} fontSize={"xl"} />
                </Center>
              }
              color={"red.500"}
            >
              Delete Meeting
            </MenuItem>
          )}
          {isArchived && canDelete && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                unarchiveMeeting(meetingId);
              }}
              icon={
                <Center>
                  <Icon as={BiArchive} fontSize={"xl"} />
                </Center>
              }
              color={"gray.500"}
            >
              Undelete Meeting
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};
