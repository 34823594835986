import { useQuery, useMutation, useInfiniteQuery } from "@tanstack/react-query";
import api from "../config";

export const useCreateShoutout = (props) =>
  useMutation({
    mutationKey: ["createShoutout"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/shoutout`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateShoutout = (props) =>
  useMutation({
    mutationKey: ["updateShoutout"],
    mutationFn: async ({ accountId, shoutoutId, data }) => {
      const response = await api.put(
        `/${accountId}/shoutout/${shoutoutId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteShoutout = (props) =>
  useMutation({
    mutationKey: ["deleteShoutout"],
    mutationFn: async ({ accountId, shoutoutId }) => {
      const response = await api.delete(`/${accountId}/shoutout/${shoutoutId}`);
      return response.data;
    },
    ...props,
  });

export const useFetchShoutouts = (accountId) => {
  return useInfiniteQuery(
    ["fetchShoutouts", accountId],
    async ({ pageParam = 1 }) => {
      const response = await api.get(
        `/${accountId}/shoutout?page=${pageParam}`
      );
      return response.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.pageInfo?.hasMore ? pages.length + 1 : undefined;
      },
    }
  );
};
