import { RRule } from "rrule";

const frequencyToRrule = {
  WEEKLY: RRule.WEEKLY,
  MONTHLY: RRule.MONTHLY,
};

const byweekdayToRrule = {
  MO: RRule.MO,
  TU: RRule.TU,
  WE: RRule.WE,
  TH: RRule.TH,
  FR: RRule.FR,
  SA: RRule.SA,
  SU: RRule.SU,
};

export const intToWeekday = {
  0: "MO",
  1: "TU",
  2: "WE",
  3: "TH",
  4: "FR",
  5: "SA",
  6: "SU",
};

export const getRruleFromFormData = (data) => {
  let ruleStr;
  if (data.frequency === "WEEKLY") {
    ruleStr = new RRule({
      freq: frequencyToRrule[data.frequency],
      interval: data.interval,
      byweekday:
        data.byWeekDay?.length > 0 &&
        data.byWeekDay.map((day) => byweekdayToRrule[day]),
      dtstart: data.startDate, // TODO convert to UTC??
    }).toString();
  } else if (data.frequency === "MONTHLY") {
    ruleStr = new RRule({
      freq: frequencyToRrule[data.frequency],
      interval: data.interval,
      bysetpos: data.bySetPos,
      byweekday: byweekdayToRrule[data.byWeekDay],
      dtstart: data.startDate, // TODO convert to UTC??
    }).toString();
  } else {
    ruleStr = null;
  }
  return ruleStr;
};
