import { Box, Container, SlideFade, Stack } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useAccountId from "../../hooks/customDomainHooks";
import { useFetchSetupIntent } from "../../api/billing/billing";
import React, { useState } from "react";
import LoadingBox from "../../components/common/LoadingBox";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const BillingLayout = (props) => {
  const { children } = props;
  const { accountId } = useAccountId();

  // get setup intent
  const {
    data: setupIntentData,
    isLoading: isLoadingSetupIntent,
    isError: isErrorSetupIntent,
    error: errorSetupIntent,
  } = useFetchSetupIntent(accountId);

  if (isLoadingSetupIntent || !setupIntentData) {
    return <LoadingBox />;
  }
  if (isErrorSetupIntent) {
    return <p>Error: {errorSetupIntent?.message}</p>;
  }

  const options = {
    // passing the client secret obtained from the server
    clientSecret: setupIntentData.setupIntentClientSecret,
  };

  return (
    <SlideFade
      in
      offsetY={-30}
      style={{ display: "flex", flex: 1, flexDirection: "column" }}
    >
      <Container
        maxW="lg"
        py={{ base: "2", md: "12" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Box
            py={{ base: "2", md: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={"white"}
            borderRadius={{ base: "none", sm: "xl" }}
            border={"1px"}
            borderColor={"gray.200"}
          >
            <Elements stripe={stripePromise} options={options}>
              {children(setupIntentData)}
            </Elements>
          </Box>
        </Stack>
      </Container>
    </SlideFade>
  );
};

export default BillingLayout;
