import { Box, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import useAccountId from "../../../hooks/customDomainHooks";
import { useContext, useEffect, useState } from "react";
import LoadingBox from "../../../components/common/LoadingBox";
import { AccountContext } from "../../../context/AccountContextComponent";
import { useFetchTenureMetrics } from "../../../api/profiles";
import { SelectField } from "../../../components/fields/SelectField";
import { FormProvider, useForm } from "react-hook-form";
import { EmployeeTenureReportFilters } from "./EmployeeTenureReportFilters";
import DonutChart from "../../../components/common/charts/DonutChart";
import BarGraph from "../../../components/common/charts/BarGraph";
import { TenureFullDataTable } from "./TenureFullDataTable";
import { allTenures } from "../../../constants";

const EmployeeTenureReport = () => {
  const { accountId } = useAccountId();
  const { accountData, aRankLabel, bRankLabel, cRankLabel, hasModuleTurnedOn } =
    useContext(AccountContext);
  const [departmentsToFilter, setDepartmentsToFilter] = useState([]);
  const [ranksToFilter, setRanksToFilter] = useState([]);

  const {
    data: profileMetrics,
    isLoading: isLoadingProfileMetrics,
    isError: isErrorProfileMetrics,
    error: errorProfileMetrics,
    fetchStatus,
  } = useFetchTenureMetrics(accountId, departmentsToFilter, ranksToFilter);

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      graphType: "all",
      timePeriod: "LAST_6_REPORTS", // timePeriodOptions?.[0]?.value,
    },
  });

  const watchGraphType = form.watch("graphType");

  useEffect(() => {
    if (watchGraphType !== "all") {
      const defaultValue = profileMetrics[0]?.period?.id;
      if (defaultValue) {
        form.setValue("timePeriod", defaultValue);
      }
    }

    setRanksToFilter([]);
    setDepartmentsToFilter([]);
  }, [watchGraphType, form.setValue]);

  const rankingLabels = {
    A: aRankLabel,
    B: bRankLabel,
    C: cRankLabel,
    Unranked: "Unranked",
  };

  const tenureColors = allTenures.reduce(
    (acc, tenure) => ({
      ...acc,
      [tenure.label]: tenure.color,
    }),
    {}
  );

  const getRankingByTenure = () => {
    if (!profileMetrics) return null;

    let initialData = {};
    allTenures.forEach((tenure) => {
      initialData[tenure.label] = 0;
    });

    const dataForRankingChart = {
      A: { ...initialData },
      B: { ...initialData },
      C: { ...initialData },
      Unranked: { ...initialData },
    };

    profileMetrics.forEach((profile) => {
      const ranking = profile.ranking || "Unranked";

      if (profile.tenureBucket && dataForRankingChart[ranking]) {
        dataForRankingChart[ranking][profile.tenureBucket]++;
      }
    });

    return Object.entries(dataForRankingChart).map(([ranking, data]) => ({
      // Convert the ranking to the custom label the account may have set
      label: rankingLabels[ranking],
      ...data,
    }));
  };

  const getTenureByDepartment = () => {
    if (!profileMetrics) return null;

    let initialData = {};
    allTenures.forEach((tenure) => {
      initialData[tenure.label] = 0;
    });

    let dataForRankingChart = {};
    accountData.departments.forEach((department) => {
      dataForRankingChart[department.name] = { ...initialData };
    });
    // const dataForRankingChart = {
    //   A: { ...initialData },
    //   B: { ...initialData },
    //   C: { ...initialData },
    //   Unranked: { ...initialData },
    // };

    profileMetrics.forEach((profile) => {
      const departmentName = profile.department?.name || "Unassigned";

      if (profile.tenureBucket && dataForRankingChart[departmentName]) {
        dataForRankingChart[departmentName][profile.tenureBucket]++;
      }
    });

    return Object.entries(dataForRankingChart).map(([ranking, data]) => ({
      // Convert the ranking to the custom label the account may have set
      label: ranking,
      ...data,
    }));
  };

  const getDataForDonutChart = () => {
    if (!profileMetrics) return [];

    const initialData = allTenures.reduce(
      (acc, tenure) => ({
        ...acc,
        [tenure.label]: { count: 0, color: tenure.color },
      }),
      {}
    );

    profileMetrics.forEach((profile) => {
      if (profile.tenureBucket) {
        initialData[profile.tenureBucket].count++;
      }
    });

    return initialData;
  };

  const tenureByDepartment = getTenureByDepartment();
  const dataForDonutChart = getDataForDonutChart();
  const allEmployeeTenureData = getRankingByTenure();

  const getViewByOptions = () => {
    const options = [
      {
        label: "All Employees",
        value: "all",
      },
      {
        label: "By Department",
        value: "byDepartment",
      },
    ];

    if (hasModuleTurnedOn("ABC_RANKING")) {
      options.push({
        label: "By Rank",
        value: "byRank",
      });
    }

    return options;
  };

  return (
    <VStack alignItems={"flex-start"} spacing={4}>
      <VStack alignItems={"flex-start"} w={"100%"}>
        <Text fontWeight={800} fontSize={"xl"}>
          Employee Tenure Report
        </Text>
        <Box alignSelf={"flex-start"}>
          <FormProvider {...form}>
            <form onSubmit={() => {}}>
              <Wrap>
                <WrapItem alignItems={"center"} fontWeight={700}>
                  <Text>View:</Text>
                </WrapItem>
                <WrapItem alignItems={"center"}>
                  <SelectField
                    field={{
                      id: "graphType",
                      validation: { required: true },
                    }}
                    options={getViewByOptions()}
                    selectStyles={{
                      input: (provided, state) => ({
                        ...provided,
                        color: "blue.500",
                        fontWeight: 700,
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        color: "blue.500",
                        fontWeight: 700,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }),
                    }}
                    selectProps={{
                      variant: "unstyled",
                    }}
                  />
                </WrapItem>
              </Wrap>
            </form>
          </FormProvider>
        </Box>
        <EmployeeTenureReportFilters
          ranksToFilter={ranksToFilter}
          setRanksToFilter={setRanksToFilter}
          departmentsToFilter={departmentsToFilter}
          setDepartmentsToFilter={setDepartmentsToFilter}
          watchGraphType={watchGraphType}
        />
      </VStack>
      {isLoadingProfileMetrics && fetchStatus !== "idle" && (
        <Box alignSelf={"center"}>
          <LoadingBox height={"600px"} />
        </Box>
      )}

      {!isLoadingProfileMetrics && watchGraphType === "all" && (
        <Box
          overflow={"auto"}
          bg={"white"}
          w={"100%"}
          p={4}
          borderRadius={"md"}
          border={"1px "}
          borderColor={"gray.200"}
          maxW={"900px"}
          minW={"900px"}
        >
          <Box justifyContent={"center"}>
            <Text textAlign={"center"}>All Employee Tenures</Text>
          </Box>
          <DonutChart data={dataForDonutChart} />
        </Box>
      )}
      {!isLoadingProfileMetrics && watchGraphType === "byRank" && (
        <Box
          overflow={"auto"}
          bg={"white"}
          w={"100%"}
          p={4}
          borderRadius={"md"}
          border={"1px "}
          borderColor={"gray.200"}
          maxW={"900px"}
          minW={"900px"}
          height={"400px"}
        >
          <Box justifyContent={"center"}>
            <Text textAlign={"center"}>All Employee Tenures by Rank</Text>
          </Box>
          {allEmployeeTenureData && (
            <BarGraph
              data={allEmployeeTenureData || []}
              height={"90%"}
              colors={tenureColors}
              displayPercentages={false}
              xAxisProps={{
                tick: {
                  fontSize: "12px",
                },
              }}
            />
          )}
        </Box>
      )}
      {!isLoadingProfileMetrics && watchGraphType === "byDepartment" && (
        <Box
          overflow={"auto"}
          bg={"white"}
          w={"100%"}
          p={4}
          borderRadius={"md"}
          border={"1px "}
          borderColor={"gray.200"}
          maxW={"800px"}
          minW={"900px"}
          height={"400px"}
        >
          <Box justifyContent={"center"}>
            <Text textAlign={"center"}>All Employee Tenures by Department</Text>
          </Box>
          {tenureByDepartment && (
            <BarGraph
              data={tenureByDepartment || []}
              height={"90%"}
              colors={tenureColors}
              displayPercentages={false}
              xAxisProps={{
                tick: {
                  fontSize: "12px",
                },
              }}
            />
          )}
        </Box>
      )}
      <TenureFullDataTable profileMetrics={profileMetrics} />
    </VStack>
  );
};

export default EmployeeTenureReport;
