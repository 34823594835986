import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateSlackIntegration = (props) =>
  useMutation({
    mutationKey: ["createSlackIntegration"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/integrations/slack/accessToken`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUninstallSlackIntegration = (props) =>
  useMutation({
    mutationKey: ["uninstallSlackIntegration"],
    mutationFn: async ({ accountId }) => {
      const response = await api.delete(
        `/${accountId}/integrations/slack/uninstall`
      );
      return response.data;
    },
    ...props,
  });

export const useListSlackChannels = (accountId, enabled) =>
  useQuery({
    queryKey: ["listSlackChannels", accountId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/integrations/slack/channels`
      );
      return response.data;
    },
    enabled,
  });

export const useUpdateSlackIntegration = (props) =>
  useMutation({
    mutationKey: ["updateSlackIntegration"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.put(`/${accountId}/integrations/slack`, data);
      return response.data;
    },
    ...props,
  });

export const useBindSlackUser = (props) =>
  useMutation({
    mutationKey: ["bindSlackUser"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/integrations/slack/bindSlackUser`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useSendSlackWelcomeMessage = (props) =>
  useMutation({
    mutationKey: ["sendSlackWelcomeMessage"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/integrations/slack/sendWelcomeMessage`,
        data
      );
      return response.data;
    },
    ...props,
  });
