import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useConfirmSetupIntent = (props) =>
  useMutation({
    mutationKey: ["confirmSetupIntent"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/billing/confirmSetupIntent`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useFetchSetupIntent = (accountId) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["fetchSetupIntent", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/billing/setupIntent`);
      return response.data;
    },
  });
};

export const useCreateAccountSubscription = () => {
  return useMutation({
    mutationKey: ["createAccountSubscription"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/billing/accountSubscription`,
        data
      );
      return response.data;
    },
  });
};

export const useCancelSubscription = () => {
  return useMutation({
    mutationKey: ["cancelSubscription"],
    mutationFn: async ({ accountId }) => {
      const response = await api.post(
        `/${accountId}/billing/cancelSubscription`
      );
      return response.data;
    },
  });
};
