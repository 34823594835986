import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
  connectAuthEmulator,
} from "firebase/auth";
import api from "../../api/config";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import axios from "axios";
import { clearAllLocalStorage } from "../../utils/localStorageHelper";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_REACT_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env
    .VITE_REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_REACT_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
if (import.meta.env.VITE_ENVIRONMENT === "dev") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}
const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com");
microsoftProvider.setCustomParameters({
  prompt: "consent",
});

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    return res.user;
  } catch (err) {
    if (
      err.code === "auth/popup-closed-by-user" ||
      err.code === "auth/cancelled-popup-request"
    ) {
      return;
    }
    throw err;
  }
};

const signInWithMicrosoft = async () => {
  try {
    const res = await signInWithPopup(auth, microsoftProvider);
    return res.user;
  } catch (err) {
    if (
      err.code === "auth/popup-closed-by-user" ||
      err.code === "auth/cancelled-popup-request"
    ) {
      return;
    }
    throw err;
  }
};

const signInWithEmailAndPassword = async (email, password) => {
  return await firebaseSignInWithEmailAndPassword(auth, email, password);
};

const registerWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    try {
      // login with google was successful
      // check if the user is signed up
      await api.get("/users/me");
    } catch (error) {
      // check if the error was because the email is not verified
      if (error.response?.data?.error === "EMAIL_NOT_VERIFIED") {
        window.location = `/_auth/not-verified`;
        return;
      }
      // Sign up the user automatically
      await api.post("/users");
    }
    return res.user;
  } catch (err) {
    if (
      err.code === "auth/popup-closed-by-user" ||
      err.code === "auth/cancelled-popup-request"
    ) {
      return;
    }
    throw err;
  }
};

const registerWithMicrosoft = async () => {
  try {
    const res = await signInWithPopup(auth, microsoftProvider);
    try {
      // check if the user is signed up
      await api.get("/users/me");
    } catch (error) {
      // check if the error was because the email is not verified
      if (error.response?.data?.error === "EMAIL_NOT_VERIFIED") {
        window.location = `/_auth/not-verified`;
        return;
      }
      // Sign up the user automatically
      await api.post("/users");
      await api.post("/users/sendVerificationEmail");
    }
    return res.user;
  } catch (err) {
    if (
      err.code === "auth/popup-closed-by-user" ||
      err.code === "auth/cancelled-popup-request"
    ) {
      return;
    }
    throw err;
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  const res = await createUserWithEmailAndPassword(auth, email, password);
  const user = res.user;
  await updateProfile(user, { displayName: name });
  // Since we just updated the user, we need to get the latest user object by signing in again
  const returnUser = await signInWithEmailAndPassword(email, password);
  await api.post("/users");
  await api.post("/users/sendVerificationEmail");

  return returnUser;
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const useLogout = () => {
  const queryClient = useQueryClient();

  const logout = useCallback(
    async (skipRedirect = false) => {
      clearAllLocalStorage();
      queryClient.clear();
      await signOut(auth);
      if (!skipRedirect) {
        window.location.href = "/_auth/signin";
      }
    },
    [queryClient]
  );

  return logout;
};

const checkEmailExistsAsProvider = async (email) => {
  const response = await axios.post(
    `${import.meta.env.VITE_BASE_API_URL}/public/checkEmail`,
    {
      email: email,
    }
  );
  return response.data;
};

const checkUserExistsInDb = async (authType, email = undefined) => {
  if (authType === "email") {
    return await checkEmailExistsAsProvider(email);
  } else if (authType === "google" || authType === "microsoft") {
    try {
      // Use the stored token to check if the user exists in the db
      await api.get("/users/me");
      return { exists: true };
    } catch (error) {
      // check if the error was because the email is not verified
      if (error.response?.data?.error === "EMAIL_NOT_VERIFIED") {
        window.location = `/_auth/not-verified`;
        return { exists: true };
      } else {
        return { exists: false };
      }
    }
  }
};

export {
  auth,
  signInWithGoogle,
  registerWithGoogle,
  signInWithMicrosoft,
  registerWithMicrosoft,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  useLogout,
  checkUserExistsInDb,
};
