import { useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateAccountUploadUrl = (props) =>
  useMutation({
    mutationKey: ["createAccountUploadUrl"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/storage/getAccountUploadUrl`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useCreatePublicUploadUrl = (props) =>
  useMutation({
    mutationKey: ["createPublicUploadUrl"],
    mutationFn: async ({ data }) => {
      const response = await api.post(`/storage/getPublicUploadUrl`, data);
      return response.data;
    },
    ...props,
  });
