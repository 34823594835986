import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useFetchSurveyRuns = (accountId, surveyId) => {
  return useQuery({
    queryKey: ["fetchSurveyRuns", accountId, surveyId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/surveyRun?surveyId=${surveyId}`
      );
      return response.data;
    },
  });
};

export const useFetchAllSurveyRuns = (accountId) => {
  return useQuery({
    queryKey: ["fetchAllSurveyRuns", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/surveyRun/all`);
      return response.data;
    },
  });
};

export const useFetchMySurveyRuns = (accountId) => {
  return useQuery({
    queryKey: ["fetchMySurveyRuns", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/surveyRun/my-surveys`);
      return response.data;
    },
  });
};

export const useCreateSurveyRun = (props) =>
  useMutation({
    mutationKey: ["createSurveyRun"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/surveyRun`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateSurveyRun = (props) =>
  useMutation({
    mutationKey: ["updateSurveyRun"],
    mutationFn: async ({ accountId, surveyRunId, data }) => {
      const response = await api.put(
        `/${accountId}/surveyRun/${surveyRunId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
