import React, { useState, useEffect } from "react";
import { Box, Container } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import tbaBg1 from "../../assets/backgrounds/tba_bg_1.png";
import tbaBg2 from "../../assets/backgrounds/tba_bg_2.png";
import tbaBg3 from "../../assets/backgrounds/tba_bg_3.png";
import tbaBg4 from "../../assets/backgrounds/tba_bg_4.png";
import tbaBg5 from "../../assets/backgrounds/tba_bg_5.png";

const images = [tbaBg1, tbaBg2, tbaBg3, tbaBg4, tbaBg5];

const MotionBox = motion(Box);

const BackgroundImageGallery = ({ children }) => {
  const [currentImageIdx, setCurrentImageIdx] = useState(0);

  // Change images every 6 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIdx((prevIdx) => (prevIdx + 1) % images.length); // Loop back to the first image after the last one
    }, 6000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  return (
    <Box
      position="relative"
      pt={{ base: "0px", md: "30px", "2xl": "100px" }}
      // pb={"100px"}
      h={"100%"}
      w={"100%"}
    >
      <AnimatePresence initial={false}>
        <MotionBox
          key={currentImageIdx}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundImage: `url(${images[currentImageIdx]})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      </AnimatePresence>

      <Box position="relative" display="flex" justifyContent="center">
        {/* <Container
          maxW="md"
          py={"8"}
          px={{ base: "4", sm: "10" }}
          bg={"white"}
          boxShadow={{ base: "none", sm: "xl" }}
          borderRadius={{ base: "none", sm: "xl" }}
          position="absolute"
          {...containerStyleProps}
        > */}
        {children}
        {/* </Container> */}
      </Box>
    </Box>
  );
};

export default BackgroundImageGallery;
