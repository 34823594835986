import { useQuery, useMutation } from "@tanstack/react-query";
import api from "./config";

export const useCreateProfile = (props) =>
  useMutation({
    mutationKey: ["createProfile"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/profiles`, data);
      return response.data;
    },
    ...props,
  });

export const useBulkCreateProfiles = (props) =>
  useMutation({
    mutationKey: ["bulkCreateProfiles"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/profiles/bulk`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateProfile = (props) =>
  useMutation({
    mutationKey: ["updateProfile"],
    mutationFn: async ({ accountId, profileId, data }) => {
      const response = await api.put(
        `/${accountId}/profiles/${profileId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateMyProfile = (props) =>
  useMutation({
    mutationKey: ["updateMyProfile"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.put(`/${accountId}/profiles/me`, data);
      return response.data;
    },
    ...props,
  });

export const useArchiveProfile = (props) =>
  useMutation({
    mutationKey: ["archiveProfile"],
    mutationFn: async ({ accountId, profileId, data }) => {
      const response = await api.put(
        `/${accountId}/profiles/archive/${profileId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useSendInviteEmail = (props) =>
  useMutation({
    mutationKey: ["sendInviteEmail"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/profiles/sendInviteEmail`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useFetchTenureMetrics = (
  accountId,
  departments,
  ranksToFilter
) => {
  return useQuery({
    queryKey: ["fetchTenureMetrics", accountId, departments, ranksToFilter],
    queryFn: async () => {
      const departmentQuery = departments
        .map((department) => `&department=${department.label}`)
        ?.join("&");

      const rankQuery = ranksToFilter
        ?.map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/profiles/tenure?${departmentQuery}${rankQuery}`
      );
      return response.data;
    },
  });
};

export const useFetchTurnoverMetrics = (
  accountId,
  startDate,
  endDate,
  tenuresToFilter,
  departments,
  ranksToFilter,
  terminationTagIdsToFilter
) => {
  return useQuery({
    queryKey: [
      "fetchTurnoverMetrics",
      accountId,
      startDate,
      endDate,
      tenuresToFilter,
      departments,
      ranksToFilter,
      terminationTagIdsToFilter,
    ],
    queryFn: async () => {
      const departmentQuery = departments
        .map((department) => `&department=${department.label}`)
        ?.join("&");

      const rankQuery = ranksToFilter
        ?.map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const tenureQuery = tenuresToFilter
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const terminationTagIdQuery = terminationTagIdsToFilter
        ?.map((reason) => `&terminationTagId=${reason.value}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/profiles/turnover?startDate=${startDate}&endDate=${endDate}&${departmentQuery}${rankQuery}${tenureQuery}${terminationTagIdQuery}`
      );
      return response.data;
    },
  });
};

export const useFetchRetentionMetrics = (
  accountId,
  dateRange,
  tenuresToFilter,
  departments,
  ranksToFilter
) => {
  return useQuery({
    queryKey: [
      "fetchRetentionMetrics",
      accountId,
      dateRange,
      tenuresToFilter,
      departments,
      ranksToFilter,
    ],
    queryFn: async () => {
      const departmentQuery = departments
        .map((department) => `&department=${department.label}`)
        ?.join("&");

      const rankQuery = ranksToFilter
        ?.map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const tenureQuery = tenuresToFilter
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/profiles/retention?dateRange=${dateRange}&${departmentQuery}${rankQuery}${tenureQuery}`
      );
      return response.data;
    },
  });
};

export const useFetchActiveEmployeesOnDates = (
  accountId,
  dates,
  departments,
  ranksToFilter,
  tenuresToFilter
) => {
  return useQuery({
    queryKey: [
      "fetchActiveEmployeesOnDates",
      accountId,
      dates,
      departments,
      ranksToFilter,
      tenuresToFilter,
    ],
    queryFn: async () => {
      const departmentQuery = departments
        .map((department) => `&department=${department.label}`)
        ?.join("&");

      const rankQuery = ranksToFilter
        ?.map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const tenureQuery = tenuresToFilter
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const datesQuery = dates?.map((date) => `&date=${date}`).join("&");

      const response = await api.get(
        `/${accountId}/metrics/profiles/getActiveEmployeesOnDates?${datesQuery}${departmentQuery}${rankQuery}${tenureQuery}`
      );
      return response.data;
    },
  });
};
