import React from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

const PieChartComponent = ({ data, colors, height }) => {
  const defaultColors = [
    "#FF6384",
    "#48BB78",
    "#FFCE56",
    "#FF9F40",
    "#36A2EB",
    "#4BC0C0",
    "#9966FF",
    "#FF3D67",
    "#70D4B4",
    "#B2912F",
    "#B778FF",
  ];

  // Function to render labels only if the percentage is greater than 0
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
    fill,
  }) => {
    if (percent === 0) {
      return null;
    }

    const RADIAN = Math.PI / 180;
    const radius = outerRadius * 1.2; // This will position the label outside the outerRadius
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentageString = `${(percent * 100).toFixed(0)}%`;

    return (
      <text
        x={x}
        y={y}
        fill={fill} // Set the fill color based on the slice color
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={"12px"}
      >
        <tspan x={x} dy="0">
          {name}
        </tspan>
        <tspan x={x} dy="1.2em">
          {percentageString}
        </tspan>
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={height || 290}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="label"
          cx="50%"
          cy="50%"
          outerRadius={70}
          fill="#8884d8"
          // label={({ name, percent }) =>
          //   `${name}: ${(percent * 100).toFixed(0)}%`
          // }
          label={(props) =>
            renderCustomizedLabel({
              ...props,
              fill:
                data[props.index].color ||
                colors[data[props.index].label] ||
                defaultColors[props.index % defaultColors.length],
            })
          }
          labelLine={false}
          fontSize={"12px"}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                colors && colors[entry.label]
                  ? colors[entry.label]
                  : defaultColors[index % defaultColors.length]
              }
            />
          ))}
        </Pie>
        <Tooltip isAnimationActive={false} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
