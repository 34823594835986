import { VStack } from "@chakra-ui/react";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { useFetchAccount } from "../../../api/accounts";
import { useMemo } from "react";
import CustomTable from "../../../components/table/CustomTableV2";
import { useAddDepartmentMembers } from "../../../api/departments/department";
import useAccountId from "../../../hooks/customDomainHooks";

const AddDepartmentMembers = ({ currentMembers, onDone, departmentId }) => {
  const { accountId } = useAccountId();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const addDepartmentMembersMutation = useAddDepartmentMembers();
  const queryClient = useQueryClient();
  const { data: accountData, isLoading } = useFetchAccount(accountId);

  const addDepartmentMembers = async (data) => {
    try {
      const membersToAdd = data.map((row) => {
        return {
          id: row,
        };
      });
      await addDepartmentMembersMutation.mutateAsync({
        accountId,
        departmentId,
        data: { members: membersToAdd },
      });
      queryClient.invalidateQueries(["fetchDepartment", departmentId]);
      queryClient.invalidateQueries(["getMe"]);
      showSuccessToast(`${data.length} Members added`);
      onDone();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating member" });
    }
  };

  const profileColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Department",
        accessor: "department.name",
      },
    ],
    [accountData]
  );

  const profilesToShow = useMemo(() => {
    if (!accountData) return [];
    const currentMemberIds = currentMembers.map((row) => row.id);
    return accountData.profiles.filter((member) => {
      return member.isActive && !currentMemberIds.includes(member.id);
    });
  }, [currentMembers, accountData.profiles]);

  return (
    <VStack spacing={4}>
      {accountData && (
        <CustomTable
          columns={profileColumns}
          data={profilesToShow}
          doUsePagination
          bulkActions={[
            {
              label: "Add to department",
              action: (selectedRows) => {
                console.log(selectedRows);
                addDepartmentMembers(selectedRows);
              },
              isLoading: addDepartmentMembersMutation.isLoading,
            },
          ]}
        />
      )}
    </VStack>
  );
};

export default AddDepartmentMembers;
