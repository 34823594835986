import { useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../../hooks/useToastMessage";
import TipTapEditor from "../../../components/common/Editor/TipTapEditor";
import useAccountId from "../../../hooks/customDomainHooks";
import { useUpdateSharedNote } from "../../../api/oneOnOne/oneOnOneMeeting";
import { AccountContext } from "../../../context/AccountContextComponent";
import { FormattedDate, FormattedTime } from "react-intl";
import { SwitchField } from "../../../components/fields/SwitchField";
import HtmlViewer from "../../../components/common/Editor/HtmlViewer";

export const SharedNote = ({ meetingData }) => {
  const { meetingId } = useParams();
  const { accountId } = useAccountId();
  const updateSharedNoteMutation = useUpdateSharedNote();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const { currentProfile } = useContext(AccountContext);
  const isCreator = meetingData.creatorId === currentProfile.id;
  const canEdit = isCreator || !meetingData?.sharedNote?.lockedByOwner;
  const [sharedNoteEditor, setSharedNoteEditor] = useState(null);

  const defaultValues = {
    content: meetingData?.sharedNote?.content,
    lockedByOwner: meetingData?.sharedNote?.lockedByOwner,
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateSharedNote)(event);
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const updateSharedNote = async (data) => {
    try {
      await updateSharedNoteMutation.mutateAsync({
        accountId,
        data,
        meetingId,
      });
      queryClient.invalidateQueries(["fetchOneOnOneMeeting", meetingId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating shared notes" });
    }
  };

  // When templateId changes, force update the content because the sharedNote might be different
  useEffect(() => {
    sharedNoteEditor &&
      sharedNoteEditor.commands.setContent(meetingData?.sharedNote?.content);
  }, [meetingData.templateId]);

  return (
    <FormProvider {...form}>
      <form
        // onSubmit={handleSubmit(updateSharedNote)}
        onChange={debouncedSubmit}
        style={{
          width: "100%",
        }}
      >
        <VStack
          gap={4}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          // w={{ base: "100%", md: "70%" }}
        >
          <HStack
            gap={10}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
            w={"100%"}
          >
            <VStack gap={2} alignItems={"flex-start"} w={"100%"}>
              <Text fontSize={"xl"} fontWeight={"bold"}>
                Shared Meeting Agenda & Notes
              </Text>
              {canEdit && (
                <HStack width={"100%"}>
                  <Text
                    fontSize={"sm"}
                    fontWeight={600}
                    color={"textSecondary"}
                  >
                    Last edit by:{" "}
                  </Text>
                  <Text
                    fontSize={"sm"}
                    fontWeight={600}
                    color={"textSecondary"}
                  >
                    {meetingData?.sharedNote.updatedBy?.name} on{" "}
                    <FormattedDate
                      value={meetingData?.sharedNote.updatedAt}
                      month="short"
                      day="2-digit"
                      year="numeric"
                    />
                    {` at `}
                    <FormattedTime
                      value={meetingData?.sharedNote.updatedAt}
                    />{" "}
                  </Text>
                </HStack>
              )}
            </VStack>
            {isCreator && (
              <VStack gap={2} alignItems={"flex-start"}>
                <Text fontSize={"sm"} fontWeight={600} color={"textSecondary"}>
                  Editable by:
                </Text>
                <HStack alignItems={"flex-end"}>
                  <Text
                    fontSize={"sm"}
                    fontWeight={600}
                    color={"textSecondary"}
                    w={"40px"}
                  >
                    Both
                  </Text>
                  <Box>
                    <SwitchField
                      field={{
                        id: `lockedByOwner`,
                        validation: { required: true },
                      }}
                      // onSwitchChange={submitForm}
                    />
                  </Box>
                  <Text
                    fontSize={"sm"}
                    fontWeight={600}
                    color={"textSecondary"}
                    w={"60px"}
                  >
                    Me only
                  </Text>
                </HStack>
              </VStack>
            )}
          </HStack>
          <Box w={"100%"}>
            {canEdit ? (
              <TipTapEditor
                defaultValue={meetingData?.sharedNote.content}
                onChange={(htmlContent) => {
                  setValue("content", htmlContent);
                  debouncedSubmit();
                }}
                minHeight={400}
                setEditor={setSharedNoteEditor}
                aiContext={{
                  context: "We are writing shared notes for a 1-on-1 meeting.",
                }}
              />
            ) : (
              <Box
                bg={"white"}
                borderRadius={"md"}
                boxShadow={"sm"}
                border={"1px"}
                borderColor={"gray.200"}
                px={4}
                py={0}
              >
                <HtmlViewer html={meetingData?.sharedNote.content} />
              </Box>
            )}
          </Box>
        </VStack>
      </form>
    </FormProvider>
  );
};
