import { useEffect, useState } from "react";
import useAccountId from "../../hooks/customDomainHooks";
import { useFetchCultureScoreFactors } from "../../api/cultureScore/cultureScore";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const useCultureScoreData = () => {
  const { accountId } = useAccountId();
  const [
    currentMonthModuleWeightedScoreAverages,
    setCurrentMonthModuleWeightedScoreAverages,
  ] = useState(null);
  const [
    lastMonthModuleWeightedScoreAverages,
    setLastMonthModuleWeightedScoreAverages,
  ] = useState(null);
  const [
    currentMonthFactorWeightedScoreAverages,
    setCurrentMonthFactorWeightedScoreAverages,
  ] = useState(null);
  const [
    lastMonthFactorWeightedScoreAverages,
    setLastMonthFactorWeightedScoreAverages,
  ] = useState(null);
  const [monthlyCultureScoreAverages, setMonthlyCultureScoreAverages] =
    useState(undefined);
  const [currentCultureScore, setCurrentCultureScore] = useState(undefined);
  const { data: cultureScoreFactors } = useFetchCultureScoreFactors(accountId);

  const getFactorWeightedScoreAverage = (data) => {
    const factorScoreSums = {};

    for (const entry of data) {
      for (const module of entry.modules) {
        for (const factor of module.factors) {
          if (!factorScoreSums[factor.id]) {
            factorScoreSums[factor.id] = 0;
          }
          factorScoreSums[factor.id] += factor?.weightedScore || 0;
        }
      }
    }

    const averageWeightedScores = {};

    for (const factorId in factorScoreSums) {
      averageWeightedScores[factorId] = factorScoreSums[factorId] / data.length;
    }

    return averageWeightedScores;
  };

  const getModuleWeightedScoreAverage = (data) => {
    const moduleScoreSums = {};

    for (const entry of data) {
      for (const module of entry.modules) {
        if (!moduleScoreSums[module.id]) {
          moduleScoreSums[module.id] = 0;
        }
        moduleScoreSums[module.id] += module?.weightedScore || 0;
      }
    }

    const averageWeightedScores = {};

    for (const moduleId in moduleScoreSums) {
      averageWeightedScores[moduleId] = moduleScoreSums[moduleId] / data.length;
    }

    return averageWeightedScores;
  };

  const getMonthName = (dateString) => {
    const date = new Date(dateString);
    return monthNames[date.getMonth()];
  };
  
  const getCurrentMonthYear = () => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    return `${monthNames[currentMonth]}-${currentYear}`;
  };

  const getLastMonthYear = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const lastMonth = date.getMonth();
    const lastYear = date.getFullYear();
    return `${monthNames[lastMonth]}-${lastYear}`;
  };

  const getEntriesForMonthYear = (data, monthYear) => {
    return data.filter((entry) => {
      const monthName = getMonthName(entry.date);
      const yearName = new Date(entry.date).getFullYear();
      return `${monthName}-${yearName}` === monthYear;
    });
  };

  const calculateCurrentMonthModuleWeightedScoreAverages = (data) => {
    const thisMonthEntries = getEntriesForMonthYear(data, getCurrentMonthYear());
    return getModuleWeightedScoreAverage(thisMonthEntries);
  };

  const calculateLastMonthModuleWeightedScoreAverages = (data) => {
    const lastMonthEntries = getEntriesForMonthYear(data, getLastMonthYear());
    return getModuleWeightedScoreAverage(lastMonthEntries);
  };

  const calculateCurrentMonthFactorWeightedScoreAverages = (data) => {
    const thisMonthEntries = getEntriesForMonthYear(data, getCurrentMonthYear());
    return getFactorWeightedScoreAverage(thisMonthEntries);
  };

  const calculateLastMonthFactorWeightedScoreAverages = (data) => {
    const lastMonthEntries = getEntriesForMonthYear(data, getLastMonthYear());
    return getFactorWeightedScoreAverage(lastMonthEntries);
  };

  const calculateMonthlyAverages = (data) => {
    if (!data || data.length === 0) {
      return undefined;
    }
    // Map to keep track of the summed scores and count for each month
    const monthYearMap = {};

    data.forEach((entry) => {
      const monthName = getMonthName(entry.date);
      const yearName = new Date(entry.date).getFullYear();

      if (!monthYearMap[`${monthName}-${yearName}`]) {
        monthYearMap[`${monthName}-${yearName}`] = {
          sum: 0,
          count: 0,
        };
      }

      monthYearMap[`${monthName}-${yearName}`].sum += entry?.weightedScore * 100 || 0;
      monthYearMap[`${monthName}-${yearName}`].count += 1;
    });

    // Create the final data structure
    const monthlyAverages = Object.keys(monthYearMap).map((monthYear) => ({
      name: monthYear,
      value: Math.round(monthYearMap[monthYear].sum / monthYearMap[monthYear].count),
    }));

    return monthlyAverages.reverse();
  };

  const calculateCurrentCultureScore = (data) => {
    const latestEntry = data[0];
    if (latestEntry?.weightedScore === undefined) {
      return undefined;
    }
    return (latestEntry?.weightedScore || 0) * 100;
  };

  useEffect(() => {
    if (cultureScoreFactors) {
      setCurrentMonthModuleWeightedScoreAverages(
        calculateCurrentMonthModuleWeightedScoreAverages(cultureScoreFactors)
      );
      setLastMonthModuleWeightedScoreAverages(
        calculateLastMonthModuleWeightedScoreAverages(cultureScoreFactors)
      );
      setCurrentMonthFactorWeightedScoreAverages(
        calculateCurrentMonthFactorWeightedScoreAverages(cultureScoreFactors)
      );
      setLastMonthFactorWeightedScoreAverages(
        calculateLastMonthFactorWeightedScoreAverages(cultureScoreFactors)
      );
      setMonthlyCultureScoreAverages(
        calculateMonthlyAverages(cultureScoreFactors)
      );
      setCurrentCultureScore(calculateCurrentCultureScore(cultureScoreFactors));
    }
  }, [cultureScoreFactors]);

  return {
    cultureScoreFactors,
    currentMonthModuleWeightedScoreAverages,
    lastMonthModuleWeightedScoreAverages,
    currentMonthFactorWeightedScoreAverages,
    lastMonthFactorWeightedScoreAverages,
    monthlyCultureScoreAverages,
    currentCultureScore,
  };
};
