import { Box, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import LoadingBox from "../../../components/common/LoadingBox";
import { AccountContext } from "../../../context/AccountContextComponent";
import { SelectField } from "../../../components/fields/SelectField";
import { FormProvider, useForm } from "react-hook-form";
import { EmployeeTurnoverReportFilters } from "./EmployeeTurnoverReportFilters";
import { TurnoverFullDataTable } from "./TurnoverFullDataTable";
import CustomLineChart from "../../../components/common/charts/CustomLineChart";
import HorizontalBarChart from "../../../components/common/charts/HorizontalBarChart";
import useEmployeeTurnover from "./useEmployeeTurnover";

const EmployeeTurnoverReport = () => {
  const { hasModuleTurnedOn } = useContext(AccountContext);

  const {
    turnoverMetrics,
    turnoverByDepartment,
    turnoverByRank,
    isLoadingTurnoverMetrics,
    fetchStatus,
    allEmployeeQuarterlyTurnoverRates,
    lastSixQuarters,
    departmentsToFilter,
    setDepartmentsToFilter,
    ranksToFilter,
    setRanksToFilter,
    tenuresToFilter,
    setTenuresToFilter,
    terminationTagIdsToFilter,
    setTerminationTagIdsToFilter,
  } = useEmployeeTurnover();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      graphType: "all",
      timePeriod: "LAST_6_REPORTS", // timePeriodOptions?.[0]?.value,
    },
  });

  const watchGraphType = form.watch("graphType");

  useEffect(() => {
    if (watchGraphType !== "all") {
      if (turnoverMetrics?.length === 0) {
        return;
      }
      const defaultValue = turnoverMetrics[0]?.period?.id;
      if (defaultValue) {
        form.setValue("timePeriod", defaultValue);
      }
    }

    setRanksToFilter([]);
    setDepartmentsToFilter([]);
  }, [watchGraphType, form.setValue]);

  const getViewByOptions = () => {
    const options = [
      {
        label: "All Employees",
        value: "all",
      },
      {
        label: "By Department",
        value: "byDepartment",
      },
    ];

    if (hasModuleTurnedOn("ABC_RANKING")) {
      options.push({
        label: "By Rank",
        value: "byRank",
      });
    }

    return options;
  };

  return (
    <VStack alignItems={"flex-start"} spacing={4}>
      <VStack alignItems={"flex-start"} w={"100%"}>
        <Text fontWeight={800} fontSize={"xl"}>
          Employee Turnover Report
        </Text>
        <Box alignSelf={"flex-start"}>
          <FormProvider {...form}>
            <form onSubmit={() => {}}>
              <Wrap>
                <WrapItem alignItems={"center"} fontWeight={700}>
                  <Text>View:</Text>
                </WrapItem>
                <WrapItem alignItems={"center"}>
                  <SelectField
                    field={{
                      id: "graphType",
                      validation: { required: true },
                    }}
                    options={getViewByOptions()}
                    selectStyles={{
                      input: (provided, state) => ({
                        ...provided,
                        color: "blue.500",
                        fontWeight: 700,
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        color: "blue.500",
                        fontWeight: 700,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }),
                    }}
                    selectProps={{
                      variant: "unstyled",
                    }}
                  />
                </WrapItem>
              </Wrap>
            </form>
          </FormProvider>
        </Box>
        <EmployeeTurnoverReportFilters
          ranksToFilter={ranksToFilter}
          setRanksToFilter={setRanksToFilter}
          departmentsToFilter={departmentsToFilter}
          setDepartmentsToFilter={setDepartmentsToFilter}
          tenuresToFilter={tenuresToFilter}
          setTenuresToFilter={setTenuresToFilter}
          terminationTagIdsToFilter={terminationTagIdsToFilter}
          setTerminationTagIdsToFilter={setTerminationTagIdsToFilter}
          watchGraphType={watchGraphType}
        />
      </VStack>
      {isLoadingTurnoverMetrics && fetchStatus !== "idle" && (
        <Box alignSelf={"center"}>
          <LoadingBox height={"600px"} />
        </Box>
      )}
      {watchGraphType === "all" && (
        <AllEmployeeTurnover data={allEmployeeQuarterlyTurnoverRates} />
      )}
      {watchGraphType === "byRank" && <TurnoverByRank data={turnoverByRank} />}

      {watchGraphType === "byDepartment" && (
        <TurnoverByDepartment data={turnoverByDepartment} />
      )}
      <TurnoverFullDataTable
        profileMetrics={turnoverMetrics}
        showAfterDate={lastSixQuarters[0]}
      />
    </VStack>
  );
};

const TurnoverByRank = ({ data }) => {
  const { aRankLabel, bRankLabel, cRankLabel } = useContext(AccountContext);
  const formattedData = Object.keys(data)?.map((quarterLabel) => {
    return {
      label: quarterLabel,
      [aRankLabel]: 0,
      [bRankLabel]: 0,
      [cRankLabel]: 0,
      Unranked: 0,
      ...data[quarterLabel],
    };
  });

  return (
    <Box
      overflow={"auto"}
      bg={"white"}
      w={"100%"}
      p={4}
      borderRadius={"md"}
      border={"1px "}
      borderColor={"gray.200"}
      maxW={"900px"}
      minW={"900px"}
    >
      <Box justifyContent={"center"}>
        <Text textAlign={"center"}>Turnover by Rank Last 6 Quarters</Text>
      </Box>
      <HorizontalBarChart
        data={formattedData}
        displayPercentages={true}
        height={350}
      />
    </Box>
  );
};

const TurnoverByDepartment = ({ data }) => {
  const { accountData } = useContext(AccountContext);
  const { departments } = accountData;
  const formattedData = Object.keys(data)?.map((quarterLabel) => {
    return {
      label: quarterLabel,
      ...departments.reduce((acc, department) => {
        return {
          ...acc,
          [department.name]: 0,
        };
      }, {}),
      ...data[quarterLabel],
    };
  });

  return (
    <Box
      overflow={"auto"}
      bg={"white"}
      w={"100%"}
      p={4}
      borderRadius={"md"}
      border={"1px "}
      borderColor={"gray.200"}
      maxW={"900px"}
      minW={"900px"}
    >
      <Box justifyContent={"center"}>
        <Text textAlign={"center"}>Turnover by Department Last 6 Quarters</Text>
      </Box>
      <HorizontalBarChart
        data={formattedData}
        displayPercentages={true}
        height={350}
      />
    </Box>
  );
};

const AllEmployeeTurnover = ({ data }) => {
  const sorttedData = Object.keys(data)
    ?.map((turnoverRateDate) => {
      return {
        ...data[turnoverRateDate],
        label: turnoverRateDate,
      };
    })
    .sort((a, b) => a.startDate - b.startDate);

  const formattedData = sorttedData?.map((data) => {
    return {
      label: data.label,
      Quarter: data.average,
      Annualized: (data.average * 4).toFixed(1),
      LTM: data.ltm,
    };
  });

  return (
    <Box
      overflow={"auto"}
      bg={"white"}
      w={"100%"}
      p={4}
      borderRadius={"md"}
      border={"1px "}
      borderColor={"gray.200"}
      maxW={"900px"}
      minW={"900px"}
    >
      <Box justifyContent={"center"}>
        <Text textAlign={"center"}>All Employee Turnover Last 6 Quarters</Text>
      </Box>
      <CustomLineChart
        key={formattedData?.length}
        data={formattedData || []}
        lineProps={[
          {
            dataKey: "Quarter",
            labelListProps: {
              formatter: (value) => `${value}%`,
            },
            stroke: "#36A2EB",
          },
          {
            dataKey: "LTM",
            labelListProps: {
              formatter: (value) => `${value}%`,
            },
            stroke: "#FFD600",
          },
          {
            dataKey: "Annualized",
            labelListProps: {
              formatter: (value) => `${value}%`,
            },
            stroke: "#F56565",
          },
        ]}
        yAxisProps={{
          tickFormatter: (tick) => {
            return `${tick}%`;
          },
        }}
        tooltipProps={{
          formatter: (value) => {
            return `${value}%`;
          },
        }}
      />
    </Box>
  );
};

export default EmployeeTurnoverReport;
