import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useUpdateEmployeeAbcRanking = (props) =>
  useMutation({
    mutationKey: ["updateEmployeeAbcRanking"],
    mutationFn: async ({ accountId, reportId, employeeRankingId, data }) => {
      const response = await api.put(
        `/${accountId}/employeeAbcRanking/report/${reportId}/employeeRanking/${employeeRankingId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useSubmitEmployeeRanking = (props) =>
  useMutation({
    mutationKey: ["submitEmployeeRanking"],
    mutationFn: async ({ accountId, reportId }) => {
      const response = await api.put(
        `/${accountId}/abcRankingReports/${reportId}/submit`
      );
      return response.data;
    },
    ...props,
  });
