import {
  Box,
  FormControl,
  HStack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateAccount } from "../../../api/accounts";
import useToastMessage from "../../../hooks/useToastMessage";
import { FormProvider, useForm } from "react-hook-form";
import { SwitchField } from "../../../components/fields/SwitchField";
import { debounce } from "lodash";
import { useCallback, useContext } from "react";
import { InputField } from "../../../components/fields/InputField";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId from "../../../hooks/customDomainHooks";
import { EditValueTags } from "./EditValueTags";
import { EditBannedReactions } from "./EditBannedReactions";
import { EditTerminationTags } from "./EditTerminationTags";

export const SwitchItem = ({ label, id, size = "lg" }) => {
  return (
    <HStack w={"100%"} justifyContent={"space-between"}>
      <Text fontSize={size}>{label}</Text>
      <Box>
        <SwitchField
          field={{
            id: `${id}`,
          }}
        />
      </Box>
    </HStack>
  );
};

const Modules = () => {
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const updateAccountMutation = useUpdateAccount();
  const { accountData, isLoading } = useContext(AccountContext);
  const { showErrorToast } = useToastMessage();

  const formatModuleFormData = (data) => {
    let { bannedReactions, valueTags, ...rest } = data;
    data = rest;
    return Object.keys(data).map((key) => {
      const module = data[key];
      return {
        ...module,
        moduleName: key,
        status: module.status ? "ENABLED" : "DISABLED",
      };
    });
  };

  const updateAccount = async (data) => {
    try {
      await updateAccountMutation.mutateAsync({
        accountId,
        accountData: {
          modules: formatModuleFormData(data),
        },
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      showErrorToast({ message: `Error updating Account` });
    }
  };

  const formatDefaultValues = () => {
    const initialValues = {
      AI: {
        status: true,
      },
      EVENTS: {
        status: true,
      },
      OKR: {
        status: true,
      },
      OKR_PERSONAL: {
        status: true,
      },
      OKR_TEAM: {
        status: true,
      },
      OKR_DEPARTMENT: {
        status: true,
      },
      ONE_ON_ONE: {
        status: true,
      },
      SURVEY: {
        status: true,
      },
      SHOUTOUTS: {
        status: true,
      },
      SHOUTOUTS_REACTIONS: {
        status: true,
      },
      SHOUTOUTS_COMMENTS: {
        status: true,
      },
      SHOUTOUTS_ALLOW_USER_CREATION_OF_TAGS: {
        status: true,
      },
      KNOWLEDGE_BASE: {
        status: true,
        url: "",
      },
      ORG_CHART: {
        status: true,
      },
      ABC_RANKING: {
        status: true,
        firstRank: "A",
        secondRank: "B",
        thirdRank: "C",
      },
      CULTURE_SCORE: {
        status: true,
      },
      CULTURE_BUDGET: {
        status: true,
      },
      REPORTS: {
        status: true,
      },
    };
    Object.keys(initialValues).forEach((key) => {
      const foundModule = accountData.modules.find((m) => m.moduleName === key);
      initialValues[key].status = foundModule
        ? foundModule.status === "ENABLED"
        : true;
      const { status, ...rest } = foundModule || {};
      initialValues[key] = { ...initialValues[key], ...rest };
    });
    return initialValues;
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: formatDefaultValues(),
  });

  const {
    formState: { errors },
  } = form;

  const submitForm = async (data) => {
    if (Object.keys(errors).length === 0) {
      await updateAccount(data);
    }
  };

  const debouncedSubmit = debounce(submitForm, 1000);

  const handleFormChange = useCallback(
    (e) => {
      debouncedSubmit(form.getValues());
    },
    [debouncedSubmit]
  );

  return (
    <FormProvider {...form}>
      <form onChange={handleFormChange}>
        <VStack
          spacing={6}
          alignItems={"flex-start"}
          minW={"350px"}
          maxW={"500px"}
          divider={<StackDivider borderColor="gray.300" />}
        >
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem label={"AI"} id={"AI.status"} />
              <Text fontSize="sm">
                {
                  "Use our AI to help you write clear OKRs faster, fill out Knowledge Base articles, and more. Our AI is designed to help you save time and make your work easier."
                }
              </Text>
            </VStack>
          </FormControl>
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem label={"Events"} id={"EVENTS.status"} />
              <Text fontSize="sm">
                {
                  "The Event module streamlines event creation, employee registration, and calendar syncing. It reduces manual work, saving time and resources. Attendance and engagement are automatically tracked, and event data and reports aid in refining event strategies, ultimately boosting productivity and employee satisfaction."
                }
              </Text>
            </VStack>
          </FormControl>
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem label={"OKRs"} id={"OKR.status"} />
              <Text fontSize="sm">
                {
                  "Objectives and Key Results (OKRs) are crucial as they offer a clear and measurable roadmap for goal achievement across an organization. They foster transparency and alignment by linking individual stretch goals to company-wide objectives. OKRs encourage employees to set ambitious goals, promoting a culture of innovation and continuous improvement, knowing they are supported even if these stretch goals aren't fully met."
                }
              </Text>
              <Box w={200}>
                <SwitchItem
                  label={"Individual"}
                  id={"OKR_PERSONAL.status"}
                  size={"sm"}
                />
              </Box>
              <Box w={200}>
                <SwitchItem label={"Team"} id={"OKR_TEAM.status"} size={"sm"} />
              </Box>
              <Box w={200}>
                <SwitchItem
                  label={"Department"}
                  id={"OKR_DEPARTMENT.status"}
                  size={"sm"}
                />
              </Box>
            </VStack>
          </FormControl>
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem label={"Surveys"} id={"SURVEY.status"} />
              <Text fontSize="sm">
                {
                  "The Employee Survey module is easy to use, yet powerful and flexible, and helps assess employee engagement and sentiment. It supports quick, single-question pulse checks, detailed long-form surveys, and includes an eNPS survey to measure employee job satisfaction. Survey insights gained help identify strengths and areas for improvement, promoting productivity, retention, and workplace satisfaction."
                }
              </Text>
            </VStack>
          </FormControl>
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem label={"1-on-1s"} id={"ONE_ON_ONE.status"} />
              <Text fontSize="sm">
                {
                  "The 1-on-1 module is designed to help managers and employees to connect, communicate, and collaborate. Frequent conversations, feedback, and recognition helps to build trust, transparency, and alignment, and is a key driver of employee engagement and retention. Discussing OKR progress, and any opportunities or challenges a team member might be experiencing, should happen frequently."
                }
              </Text>
            </VStack>
          </FormControl>
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem label={"Shoutouts"} id={"SHOUTOUTS.status"} />
              <Text fontSize="sm">
                {
                  "Enable employees to communicate, collaborate, and recognize other employees that rise above, and are exemplifying strong company values."
                }
              </Text>
              <Box w={200}>
                <SwitchItem
                  label={"Reactions"}
                  id={"SHOUTOUTS_REACTIONS.status"}
                  size={"sm"}
                />
              </Box>
              <Box w={200}>
                <SwitchItem
                  label={"Comments"}
                  id={"SHOUTOUTS_COMMENTS.status"}
                  size={"sm"}
                />
              </Box>
              <EditBannedReactions account={accountData} />
              <EditValueTags />
            </VStack>
          </FormControl>
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem
                label={"Knowledge Base"}
                id={"KNOWLEDGE_BASE.status"}
              />
              <Text fontSize="sm">
                {
                  "The Knowledge Base module serves as your company's central repository for critical information, playing a vital role in fostering a strong and positive corporate culture. It enables you to document and disseminate your company's vision, mission, and values, thus promoting a sense of inclusion, identity, and belonging among all employees. By facilitating learning and collaboration, it helps onboard new hires seamlessly and maintains consistent understanding of relevant policies and programs across the organization."
                }
              </Text>
              <Box minW={400}>
                <InputField
                  field={{
                    id: `KNOWLEDGE_BASE.url`,
                    placeholder: "Link to external knowledge base (optional)",
                  }}
                />
              </Box>
            </VStack>
          </FormControl>
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem label={"Org Chart"} id={"ORG_CHART.status"} />
              <Text fontSize="sm">
                {
                  "The Org Chart module includes employee and team directories, and a visual org chart. Access to this information and resource helps promote transparency and collaboration, and enables quick identification of colleagues and their roles. Employees can be added individually or in bulk, and the org chart is automatically populated."
                }
              </Text>
            </VStack>
          </FormControl>
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem label={"Team Alignment"} id={"ABC_RANKING.status"} />
              <Text fontSize="sm">
                {
                  "The Team Alignment module is a simple way of categorizing employees based on their performance. 'A' represents top performers, 'B' signifies solid, consistent performers, and 'C' refers to underperforming individuals. Team assembly is critical to culture building, and used thoughtfully and carefully, it encourages healthy engagement, and a strong focus helping employees with career development. "
                }
              </Text>
              <VStack>
                <InputField
                  field={{
                    id: `ABC_RANKING.firstRank`,
                    placeholder: "First Rank",
                    label: "First Rank",
                  }}
                />
                <InputField
                  field={{
                    id: `ABC_RANKING.secondRank`,
                    placeholder: "Second Rank",
                    label: "Second Rank",
                  }}
                />
                <InputField
                  field={{
                    id: `ABC_RANKING.thirdRank`,
                    placeholder: "Third Rank",
                    label: "Third Rank",
                  }}
                />
              </VStack>
            </VStack>
          </FormControl>
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem
                label={"Culture Success Score"}
                id={"CULTURE_SCORE.status"}
              />
              <Text fontSize="sm">
                {
                  "The software uses an algorithm to generate a culture success score to help leadership keep their fingers on the pulse of the company. Each module is weighted by default, and administrators can change the weighting as appropriate for their business. The key to the culture success score is not the initial score, but rather how the score changes and trends over time. "
                }
              </Text>
            </VStack>
          </FormControl>
          <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem label={"Reports"} id={"REPORTS.status"} />
              <Text fontSize="sm">
                {
                  "The Reports module allows in-depth insights on tenure, turnover, eNPS, Team Alignment, and Surveys. Uncover trends and perform cohort analysis to better understand the culture of your organization."
                }
              </Text>
              <EditTerminationTags />
            </VStack>
          </FormControl>
          {/* <FormControl>
            <VStack align={"flex-start"}>
              <SwitchItem
                label={"Culture Budget"}
                id={"CULTURE_BUDGET.status"}
              />
              <Text fontSize="sm">
                {
                  "Culture building requires a budget, and this module enables event managers and organizers to track budget versus actual. This capability helps create alignment and transparency with leadership, and finance and accounting regarding this critical spend."
                }
              </Text>
            </VStack>
          </FormControl> */}
        </VStack>
      </form>
    </FormProvider>
  );
};

export default Modules;
