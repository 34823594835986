import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContextComponent";
import { useFetchAccount } from "../api/accounts";
import { useQueryClient } from "@tanstack/react-query";
import { Crisp } from "crisp-sdk-web";
import useAccountId, {
  useCustomDomainNavigate,
} from "../hooks/customDomainHooks";
import useToastMessage from "../hooks/useToastMessage";

export const AccountContext = createContext();

export const AccountContextComponent = (props) => {
  const { children } = props;
  const { userData, logout } = useContext(UserContext);
  const { customDomain, accountId } = useAccountId();
  const [currentProfile, setCurrentProfile] = useState(undefined);
  const [currentTimePeriod, setCurrentTimePeriod] = useState(undefined);
  const [nextTimePeriod, setNextTimePeriod] = useState(undefined);
  const navigate = useCustomDomainNavigate();
  const { showErrorToast } = useToastMessage();
  const {
    data: accountData,
    isLoading,
    error: errorAccountData,
    isError: isErrorAccountData,
  } = useFetchAccount(accountId);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (accountId) {
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    }
  }, [accountId]);

  const setCrispUser = (currentProfile) => {
    Crisp.user?.setEmail(currentProfile?.email);
    Crisp.user?.setNickname(currentProfile?.name);
    Crisp.user?.setCompany(currentProfile?.account?.name);
    Crisp.session?.setData({
      accountId: currentProfile?.account?.id,
      accountName: currentProfile?.account?.name,
      profileId: currentProfile?.id,
    });
  };

  // Set Crisp user
  useEffect(() => {
    setCrispUser(currentProfile);
  }, [currentProfile]);

  // Set current profile
  useEffect(() => {
    if (!userData || !accountId) return;

    const profiles = userData.profiles;

    const newCurrentProfile = profiles.find(
      (profile) => profile.account.id === accountId
    );

    // If the current accountId is not one of the accounts the user is in, redirect to first account the user is in
    // and you should log that user out of this domain otherwise it can cause a loop with the local storage
    if (!newCurrentProfile) {
      showErrorToast({
        message: "You do not have access to this account",
      });
      const selectedAccountId = profiles[0].account.id;
      if (customDomain) {
        // logout but skip default redirect because I'm about to redirect
        // to a valid domain
        logout(true);
        const protocol = window.location.protocol;
        const fullCustomDomain = `${selectedAccountId}.${
          import.meta.env.VITE_CUSTOM_BASE_DOMAIN
        }`;
        const port = window.location.port;
        window.location.href = `${protocol}//${fullCustomDomain}:${port}/home`;
      } else {
        navigate(`/${profiles[0].account.id}/home`);
      }
      return;
    }

    setCurrentProfile(newCurrentProfile);
  }, [accountId, userData]);

  // Set the current time period and next time period
  useEffect(() => {
    const currentTime = new Date();
    const timePeriods = accountData?.timePeriods || [];

    // Find the periods where current time is between the startDate and endDate
    // There may be multiple periods that match this criteria. For example, H2 and Q3.
    const matchingTimePeriods = timePeriods.filter((period) => {
      const startDate = new Date(period.startDate);
      const endDate = new Date(period.endDate);
      return currentTime >= startDate && currentTime <= endDate;
    });

    // Sort the matching periods by duration
    const sortedMatchingPeriods = matchingTimePeriods.sort((a, b) => {
      const aDuration = new Date(a.endDate) - new Date(a.startDate);
      const bDuration = new Date(b.endDate) - new Date(b.startDate);
      return aDuration - bDuration;
    });

    // Choose the shortest matching period (Q3 instead of H2)
    const desiredTimePeriod = sortedMatchingPeriods[0];

    let currentTimePeriod;
    if (desiredTimePeriod) {
      currentTimePeriod = desiredTimePeriod;
      setCurrentTimePeriod(desiredTimePeriod);
    } else {
      // Sort the periods by how close their endDate is to the current time
      // and take the closest one
      const closestPeriod = timePeriods.sort((a, b) => {
        const aDiff = Math.abs(currentTime - new Date(a.endDate));
        const bDiff = Math.abs(currentTime - new Date(b.endDate));
        return aDiff - bDiff;
      })[0];

      currentTimePeriod = closestPeriod;
      setCurrentTimePeriod(closestPeriod);
    }

    if (!currentTimePeriod) return;

    // Calculate the midpoint of the current time period
    const halfWay = new Date(
      (new Date(currentTimePeriod.startDate).getTime() +
        new Date(currentTimePeriod.endDate).getTime()) /
        2
    );

    // Decide which time period to set as "nextTimePeriod"
    if (currentTime <= halfWay) {
      setNextTimePeriod(currentTimePeriod);
    } else {
      // Filter the time periods that start after the current period ends
      const timePeriodsAfterCurrent = timePeriods.filter(
        (period) =>
          new Date(period.startDate) > new Date(currentTimePeriod.endDate)
      );

      // Then sort them by their start dates
      timePeriodsAfterCurrent.sort(
        (a, b) => new Date(a.startDate) - new Date(b.startDate)
      );

      setNextTimePeriod(timePeriodsAfterCurrent[0]);
    }
  }, [accountData?.timePeriods]);

  // Check if allowAccessUntil is null. If null, the account hasn't set up billing yet
  // If the account is past the allowAccessUntil date, redirect to billing page
  // Employees will be redirected to a different page
  useEffect(() => {
    if (!accountData || !currentProfile) return;
    const currentPath = window.location.pathname;

    if (
      currentPath.includes("/setup/billing") ||
      currentPath.includes("/setup/billing-redirect") ||
      currentPath.includes("/setup/tos")
    ) {
      return;
    }

    if (!(accountData.tosAcceptance?.length > 0)) {
      console.log("redirecting to tos");
      navigate(`/${accountId}/setup/tos`);
      return;
    }

    if (accountData.allowAccessUntil === null) {
      if (["OWNER", "ADMIN"].includes(currentProfile.role)) {
        navigate(`/${accountId}/setup/subscriptions`);
      } else {
        // TODO create this page
        navigate(`/${accountId}/setup/contact-admin`);
      }
    }

    const allowAccessUntil = new Date(accountData.allowAccessUntil);
    const currentTime = new Date();

    if (currentTime > allowAccessUntil) {
      if (["OWNER", "ADMIN"].includes(currentProfile.role)) {
        navigate(`/${accountId}/setup/subscriptions`);
      } else {
        navigate(`/${accountId}/setup/contact-admin`);
      }
    }
  }, [currentProfile, accountData]);

  // helper function to check if a module is turned on
  const hasModuleTurnedOn = (moduleName) => {
    const foundModule = accountData?.modules?.find(
      (m) => m.moduleName === moduleName
    );
    // Modules are turned on by default
    if (!foundModule) return true;
    return foundModule.status !== "DISABLED";
  };

  const getModuleConfig = (moduleName) => {
    const foundModule = accountData?.modules?.find(
      (m) => m.moduleName === moduleName
    );
    return foundModule || {};
  };

  const moduleConfig = getModuleConfig("ABC_RANKING");

  const aRankLabel = moduleConfig?.firstRank || "A";
  const bRankLabel = moduleConfig?.secondRank || "B";
  const cRankLabel = moduleConfig?.thirdRank || "C";

  const getRankLabel = (ranking) => {
    if (ranking === "A") {
      return aRankLabel;
    } else if (ranking === "B") {
      return bRankLabel;
    } else if (ranking === "C") {
      return cRankLabel;
    } else {
      return "N/A";
    }
  };

  if (accountId && (!currentTimePeriod || !currentProfile)) {
    return;
  }

  return (
    <AccountContext.Provider
      value={{
        accountData,
        currentProfile,
        currentTimePeriod,
        nextTimePeriod,
        isLoading,
        errorAccountData,
        isErrorAccountData,
        hasModuleTurnedOn,
        getModuleConfig,
        getRankLabel,
        aRankLabel,
        bRankLabel,
        cRankLabel,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
