import { VStack } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import { DualTables } from "../../../components/table/DualTables";
import { MessageBox } from "../../../components/common/MessageBox";

const EditKbPageEditors = ({ pageData, setRightRowsSelected }) => {
  const { accountData } = useContext(AccountContext);

  const profileColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Department",
        accessor: "department.name",
      },
    ],
    [accountData]
  );

  const profilesNotEditors = useMemo(() => {
    if (!accountData) return [];
    const currentEditorIds = pageData.editors.map((row) => row.id);
    return accountData.profiles.filter((member) => {
      return (
        member.isActive &&
        member.id !== pageData.creator.id &&
        !currentEditorIds.includes(member.id)
      );
    });
  }, [pageData.editors, accountData.profiles]);

  const profilesCanEdit = useMemo(() => {
    if (!accountData) return [];
    const currentEditorIds = pageData.editors.map((row) => row.id);
    return accountData.profiles.filter((member) => {
      return (
        member.isActive &&
        member.id !== pageData.creator.id &&
        currentEditorIds.includes(member.id)
      );
    });
  }, [pageData.editors, accountData.profiles]);

  return (
    <VStack spacing={4}>
      <MessageBox message={"Select people to add them as editors."} mb={4} />
      {accountData && (
        <DualTables
          columns={profileColumns}
          initialLeftData={profilesNotEditors}
          initialRightData={profilesCanEdit}
          onRightRowsChange={setRightRowsSelected}
        />
      )}
    </VStack>
  );
};

export default EditKbPageEditors;
