import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateApiKey = (props) =>
  useMutation({
    mutationKey: ["createApiKey"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/integrations/apiKeys`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useGetApiKeys = (accountId) => {
  return useQuery({
    queryKey: ["getApiKeys", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/integrations/apiKeys`);
      return response.data;
    },
  });
};

export const useDeleteApiKey = () =>
  useMutation({
    mutationKey: ["deleteApiKey"],
    mutationFn: async ({ accountId, apiKeyId }) => {
      const response = await api.delete(
        `/${accountId}/integrations/apiKeys/${apiKeyId}`
      );
      return response.data;
    },
  });

export const useUpdateApiKey = (props) =>
  useMutation({
    mutationKey: ["updateApiKey"],
    mutationFn: async ({ accountId, apiKeyId, data }) => {
      const response = await api.put(
        `/${accountId}/integrations/apiKeys/${apiKeyId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
