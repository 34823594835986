const getOwner = (objectiveSet) => {
  switch (objectiveSet?.ownerType) {
    case "PROFILE":
      return objectiveSet?.owner?.name;
    case "TEAM":
      return objectiveSet?.team?.name;
    case "DEPARTMENT":
      return objectiveSet?.department?.name;
    case "COMPANY":
      return "Company OKRs";
  }
};

const getOwnerLabel = (objectiveSet) => {
  const owner = getOwner(objectiveSet);
  const timePeriod = objectiveSet?.timePeriod?.label || "";
  return owner + " - " + timePeriod;
};

const getKrLabelToSearch = (objective, kr) => {
  const owner = getOwner(objective.objectiveSet);
  const timePeriod = objective?.objectiveSet?.timePeriod?.label || "";
  return `${owner} ${timePeriod} O${objective.index + 1}: KR${kr.index + 1}: ${
    kr.title
  }`;
};

const getObjectiveLabelToSearch = (objective, objectiveIndex) => {
  const owner = getOwner(objective.objectiveSet);
  const timePeriod = objective?.objectiveSet?.timePeriod?.label || "";
  return `${owner} ${timePeriod} O${objectiveIndex + 1}: ${objective.title}`;
};

export function formatParentKrOptions(allObjectiveSets, objective) {
  if (!objective || !allObjectiveSets) {
    return [];
  }

  // Remove the current objective from the list of objectives in the objective sets

  const filteredObjectiveSets = allObjectiveSets
    .map((objectiveSet) => {
      const filteredObjectives = objectiveSet.objectives?.filter(
        (objectiveWithKr) => objectiveWithKr.id !== objective.id
      );
      // ?.filter(
      //   (objectiveWithKrs) =>
      //     objectiveWithKrs.objectiveSet?.timePeriod?.id ===
      //     objective?.objectiveSet?.timePeriod?.id
      // );

      return { ...objectiveSet, objectives: filteredObjectives };
    })
    .filter(
      (objectiveSet) =>
        objectiveSet.objectives && objectiveSet.objectives.length > 0
    );

  return filteredObjectiveSets?.map((objectiveSet) => {
    // Add the objective to each KR so we can get the objective title in the label
    const allKrs = objectiveSet.objectives
      .map((objective) => {
        return objective.keyResults.map((kr, index) => {
          return { ...kr, objective: objective };
        });
      })
      .reduce((acc, val) => acc.concat(val), []);

    return {
      label: getOwnerLabel(objectiveSet),
      options: allKrs.map((kr) => {
        return {
          label: (
            <>
              <p>{`O${kr.objective.index + 1}: KR${kr.index + 1}: ${
                kr.title.length > 50
                  ? `${kr.title?.substring(0, 50)}...`
                  : kr.title
              }`}</p>
            </>
          ),
          labelToSearch: getKrLabelToSearch(kr.objective, kr),
          value: kr.id,
        };
      }),
    };
  });
}

export function formatOkrOptions(allObjectiveSets) {
  // This will return all objectives across all time periods
  return allObjectiveSets?.map((objectiveSet) => {
    return {
      label: getOwnerLabel(objectiveSet),
      options: objectiveSet.objectives.map((objective, index) => {
        return {
          label: (
            <>
              <p>{`O${index + 1} ${
                objective.title.length > 50
                  ? `${objective.title?.substring(0, 50)}...`
                  : objective.title
              }`}</p>
            </>
          ),
          labelToSearch: getObjectiveLabelToSearch(objective, index),
          value: {
            objective: objective.id,
          },
        };
      }),
    };
  });
}
