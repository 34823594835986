import Papa from "papaparse";
import _ from "lodash";

export const downloadCSV = (dataList, filename, columnMap = null) => {
  let csvData = dataList;

  // Check if a columnMap is provided.
  if (columnMap) {
    // Transform the dataList to include only the fields specified in the columnMap.
    const headers = Object.keys(columnMap);
    const accessors = Object.values(columnMap);

    csvData = dataList.map((item) => {
      return accessors.reduce((obj, accessor, index) => {
        obj[headers[index]] = _.get(item, accessor);
        return obj;
      }, {});
    });
  }

  // Convert the data to CSV format.
  const csv = Papa.unparse(csvData);

  // Create a Blob with the CSV data.
  const blob = new Blob([csv], { type: "text/csv" });

  // Create a URL to download the Blob.
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;

  // Set the download attribute with the desired file name.
  link.download = `${filename}.csv`;

  // Trigger the download by simulating a click event.
  link.click();

  // Clean up by revoking the object URL.
  window.URL.revokeObjectURL(url);
};

// function convertArrayOfObjectsToCSV(array) {
//   let result;

//   const columnDelimiter = ",";
//   const lineDelimiter = "\n";
//   const keys = Object.keys(array[0]);

//   result = "";
//   result += keys.join(columnDelimiter);
//   result += lineDelimiter;

//   array.forEach((item) => {
//     let ctr = 0;
//     keys.forEach((key) => {
//       if (ctr > 0) result += columnDelimiter;

//       result +=
//         typeof item[key] === "string" && item[key].includes(columnDelimiter)
//           ? `"${item[key]}"`
//           : item[key];
//       ctr++;
//     });
//     result += lineDelimiter;
//   });

//   return result;
// }

// function downloadCSV(array, fileName = "export") {
//   const link = document.createElement("a");
//   let csv = convertArrayOfObjectsToCSV(array);
//   if (csv == null) return;

//   const filename = `${fileName}.csv`;

//   if (!csv.match(/^data:text\/csv/i)) {
//     csv = `data:text/csv;charset=utf-8,${csv}`;
//   }

//   link.setAttribute("href", encodeURI(csv));
//   link.setAttribute("download", filename);
//   link.click();
// }
