import { Box, Text, VStack } from "@chakra-ui/react";
import LoadingBox from "../../../components/common/LoadingBox";
import { useFetchMySurveyRunResponse } from "../../../api/survey/surveyRunResponse";
import SurveyWidget from "../../Surveys/TakeSurvey/SurveyWidget";
import { useFetchMySurveyRuns } from "../../../api/survey/surveyRun";
import useAccountId from "../../../hooks/customDomainHooks";

const TopSurveysWidget = () => {
  const { accountId } = useAccountId();
  const {
    data: mySurveyRuns,
    isLoading: isLoadingMySurveyRuns,
    isError: isErrorMySurveyRuns,
    error: errorMySurveyRuns,
  } = useFetchMySurveyRuns(accountId);

  if (isLoadingMySurveyRuns) {
    return <LoadingBox height={"200px"} />;
  }
  if (isErrorMySurveyRuns) {
    return <Text color={"red.500"}>Error: {errorMySurveyRuns.message}</Text>;
  }

  return (
    <VStack w={"100%"} gap={4} alignItems={"flex-start"}>
      {mySurveyRuns && mySurveyRuns.length === 0 ? (
        <Text fontSize={"lg"}>No open surveys to take</Text>
      ) : (
        <Box
          bg={"white"}
          borderRadius={"md"}
          border={"1px"}
          borderColor={"gray.200"}
          p={4}
          w={"100%"}
          maxWidth={"700px"}
        >
          <SurveyWidget surveyRun={mySurveyRuns[0]} />
        </Box>
      )}
    </VStack>
  );
};

export default TopSurveysWidget;
