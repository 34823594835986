import {
  Box,
  Button,
  FormControl,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import tbaLogo from "../../../../assets/logo.png";
import { FormattedTime } from "react-intl";
import {
  useListSlackChannels,
  useSendSlackWelcomeMessage,
} from "../../../../api/integrations/slack";
import useToastMessage from "../../../../hooks/useToastMessage";
import useAccountId from "../../../../hooks/customDomainHooks";
import { SelectField } from "../../../../components/fields/SelectField";

export const SlackWelcomeModal = (props) => {
  const { isOpen, setIsOpen } = props;
  const { accountId } = useAccountId();
  const sendSlackMessageMutation = useSendSlackWelcomeMessage();
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const { data: slackChannels, isLoading: isLoadingSlackChannels } =
    useListSlackChannels(accountId, true);

  const form = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      channelId: "",
      message:
        "Hi everyone! We have a new app called The Best Answer (TBA). TBA is a SaaS platform (integrated with Slack of course) designed to help us collaborate and recognize our peers with shoutouts, manage events, surveys, share knowledge, and create alignment and transparency regarding our company Objectives and Key Results (OKRs), and so much more. Check it out!",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = form;

  const handleSendWelcomeMessage = async (message, channelId) => {
    try {
      await sendSlackMessageMutation.mutateAsync({
        accountId,
        data: {
          message,
          channelId,
        },
      });
      showSuccessToast("Message sent successfully");
      setIsOpen(false);
    } catch (e) {
      console.log(e);
      showErrorToast({ message: "Error sending message" });
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    await handleSendWelcomeMessage(data.message, data.channelId);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>{"Let everyone know about this new app"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack alignItems={"flex-start"}>
                <Text>
                  Now that you have The Best Answer Slack app connected to a
                  channel, send your first message to let everyone know about
                  this new app.
                </Text>
                <Text>
                  Here is what the message will look like in Slack. Feel free to
                  modify it before sending.
                </Text>
                <Box
                  marginY={"12px"}
                  padding={"12px"}
                  width={"100%"}
                  backgroundColor={"gray.100"}
                  borderRadius={"10px"}
                  shadow={"md"}
                >
                  <HStack w={"100%"} alignItems={"flex-start"}>
                    <Image
                      src={tbaLogo}
                      alt="The Best Answer"
                      w={"38px"}
                      border={"1px"}
                      borderColor={"gray.300"}
                      borderRadius={"10px"}
                    />

                    <VStack alignItems={"flex-start"} w={"100%"}>
                      <HStack
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={1}
                      >
                        <Text fontWeight={"bold"}>The Best Answer</Text>
                        <Box
                          backgroundColor={"gray.500"}
                          paddingX={"3px"}
                          borderRadius={"2px"}
                        >
                          <Text
                            color={"gray.200"}
                            fontSize={"2xs"}
                            fontWeight={700}
                          >
                            APP
                          </Text>
                        </Box>
                        <Text fontSize={"2xs"} color={"gray.500"}>
                          <FormattedTime value={new Date()} />
                        </Text>
                      </HStack>
                      <FormControl isRequired>
                        <Textarea
                          type="text"
                          {...register("message", {
                            required: "Message is required",
                          })}
                          height={"220px"}
                          noOfLines={6}
                          style={{ backgroundColor: "white" }}
                        />
                        {errors.name && (
                          <Text color="red.500">{errors.name.message}</Text>
                        )}
                      </FormControl>
                      <LinkButtonPopover />
                    </VStack>
                  </HStack>
                </Box>

                <Box>
                  <SelectField
                    isLoading={isLoadingSlackChannels}
                    field={{
                      label: "Send Message in Channel",
                      id: "channelId",
                      validation: {
                        required: true,
                      },
                    }}
                    isClearable
                    options={slackChannels?.map((channel) => ({
                      value: channel.id,
                      label: `# ${channel.name}`,
                    }))}
                  />
                </Box>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                onClick={() => {
                  setIsOpen(false);
                }}
                variant="outline"
              >
                Close
              </Button>
              <Button
                isLoading={isSubmitting}
                type="submit"
                isDisabled={!isValid}
              >
                Send Message
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

const LinkButtonPopover = () => {
  return (
    <Box>
      <Popover trigger={"hover"}>
        <PopoverTrigger>
          <Button
            variant="outline"
            size={"sm"}
            borderRadius={"6px"}
            cursor={"default"}
          >
            🔗 Link my Slack Account
          </Button>
        </PopoverTrigger>
        <PopoverContent width="auto" maxWidth="none">
          <PopoverArrow />
          <PopoverBody maxW={200}>
            <Text>
              When people click this button in the message, they will receive a
              message to link their account.
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
