import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
  usePagination,
} from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Stack,
  VStack,
  Checkbox,
  HStack,
  Button,
  Select,
  Text,
  Link,
} from "@chakra-ui/react";
import { BiDownArrow, BiDownload, BiUpArrow } from "react-icons/bi";
const TWO_HUNDRED_MS = 200;
import SearchIcon from "../../assets/icons/search.svg?react";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "../../utils/localStorageHelper";
import { downloadCSV } from "../../utils/downloadAsCsv";

function SearchBar({ globalFilter, setGlobalFilter, gotoPage }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, TWO_HUNDRED_MS);

  return (
    <InputGroup maxW="xs">
      <InputLeftElement pointerEvents="none">
        <Icon as={SearchIcon} color="muted" boxSize="5" />
      </InputLeftElement>
      <Input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          gotoPage(0);
        }}
        // Search on enter
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onChange(e.target.value);
          }
        }}
        placeholder={`Search`}
      />
    </InputGroup>
  );
}

function CustomTable({
  columns,
  data,
  leftElement,
  rightElement,
  filterElements,
  onRowClick,
  initialState,
  itemsName,
  showSearchBar = true,
  selectedRows,
  setSelectedRows,
  boxStyle,
  tableStyle,
  selectionType = "row", // 'row' or 'checkbox' or 'link'
  doUsePagination = false,
  tableId, // Used for localStorage key to get saved state. If not provided, localStorage will not be used.
  showDownloadLink = false,
  csvFileName,
  csvMapping,
  renderRowSubComponent,
}) {
  let filteredRows = [];
  const lastRowClicked = useRef(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  // Fetch initial state from local storage or use passed initialState
  const savedInitialState = tableId ? getLocalStorageItem(tableId) : null;
  const resolvedInitialState = savedInitialState || initialState;

  const toggleRowSelection = (rowId, shiftPressed) => {
    const newSelectedRows = new Set(selectedRows);
    if (selectedRows?.has(rowId)) {
      newSelectedRows.delete(rowId);
    } else {
      if (shiftPressed && lastRowClicked.current !== null) {
        const lastRowIndex = filteredRowsRef.current.findIndex(
          (r) => r.original.id === lastRowClicked.current
        );
        const clickedRowIndex = filteredRowsRef.current.findIndex(
          (r) => r.original.id === rowId
        );
        const start = Math.min(lastRowIndex, clickedRowIndex);
        const end = Math.max(lastRowIndex, clickedRowIndex);
        for (let i = start; i <= end; i++) {
          newSelectedRows.add(filteredRowsRef.current[i].original.id);
        }
      } else {
        newSelectedRows.add(rowId);
      }
    }
    lastRowClicked.current = rowId;
    setSelectedRows && setSelectedRows(newSelectedRows);
  };

  function IndeterminateCheckbox({ indeterminate, ...rest }) {
    const ref = React.useRef();

    React.useEffect(() => {
      if (ref.current) {
        ref.current.indeterminate = indeterminate;
      }
    }, [indeterminate, ref]);

    return <Checkbox type="checkbox" ref={ref} {...rest} />;
  }

  const toggleAllRowsSelection = useMemo(() => {
    return () => {
      if (isAllSelected) {
        setSelectedRows(new Set());
      } else {
        const allRowIds = filteredRows.map((row) => row.original.id);
        setSelectedRows(new Set(allRowIds));
      }
      setIsAllSelected(!isAllSelected);
    };
  }, [filteredRows, isAllSelected]);

  const checkboxColumn = useMemo(
    () => ({
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => {
        const props = getToggleAllRowsSelectedProps();
        return (
          <IndeterminateCheckbox
            indeterminate={props.indeterminate}
            isChecked={isAllSelected}
            onChange={toggleAllRowsSelection}
          />
        );
      },
      Cell: ({ row }) => (
        <Checkbox
          isChecked={selectedRows.has(row.original.id)}
          onChange={() => toggleRowSelection(row.original.id)}
        />
      ),
    }),
    [selectedRows, toggleRowSelection]
  );

  const newColumns = useMemo(
    () =>
      selectionType === "checkbox" ? [checkboxColumn, ...columns] : columns,
    [selectionType, checkboxColumn, columns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    state: { globalFilter },
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    state,
  } = useTable(
    {
      columns: newColumns,
      data,
      initialState: resolvedInitialState,
      autoResetHiddenColumns: false,
    },
    useGlobalFilter,
    useSortBy,
    doUsePagination ? usePagination : () => {},
    useRowSelect
  );

  const filteredRowsRef = useRef(rows);

  useEffect(() => {
    filteredRowsRef.current = rows;
  }, [rows]);

  useMemo(() => {
    filteredRows = rows;
  }, [rows]);

  // When component unmounts, save the current state to local storage
  useEffect(() => {
    return () => {
      if (tableId) {
        setLocalStorageItem(tableId, { ...state });
      }
    };
  }, [state, tableId]);

  const handleRowClick = (row, event) => {
    if (onRowClick) {
      onRowClick(row);
    }
    if (selectionType === "row") {
      toggleRowSelection(row.original.id, event.shiftKey);
    }
  };

  const [expandedRows, setExpandedRows] = useState({}); // Step 1: State for expanded rows

  const toggleRowExpanded = (rowId) => {
    setExpandedRows({
      ...expandedRows,
      [rowId]: !expandedRows[rowId],
    });
  };

  return (
    <VStack width={"100%"}>
      {(showSearchBar || leftElement || rightElement) && (
        <Box py="5" w={"100%"}>
          <Stack
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            {leftElement}
            {showSearchBar && (
              <SearchBar
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                gotoPage={gotoPage}
              />
            )}
            {rightElement}
          </Stack>
        </Box>
      )}

      {filterElements && (
        <Box pb="5" w={"100%"}>
          {filterElements}
        </Box>
      )}

      <Box overflowY="auto" w={"100%"} {...boxStyle}>
        {showDownloadLink && (
          <HStack justifyContent={"space-between"}>
            <Box pb={4} />
            <Box pb={4}>
              <Link
                onClick={() => downloadCSV(data, csvFileName, csvMapping)}
                alignSelf={"flex-end"}
              >
                <Flex align="center">
                  <Text color={"primary"} pr={1} fontSize={"sm"}>
                    Download CSV
                  </Text>
                  <Icon as={BiDownload} boxSize="4" color={"primary"} />
                </Flex>
              </Link>
            </Box>
          </HStack>
        )}
        <Table size={"sm"} {...getTableProps()} {...tableStyle} width={"100%"}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    userSelect="none"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <Flex alignItems="center">
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BiDownArrow ml={1} w={4} h={4} />
                        ) : (
                          <BiUpArrow ml={1} w={4} h={4} />
                        )
                      ) : (
                        ""
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {(page || rows).map((row, i) => {
              prepareRow(row);
              const isExpanded = expandedRows[row.original.id]; // Check if the current row is expanded

              return (
                <React.Fragment key={row.getRowProps().key}>
                  <Tr
                    {...row.getRowProps()}
                    onClick={(e) => {
                      handleRowClick(row, e);
                      renderRowSubComponent &&
                        toggleRowExpanded(row.original.id);
                    }}
                    _hover={{
                      bg:
                        selectionType === "row" &&
                        (selectedRows?.has(row.original.id)
                          ? "gray.200"
                          : "gray.50"),
                    }}
                    // Prevent text highlighting on shift + click
                    onMouseDown={(e) =>
                      selectionType === "row" &&
                      !renderRowSubComponent &&
                      e.preventDefault()
                    }
                    bg={
                      selectedRows?.has(row.original.id) ? "gray.200" : "white"
                    }
                    cursor={selectionType === "row" && "pointer"}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          style={{
                            border:
                              isExpanded && renderRowSubComponent
                                ? "none"
                                : undefined,
                          }}
                        >
                          {cell.render("Cell", { isRowExpanded: isExpanded })}
                        </Td>
                      );
                    })}
                  </Tr>
                  {isExpanded && renderRowSubComponent ? (
                    <Tr>
                      <Td colSpan={newColumns.length}>
                        {renderRowSubComponent(row)}
                      </Td>
                    </Tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </Tbody>
        </Table>
        {doUsePagination && (
          <HStack justifyContent={"space-between"} p={4} w={"100%"}>
            <Text pl={6}>
              Showing {pageIndex * pageSize + 1}-
              {pageIndex * pageSize + page.length} of {data.length} {itemsName}
            </Text>

            <Box>
              <HStack>
                <Button
                  onClick={() => previousPage()}
                  isDisabled={!canPreviousPage}
                  variant={"outline"}
                  fontSize={"xs"}
                >
                  Previous
                </Button>
                <Button
                  onClick={() => nextPage()}
                  isDisabled={!canNextPage}
                  variant={"outline"}
                  fontSize={"xs"}
                >
                  Next
                </Button>
                <Select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </Select>
              </HStack>
            </Box>
          </HStack>
        )}
      </Box>
    </VStack>
  );
}

export default CustomTable;
