import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateReportViewableBy = (props) =>
  useMutation({
    mutationKey: ["createReportViewableBy"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/abcRankingReportsViewableBy`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateReportViewableBy = (props) =>
  useMutation({
    mutationKey: ["updateReportViewableBy"],
    mutationFn: async ({ accountId, viewableById, data }) => {
      const response = await api.put(
        `/${accountId}/abcRankingReportsViewableBy/${viewableById}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteReportViewableBy = (props) =>
  useMutation({
    mutationKey: ["deleteReportViewableBy"],
    mutationFn: async ({ accountId, viewableById }) => {
      const response = await api.delete(
        `/${accountId}/abcRankingReportsViewableBy/${viewableById}`
      );
      return response.data;
    },
    ...props,
  });
