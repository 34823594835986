import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useGetMyNotificationSettings = (accountId) => {
  return useQuery({
    queryKey: ["getMyNotificationSettings", accountId],
    queryFn: async () => {
      const response = await api.get(`${accountId}/notificationSettings`);
      return response.data;
    },
  });
};

export const useUpdateMyNotificationSettings = (props) => {
  return useMutation({
    mutationKey: ["updateMyNotificationSettings"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.put(`${accountId}/notificationSettings`, data);

      return response.data;
    },
    ...props,
  });
};
