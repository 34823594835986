import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../config";

export const useAcceptTos = (props) =>
  useMutation({
    mutationKey: ["acceptTos"],
    mutationFn: async ({ accountId }) => {
      const response = await api.post(`/${accountId}/tosAcceptance`);
      return response.data;
    },
    ...props,
  });
