import { useParams } from "react-router-dom";
import PageShell from "../../layout/PageShell";
import { useFetchAccount } from "../../api/accounts";
import { Text } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import ViewEmployees from "./ViewEmployees";
import LoadingBox from "../../components/common/LoadingBox";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";

const PeoplePage = () => {
  const { accountId } = useAccountId();
  const { data, error, isLoading, isError } = useFetchAccount(accountId);
  const { currentProfile } = useContext(AccountContext);
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "People" },
  ]);

  if (isLoading) {
    return <LoadingBox />;
  }
  if (isError) {
    return <Text>{error.message}</Text>;
  }
  const profileRole = currentProfile?.role;

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "PEOPLE",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
    >
      {isError && <Text>{error.message}</Text>}
      <InfoBox
        pageInfoType={"PEOPLE"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />

      <ViewEmployees
        members={data.profiles}
        allowEdit={["ADMIN", "OWNER", "PARTNER"].includes(profileRole)}
      />
    </PageShell>
  );
};

export default PeoplePage;
