import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import AddTeamMembers from "../AddTeamMembers";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useSetTeamMembers } from "../../../api/team";
import useToastMessage from "../../../hooks/useToastMessage";
import useAccountId from "../../../hooks/customDomainHooks";

export const AddTeamMemberModal = (props) => {
  const { isOpen, setIsOpen, currentMembers } = props;
  const queryClient = useQueryClient();
  const { teamId } = useParams();
  const { accountId } = useAccountId();
  const setTeamMembersMutation = useSetTeamMembers();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const [rightRowsSelected, setRightRowsSelected] = useState([]);

  const setTeamMembers = async (data) => {
    try {
      const membersToAdd = data.map((row) => {
        return {
          id: row.id,
        };
      });
      await setTeamMembersMutation.mutateAsync({
        accountId,
        teamId,
        data: { members: membersToAdd },
      });
      queryClient.invalidateQueries(["fetchTeam", teamId]);
      queryClient.invalidateQueries(["getMe"]);
      showSuccessToast(`Team members updated`);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating member" });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"6xl"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Team membership</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AddTeamMembers
            currentMembers={currentMembers}
            setRightRowsSelected={setRightRowsSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            mr={4}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={() => setTeamMembers(rightRowsSelected)}
            isLoading={setTeamMembersMutation.isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
