import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateTerminationTag = (props) =>
  useMutation({
    mutationKey: ["createTerminationTag"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/terminationTag`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteTerminationTag = (props) =>
  useMutation({
    mutationKey: ["deleteTerminationTag"],
    mutationFn: async ({ accountId, valueTagId }) => {
      const response = await api.delete(
        `/${accountId}/terminationTag/${valueTagId}`
      );
      return response.data;
    },
    ...props,
  });

export const useFetchTerminationTags = (accountId) => {
  return useQuery({
    queryKey: ["fetchTerminationTags"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/terminationTag`);
      return response.data;
    },
  });
};
