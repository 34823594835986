import { useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateShoutoutComment = (props) =>
  useMutation({
    mutationKey: ["createShoutoutComment"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/shoutoutComment`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateShoutoutComment = (props) =>
  useMutation({
    mutationKey: ["updateShoutoutComment"],
    mutationFn: async ({ accountId, shoutoutCommentId, data }) => {
      const response = await api.put(
        `/${accountId}/shoutoutComment/${shoutoutCommentId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteShoutoutComment = (props) =>
  useMutation({
    mutationKey: ["deleteShoutoutComment"],
    mutationFn: async ({ accountId, shoutoutCommentId }) => {
      const response = await api.delete(
        `/${accountId}/shoutoutComment/${shoutoutCommentId}`
      );
      return response.data;
    },
    ...props,
  });
