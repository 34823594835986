import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateBannedReaction = (props) =>
  useMutation({
    mutationKey: ["createBannedReaction"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/bannedReaction`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteBannedReaction = (props) =>
  useMutation({
    mutationKey: ["deleteBannedReaction"],
    mutationFn: async ({ accountId, emoji }) => {
      const response = await api.delete(
        `/${accountId}/bannedReaction/${emoji}`
      );
      return response.data;
    },
    ...props,
  });

// export const useFetchBannedReactions = (accountId) => {
//   return useQuery({
//     queryKey: ["fetchBannedReactions"],
//     queryFn: async () => {
//       const response = await api.get(`/${accountId}/bannedReaction`);
//       return response.data;
//     },
//   });
// };
