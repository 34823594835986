import { SelectField } from "../../../../components/fields/SelectField";
import { useEffect, useMemo } from "react";
import { useFetchSurvey, useFetchSurveys } from "../../../../api/survey/survey";
import { useFormContext } from "react-hook-form";
import { Text, VStack } from "@chakra-ui/react";
import useAccountId from "../../../../hooks/customDomainHooks";

const SurveyQuestionWidgetConfig = ({ disallowedQuestionTypes, type }) => {
  const { accountId } = useAccountId();
  const {
    data: surveys,
    isLoading: isLoadingSurveys,
    isError: isErrorSurveys,
    error: errorSurveys,
  } = useFetchSurveys(accountId);

  const form = useFormContext();
  const { watch, setValue, unregister, trigger } = form;
  const surveyId = watch("data.surveyId");

  useEffect(() => {
    setValue("data.familyId", undefined, { shouldValidate: true });
    unregister("data.familyId");
    trigger("data");
  }, [surveyId, setValue, unregister, trigger]);

  const surveyOptions = useMemo(() => {
    if (!surveys) return [];
    return surveys.map((survey) => ({
      label: survey.title,
      value: survey.id,
    }));
  }, [surveys]);

  if (isErrorSurveys) {
    console.log(errorSurveys);
    return <Text>Error loading surveys</Text>;
  }

  return (
    <VStack>
      <SelectField
        field={{
          id: "data.surveyId",
          label: "Survey",
          validation: { required: true },
        }}
        isLoading={isLoadingSurveys}
        options={surveyOptions}
      />
      {surveyId && (
        <QuestionIdSelect
          surveyId={surveyId}
          disallowedQuestionTypes={disallowedQuestionTypes}
          type={type}
        />
      )}
    </VStack>
  );
};

const QuestionIdSelect = ({ surveyId, disallowedQuestionTypes, type }) => {
  const { accountId } = useAccountId();
  const {
    data: survey,
    isLoading: isLoadingSurvey,
    isError: isErrorSurvey,
    error: errorSurvey,
  } = useFetchSurvey(accountId, surveyId);

  const questionOptions = useMemo(() => {
    if (!survey) return [];
    return survey.questions
      .filter(
        (q) =>
          q.element?.type !== "HEADER" &&
          q.element?.type !== "PARAGRAPH" &&
          !disallowedQuestionTypes?.includes(q.element?.type)
      )
      .map((question) => ({
        label: question.prompt,
        value: question.familyId,
      }));
  }, [survey]);

  const isQuestionRequired =
    questionOptions.length > 1 || type === "SURVEY_QUESTION_SERIES";

  if (isErrorSurvey) {
    console.log(errorSurvey);
    return <Text>Error loading survey questions</Text>;
  }

  // Check length of questionOptions
  if (questionOptions.length === 1 && type === "SURVEY_QUESTION_LATEST") {
    return (
      <Text>
        This survey currently only has 1 question that can be tracked as a
        series. This widget will always track the top question.
      </Text>
    );
  }

  return (
    <>
      <SelectField
        field={{
          id: "data.familyId",
          label: "Question",
          validation: isQuestionRequired ? { required: true } : {},
        }}
        isLoading={isLoadingSurvey}
        options={questionOptions}
        key={surveyId}
      />
    </>
  );
};
export default SurveyQuestionWidgetConfig;
