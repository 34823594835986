import { useParams } from "react-router-dom";
import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useCallback } from "react";
import {
  useCreateQuestion,
  useDeleteQuestion,
} from "../../../api/survey/question";
import { useUpdateQuestion } from "../../../api/survey/question";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { debounce } from "lodash";
import useToastMessage from "../../../hooks/useToastMessage";
import QuestionMenu from "./QuestionMenu";
import TextQuestion from "./Questions/TextQuestion";
import NumberQuestion from "./Questions/NumberQuestion";
import { useQueryClient } from "@tanstack/react-query";
import RadioQuestion from "./Questions/RadioQuestion";
import DropdownQuestion from "./Questions/DropdownQuestion";
import CheckboxQuestion from "./Questions/CheckboxQuestion";
import LinearScaleQuestion from "./Questions/LinearScaleQuestion";
import NPSQuestion from "./Questions/NPSQuestion";
import { CreatableSelectField } from "../../../components/fields/CreatableSelectField";
import { SwitchField } from "../../../components/fields/SwitchField";
import useAccountId from "../../../hooks/customDomainHooks";

const QuestionForm = ({
  question,
  index,
  moveQuestion,
  questionsLength,
  questionTags,
}) => {
  const { surveyId } = useParams();
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const updateQuestionMutation = useUpdateQuestion();
  const deleteQuestionMutation = useDeleteQuestion();
  const createQuestionMutation = useCreateQuestion();
  const queryClient = useQueryClient();

  const defaultValues = {
    ...question,
    tags: question.tags.map((tag) => tag.label),
  };
  const form = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
  } = form;

  const updateQuestion = async (data) => {
    try {
      await updateQuestionMutation.mutateAsync({
        accountId,
        questionId: data.id,
        data,
      });
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating survey" });
    }
  };

  const deleteQuestion = async (questionId) => {
    try {
      await deleteQuestionMutation.mutateAsync({ accountId, questionId });
      queryClient.invalidateQueries(["fetchSurvey", surveyId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting question" });
    }
  };

  const duplicateQuestion = async () => {
    try {
      const currentFormValues = form.getValues();
      const newQuestion = {
        prompt: currentFormValues.prompt,
        isRequired: currentFormValues.isRequired,
        surveyId: currentFormValues.surveyId,
        element: currentFormValues.element,
      };
      // create a new question with the same data and add it after the current question (index +1)
      // need to still set the questionsOrder.. not sure if I want to do this all in 1 request or two
      await createQuestionMutation.mutateAsync({
        accountId,
        data: newQuestion,
      });
      queryClient.invalidateQueries(["fetchSurvey", surveyId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating question" });
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => submitForm(event), 1000),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = async (event) => {
    if (Object.keys(errors).length === 0) {
      await handleSubmit(updateQuestion)(event);
    } else {
      console.log("errors", errors);
    }
  };

  const renderQuestionType = (questionType, index) => {
    switch (questionType) {
      case "TEXT":
        return (
          <TextQuestion
            question={question}
            questionIndex={index}
            onChange={debouncedSubmit}
            questionTags={questionTags}
          />
        );
      case "NUMBER":
        return (
          <NumberQuestion
            question={question}
            questionIndex={index}
            onChange={debouncedSubmit}
          />
        );
      case "RADIO":
        return (
          <RadioQuestion
            question={question}
            questionIndex={index}
            onChange={debouncedSubmit}
          />
        );
      case "DROPDOWN":
        return (
          <DropdownQuestion
            question={question}
            questionIndex={index}
            onChange={debouncedSubmit}
          />
        );
      case "CHECKBOX":
        return (
          <CheckboxQuestion
            question={question}
            questionIndex={index}
            onChange={debouncedSubmit}
          />
        );
      case "LINEAR_SCALE":
        return (
          <LinearScaleQuestion
            question={question}
            questionIndex={index}
            onChange={debouncedSubmit}
          />
        );
      case "NPS":
        return (
          <NPSQuestion
            question={question}
            questionIndex={index}
            onChange={debouncedSubmit}
          />
        );
      default:
        return <Text>Unknown Question Type</Text>;
    }
  };

  // If tags are changed, refetch survey to get new tags
  const onTagChange = async () => {
    await submitForm();
    queryClient.invalidateQueries(["fetchSurvey", surveyId]);
  };

  const watchQuestionType = useWatch({
    control,
    name: `element.type`,
  });

  return (
    <Box
      bg={"white"}
      borderRadius={"md"}
      border={"2px"}
      borderColor={"gray.200"}
      p={4}
      mb={4}
      w={"100%"}
    >
      <FormProvider {...form}>
        <form
          onChange={debouncedSubmit}
          // Prevent form submission on enter key
          onKeyDown={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
        >
          <HStack justifyContent={"space-between"}>
            <VStack w={"100%"} spacing={8}>
              {renderQuestionType(watchQuestionType, index)}
              <Stack
                spacing={4}
                w={"100%"}
                direction={{ base: "column", md: "row" }}
                justifyContent={"space-between"}
              >
                <Box maxW={"600px"}>
                  <CreatableSelectField
                    field={{
                      placeholder: "Question Tags",
                      id: "tags",
                    }}
                    options={questionTags?.map((questionTag) => ({
                      value: questionTag.label,
                      label: questionTag.label,
                    }))}
                    isMulti={true}
                    onSelectionChange={onTagChange}
                  />
                </Box>
                <Box>
                  <SwitchField
                    field={{
                      id: `isRequired`,
                      label: "Required",
                    }}
                  />
                </Box>
              </Stack>
            </VStack>
            <QuestionMenu
              moveQuestion={moveQuestion}
              removeQuestion={() => deleteQuestion(question.id)}
              duplicateQuestion={duplicateQuestion}
              questionIndex={index}
              questionsLength={questionsLength}
              isLoading={
                updateQuestionMutation.isLoading ||
                deleteQuestionMutation.isLoading ||
                createQuestionMutation.isLoading
              }
            />
          </HStack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default React.memo(QuestionForm);
