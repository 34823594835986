import { FormProvider, useForm } from "react-hook-form";
import { SelectField } from "../../../components/fields/SelectField";

export const MeetingParticipantFilterSelect = ({
  participantFilterOptions,
  onSelection,
  participantsSelected,
}) => {
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      participantId: "",
    },
  });

  const clearParticipantId = () => {
    form.setValue("participantId", "");
  };

  return (
    <FormProvider {...form}>
      <form
        key={participantsSelected?.length}
        // Prevent form submission on enter key
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <SelectField
          field={{
            id: "participantId",
            placeholder: "+ Participant Filter",
          }}
          options={participantFilterOptions}
          onSelectionChange={(e) => {
            clearParticipantId();
            onSelection(e);
          }}
          selectProps={{
            variant: "unstyled",
            components: {
              DropdownIndicator: () => null,
            },
          }}
          selectStyles={{
            control: (provided, state) => ({
              ...provided,
              cursor: "pointer",
              padding: "0px 4px 0px 4px",
              borderRadius: "10px",
              // border: "1px solid",
              _hover: {
                bg: "gray.100",
              },
              fontSize: "sm",
            }),
          }}
        />
      </form>
    </FormProvider>
  );
};
