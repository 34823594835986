import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Input, Button, FormControl, FormLabel } from "@chakra-ui/react";
import { getAuth, confirmPasswordReset } from "firebase/auth";
import useToastMessage from "../../../hooks/useToastMessage";
import AuthLayout from "../../components/AuthLayout";
import PasswordRequirements from "../../../components/common/PasswordRequirements";

function ResetPasswordHandler() {
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const searchParams = new URLSearchParams(useLocation().search);
  const oobCode = searchParams.get("oobCode");
  const navigate = useNavigate();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const passwordsMatch = password === confirmPassword;
  const passwordCriteria = {
    minLength: password.length >= 8,
    hasNumber: /\d/.test(password),
    hasUppercase: /[A-Z]/.test(password),
    hasLowercase: /[a-z]/.test(password),
    hasSpecialCharacter: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    ),
  };

  useEffect(() => {
    setIsPasswordValid(
      passwordCriteria.minLength &&
        passwordCriteria.hasNumber &&
        passwordCriteria.hasUppercase &&
        passwordCriteria.hasLowercase &&
        passwordCriteria.hasSpecialCharacter &&
        passwordsMatch
    );
  }, [password, confirmPassword]);

  const auth = getAuth();

  const resetPassword = async (event) => {
    event.preventDefault();

    if (!passwordsMatch || !isPasswordValid) {
      showErrorToast({
        message: !passwordsMatch
          ? "The passwords do not match. Please try again."
          : "Password doesn't meet the requirements. Please try again.",
      });
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, password);
      showSuccessToast("Password reset successfully");
      navigate("/_auth/signin");
    } catch (e) {
      console.error(e);
      showErrorToast({ message: "Failed to reset password" });
    }
  };

  return (
    <AuthLayout>
      <form onSubmit={resetPassword}>
        <FormControl isRequired>
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            placeholder="New password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            mb={3}
            minLength="8"
          />
          <PasswordRequirements
            password={password}
            passwordCriteria={passwordCriteria}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Confirm Password</FormLabel>
          <Input
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            mb={3}
          />
        </FormControl>
        <Button w="100%" type="submit" isDisabled={!isPasswordValid}>
          Reset Password
        </Button>
      </form>
    </AuthLayout>
  );
}

export default ResetPasswordHandler;
