export const getLocalStorageItem = (name) => {
  try {
    const itemStr = localStorage.getItem(name);
    if (!itemStr) {
      console.log(`Item with name "${name}" not found in localStorage.`);
      return null;
    }

    const item = JSON.parse(itemStr);
    const currentTime = new Date().getTime();

    if (item.expirationTime && currentTime > item.expirationTime) {
      console.log(`Item with name "${name}" is expired.`);
      localStorage.removeItem(name); // Optional: remove expired item from localStorage
      return null;
    }

    return item.value;
  } catch (err) {
    console.error(
      `Error retrieving item with name "${name}" from localStorage.`,
      err
    );
    return null;
  }
};

// All local storage goes away after logging out.
export const setLocalStorageItem = (name, value, expiresIn = 0) => {
  const currentTime = new Date().getTime();
  // const expiresIn = 60 * 60 * 1000; // 1 hour in milliseconds
  const expirationTime = currentTime + expiresIn;
  const item = {
    value: value,
    expirationTime: expiresIn ? expirationTime : null,
  };
  localStorage.setItem(name, JSON.stringify(item));
};

export const clearAllLocalStorage = () => {
  localStorage.clear();
};
