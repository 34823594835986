import { Box, Button, Center } from "@chakra-ui/react";
import UpcomingEvents from "../../Events/UpcomingEvents";
import useAccountId, { useCustomDomainNavigate } from "../../../hooks/customDomainHooks";

const UpcomingEventsWidget = ({ widgetData }) => {
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();
  return (
    <Box w={"100%"} p={2}>
      <UpcomingEvents maxEvents={1} onlyPublished={true} />
      <Center>
        <Button
          mt={4}
          w={"100%"}
          size={"sm"}
          onClick={() => {
            navigate(`/${accountId}/events`);
          }}
        >
          View all Events
        </Button>
      </Center>
    </Box>
  );
};

export default UpcomingEventsWidget;
