import { FormProvider, useForm } from "react-hook-form";
import { useContext, useMemo } from "react";
import { SelectField } from "../../../components/fields/SelectField";
import { AccountContext } from "../../../context/AccountContextComponent";
import { formatTimeperiodOptions } from "../formatters";

export const ObjectiveTimeFilterSelect = ({
  onSelection,
  timePeriodsSelected,
}) => {
  const { accountData } = useContext(AccountContext);

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      timePeriodId: "",
    },
  });

  const timePeriodOptions = useMemo(
    () =>
      formatTimeperiodOptions(accountData?.timePeriods, timePeriodsSelected),
    [accountData, timePeriodsSelected]
  );

  const clearTimePeriod = () => {
    form.setValue("timePeriodId", "");
  };

  return (
    <FormProvider {...form}>
      <form
        key={timePeriodsSelected?.length}
        // Prevent form submission on enter key
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <SelectField
          field={{
            id: "timePeriodId",
            placeholder: "+ Time Filter",
          }}
          options={timePeriodOptions}
          onSelectionChange={(e) => {
            clearTimePeriod();
            onSelection(e);
          }}
          selectProps={{
            variant: "unstyled",
            components: {
              DropdownIndicator: () => null,
            },
          }}
          selectStyles={{
            control: (provided, state) => ({
              ...provided,
              cursor: "pointer",
              padding: "0px 4px 0px 4px",
              borderRadius: "10px",
              // border: "1px solid",
              _hover: {
                bg: "gray.100",
              },
              fontSize: "sm",
            }),
          }}
        />
      </form>
    </FormProvider>
  );
};
