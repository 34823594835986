import PageShell from "../../layout/PageShell";
import { Box, Button, Text } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import LoadingBox from "../../components/common/LoadingBox";
import { IoAddOutline } from "react-icons/io5";
import {
  useCreateKbPage,
  useFetchKbPages,
} from "../../api/knowledgeBase/kbPage";
import useToastMessage from "../../hooks/useToastMessage";
import KbPages from "./KbPages";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";

const KnowledgeBasePage = () => {
  const { accountId } = useAccountId();
  const { isLoading: isLoadingAccount, currentProfile } =
    useContext(AccountContext);
  const createKbPageMutation = useCreateKbPage();
  const { showErrorToast } = useToastMessage();
  const navigate = useCustomDomainNavigate();
  const {
    data: pages,
    isLoading: isLoadingPages,
    isError: isErrorPages,
    error: errorPages,
  } = useFetchKbPages(accountId);
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);

  if (isErrorPages) {
    console.log(errorPages);
    return <Text>Error loading pages</Text>;
  }

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Knowledge Base" },
  ]);

  const canCreatePage = ["ADMIN", "OWNER", "PARTNER"].includes(
    currentProfile?.role
  );

  if (isLoadingAccount || isLoadingPages) {
    return <LoadingBox />;
  }

  const createKbPage = async () => {
    try {
      const kbPageResp = await createKbPageMutation.mutateAsync({
        accountId,
        data: {},
      });
      navigate(
        `/${accountId}/knowledge-base/${kbPageResp.id}/edit?focusOnTitle=true`
      );
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating page" });
    }
  };

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "KNOWLEDGE_BASE",
        },
      ]}
      headerComponentRight={
        canCreatePage && (
          <Button
            leftIcon={<IoAddOutline />}
            onClick={createKbPage}
            isLoading={createKbPageMutation.isLoading}
          >
            Create Page
          </Button>
        )
      }
      setPageInfoEditMode={setPageInfoEditMode}
    >
      <InfoBox
        pageInfoType={"KNOWLEDGE_BASE"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />

      <Box py={4} pr={6}>
        <KbPages pages={pages} />
      </Box>
    </PageShell>
  );
};

export default KnowledgeBasePage;
