import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import useAuthState from "../useAuthState";
import LoadingBox from "../../components/common/LoadingBox";

const ProtectedRoute = (props) => {
  const { children } = props;
  const { user, loading } = useAuthState();

  if (loading) {
    return <LoadingBox />;
  }

  if (!user) {
    const currentPathWithQuery =
      window.location.pathname + window.location.search;
    return (
      <Navigate
        to={`/_auth/signin?redirectPath=${currentPathWithQuery}`}
        replace
      />
    );
  }

  return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;
