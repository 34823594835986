import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import UploadArea from "./UploadArea";
import { BsFiletypeJpg } from "react-icons/bs";
import ReactCrop from "react-easy-crop";
import useAccountId from "../../../hooks/customDomainHooks";
import useUploadFile from "./useUploadFile";

const ImageUpload = ({
  imageUrl,
  removeImage,
  addImage,
  isPublic = false,
  imageStyle,
  aspect = 16 / 9,
  allowCrop = true,
}) => {
  const { accountId } = useAccountId();
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isAttachingImage, setIsAttachingImage] = useState(false);
  const { uploadImage } = useUploadFile();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    if (image) {
      setIsModalOpen(true);
    }
  }, [image, file]);

  const handleClose = () => {
    setIsModalOpen(false);
    setImage(null);
    setFile(null);
  };

  return (
    <>
      <VStack alignItems={"flex-start"}>
        <UploadArea
          allowedFileTypes={["image/png", "image/jpeg"]}
          onFileSelect={(file) => {
            setFile(file);
            setImage(URL.createObjectURL(file));
            setIsModalOpen(true);
          }}
          style={{ bg: "white" }}
        >
          {!imageUrl && (
            <>
              <Text>Drop an image here or click to select</Text>
              <BsFiletypeJpg size={62} />
            </>
          )}
          {imageUrl && (
            <Image
              maxW={"700px"}
              w={"100%"}
              alt="preview image"
              src={imageUrl}
              borderRadius="lg"
              {...imageStyle}
            />
          )}
        </UploadArea>
        {imageUrl && (
          <Button size={"sm"} variant={"ghost"} onClick={removeImage}>
            Remove image
          </Button>
        )}
      </VStack>
      <Modal isOpen={isModalOpen} onClose={handleClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crop Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              h={"600px"}
              w={"100%"}
              position={"relative"}
              bg={"gray.100"}
              p={4}
            >
              <Center h={"100%"}>
                {allowCrop && image ? (
                  <ReactCrop
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspect}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                ) : null}
                {/* Just show the image if not cropping */}
                {!allowCrop && image ? (
                  <Box bg={"white"}>
                    <Image src={image} maxH={"500px"} maxW={"500px"} />
                  </Box>
                ) : null}
              </Center>
            </Box>
            <Button
              mt={4}
              onClick={async () => {
                setIsAttachingImage(true);
                const resp = await uploadImage({
                  file,
                  accountId,
                  croppedAreaPixels,
                  allowCrop,
                  isPublic,
                });
                if (!resp) {
                  setIsAttachingImage(false);
                  handleClose();
                  return;
                }
                await addImage(resp);
                setIsAttachingImage(false);
                handleClose();
              }}
              isLoading={isAttachingImage}
            >
              Use Image
            </Button>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageUpload;
