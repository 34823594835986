import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { scaleQuantize } from "d3-scale";

function createBins(data, binCount) {
  const sortedData = [...data].sort((a, b) => a.name - b.name); // assuming 'name' is numeric
  const binSize = Math.ceil(sortedData.length / binCount);

  const bins = [];
  for (let i = 0; i < sortedData.length; i += binSize) {
    const binItems = sortedData.slice(i, i + binSize);
    const minValue = binItems[0].name;
    const maxValue = binItems[binItems.length - 1].name;
    const totalValue = binItems.reduce((acc, item) => acc + item.value, 0);
    bins.push({
      name: binSize > 1 ? `${minValue} - ${maxValue}` : `${minValue}`,
      value: totalValue,
    });
  }

  return bins;
}

const HistogramChart = ({
  data,
  domain,
  maxBins,
  colorsBasedOnNames,
  colorsOnValues,
  height,
  minValueForColor,
  maxValueForColor,
}) => {
  if (maxBins) {
    data = createBins(data, maxBins);
  }

  const defaultColor = "#48BB78";

  const getScaledColor = (value) => {
    const min = minValueForColor || Math.min(...data.map((d) => d.value));
    const max = maxValueForColor || Math.max(...data.map((d) => d.value));
    const colorScale = scaleQuantize()
      .domain([min, max])
      .range(["#F56565", "#FFD600", "#48BB78"]); // Red to Yellow to Green

    return colorScale(value);
  };

  const getColor = (entry) => {
    if (colorsBasedOnNames && colorsBasedOnNames[entry.name]) {
      return colorsBasedOnNames[entry.name];
    }
    if (colorsOnValues) {
      return getScaledColor(entry.value);
    }
    return defaultColor;
  };

  return (
    <ResponsiveContainer width="100%" height={height || 200}>
      <BarChart
        width={500}
        height={"100%"}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis allowDecimals={false} domain={domain} />
        <Tooltip isAnimationActive={false}/>
        <Bar dataKey="value" maxBarSize={40}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getColor(entry)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HistogramChart;
