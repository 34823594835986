import { useParams } from "react-router-dom";
import PageShell from "../../../layout/PageShell";
import LoadingBox from "../../../components/common/LoadingBox";
import {
  Avatar,
  Box,
  Center,
  HStack,
  Link,
  Stack,
  StackDivider,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFetchKbPage } from "../../../api/knowledgeBase/kbPage";
import HtmlViewer from "../../../components/common/Editor/HtmlViewer";
import { FormattedDate } from "react-intl";
import { AccountContext } from "../../../context/AccountContextComponent";
import { useContext } from "react";
import EditIcon from "../../../assets/icons/circle_more.svg?react";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";
import { KbChildPagesList } from "./KbChildPagesList";
import KbPageMenu from "../KbPageMenu";

const ViewKbPage = () => {
  const { pageId } = useParams();
  const { accountId } = useAccountId();
  const {
    data: pageData,
    isLoading: isLoadingKbPage,
    isError: isErrorFetchingKbPage,
    error: errorFetchingKbPage,
  } = useFetchKbPage(accountId, pageId);
  const navigate = useCustomDomainNavigate();
  const { currentProfile } = useContext(AccountContext);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Knowledge Base", href: `/${accountId}/knowledge-base` },
    ...(pageData?.parent?.parent
      ? [
          {
            name: "...",
            href: `/${accountId}/knowledge-base/${pageData.parent.parent.id}/view`,
          },
        ]
      : []),
    ...(pageData?.parent
      ? [
          {
            name: pageData.parent.title,
            href: `/${accountId}/knowledge-base/${pageData.parent.id}/view`,
          },
        ]
      : []),
    { name: pageData?.title },
  ]);

  if (isLoadingKbPage) {
    return <LoadingBox />;
  }
  if (isErrorFetchingKbPage) {
    return (
      <Center h={"200px"}>
        <Box
          alignContent={"center"}
          textAlign={"center"}
          bg="white"
          p={6}
          borderRadius={"md"}
        >
          <Text color={"gray.600"} fontSize={"lg"}>
            The requested page is not available.
          </Text>
          <Text color={"gray.600"} fontSize={"lg"}>
            You may not have permission to view or it may be in draft mode.
          </Text>
        </Box>
      </Center>
    );
  }
  if (!pageData) {
    return <p>Page not found</p>;
  }
  const canEditKbPage =
    ["ADMIN", "OWNER", "PARTNER"].includes(currentProfile?.role) ||
    pageData.creator?.id === currentProfile?.id ||
    pageData.editors?.some((member) => member.id === currentProfile?.id);

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponentRight={
        <HStack>
          {!pageData.isPublished && (
            <Tag colorScheme="gray" variant={"outline"}>
              DRAFT
            </Tag>
          )}
          <KbPageMenu
            pageData={pageData}
            menuItems={["EDIT", "COPY", "DELETE"]}
          />
        </HStack>
      }
    >
      <Stack
        px={0}
        divider={<StackDivider />}
        h={"100%"}
        alignItems={"flex-start"}
        spacing={8}
        direction={{ base: "column", md: "row" }}
      >
        <VStack alignItems={"flex-start"}>
          <VStack alignItems={"flex-start"}>
            <Text fontSize={40} fontWeight={700} color={"black"}>
              {pageData.title}
            </Text>
            <Stack spacing={4} direction={{ base: "column", md: "row" }}>
              <HStack divider={<StackDivider />}>
                <HStack>
                  <Avatar
                    objectFit="cover"
                    src={pageData.updater?.user?.photoUrl}
                    name={pageData.updater?.name}
                    size={"sm"}
                  />
                  <Text fontSize={"sm"} fontWeight={600}>
                    {pageData.updater?.name}
                  </Text>
                </HStack>
                <Text fontSize={"sm"} fontWeight={600} color={"textSecondary"}>
                  Last Updated:{" "}
                  <FormattedDate
                    value={pageData.updatedAt}
                    month="short"
                    day="2-digit"
                    year="numeric"
                  />
                </Text>
              </HStack>
              {canEditKbPage && (
                <Link
                  textDecoration={"none"}
                  onClick={() => {
                    navigate(
                      `/${accountId}/knowledge-base/${pageData.id}/edit`
                    );
                  }}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <EditIcon stroke={"green"} fill={"transparent"} />
                  <Text color={"primary"} ml={2}>
                    Edit Page
                  </Text>
                </Link>
              )}
            </Stack>
          </VStack>
          <Box minW={"900px"}>
            <HtmlViewer
              html={pageData.content}
              key={`${pageId}${pageData.id}`}
            />
          </Box>
        </VStack>
        <Box>
          <Text fontSize={20} fontWeight={"bold"} ml={4} mb={4} w={"150px"}>
            Child Pages
          </Text>
          <KbChildPagesList childPages={pageData.children} />
        </Box>
      </Stack>
    </PageShell>
  );
};

export default ViewKbPage;
