import { useRoutes } from "react-router-dom";
import routes, { customDomainRoutes } from "./routes";
import { Helmet } from "react-helmet-async";
import "regenerator-runtime/runtime";
import { Crisp } from "crisp-sdk-web";
import { useEffect } from "react";
import { useCustomDomainAccountId } from "./hooks/customDomainHooks";

function App() {
  const { customDomain } = useCustomDomainAccountId();
  const content = customDomain ? useRoutes(customDomainRoutes(routes)) : useRoutes(routes);

  useEffect(() => {
    Crisp.configure("bf4eb4c7-29bf-4a47-a7b4-245a85abc271", {
      autoload: false,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>TBA App Sign on</title>
      </Helmet>
      {content}
    </>
  );
}

export default App;
