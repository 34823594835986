import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateUser = (props) =>
  useMutation({
    mutationKey: ["createUser"],
    mutationFn: async () => {
      const response = await api.post("/users");
      return response.data;
    },
    ...props,
  });

export const useGetMe = () => {
  return useQuery({
    queryKey: ["getMe"],
    queryFn: async () => {
      const response = await api.get("/users/me");
      return response.data;
    },
  });
};

export const useUpdateUser = (props) => {
  return useMutation({
    mutationKey: ["updateUser"],
    mutationFn: async (data) => {
      const response = await api.put("/users/me", data);
      return response.data;
    },
    ...props,
  });
};
