import {
  Box,
  Stack,
  HStack,
  Text,
  VStack,
  useColorModeValue,
  List,
  Button,
  useRadioGroup,
  Icon,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import { Crisp } from "crisp-sdk-web";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import {
  RadioCard,
  PriceWrapper,
  plans,
  PlanFeature,
} from "./SubscriptionsPage";
import { FormattedDate } from "react-intl";
import { pricingPlans } from "./common";
import ArrowRight from "../../assets/icons/arrow_right.svg?react";
import enterpriseBg from "../../assets/backgrounds/enterprise_bg.png";
import GreenCheckWaveyIcon from "../../assets/icons/green_check_wavey.svg?react";

export const Subscriptions = ({
  handlePlanSelect,
  currentPlan,
  currentBillingPeriod,
  futurePlan,
  futureBillingPeriod,
  futureStartDate,
  nextInvoiceDate,
}) => {
  const [billingPeriod, setBillingPeriod] = useState(
    currentBillingPeriod || "MONTHLY"
  );
  const navigate = useCustomDomainNavigate();
  const { accountData } = useContext(AccountContext);

  const numAdmins = accountData?._count?.adminsAndOwners || 0;
  const numEmployees = accountData?._count?.employees || 0;

  const { accountId } = useAccountId();
  const { getRadioProps, getRootProps } = useRadioGroup({
    name: "billingPeriod",
    defaultValue: billingPeriod,
    onChange: (value) => {
      setBillingPeriod(value);
    },
  });
  const group = getRootProps();

  const navigateToBillingPage = (planName) => {
    navigate(
      `/${accountId}/setup/billing?planName=${planName}&billingPeriod=${billingPeriod}`
    );
  };

  const onPlanSelect = (planName) => {
    handlePlanSelect
      ? handlePlanSelect(planName, billingPeriod)
      : navigateToBillingPage(planName);
  };

  return (
    <>
      <Box w={"100%"} pt={10}>
        <HStack justifyContent={"center"} spacing={0} w={"100%"} {...group}>
          <RadioCard
            key={"Monthly"}
            value={"MONTHLY"}
            styleProps={{
              borderLeftRadius: "lg",
            }}
            {...getRadioProps({ value: "MONTHLY" })}
          >
            Monthly
          </RadioCard>
          <RadioCard
            key={"Yearly"}
            value={"YEARLY"}
            styleProps={{
              borderRightRadius: "lg",
            }}
            {...getRadioProps({ value: "YEARLY" })}
          >
            Yearly
          </RadioCard>
        </HStack>
      </Box>

      <Stack
        direction={{ base: "column", xl: "row" }}
        textAlign="center"
        justifyContent={"center"}
        spacing={{ base: 2, lg: 10 }}
        py={10}
      >
        <Box align={"center"}>
          <PriceWrapper>
            <Box>
              <VStack py={4} spacing={4}>
                <Text
                  fontWeight="800"
                  fontSize="3xl"
                  textDecoration={"underline"}
                >
                  Starter
                </Text>
                <Box
                  borderWidth={"6px"}
                  borderColor={"primary"}
                  borderRadius={"50%"}
                  w={"140px"}
                  h={"140px"}
                  justifyContent={"center"}
                  display={"flex"}
                >
                  <Price billingPeriod={billingPeriod} plan={"Starter"} />
                </Box>
                <VStack>
                  <PlanLimit text={"Includes 10 users"} />
                  <PlanLimit text={"3 Culture Admin users"} />
                  <PlanLimit text={"7 Employees and Managers"} />
                </VStack>
              </VStack>
              <Box bg={"#33D1821A"} py={4} px={4} borderRadius={"xl"}>
                <List spacing={2} textAlign="start">
                  {plans.starter.features.map((feature) => (
                    <Feature text={feature} key={feature} />
                  ))}
                </List>
              </Box>
            </Box>
            <Box w="100%">
              {futurePlan === "Starter" &&
              futureBillingPeriod === billingPeriod ? (
                <Button
                  w="full"
                  variant="outline"
                  borderColor={"primary"}
                  color={"primary"}
                  bg={"white"}
                  onClick={() => onPlanSelect("Starter")}
                  isDisabled={true}
                >
                  Starts{" "}
                  <FormattedDate
                    value={futureStartDate}
                    year="numeric"
                    month="numeric"
                    day="2-digit"
                  />
                </Button>
              ) : (
                <Button
                  w="full"
                  variant="outline"
                  borderColor={"primary"}
                  color={"primary"}
                  isDisabled={
                    numAdmins > 3 ||
                    numEmployees > 7 ||
                    (currentPlan === "Starter" &&
                      currentBillingPeriod === billingPeriod &&
                      nextInvoiceDate)
                  }
                  bg={"white"}
                  onClick={() => onPlanSelect("Starter")}
                >
                  {currentPlan === "Starter" &&
                  currentBillingPeriod === billingPeriod &&
                  nextInvoiceDate
                    ? "Current Plan"
                    : "Start Free Trial"}
                </Button>
              )}
            </Box>
          </PriceWrapper>
        </Box>

        <Box align={"center"}>
          <PriceWrapper>
            <Box>
              <VStack py={4} spacing={4}>
                <Text
                  fontWeight="800"
                  fontSize="3xl"
                  textDecoration={"underline"}
                >
                  Professional
                </Text>
                <Box
                  borderWidth={"6px"}
                  bg={"primary"}
                  borderColor={"#1A202C54"}
                  borderRadius={"50%"}
                  w={"140px"}
                  h={"140px"}
                  justifyContent={"center"}
                  display={"flex"}
                >
                  <Price
                    billingPeriod={billingPeriod}
                    plan={"Professional"}
                    priceColor={"white"}
                    periodColor={"#FFFFFF99"}
                  />
                </Box>
                <VStack>
                  <PlanLimit text={"Includes 25 users"} />
                  <PlanLimit text={"5 Culture Admin users"} />
                  <PlanLimit text={"20 Employees and Managers"} />
                </VStack>
                <Text
                  textTransform="uppercase"
                  bg={"#F3B500"}
                  px={3}
                  py={1}
                  fontSize="12px"
                  fontWeight="900"
                  color={"white"}
                  borderRadius={"70px"}
                  borderWidth={"1px"}
                  borderColor={"#1A202C54"}
                  w={"190px"}
                  letterSpacing={"0.17em"}
                >
                  Most Popular
                </Text>
              </VStack>
              <Box bg={"#33D1821A"} py={4} px={4} borderRadius={"xl"}>
                <List spacing={2} textAlign="start">
                  {plans.professional.features.map((feature) => (
                    <Feature text={feature} key={feature} />
                  ))}
                </List>

                <Text fontWeight={700} pt={2}>
                  Add Additional Users:
                </Text>

                {billingPeriod === "MONTHLY" && (
                  <List spacing={0} textAlign="start">
                    <PlanLimit text={"Admins $10.00/user/mo"} />
                    <PlanLimit text={"Employees and Manager $4.95/user/mo"} />
                  </List>
                )}

                {billingPeriod === "YEARLY" && (
                  <List spacing={0} textAlign="start">
                    <PlanLimit text={"Admins $108.00/user/yr"} />
                    <PlanLimit text={"Employees and Manager $53.46/user/yr"} />
                  </List>
                )}
              </Box>
            </Box>
            <Box w="100%">
              {futurePlan === "Professional" &&
              futureBillingPeriod === billingPeriod ? (
                <Button
                  w="full"
                  onClick={() => onPlanSelect("Professional")}
                  isDisabled={true}
                >
                  Starts{" "}
                  <FormattedDate
                    value={futureStartDate}
                    year="numeric"
                    month="numeric"
                    day="2-digit"
                  />
                </Button>
              ) : (
                <Button
                  w="full"
                  onClick={() => onPlanSelect("Professional")}
                  isDisabled={
                    currentPlan === "Professional" &&
                    currentBillingPeriod === billingPeriod &&
                    nextInvoiceDate
                  }
                >
                  {currentPlan === "Professional" &&
                  currentBillingPeriod === billingPeriod &&
                  nextInvoiceDate
                    ? "Current Plan"
                    : "Start Free Trial"}
                </Button>
              )}
            </Box>
          </PriceWrapper>
        </Box>

        <Box align={"center"}>
          <PriceWrapper>
            <Box>
              <Box
                h={"416px"}
                borderRadius={"10px"}
                style={{
                  position: "relative",
                  width: "100%",
                  backgroundImage: `url(${enterpriseBg})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <VStack py={4} spacing={4}>
                  <Text
                    fontWeight="800"
                    fontSize="3xl"
                    textDecoration={"underline"}
                    color={"white"}
                  >
                    Enterprise
                  </Text>
                  <Box
                    borderWidth={"6px"}
                    borderColor={"primary"}
                    borderRadius={"50%"}
                    w={"140px"}
                    h={"140px"}
                    justifyContent={"center"}
                    display={"flex"}
                    style={{
                      background:
                        "linear-gradient(0deg, rgba(26, 32, 44, 0.7), rgba(26, 32, 44, 0.7))",
                    }}
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      <Text
                        fontSize="24px"
                        fontWeight="800"
                        color={"white"}
                        lineHeight={"24px"}
                      >
                        Custom
                      </Text>
                    </Box>
                  </Box>
                </VStack>
              </Box>
              <Box pt={4} />
              <Box bg={"#33D1821A"} py={4} px={4} borderRadius={"xl"}>
                <List spacing={2} textAlign="start">
                  {plans.enterprise.features.map((feature) => (
                    <Feature text={feature} key={feature} />
                  ))}
                </List>
              </Box>
            </Box>
            <Box w="100%">
              <Button
                w="full"
                variant="outline"
                borderColor={"primary"}
                color={"primary"}
                onClick={() => {
                  Crisp.chat.open();
                }}
                bg={"white"}
              >
                Contact us
              </Button>
            </Box>
          </PriceWrapper>
        </Box>
      </Stack>
    </>
  );
};

const PlanLimit = ({ text }) => {
  return (
    <HStack>
      <Icon as={ArrowRight} fill="#2F855A" />
      <Text fontSize={"12px"} fontWeight={500}>
        {text}
      </Text>
    </HStack>
  );
};

const Feature = ({ text }) => {
  return (
    <HStack>
      <Icon as={GreenCheckWaveyIcon} />
      <Text fontSize={"12px"} fontWeight={700}>
        {text}
      </Text>
    </HStack>
  );
};

const Price = ({ billingPeriod, plan, priceColor, periodColor }) => {
  const amount =
    pricingPlans[plan][
      billingPeriod === "MONTHLY" ? "monthlyPrice" : "yearlyPrice"
    ];
  const formattedAmount = amount.toFixed(2);
  const dollars = Number(formattedAmount.split(".")[0]).toLocaleString();
  const cents = formattedAmount.split(".")[1];

  return (
    <VStack justifyContent="center" spacing={0}>
      <HStack spacing={0}>
        <Text
          fontSize="20px"
          fontWeight="800"
          alignSelf={"flex-start"}
          lineHeight={"30px"}
          color={priceColor || "primary"}
        >
          $
        </Text>
        <Text
          fontSize="3xl"
          fontWeight="800"
          alignSelf={"flex-start"}
          lineHeight={"34px"}
          color={priceColor || "primary"}
        >
          {dollars}
        </Text>
        <Text
          fontSize="20px"
          fontWeight="800"
          alignSelf={"flex-start"}
          lineHeight={"30px"}
          color={priceColor || "primary"}
        >
          {cents}
        </Text>
      </HStack>
      <Text
        fontSize="10px"
        color={periodColor || "gray.500"}
        fontWeight={700}
        letterSpacing={".1em"}
      >
        {billingPeriod === "MONTHLY" ? "/ MONTH" : "/ YEAR"}
      </Text>
    </VStack>
  );
};
