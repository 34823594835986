import { useMutation } from "@tanstack/react-query";
import api from "../config";

// Update (or create) a question response
export const useUpdateQuestionResponse = (props) =>
  useMutation({
    mutationKey: ["updateQuestionResponse"],
    mutationFn: async ({
      accountId,
      surveyRunResponseId,
      questionId,
      data,
    }) => {
      const response = await api.put(
        `/${accountId}/surveyRunResponse/${surveyRunResponseId}/question/${questionId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
