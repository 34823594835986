import { useContext, useState } from "react";
import { Subscriptions } from "./Subscriptions";
import { SubscriptionChangeModal } from "./modals/SubscriptionChangeModal";
import {
  useCancelSubscription,
  useCreateAccountSubscription,
} from "../../api/billing/billing";
import useToastMessage from "../../hooks/useToastMessage";
import useAccountId from "../../hooks/customDomainHooks";
import { AccountContext } from "../../context/AccountContextComponent";
import { useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FormattedDate } from "react-intl";
import { AreYouSureModal } from "../../components/common/AreYouSureModal";
import { estimateInvoiceAmount } from "./common";

const SubscriptionSettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(false);
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(false);
  const createAccountSubscriptionMutation = useCreateAccountSubscription();
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const { accountId } = useAccountId();
  const { accountData } = useContext(AccountContext);
  const currentPlan =
    accountData?.accountSubscription?.subscriptionPlan?.planName;
  const currentBillingPeriod =
    accountData?.accountSubscription?.subscriptionPlan?.billingPeriod;
  const queryClient = useQueryClient();

  const numAdmins = accountData?._count?.adminsAndOwners || 0;
  const numEmployees = accountData?._count?.employees || 0;
  const numAdminsBeingBilled =
    accountData?.accountSubscription?.numAdminsBeingBilled || 0;
  const numEmployeesBeingBilled =
    accountData?.accountSubscription?.numEmployeesBeingBilled || 0;
  const maxEmployees =
    accountData?.accountSubscription?.subscriptionPlan?.maxEmployees;
  const maxAdmins =
    accountData?.accountSubscription?.subscriptionPlan?.maxAdmins;
  const scheduledFutureSubscriptionJson =
    accountData?.stripeCustomer?.futureSubscription;
  const includedAdmins =
    accountData?.accountSubscription?.subscriptionPlan?.includedAdmins || 0;
  const includedEmployees =
    accountData?.accountSubscription?.subscriptionPlan?.includedEmployees || 0;
  const nextInvoiceDate = accountData?.accountSubscription?.nextInvoiceDate;
  const trialEndDate = accountData?.accountSubscription?.trialEndDate;

  const estimatedCurrentInvoiceAmount = estimateInvoiceAmount(
    currentPlan,
    currentBillingPeriod,
    numAdmins,
    numEmployees
  );
  const estimatedNewInvoiceAmount = estimateInvoiceAmount(
    selectedPlan,
    selectedBillingPeriod,
    numAdmins,
    numEmployees
  );

  const handlePlanSelect = (plan, billingPeriod) => {
    setSelectedPlan(plan);
    setSelectedBillingPeriod(billingPeriod);
    setIsModalOpen(true);
  };

  const createAccountSubscription = async () => {
    try {
      await createAccountSubscriptionMutation.mutateAsync({
        accountId,
        data: {
          planName: selectedPlan,
          billingPeriod: selectedBillingPeriod,
        },
      });
      setIsModalOpen(false);
      showSuccessToast("Subscription updated.");
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      showErrorToast({ message: `Error creating account subscription.` });
    }
  };

  const handlePlanConfirm = () => {
    createAccountSubscription();
  };

  return (
    <Box maxW="1200px">
      <SubscriptionChangeModal
        selectedPlan={selectedPlan}
        selectedBillingPeriod={selectedBillingPeriod}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onConfirm={handlePlanConfirm}
        isCreatingSubscription={createAccountSubscriptionMutation.isLoading}
        currentPlan={currentPlan}
        currentBillingPeriod={currentBillingPeriod}
        estimatedCurrentInvoiceAmount={estimatedCurrentInvoiceAmount}
        estimatedNewInvoiceAmount={estimatedNewInvoiceAmount}
        numAdmins={numAdmins}
        numEmployees={numEmployees}
      />
      <Box
        bg={"white"}
        borderRadius={"xl"}
        border={"1px"}
        p={4}
        overflow={"auto"}
        shadow="base"
        borderWidth="1px"
        borderColor={"gray.500"}
        //maxW={"850px"}
        minW={"500px"}
        alignContent={"center"}
      >
        <CurrentSubscription
          currentPlan={currentPlan}
          currentBillingPeriod={currentBillingPeriod}
          numAdmins={numAdmins}
          numEmployees={numEmployees}
          numAdminsBeingBilled={numAdminsBeingBilled}
          numEmployeesBeingBilled={numEmployeesBeingBilled}
          maxEmployees={maxEmployees}
          maxAdmins={maxAdmins}
          scheduledFutureSubscriptionJson={scheduledFutureSubscriptionJson}
          includedAdmins={includedAdmins}
          includedEmployees={includedEmployees}
          nextInvoiceDate={nextInvoiceDate}
          trialEndDate={trialEndDate}
        />
      </Box>
      <Box align={"flex-start"}>
        <Subscriptions
          handlePlanSelect={handlePlanSelect}
          currentPlan={currentPlan}
          currentBillingPeriod={currentBillingPeriod}
          futurePlan={scheduledFutureSubscriptionJson?.planName}
          futureBillingPeriod={scheduledFutureSubscriptionJson?.billingPeriod}
          futureStartDate={scheduledFutureSubscriptionJson?.startDate}
          nextInvoiceDate={nextInvoiceDate}
        />
      </Box>
    </Box>
  );
};

const CurrentSubscription = ({
  currentPlan,
  currentBillingPeriod,
  numAdmins,
  numEmployees,
  numAdminsBeingBilled,
  numEmployeesBeingBilled,
  maxEmployees,
  maxAdmins,
  scheduledFutureSubscriptionJson,
  includedEmployees,
  includedAdmins,
  nextInvoiceDate,
  trialEndDate,
}) => {
  const { currentProfile } = useContext(AccountContext);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const { accountId } = useAccountId();
  const cancelSubscriptionMutation = useCancelSubscription();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  const renderAdminProfilesBilled = () => {
    let label;
    if (maxAdmins) {
      label = `${numAdmins}`;
    } else {
      label = `${numAdmins}`;
    }

    if (includedAdmins) {
      label = `${label} (${includedAdmins} included in plan`;
      if (numAdminsBeingBilled !== numAdmins) {
        label = `${label}, ${numAdminsBeingBilled} additional)`;
      } else {
        label = `${label})`;
      }
    }

    return label;
  };

  const renderEmployeeProfilesBilled = () => {
    let label;
    if (maxEmployees) {
      label = `${numEmployees}`;
    } else {
      label = `${numEmployees}`;
    }

    if (includedEmployees) {
      label = `${label} (${includedEmployees} included in plan`;
      if (numEmployeesBeingBilled !== numEmployees) {
        label = `${label}, ${numEmployeesBeingBilled} additional)`;
      } else {
        label = `${label})`;
      }
    }

    return label;
  };

  const renderScheduledFutureSubscription = () => {
    if (
      scheduledFutureSubscriptionJson &&
      scheduledFutureSubscriptionJson.planName
    ) {
      return (
        <>
          {`, will change to ${
            scheduledFutureSubscriptionJson.planName
          } (${scheduledFutureSubscriptionJson.billingPeriod.toLowerCase()}) on `}
          <FormattedDate
            value={scheduledFutureSubscriptionJson.startDate}
            year="numeric"
            month="long"
            day="2-digit"
          />
          .
        </>
      );
    }
  };

  const cancelSubscription = async () => {
    try {
      await cancelSubscriptionMutation.mutateAsync({
        accountId,
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      showErrorToast({ message: `Error cancelling subscription.` });
    }
  };

  return (
    <Box>
      <AreYouSureModal
        isOpen={isAreYouSureModalOpen}
        isDeleting={cancelSubscriptionMutation.isLoading}
        setIsOpen={setIsAreYouSureModalOpen}
        headerText={"Are you sure?"}
        bodyText={`Are you sure you want to cancel your subscription? Your employees will lose access to The Best Answer at the end of the current billing period.`}
        actionFunction={async () => {
          await cancelSubscription();
        }}
        buttonText={"Cancel Subscription"}
      />
      {trialEndDate && (
        <HStack justifyContent={"center"} w={"100%"} pb={4}>
          <Text fontSize={"lg"} fontWeight={"600"} color={"primary"}>
            Free Trial
          </Text>
          <Text>
            (ends on {` `}
            <FormattedDate
              value={trialEndDate}
              year="numeric"
              month="short"
              day="2-digit"
            />
            )
          </Text>
        </HStack>
      )}
      <Grid templateColumns={"repeat(12, 1fr)"} gap={0}>
        <GridItem colSpan={6}>
          <VStack alignItems={"flex-start"} spacing={4}>
            <VStack alignItems={"flex-start"}>
              <Text fontWeight={600}>Admins:</Text>
              <Text>{renderAdminProfilesBilled()}</Text>
            </VStack>
            <VStack alignItems={"flex-start"}>
              <Text fontWeight={600}>Employees:</Text>
              <Text>{renderEmployeeProfilesBilled()}</Text>
            </VStack>
          </VStack>
        </GridItem>
        <GridItem colSpan={6}>
          <VStack alignItems={"flex-start"} spacing={4}>
            <VStack alignItems={"flex-start"}>
              <Text fontWeight={600}>Plan:</Text>
              <Text>
                {`${currentPlan} (${
                  currentBillingPeriod && currentBillingPeriod.toLowerCase()
                })`}
                {renderScheduledFutureSubscription()}
              </Text>
            </VStack>
            <VStack alignItems={"flex-start"}>
              <Text fontWeight={600}>Next Invoice Date:</Text>
              {nextInvoiceDate ? (
                <HStack>
                  <FormattedDate
                    value={nextInvoiceDate}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                  {"OWNER" === currentProfile.role && (
                    <Link
                      size={"sm"}
                      onClick={() => {
                        setIsAreYouSureModalOpen(true);
                      }}
                    >
                      Cancel subscription
                    </Link>
                  )}
                </HStack>
              ) : (
                <Text color={"red.500"}> No future invoices scheduled.</Text>
              )}
            </VStack>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default SubscriptionSettings;
