import useAccountId from "../../../hooks/customDomainHooks";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useContext, useMemo } from "react";
import { debounce } from "lodash";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { SelectField } from "../../../components/fields/SelectField";
import { AccountContext } from "../../../context/AccountContextComponent";
import { useUpdateKeyResult } from "../../../api/okr/keyResult";
import { Avatar, HStack, Text } from "@chakra-ui/react";

export const KeyResultOwner = ({ keyResult, objective, canEditKeyResult }) => {
  const { accountId } = useAccountId();
  const { accountData } = useContext(AccountContext);
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateKeyResultMutation = useUpdateKeyResult();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      ownerId: keyResult?.ownerId,
    },
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
  } = form;

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const submitForm = (data) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateKeyResult)(data);
    }
  };

  const updateKeyResult = async (data) => {
    try {
      await updateKeyResultMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        keyResultId: keyResult.id,
        data: {
          title: data.title,
          ownerId: data.ownerId,
        },
      });
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating Key Result" });
    }
  };

  const formatKrOwnerOptions = (accountData) => {
    if (!accountData) return [];
    const profiles = accountData.profiles;
    return [
      {
        label: "People",
        options: profiles.map((profile) => ({
          value: profile.id,
          label: profile.name,
        })),
      },
    ];
  };

  const ownerOptions = useMemo(
    () => formatKrOwnerOptions(accountData),
    [accountData]
  );

  return (
    <HStack h={"100%"}>
      <Avatar
        key={keyResult.owner?.user?.photoUrl}
        size="sm"
        src={keyResult.owner?.user?.photoUrl}
        name={keyResult.owner?.user?.name}
      />
      <HStack spacing={0}>
        <FormProvider {...form}>
          <form
            onChange={debouncedSubmit}
            // Prevent form submission on enter key
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          >
            <SelectField
              field={{
                id: "ownerId",
                isReadOnly: !canEditKeyResult,
              }}
              options={ownerOptions}
              onSelectionChange={submitForm}
              selectStyles={{
                input: (provided, state) => ({
                  ...provided,
                  color: "blue.500",
                  fontWeight: 800,
                }),
                control: (provided, state) => ({
                  ...provided,
                  color: "blue.500",
                  fontWeight: 800,
                  textDecoration: canEditKeyResult ? "underline" : "none",
                  cursor: canEditKeyResult ? "pointer" : "default",
                }),
              }}
              selectProps={{
                variant: "unstyled",
                components: {
                  DropdownIndicator: () => null,
                },
              }}
            />
          </form>
        </FormProvider>
        <Text color={"blue.500"} fontWeight={800}>
          :
        </Text>
      </HStack>
    </HStack>
  );
};
