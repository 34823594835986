import { Box, Spinner, Text } from "@chakra-ui/react";
import useAccountId from "../../../../hooks/customDomainHooks";
import SampleDataWidget from "./SampleDataWidget";
import { useFetchRetentionMetrics } from "../../../../api/profiles";
import CustomLineChart from "../../../../components/common/charts/CustomLineChart";
import { timeRangeOptions } from "../../../../constants";
import { useContext } from "react";
import { AccountContext } from "../../../../context/AccountContextComponent";

const EmployeeRetentionWidget = ({ widgetData }) => {
  const { accountId } = useAccountId();
  const { accountData } = useContext(AccountContext);
  const dateRange = widgetData.data?.dateRange;

  const {
    data: retentionMetrics,
    isLoading: isLoadingRetentionMetrics,
    isError: isErrorRetentionMetrics,
    error: errorRetentionMetrics,
    fetchStatus,
  } = useFetchRetentionMetrics(accountId, dateRange, [], [], []);

  const getDayRangeLabel = () => {
    const dateRangeLabel = timeRangeOptions.find(
      (option) => option.value === dateRange
    )?.label;
    return `Past ${dateRangeLabel}`;
  };

  const getQuarterAndYear = (date) => {
    const currentDateQuarter = Math.ceil(date.getMonth() / 3).toString();
    const currentYear = date.getFullYear().toString();
    return `Q${currentDateQuarter} ${currentYear}`;
  };

  const doShowSampleData = () => {
    if (!retentionMetrics || retentionMetrics.length === 0) return true;
    // If the account has been created in the current quarter, show sample data.
    const currentQuarter = getQuarterAndYear(new Date());
    const accountCreationQuarter = getQuarterAndYear(
      new Date(accountData?.createdAt)
    );
    if (currentQuarter === accountCreationQuarter) return true;
    return false;
  };

  if (isLoadingRetentionMetrics && fetchStatus !== "idle") return <Spinner />;
  if (isErrorRetentionMetrics) {
    console.error(errorRetentionMetrics);
    return <Text>Error loading Retention metrics!</Text>;
  }

  if (doShowSampleData()) {
    return (
      <SampleDataWidget
        imageUrl={"retention-widget.png"}
        text={
          "This chart will update with live data at the start of next quarter."
        }
        width={740}
        height={280}
        accountId={accountId}
      />
    );
  }

  const formattedData = retentionMetrics?.map((data) => {
    let retention = "N/A";
    if (data.averageNumEmployeesInPeriod > 0) {
      retention = parseFloat(
        (
          ((data.averageNumEmployeesInPeriod - data.departures) /
            data.averageNumEmployeesInPeriod) *
          100
        ).toFixed(1)
      );
    }
    return {
      label: data.label,
      Retention: retention,
    };
  });

  return (
    <Box w={"100%"}>
      <Text fontSize={"sm"} textAlign={"center"} pb={1}>
        {getDayRangeLabel()}
      </Text>
      <CustomLineChart
        key={formattedData?.length}
        data={formattedData || []}
        height={280}
        width={"100%"}
        lineProps={[
          {
            dataKey: "Retention",
            labelListProps: {
              formatter: (value) => `${value}%`,
            },
            stroke: "#36A2EB",
          },
        ]}
        yAxisProps={{
          tickFormatter: (value) => {
            return typeof value === "number" ? `${value}%` : value;
          },
        }}
        yAxisDomain={[0, 100]}
        tooltipProps={{
          formatter: (value) => {
            return typeof value === "number" ? `${value}%` : value;
          },
        }}
      />
    </Box>
  );
};

export default EmployeeRetentionWidget;
