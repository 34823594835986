import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  registerWithEmailAndPassword,
  registerWithGoogle,
  registerWithMicrosoft,
} from "../firebase";
import {
  Box,
  Input,
  FormControl,
  Button,
  Stack,
  Text,
  Divider,
  HStack,
} from "@chakra-ui/react";
import AuthLayout from "../components/AuthLayout";
import { OAuthButtonGroup } from "../components/OAuthButtonGroup";
import useToastMessage from "../../hooks/useToastMessage";
import PasswordRequirements from "../../components/common/PasswordRequirements";

const Register = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const emailFromUrl = searchParams.get("email");

  const [email, setEmail] = useState(emailFromUrl || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const navigate = useNavigate();
  const { showErrorToast } = useToastMessage();
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const passwordsMatch = password === confirmPassword;
  const passwordCriteria = {
    minLength: password.length >= 8,
    hasNumber: /\d/.test(password),
    hasUppercase: /[A-Z]/.test(password),
    hasLowercase: /[a-z]/.test(password),
    hasSpecialCharacter: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    ),
  };
  useEffect(() => {
    setIsPasswordValid(
      passwordCriteria.minLength &&
        passwordCriteria.hasNumber &&
        passwordCriteria.hasUppercase &&
        passwordCriteria.hasLowercase &&
        passwordCriteria.hasSpecialCharacter &&
        passwordsMatch
    );
  }, [password, confirmPassword]);

  const register = async (
    authType,
    name = undefined,
    email = undefined,
    password = undefined
  ) => {
    let firebaseUser = null;

    // First, create the user in firebase auth
    try {
      if (authType === "email") {
        setIsCreatingUser(true);
        firebaseUser = await registerWithEmailAndPassword(
          name,
          email,
          password
        );
      } else if (authType === "google") {
        firebaseUser = await registerWithGoogle();
      } else if (authType === "microsoft") {
        firebaseUser = await registerWithMicrosoft();
      }

      if (firebaseUser) {
        // if the user is successfully logged in, we can navigate to the dashboard
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      showErrorToast({
        message:
          "Error on sign up. Did you use a different method to sign up? For example, Google, Microsoft, or email/password",
      });
    }
    setIsCreatingUser(false);
  };

  return (
    <AuthLayout isSignup>
      {/* <Text fontSize={"2xl"} fontWeight={600} pb={4}>
        Sign up
      </Text> */}
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (!passwordsMatch || !isPasswordValid) {
            showErrorToast({
              message: !passwordsMatch
                ? "The passwords do not match. Please try again."
                : "Password doesn't meet the requirements. Please try again.",
            });
            return;
          }
          await register("email", name, email, password);
        }}
      >
        <Stack spacing="6">
          <OAuthButtonGroup login={register} />
          <Stack spacing="6">
            <HStack>
              <Divider borderColor={"gray.500"} />
              <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                or
              </Text>
              <Divider borderColor={"gray.500"} />
            </HStack>
          </Stack>
          <Stack spacing={{ base: 2, md: 5 }}>
            <FormControl isRequired>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Full Name"
                autoComplete="name"
              />
            </FormControl>
            <FormControl isRequired>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail Address"
                type="email"
                autoComplete="email"
              />
            </FormControl>
            <FormControl isRequired>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                autoComplete="current-password"
                minLength="8"
              />
              <PasswordRequirements
                password={password}
                passwordCriteria={passwordCriteria}
              />
            </FormControl>
            <FormControl isRequired>
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                autoComplete="new-password"
                minLength="8"
              />
            </FormControl>
          </Stack>
          <Button
            type="submit"
            isLoading={isCreatingUser}
            isDisabled={!isPasswordValid}
          >
            Sign up
          </Button>
        </Stack>
      </form>
      <Box mt="4" textAlign="center" />
    </AuthLayout>
  );
};
export default Register;
