import { Box, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import useAccountId from "../../../hooks/customDomainHooks";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import { useFetchAllAbcRankingPeriodMetrics } from "../../../api/abcRankings/abcRankingPeriod";
import BarGraph from "../../../components/common/charts/BarGraph";
import { teamAlignment } from "../../../constants";

export const AllEmployeesChartLastSix = ({
  timePeriod,
  departmentsToFilter,
  tenuresToFilter,
}) => {
  const { aRankLabel, bRankLabel, cRankLabel } = useContext(AccountContext);
  const { accountId } = useAccountId();
  const {
    data: abcRankingPeriodMetrics,
    isLoading: isLoadingAbcRankingPeriodMetrics,
    isError: isErrorAbcRankingPeriodMetrics,
    error: errorAbcRankingPeriodMetrics,
  } = useFetchAllAbcRankingPeriodMetrics(
    accountId,
    departmentsToFilter,
    tenuresToFilter
  );

  if (isLoadingAbcRankingPeriodMetrics) return <Spinner />;
  if (isErrorAbcRankingPeriodMetrics) {
    console.error(errorAbcRankingPeriodMetrics);
    return <Text>Error loading Team Alignment Period metrics!</Text>;
  }

  const graphData = abcRankingPeriodMetrics.map((metric) => {
    const { aCount, bCount, cCount, unrankedCount } = metric;
    return {
      label: metric.period.label,
      Unranked: unrankedCount,
      [aRankLabel]: aCount,
      [bRankLabel]: bCount,
      [cRankLabel]: cCount,
    };
  });

  const colors = {
    [aRankLabel]: teamAlignment.A.color,
    [bRankLabel]: teamAlignment.B.color,
    [cRankLabel]: teamAlignment.C.color,
    Unranked: teamAlignment.Unranked.color,
  };

  return (
    <VStack>
      <Box
        overflow={"auto"}
        bg={"white"}
        w={"100%"}
        p={4}
        borderRadius={"md"}
        border={"1px "}
        borderColor={"gray.200"}
        maxW={"900px"}
        minW={"900px"}
      >
        <Box justifyContent={"center"}>
          <Text textAlign={"center"}>
            Team Alignment for {timePeriod.label}
          </Text>
        </Box>
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Box />
          {/* <PercentageSwitch
              widgetData={widgetData}
              updateAdminWidget={updateAdminWidget}
            /> */}
        </HStack>
        <BarGraph data={graphData} colors={colors} displayPercentages={false} />
      </Box>
    </VStack>
  );
};
