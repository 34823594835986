import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateEventSignup = (props) =>
  useMutation({
    mutationKey: ["createEventSignup"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/eventSignup`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateEventSignup = (props) =>
  useMutation({
    mutationKey: ["updateEventSignup"],
    mutationFn: async ({ accountId, eventSignupId, data }) => {
      const response = await api.put(
        `/${accountId}/eventSignup/${eventSignupId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteEventSignup = (props) =>
  useMutation({
    mutationKey: ["deleteEventSignup"],
    mutationFn: async ({ accountId, eventSignupId }) => {
      const response = await api.delete(
        `/${accountId}/eventSignup/${eventSignupId}`
      );
      return response.data;
    },
    ...props,
  });
