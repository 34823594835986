import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { CreatableSelect } from "chakra-react-select";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";

export const CreatableSelectField = (props) => {
  const { field, options, isMulti, isClearable, onSelectionChange } = props;
  const form = useFormContext();

  const {
    formState: { errors },
    register,
    getValues,
    control,
  } = form;

  const findOption = (currentValue) => {
    for (const option of options) {
      if (!option) continue;
      // TODO if you want to search on nested options need to implement this
      //   const foundOption = option?.options?.find(
      //     (o) => o.value === currentValue
      //   );
      const foundOption = option.value === currentValue ? option : undefined;
      if (foundOption) return foundOption;
    }
    return undefined;
  };

  return (
    <FormControl isInvalid={get(errors, field.id)}>
      {field.label && <FormLabel>{field.label}</FormLabel>}
      {field.customLabel}
      <Controller
        name={field.id}
        key={field.key}
        id={field.id}
        control={control}
        {...register(field.id, field.validation)}
        ref={null}
        render={(props) => (
          <CreatableSelect
            inputRef={props.field.ref}
            options={options}
            defaultValue={() => {
              const formValues = getValues();
              const valueFromInitial = get(formValues, field.id);
              if (isMulti) {
                let listOptions = [];
                for (let index = 0; index < valueFromInitial?.length; index++) {
                  const val = valueFromInitial[index];
                  const option = findOption(val);
                  listOptions.push(option);
                }
                if (listOptions) return listOptions;
              } else {
                const option = findOption(valueFromInitial);
                if (option) return option;
              }
              if (valueFromInitial) {
                return { value: valueFromInitial, label: valueFromInitial };
              } else {
                return undefined;
              }
            }}
            onChange={(val) => {
              if (Array.isArray(val)) {
                const vals = val.map((v) => {
                  return v.value;
                });
                props.field.onChange(vals);
                if (onSelectionChange) {
                  onSelectionChange(val);
                }
              } else {
                props.field.onChange(val?.value || null);
                if (onSelectionChange) {
                  onSelectionChange(val);
                }
              }
            }}
            placeholder={field.placeholder}
            closeMenuOnSelect={true}
            openMenuOnClick={true}
            formatCreateLabel={(inputLabel) => {
              return `Use "${inputLabel}"`;
            }}
            createOptionPosition={"last"}
            isMulti={isMulti}
            isClearable={isClearable}
          />
        )}
      />
      <FormErrorMessage>
        {get(errors, field.id) && get(errors, `${field.id}.message`)}
      </FormErrorMessage>
    </FormControl>
  );
};
