import { createContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import api from '../api/config';

export const LocaleContext = createContext();

export const LocaleContextComponent = props => {
  const { children } = props;
  const { data,
    isLoading,
    error: errorLocales,
    isError: isErrorLocales } = useGetLocales();

  const locales = isLoading ? [] : data;

  return (
    <LocaleContext.Provider value={{ locales }}>
      {children}
    </LocaleContext.Provider>
  );
};

const useGetLocales = () => {
  return useQuery({
    queryKey: ["getLocales"],
    queryFn: async () => {
      const response = await api.get("/users/locales");
      return response.data;
    },
  });
};
