import { VStack } from "@chakra-ui/react";
import { debounce } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import useToastMessage from "../../../hooks/useToastMessage";
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { InputField } from "../../../components/fields/InputField";
import { NumberField } from "../../../components/fields/NumberField";
import { useUpdateQuestionResponse } from "../../../api/survey/questionResponse";
import { RadioGroupField } from "../../../components/fields/RadioGroupField";
import { SelectField } from "../../../components/fields/SelectField";
import { CheckboxGroupField } from "../../../components/fields/CheckBoxGroupField";
import { useQueryClient } from "@tanstack/react-query";
import WhiteBox from "../../../components/common/WhiteBox";
import useAccountId from "../../../hooks/customDomainHooks";

const SurveyQuestion = forwardRef(
  (
    {
      question,
      response,
      isReadOnly,
      surveyRunId,
      questionIndex,
      setFormErrors,
      surveyRunResponseId,
    },
    ref
  ) => {
    const { showErrorToast } = useToastMessage();
    const updateQuestionResponseMutation = useUpdateQuestionResponse();
    const { accountId } = useAccountId();
    const queryClient = useQueryClient();

    const form = useForm({
      mode: "onChange",
      defaultValues: response,
      reValidateMode: "onChange",
    });

    const {
      handleSubmit,
      formState: { errors, isValidating },
    } = form;

    useEffect(() => {
      if (Object.keys(errors).length === 0) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [question.id]: {},
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [question.id]: errors,
        }));
      }
    }, [errors, isValidating, setFormErrors, question.id]);

    const updateQuestionResponse = async (data) => {
      try {
        await updateQuestionResponseMutation.mutateAsync({
          accountId,
          surveyRunResponseId,
          questionId: question.id,
          data: {
            response: data,
          },
        });
        queryClient.invalidateQueries([
          "fetchMySurveyRunResponse",
          accountId,
          surveyRunId,
        ]);
      } catch (error) {
        console.log(error);
        showErrorToast({ message: "Error submitting question response" });
      }
    };

    const debouncedSubmit = useCallback(
      debounce((event) => {
        submitForm(event);
      }, 1000),
      [handleSubmit, errors]
    );

    // Only update question if there are no errors
    const submitForm = (event) => {
      if (isReadOnly) return;
      if (Object.keys(errors).length === 0) {
        handleSubmit(updateQuestionResponse)(event);
      } else {
        console.log("errors", errors);
      }
    };

    // This allows us to trigger validation from outside the form
    useImperativeHandle(ref, () => ({
      trigger: () => {
        return form.trigger();
      },
    }));

    const questionLabel = `${questionIndex + 1}. ${question.prompt}`;
    return (
      <WhiteBox>
        <FormProvider {...form}>
          <form
            onChange={() => {
              form.trigger();
              debouncedSubmit();
            }}
            // Prevent form submission on enter key
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          >
            <VStack alignItems={"flex-start"}>
              {question.element.type === "TEXT" && (
                <InputField
                  field={{
                    id: "value",
                    label: questionLabel,
                    validation: {
                      required: question.isRequired,
                    },
                  }}
                />
              )}
              {question.element.type === "NUMBER" && (
                <NumberField
                  field={{
                    id: "value",
                    label: questionLabel,
                    min: question.element?.min,
                    max: question.element?.max,
                    validation: {
                      required: question.isRequired,
                    },
                  }}
                  styleProps={{
                    maxW: "90px",
                  }}
                />
              )}
              {question.element.type === "RADIO" && (
                <RadioGroupField
                  field={{
                    id: `value`,
                    label: questionLabel,
                    validation: {
                      required: question.isRequired,
                      validate: (value) => {
                        if (!question.isRequired) return true;
                        return (
                          !!value || "Please select at least one radio button."
                        );
                      },
                    },
                    options: question.element?.choices.map((choice) => {
                      return {
                        label: choice.label,
                        value:
                          choice.value !== "" &&
                          choice.value !== undefined &&
                          choice.value !== null
                            ? choice.value
                            : choice.label,
                      };
                    }),
                  }}
                />
              )}
              {question.element.type === "LINEAR_SCALE" && (
                <RadioGroupField
                  styleType="LinearScale"
                  field={{
                    id: `value`,
                    label: questionLabel,
                    validation: {
                      required: question.isRequired,
                      validate: (value) => {
                        if (!question.isRequired) return true;
                        return (
                          !!value || "Please select at least one radio button."
                        );
                      },
                    },
                    options: question.element?.choices
                      .map((choice) => {
                        return {
                          label: choice.label,
                          value:
                            choice.value !== "" &&
                            choice.value !== undefined &&
                            choice.value !== null
                              ? choice.value
                              : choice.label,
                        };
                      })
                      .sort((a, b) => a.value - b.value),
                  }}
                />
              )}
              {question.element.type === "CHECKBOX" && (
                <CheckboxGroupField
                  field={{
                    id: `value`,
                    label: questionLabel,
                    validation: {
                      required: question.isRequired,
                      validate: (value) => {
                        if (!question.isRequired) return true;
                        return (
                          (value && value.length > 0) ||
                          "Please select at least one checkbox."
                        );
                      },
                    },
                    options: question.element?.choices.map((choice) => {
                      return {
                        label: choice.label,
                        value:
                          choice.value !== "" &&
                          choice.value !== undefined &&
                          choice.value !== null
                            ? choice.value
                            : choice.label,
                      };
                    }),
                  }}
                />
              )}
              {question.element.type === "DROPDOWN" && (
                <SelectField
                  field={{
                    id: `value`,
                    label: questionLabel,
                    validation: {
                      required: question.isRequired,
                      validate: (value) => {
                        if (!question.isRequired) return true;
                        return !!value || "Please select at least one item.";
                      },
                    },
                  }}
                  options={question.element?.choices.map((choice) => {
                    return {
                      label: choice.label,
                      value: choice.value || choice.label,
                    };
                  })}
                  onSelectionChange={debouncedSubmit}
                />
              )}
              {question.element.type === "NPS" && (
                <RadioGroupField
                  styleType="NPS"
                  field={{
                    id: `value`,
                    label: questionLabel,
                    validation: {
                      required: question.isRequired,
                      validate: (value) => {
                        if (!question.isRequired) return true;
                        return (
                          (value !== undefined &&
                            value !== null &&
                            value !== "") ||
                          "Please select at least one radio button."
                        );
                      },
                    },
                    options: question.element?.choices.map((choice) => {
                      return {
                        label: choice.label,
                        value:
                          choice.value !== "" &&
                          choice.value !== undefined &&
                          choice.value !== null
                            ? choice.value
                            : choice.label,
                      };
                    }),
                  }}
                />
              )}
            </VStack>
          </form>
        </FormProvider>
      </WhiteBox>
    );
  }
);

export default SurveyQuestion;
