import {
  Box,
  Center,
  FormControl,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useRef } from "react";
import useToastMessage from "../../../hooks/useToastMessage";

const UploadArea = ({ children, allowedFileTypes, onFileSelect, style }) => {
  const fileInputRef = useRef();
  const { showErrorToast } = useToastMessage();

  const handleFileChange = (file) => {
    console.log(file);
    if (allowedFileTypes.includes(file.type)) {
      onFileSelect(file);
    } else {
      showErrorToast({
        message: "Invalid file type",
      });
    }
  };

  return (
    <FormControl>
      <Box
        border="1px"
        borderStyle={"dashed"}
        borderColor="gray.500"
        borderRadius="md"
        p={4}
        textAlign="center"
        cursor="pointer"
        _hover={{ bg: "gray.50" }}
        onClick={() => fileInputRef.current.click()}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          if (e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            handleFileChange(file);
          }
        }}
        {...style}
      >
        <Center h={"100%"}>
          <Input
            ref={fileInputRef}
            type="file"
            accept={allowedFileTypes}
            display="none"
            onChange={(event) => {
              event.stopPropagation();
              const file = event.target.files[0];
              handleFileChange(file);
              event.target.value = "";
            }}
          />
          <VStack>{children}</VStack>
        </Center>
      </Box>
    </FormControl>
  );
};

export default UploadArea;
