import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import EditSurveyInvites from "./EditSurveyInvites";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateSurvey } from "../../../../api/survey/survey";
import useToastMessage from "../../../../hooks/useToastMessage";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useAccountId from "../../../../hooks/customDomainHooks";

export const EditSurveyInvitesModal = (props) => {
  const { isOpen, setIsOpen, currentInvited } = props;
  const queryClient = useQueryClient();
  const { surveyId } = useParams();
  const { accountId } = useAccountId();
  const updateSurveyMutation = useUpdateSurvey();
  const [rightRowsSelected, setRightRowsSelected] = useState([]);
  const { showSuccessToast, showErrorToast } = useToastMessage();

  const inviteToSurvey = async (data) => {
    try {
      const membersToInvite = data.map((row) => {
        return row.id;
      });
      await updateSurveyMutation.mutateAsync({
        accountId,
        surveyId,
        data: { invitees: membersToInvite },
      });
      queryClient.invalidateQueries(["fetchSurvey", surveyId]);
      showSuccessToast(`Invitees updated`);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error adding people as invited" });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"5xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite people</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EditSurveyInvites
            currentInvited={currentInvited}
            setRightRowsSelected={setRightRowsSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            mr={4}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={() => inviteToSurvey(rightRowsSelected)}
            isLoading={updateSurveyMutation.isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
