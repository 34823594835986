import { useMutation } from "@tanstack/react-query";
import api from "../config";

export const useCreateShoutoutReaction = (props) =>
  useMutation({
    mutationKey: ["createShoutoutReaction"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/shoutoutReaction`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateShoutoutReaction = (props) =>
  useMutation({
    mutationKey: ["updateShoutoutReaction"],
    mutationFn: async ({ accountId, shoutoutReactionId, data }) => {
      const response = await api.put(
        `/${accountId}/shoutoutReaction/${shoutoutReactionId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteShoutoutReaction = (props) =>
  useMutation({
    mutationKey: ["deleteShoutoutReaction"],
    mutationFn: async ({ accountId, shoutoutReactionId }) => {
      const response = await api.delete(
        `/${accountId}/shoutoutReaction/${shoutoutReactionId}`
      );
      return response.data;
    },
    ...props,
  });
