import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { OAuthButtonGroup } from "../components/OAuthButtonGroup";
import {
  checkUserExistsInDb,
  signInWithEmailAndPassword,
  signInWithGoogle,
  signInWithMicrosoft,
} from "../firebase";
import AuthLayout from "../components/AuthLayout";
import { useNavigate } from "react-router-dom";
import useToastMessage from "../../hooks/useToastMessage";
import { useState, useRef } from "react";
import { VerifySMSCodeModal } from "../components/VerifySMSCodeModal";
import { sendVerificationCode } from "../components/sendVerificationCode";

export const Login = () => {
  const navigate = useNavigate();
  const { showErrorToast } = useToastMessage();
  const [verificationId, setVerificationId] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [MFAResolver, setMFAResolver] = useState(null);
  const [confirmedEmail, setConfirmedEmail] = useState(null);
  const [resentInviteEmail, setResentInviteEmail] = useState(false);
  const [showVerificationCodeInput, setShowVerificationCodeInput] =
    useState(false);
  const passwordRef = useRef(null);
  // Get the redirectPath from the query parameter
  const redirectPath = new URLSearchParams(window.location.search).get(
    "redirectPath"
  );

  const navigateToDashboard = () => {
    if (redirectPath) {
      navigate(redirectPath);
      return;
    }
    navigate("/");
  };

  const handleLogin = async (authType, email, password) => {
    try {
      let firebaseUser;
      let userExistsInDb = true;

      switch (authType) {
        case "email":
          firebaseUser = await signInWithEmailAndPassword(email, password);
          break;
        case "google":
          firebaseUser = await signInWithGoogle();
          userExistsInDb = (await checkUserExistsInDb(authType)).exists;
          break;
        case "microsoft":
          firebaseUser = await signInWithMicrosoft();
          userExistsInDb = (await checkUserExistsInDb(authType)).exists;
          break;
      }

      if (firebaseUser && userExistsInDb) {
        navigateToDashboard();
      } else if (firebaseUser && !userExistsInDb) {
        showErrorToast({ message: "User does not exist. Please try again." });
      } else {
        showErrorToast({ message: "Error on login. Please try again." });
      }
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        try {
          const { verificationId, resolver } = await sendVerificationCode(
            error
          );
          setVerificationId(verificationId);
          setMFAResolver(resolver);
          setShowVerificationCodeInput(true);
        } catch (error) {
          console.error(error);
          showErrorToast({
            message: "Error on phone verification. Please try again.",
          });
        }
        return;
      }
      console.error(error);
      showErrorToast({
        message: "Error on login. Check your password or sign up method.",
      });
    }
  };

  return (
    <AuthLayout isLogin>
      <VerifySMSCodeModal
        isOpen={showVerificationCodeInput}
        setIsOpen={setShowVerificationCodeInput}
        verificationId={verificationId}
        resolver={MFAResolver}
        onSuccess={() => {
          navigateToDashboard();
        }}
      />
      <div
        id="recaptcha-container-id"
        style={{ display: "flex", justifyContent: "center" }}
      ></div>
      {/* <Text fontSize={"2xl"} fontWeight={600} pb={4}>
        Login
      </Text> */}
      {userExists && !resentInviteEmail && (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const password = e.target.password.value;
            await handleLogin("email", confirmedEmail, password);
          }}
        >
          <Stack spacing="6">
            <OAuthButtonGroup login={handleLogin} />
            <HStack>
              <Divider />
              <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                or
              </Text>
              <Divider />
            </HStack>
            <Stack spacing={{ base: 2, md: 5 }}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Text>{confirmedEmail}</Text>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input id="password" type="password" ref={passwordRef} />
                <FormHelperText color="muted">
                  At least 8 characters long
                </FormHelperText>
              </FormControl>
            </Stack>
            <HStack justify="space-between">
              <Checkbox defaultChecked>Remember me</Checkbox>
              <Link
                color="blue.500"
                onClick={() => {
                  navigate(`/_auth/reset-password`);
                }}
              >
                Forgot password?
              </Link>
            </HStack>
            <Stack spacing="6">
              <Button type="submit">Sign in</Button>
            </Stack>
          </Stack>
        </form>
      )}
      {!userExists && !resentInviteEmail && (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const email = e.target.email.value;
            setIsCheckingEmail(true);
            const userExistsResp = await checkUserExistsInDb("email", email);
            if (userExistsResp.sentInviteEmail) {
              setResentInviteEmail(true);
              setConfirmedEmail(email);
            } else if (userExistsResp.exists) {
              setConfirmedEmail(email);
              setUserExists(true);
              setTimeout(() => {
                if (passwordRef.current) {
                  passwordRef.current.focus();
                }
              }, 100);
            } else {
              showErrorToast({
                message: "User does not exist. Please try again.",
              });
            }
            setIsCheckingEmail(false);
          }}
        >
          <Stack spacing="6">
            <OAuthButtonGroup login={handleLogin} />
            <HStack>
              <Divider borderColor={"gray.500"} />
              <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                or
              </Text>
              <Divider borderColor={"gray.500"} />
            </HStack>
            <Stack spacing={{ base: 2, md: 5 }}>
              <FormControl>
                <Input id="email" type="email" placeholder="Email" />
              </FormControl>
            </Stack>
            <Button type="submit" isLoading={isCheckingEmail}>
              Next
            </Button>
          </Stack>
        </form>
      )}
      {resentInviteEmail && (
        <Stack spacing="6">
          <Text>
            An invite email has been sent to {confirmedEmail}. Please check your
            inbox.
          </Text>
        </Stack>
      )}
    </AuthLayout>
  );
};
