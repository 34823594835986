import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import useAccountId from "../../../hooks/customDomainHooks";
import { AccountContext } from "../../../context/AccountContextComponent";
import { useContext, useMemo } from "react";
import { SelectField } from "../../../components/fields/SelectField";
import { CreatableSelectField } from "../../../components/fields/CreatableSelectField";
import { useCreateOneOnOneSchedule } from "../../../api/oneOnOne/oneOnOneSchedule";
import { DateField } from "../../../components/fields/DateField";
import { getRruleFromFormData } from "../../../utils/getRrule";
import { RecurrenceFields } from "./RecurrenceFields";
import { useFetchOneOnOneTemplates } from "../../../api/oneOnOne/oneOnOneTemplate";

const CreateOneOnOneSchedule = ({ onSuccess, profileId }) => {
  const { accountId } = useAccountId();
  const { accountData, isLoading, currentProfile } = useContext(AccountContext);
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const createOneOnOneScheduleMutation = useCreateOneOnOneSchedule();
  const queryClient = useQueryClient();
  const {
    data: templates,
    isLoading: isLoadingTemplates,
    isError,
    error,
  } = useFetchOneOnOneTemplates(accountId);
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      inviteeId: profileId || "",
      startDate: "",
      durationMinutes: 30,
      interval: 1,
    },
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = form;

  const createSchedule = async (data) => {
    try {
      let ruleStr = getRruleFromFormData(data);

      const submitData = {
        inviteeId: data.inviteeId,
        startDate: data.startDate,
        durationMinutes: parseInt(data.durationMinutes),
        rrule: ruleStr,
        templateId: data.templateId,
      };
      await createOneOnOneScheduleMutation.mutateAsync({
        accountId,
        data: submitData,
      });
      showSuccessToast("1-on-1 scheduled successfully.");
      await queryClient.invalidateQueries(["fetchOneOnOneMeetings"]);
      queryClient.invalidateQueries(["fetchMyTeam"]);
      onSuccess();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating schedule." });
    }
  };

  const onSubmit = async (data) => {
    await createSchedule(data);
  };

  const templateOptions = useMemo(() => {
    if (!templates) return [];
    return templates.map((template) => ({
      label: template.title,
      value: template.id,
    }));
  }, [templates]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} alignItems={"flex-start"} display={"flex"}>
          <SelectField
            field={{
              id: "inviteeId",
              validation: {
                required: true,
              },
              label: "Employee",
            }}
            options={accountData?.profiles
              .filter((profile) => profile.reportsToId === currentProfile.id)
              .filter((profile) => profile.id !== currentProfile.id)
              .map((profile) => ({
                value: profile.id,
                label: profile.name,
              }))}
            isLoading={isLoading}
            isClearable={true}
          />
          <HStack w={"100%"}>
            <DateField
              field={{
                id: "startDate",
                label: "Start Date",
                validation: {
                  required: true,
                },
              }}
              dateFormat={"MMMM d, yyyy h:mm aaaa"}
              showTimeSelect={true}
              datePickerProps={{
                minDate: new Date(),
              }}
            />
            <HStack w={"100%"} alignItems={"flex-end"} display={"flex"}>
              <Box w={"130px"}>
                <CreatableSelectField
                  field={{
                    id: "durationMinutes",
                    validation: {
                      required: true,
                    },
                    label: "Duration",
                  }}
                  options={[
                    { value: 15, label: "15" },
                    { value: 30, label: "30" },
                    { value: 45, label: "45" },
                    { value: 60, label: "60" },
                  ]}
                />
              </Box>
              <Text fontSize={16} fontWeight={500}>
                minutes
              </Text>
            </HStack>
          </HStack>
          <SelectField
            field={{
              id: "templateId",
              label: "Template",
              validation: {
                required: false,
              },
            }}
            options={templateOptions}
            isLoading={isLoadingTemplates}
            isClearable={true}
          />
          <RecurrenceFields />
          <Button type="submit" isLoading={isSubmitting} isDisabled={!isValid}>
            Schedule 1-on-1
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default CreateOneOnOneSchedule;
