import React, { useEffect, useState } from "react";
import {
  DndContext,
  closestCorners,
  pointerWithin,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

const DragAndDrop = ({ children, onUpdate, containersList }) => {
  //   {
  //     container1: [{id:"4"}],
  //     container2: [{id:"7"}],
  //     container3: [],
  //   }
  const formatContainers = (containersList) => {
    const containers = {};
    containersList.forEach((container, index) => {
      containers[`container${index + 1}`] = container;
    });
    return containers;
  };

  const unformatContainers = (containers) => {
    const containersList = [];
    Object.keys(containers).forEach((key) => {
      containersList.push(containers[key]);
    });
    return containersList;
  };

  const [containers, setContainers] = useState(
    formatContainers(containersList)
  );

  useEffect(() => {
    setContainers(formatContainers(containersList));
  }, [containersList]);

  const [activeId, setActiveId] = useState();

  const sensors = useSensors(useSensor(PointerSensor));

  const onItemsChange = (updatedContainers) => {
    const containersAsList = unformatContainers(updatedContainers);
    onUpdate(containersAsList);
  };

  function findContainer(id) {
    if (id in containers) {
      return id;
    }

    return Object.keys(containers).find((key) => containers[key].includes(id));
  }

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const overId = over?.id;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setContainers((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect?.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item !== active.id),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          containers[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = containers[activeContainer].indexOf(active.id);
    const overIndex = containers[overContainer].indexOf(overId);

    let updatedContainers = containers;
    if (activeIndex !== overIndex) {
      setContainers((items) => {
        updatedContainers = {
          ...items,
          [overContainer]: arrayMove(
            items[overContainer],
            activeIndex,
            overIndex
          ),
        };

        return updatedContainers;
      });
    }

    onItemsChange(updatedContainers);
    setActiveId(null);
  }

  function customCollisionDetectionAlgorithm(args) {
    // First, let's see if there are any collisions with the pointer
    const pointerCollisions = pointerWithin(args);

    // Collision detection algorithms return an array of collisions
    if (pointerCollisions.length > 0) {
      return pointerCollisions;
    }

    // If there are no collisions with the pointer, return rectangle intersections
    return closestCorners(args);
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={customCollisionDetectionAlgorithm}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      autoScroll={false}
    >
      {Object.keys(containers).map((id, index) =>
        children({ id: id, items: containers[id], index: index })
      )}
      {/* This is the item being dragged */}
      {/* <DragOverlay>{activeId ? <Item id={activeId} /> : null}</DragOverlay> */}
    </DndContext>
  );
};

export default DragAndDrop;
