import { IconButton, Link, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { OkrSetTreeModal } from "../modals/OkrSetTreeModal";
import TreeIcon from "../../../assets/icons/tree.svg?react";

export const ViewOkrTreeButton = ({ objective, showText }) => {
  const [isOkrTreeModalOpen, setIsOkrTreeModalOpen] = useState(false);

  return (
    <>
      <OkrSetTreeModal
        isOpen={isOkrTreeModalOpen}
        setIsOpen={setIsOkrTreeModalOpen}
        objectiveId={objective.id}
      />
      <VStack spacing={0} h={"100%"} justifyContent={"center"}>
        <IconButton
          onClick={() => {
            setIsOkrTreeModalOpen(true);
          }}
          icon={<TreeIcon />}
          borderRadius={"50%"}
          h={"32px"}
          w={"32px"}
          p={0}
          minW={0}
        />
        {showText && (
          <Link
            textAlign={"center"}
            lineHeight={"14px"}
            pt={1}
            onClick={() => {
              setIsOkrTreeModalOpen(true);
            }}
            fontSize={"10px"}
            fontWeight={700}
          >
            View Connections
          </Link>
        )}
      </VStack>
    </>
  );
};
