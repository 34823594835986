import { VStack } from "@chakra-ui/react";
import { useFetchAccount } from "../../../../api/accounts";
import { useMemo } from "react";
import { DualTables } from "../../../../components/table/DualTables";
import useAccountId from "../../../../hooks/customDomainHooks";

const EditSurveyInvites = ({ currentInvited, setRightRowsSelected }) => {
  const { accountId } = useAccountId();
  const { data: accountData } = useFetchAccount(accountId);

  const profileColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Department",
        accessor: "department.name",
      },
    ],
    [accountData]
  );

  const profilesNotInvited = useMemo(() => {
    if (!accountData) return [];
    const currentInvitedIds = currentInvited.map((profile) => profile?.id);
    return accountData.profiles.filter((member) => {
      return member.isActive && !currentInvitedIds.includes(member.id);
    });
  }, [currentInvited, accountData.profiles]);

  const profilesInvited = useMemo(() => {
    if (!accountData) return [];
    const currentInvitedIds = currentInvited.map((profile) => profile?.id);
    return accountData.profiles.filter((member) => {
      return member.isActive && currentInvitedIds.includes(member.id);
    });
  }, [currentInvited, accountData.profiles]);

  return (
    <VStack spacing={4}>
      {accountData && (
        <DualTables
          columns={profileColumns}
          initialLeftData={profilesNotInvited}
          initialRightData={profilesInvited}
          onRightRowsChange={setRightRowsSelected}
        />
      )}
    </VStack>
  );
};

export default EditSurveyInvites;
