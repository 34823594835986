import { useQuery, useMutation } from "@tanstack/react-query";
import api from "./config";

export const useFetchTeams = (accountId) => {
  return useQuery({
    queryKey: ["fetchTeams"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/teams`);
      return response.data;
    },
  });
};

export const useFetchTeam = (accountId, teamId) => {
  return useQuery({
    queryKey: ["fetchTeam", teamId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/teams/${teamId}`);
      return response.data;
    },
  });
};

export const useFetchMyTeam = (accountId) => {
  return useQuery({
    queryKey: ["fetchMyTeam"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/teams/myTeam`);
      return response.data;
    },
  });
};

export const useCreateTeam = (props) =>
  useMutation({
    mutationKey: ["createTeam"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/teams`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateTeam = (props) =>
  useMutation({
    mutationKey: ["updateTeam"],
    mutationFn: async ({ accountId, teamId, data }) => {
      const response = await api.put(`/${accountId}/teams/${teamId}`, data);
      return response.data;
    },
    ...props,
  });

export const useAddTeamMembers = (props) =>
  useMutation({
    mutationKey: ["addTeamMembers"],
    mutationFn: async ({ accountId, teamId, data }) => {
      const response = await api.put(
        `/${accountId}/teams/${teamId}/addMembers`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useSetTeamMembers = (props) =>
  useMutation({
    mutationKey: ["setTeamMembers"],
    mutationFn: async ({ accountId, teamId, data }) => {
      const response = await api.put(
        `/${accountId}/teams/${teamId}/setMembers`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useRemoveTeamMembers = (props) =>
  useMutation({
    mutationKey: ["removeTeamMembers"],
    mutationFn: async ({ accountId, teamId, data }) => {
      console.log(data);
      const response = await api.delete(
        `/${accountId}/teams/${teamId}/removeMembers`,
        { data }
      );
      return response.data;
    },
    ...props,
  });
