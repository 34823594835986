import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import EditPlanningTeam from "../EditEvent/EditPlanningTeam";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateEvent } from "../../../api/events/event";
import useToastMessage from "../../../hooks/useToastMessage";
import { useContext, useState } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";

export const EditEventPlanningTeamModal = (props) => {
  const { isOpen, setIsOpen, event } = props;
  const { eventId } = useParams();
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();
  const queryClient = useQueryClient();
  const updateEventMutation = useUpdateEvent();
  const [rightRowsSelected, setRightRowsSelected] = useState([]);
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const { currentProfile } = useContext(AccountContext);

  const addToPlanningTeam = async (data) => {
    try {
      const planningTeam = data.map((row) => {
        return row.id;
      });
      await updateEventMutation.mutateAsync({
        accountId,
        eventId,
        data: { planningTeam },
      });
      if (
        !currentProfile.id === event.creator.id &&
        !planningTeam.some((profileId) => profileId === currentProfile.id)
      ) {
        showSuccessToast(`You have been removed from the planning team`);
        navigate(`/${accountId}/events`);
      }
      queryClient.invalidateQueries(["fetchEvent", eventId]);
      showSuccessToast(`Planning team updated`);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error adding people to planning team" });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"5xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Planning Team</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EditPlanningTeam
            event={event}
            setRightRowsSelected={setRightRowsSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            mr={4}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={() => addToPlanningTeam(rightRowsSelected)}
            isLoading={updateEventMutation.isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
