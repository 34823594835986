import PageShell from "../../layout/PageShell";
import { useContext, useState } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import AbcRankingReports from "./AbcRankingReports";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import AbcRankingPeriods from "./AbcRankingPeriods";
import { Box, Tag } from "@chakra-ui/react";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";

const AbcRankingsPage = () => {
  const { accountId } = useAccountId();
  const { currentProfile, isLoading } = useContext(AccountContext);
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Team Alignment" },
  ]);

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponent={
        <Box alignSelf={"flex-end"} pl={4}>
          <Tag size={"md"} colorScheme={"yellow"}>
            Viewable by Managers & Admins
          </Tag>
        </Box>
      }
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "ABC_RANKING",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
    >
      <InfoBox
        pageInfoType={"ABC_RANKING"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />

      {!isLoading &&
        ["OWNER", "ADMIN", "PARTNER"].includes(currentProfile?.role) && (
          <>
            <AbcRankingPeriods />
            <Box mb={4} />
          </>
        )}
      {!isLoading && <AbcRankingReports />}
    </PageShell>
  );
};

export default AbcRankingsPage;
