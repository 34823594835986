import { Box, Button, HStack, IconButton, VStack } from "@chakra-ui/react";
import { debounce } from "lodash";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { InputField } from "../../../components/fields/InputField";
import { DraggableItem } from "../../../components/common/Draggable/DraggableItem";
import DragIndicator from "../../../components/common/Draggable/DragIndicator";
import { MdClose } from "react-icons/md";
import { useCallback } from "react";
import LinksList from "../../../components/common/LinksList";

const UsefulLinksWidget = ({
  widgetData,
  updateCultureHubWidget,
  editMode,
  setEditMode,
  canEdit,
  showViewButton,
}) => {
  if (editMode) {
    return (
      <Box
      // w={"400px"}
      >
        <EditUsefulLinks
          data={widgetData.data}
          submitLinks={updateCultureHubWidget}
          setEditMode={setEditMode}
          showViewButton={showViewButton}
        />
      </Box>
    );
  } else {
    return (
      <ViewUsefulLinks
        widgetData={widgetData}
        setEditMode={setEditMode}
        canEdit={canEdit}
      />
    );
  }
};

export const EditUsefulLinks = ({
  data,
  submitLinks,
  setEditMode,
  showViewButton,
}) => {
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      data,
    },
  });
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
  } = form;

  const {
    fields: usefulLinks,
    append: appendLink,
    remove: removeLink,
    move: moveLink,
  } = useFieldArray({
    control,
    name: "data.links",
  });

  const debouncedSubmit = useCallback(
    debounce((event) => submitForm(event), 1000),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = async (event) => {
    if (Object.keys(errors).length === 0) {
      await handleSubmit(submitLinks)(event);
    } else {
      console.log("errors", errors);
    }
  };

  return (
    <Box w={"100%"}>
      <FormProvider {...form}>
        <form
          onChange={debouncedSubmit}
          // Prevent form submission on enter key
          onKeyDown={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
        >
          <DndProvider backend={HTML5Backend} context={window}>
            <VStack w={"100%"} spacing={6}>
              {usefulLinks.map((usefulLink, index) => {
                return (
                  <DraggableItem
                    key={usefulLink.id}
                    id={usefulLink.id}
                    index={index}
                    moveItem={(dragIndex, hoverIndex) => {
                      moveLink(dragIndex, hoverIndex);
                      debouncedSubmit();
                    }}
                    setDrag={(drag) => {
                      const dragHandle = document.getElementById(
                        `drag-handle-${usefulLink.id}`
                      );
                      if (dragHandle) {
                        drag(dragHandle);
                      }
                    }}
                    styleProps={{
                      w: "100%",
                    }}
                  >
                    {() => {
                      return (
                        <HStack
                          key={usefulLink.id}
                          w={"100%"}
                          className="draggableThing"
                          spacing={4}
                          align={"flex-start"}
                          border={"1px"}
                          borderColor={"gray.200"}
                          borderRadius={"md"}
                          p={2}
                        >
                          <DragIndicator id={usefulLink.id} width={"15px"} />
                          <VStack w={"100%"} spacing={0}>
                            <InputField
                              field={{
                                id: `data.links[${index}].label`,
                                placeholder: `Label`,
                              }}
                              inputStyle={{ variant: "flushed" }}
                            />
                            <InputField
                              field={{
                                id: `data.links[${index}].url`,
                                placeholder: `URL`,
                              }}
                              inputStyle={{ variant: "flushed" }}
                            />
                          </VStack>
                          <IconButton
                            icon={<MdClose />}
                            variant="ghost"
                            p={0}
                            onClick={() => {
                              removeLink(index);
                              debouncedSubmit();
                            }}
                          />
                        </HStack>
                      );
                    }}
                  </DraggableItem>
                );
              })}
              {/* {usefulLinks.length === 0 && <Box w={"400px"} />} */}
              <HStack w={"100%"}>
                <Box w={"15px"}></Box>
                <HStack justifyContent={"space-between"} w={"100%"}>
                  <Button
                    onClick={() => {
                      appendLink({
                        label: `Link ${usefulLinks.length + 1}`,
                      });
                      debouncedSubmit();
                    }}
                    alignSelf={"flex-start"}
                  >
                    Add Link
                  </Button>
                  {showViewButton && (
                    <Button
                      onClick={() => {
                        setEditMode(false);
                      }}
                      alignSelf={"flex-end"}
                      variant={"outline"}
                    >
                      Done
                    </Button>
                  )}
                </HStack>
              </HStack>
            </VStack>
          </DndProvider>
        </form>
      </FormProvider>
    </Box>
  );
};

const ViewUsefulLinks = ({ widgetData, setEditMode, canEdit }) => {
  return (
    <VStack alignItems={"flex-start"} spacing={4}>
      {widgetData.data.links && <LinksList links={widgetData.data.links} />}
      {canEdit && (
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Box w={"15px"}></Box>
          <Button
            onClick={() => {
              setEditMode(true);
            }}
            alignSelf={"flex-end"}
            variant={"outline"}
          >
            Edit
          </Button>
        </HStack>
      )}
    </VStack>
  );
};

export default UsefulLinksWidget;
