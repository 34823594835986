import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import GreenCheckWaveyIcon from "../../../assets/icons/green_check_wavey.svg?react";
import { SkipButton } from "../components/SkipButton";
import { WizardOverlay } from "../components/WizardOverlay";
import ArrowRight from "../../../assets/icons/arrow_right.svg?react";

const WelcomeWizardScreen = ({ wizardName, startWizard }) => {
  return (
    <WizardOverlay bg={"rgba(26, 32, 44, 0.94)"}>
      <Box h={"100%"}>
        <Box w={"500px"} pt={"90px"} pl={"100px"}>
          <VStack alignItems={"flex-start"} spacing={6}>
            {/* <HStack>
              <GreenCheckWaveyIcon />
              <Text color={"white"} fontSize={"22px"} fontWeight={400}>
                Sign up successful—now let's get started.
              </Text>
            </HStack> */}
            <Box w={"475px"}>
              <Text color={"white"} fontSize={"40px"} fontWeight={800}>
                Welcome! We're excited to start building culture with you!
              </Text>
            </Box>
            <HStack spacing={4}>
              <Button
                onClick={startWizard}
                leftIcon={<ArrowRight fill="#fff" />}
              >
                Continue Guiding Me
              </Button>
              <SkipButton wizardName={wizardName} />
            </HStack>
            <Text color={"white"} fontSize={"22px"} fontWeight={400}>
              Take a look around. We'll be here every step of the way, or, if
              you'd like to skip and explore on your own—you can always find
              more help & support at any time!
            </Text>
          </VStack>
        </Box>
      </Box>
    </WizardOverlay>
  );
};

export default WelcomeWizardScreen;
