import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  CheckboxGroup,
  Checkbox,
  Stack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";

export const CheckboxGroupField = (props) => {
  const { field, direction = "column" } = props;
  const form = useFormContext();
  const {
    formState: { errors },
    control,
    getValues,
  } = form;

  const renderFields = field.options.map((option) => (
    <Box key={option.value}>
      <Checkbox
        isDisabled={option.disabled}
        key={option.value}
        value={option.value}
        colorScheme="blue"
      >
        {option.label}
      </Checkbox>
    </Box>
  ));

  return (
    <Controller
      name={field.id}
      control={control}
      rules={field.validation}
      render={({ field: { value, onChange } }) => (
        <FormControl
          isInvalid={get(errors, field.id)}
          isRequired={field.validation?.required}
        >
          <FormLabel htmlFor={field.id}>{field.label}</FormLabel>
          <CheckboxGroup value={value} onChange={onChange}>
            {(direction === "row" || direction === "column") && (
              <Stack spacing={3} direction={direction}>
                {field.options.map((option) => (
                  <Box key={option.value}>
                    <Checkbox
                      isDisabled={option.disabled}
                      key={option.value}
                      value={option.value}
                      colorScheme="blue"
                    >
                      {option.label}
                    </Checkbox>
                  </Box>
                ))}
              </Stack>
            )}
            {direction === "wrap" && (
              <Wrap spacing={3} direction={direction}>
                {field.options.map((option) => (
                  <WrapItem key={option.value}>
                    <Checkbox
                      isDisabled={option.disabled}
                      key={option.value}
                      value={option.value}
                      colorScheme="blue"
                    >
                      {option.label}
                    </Checkbox>
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </CheckboxGroup>
          <FormErrorMessage>
            {get(errors, field.id) && get(errors, `${field.id}.message`)}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
