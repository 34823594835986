import { useBreakpointValue } from "@chakra-ui/react";
import { useContext } from "react";
import useAccountId from "../../hooks/customDomainHooks";
import { AccountContext } from "../../context/AccountContextComponent";
import { useUpdateMyProfile } from "../../api/profiles";
import { useQueryClient } from "@tanstack/react-query";
import { AdminDashboardWizard } from "./AminDashboardWizard/AdminDashboardWizard";
import { CultureHubWizard } from "./CultureHubWizard/CultureHubWizard";

const wizardSettings = {
  "admin-dashboard": {
    wizardName: "ADMIN_DASHBOARD_WIZARD",
  },
  home: {
    wizardName: "CULTURE_HUB_WIZARD",
  },
};

const Wizards = () => {
  const { accountId } = useAccountId();
  const { currentProfile } = useContext(AccountContext);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const updateMyProfileMutation = useUpdateMyProfile();
  const queryClient = useQueryClient();

  const pageBaseUrl = window.location.pathname
    .replace(`/${accountId}`, "")
    .replace("/", "");
  const relevantWizard = wizardSettings[pageBaseUrl];

  // Don't render on mobile because the wizard is not designed for mobile
  // If there is no relevant wizard, or if the user has already completed the wizard, return null
  if (
    !isDesktop ||
    !relevantWizard ||
    currentProfile.wizardsCompleted.includes(relevantWizard.wizardName)
  ) {
    return null;
  }

  const completeWizard = async ({ wizardName }) => {
    try {
      await updateMyProfileMutation.mutateAsync({
        accountId,
        data: {
          wizardsCompleted: [...currentProfile.wizardsCompleted, wizardName],
        },
      });
      await queryClient.invalidateQueries(["getMe"]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {
        {
          ADMIN_DASHBOARD_WIZARD: (
            <AdminDashboardWizard
              wizardName={relevantWizard.wizardName}
              completeWizard={completeWizard}
            />
          ),
          CULTURE_HUB_WIZARD: (
            <CultureHubWizard
              wizardName={relevantWizard.wizardName}
              completeWizard={completeWizard}
            />
          ),
        }[relevantWizard.wizardName]
      }
    </>
  );
};

export default Wizards;
