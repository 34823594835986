import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Switch,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { InputField } from "../../../components/fields/InputField";
import { DateField } from "../../../components/fields/DateField";
import { SwitchField } from "../../../components/fields/SwitchField";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import useToastMessage from "../../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useUpdateSurveyRun } from "../../../api/survey/surveyRun";
import { BsIncognito } from "react-icons/bs";
import { BiDownload, BiTrashAlt } from "react-icons/bi";
import { MdUnarchive } from "react-icons/md";
import { DeleteSomethingModal } from "../../../components/modals/DeleteSomethingModal";
import { useGetAllSurveyRunResponsesAsCsv } from "../../../api/survey/surveyRunResponse";
import useAccountId from "../../../hooks/customDomainHooks";
import { useIntl } from "react-intl";

const EditSurveyRuns = ({
  survey,
  surveyRuns,
  createSurveyRun,
  isCreatingSurveyRun,
}) => {
  const [showArchived, setShowArchived] = useState(false);
  const intl = useIntl();

  const surveyRunsToShow = useMemo(() => {
    return surveyRuns?.filter((survey) => {
      if (showArchived) {
        return survey.isArchived;
      } else {
        return !survey.isArchived;
      }
    });
  }, [surveyRuns, showArchived]);

  return (
    <Box>
      <HStack justifyContent={"space-between"}>
        <HStack pb={4} spacing={4}>
          <Text fontSize={"lg"}>{survey.title}</Text>
          <HStack spacing={4}>
            <Button onClick={createSurveyRun} isLoading={isCreatingSurveyRun}>
              Create Survey Run
            </Button>
          </HStack>
        </HStack>
        <HStack spacing={2}>
          <Switch
            isChecked={showArchived}
            onChange={(e) => {
              setShowArchived(e.target.checked);
            }}
          />
          <Text>Show Archived</Text>
        </HStack>
      </HStack>
      <Box
        borderRadius={"10px"}
        border={"1px"}
        borderColor={"gray.200"}
        mb={4}
        overflow={"auto"}
        bg={"white"}
        p={4}
      >
        <Grid templateColumns="repeat(12, 1fr)" minWidth={"900px"}>
          <GridItem
            p={2}
            colSpan={3}
            fontSize={"xs"}
            fontWeight={"600"}
            color={"gray.600"}
            textTransform={"uppercase"}
          >
            Label
          </GridItem>

          <GridItem
            p={2}
            colSpan={2}
            fontSize={"xs"}
            fontWeight={"600"}
            color={"gray.600"}
            textTransform={"uppercase"}
          >
            <Tooltip
              label={intl.formatMessage({
                id: "surveyDueDate",
              })}
            >
              Due Date
            </Tooltip>
          </GridItem>

          <GridItem
            p={2}
            colSpan={1}
            fontSize={"xs"}
            fontWeight={"600"}
            color={"gray.600"}
            textTransform={"uppercase"}
          >
            Invited
          </GridItem>

          <GridItem
            p={2}
            colSpan={1}
            fontSize={"xs"}
            fontWeight={"600"}
            color={"gray.600"}
            textTransform={"uppercase"}
          >
            Started
          </GridItem>

          <GridItem
            p={2}
            colSpan={1}
            fontSize={"xs"}
            fontWeight={"600"}
            color={"gray.600"}
            textTransform={"uppercase"}
          >
            Submitted
          </GridItem>

          <GridItem
            p={2}
            colSpan={1}
            fontSize={"xs"}
            fontWeight={"600"}
            color={"gray.600"}
            textTransform={"uppercase"}
          >
            <Tooltip
              label={intl.formatMessage({
                id: "surveyAllowResponses",
              })}
            >
              Allow responses
            </Tooltip>
          </GridItem>

          <GridItem
            p={2}
            colSpan={1}
            fontSize={"xs"}
            fontWeight={"600"}
            color={"gray.600"}
            textTransform={"uppercase"}
          >
            <Tooltip
              label={intl.formatMessage({
                id: "surveyMeasureEngagement",
              })}
            >
              Measure Engagement
            </Tooltip>
          </GridItem>

          <GridItem p={2} colSpan={1}></GridItem>

          {surveyRunsToShow.map((surveyRun, index) => (
            <GridItem colSpan={12} key={surveyRun.id} bg={"white"}>
              <SurveyRunRow surveyRun={surveyRun} index={index} />
            </GridItem>
          ))}

          {surveyRunsToShow.length === 0 && (
            <GridItem colSpan={12} bg={"white"} p={4}>
              <Center>
                <Text>Create a survey run</Text>
              </Center>
            </GridItem>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

const SurveyRunRow = ({ surveyRun, index }) => {
  const updateSurveyRunMutation = useUpdateSurveyRun();
  const { accountId } = useAccountId();
  const getAllSurveyRunResponsesAsCsvMutation =
    useGetAllSurveyRunResponsesAsCsv();
  const [isDeleteSurveyRunModalOpen, setIsDeleteSurveyRunModalOpen] =
    useState(false);
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const form = useForm({
    mode: "onChange",
    defaultValues: surveyRun,
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = form;

  useEffect(() => {
    if (surveyRun && !isDirty) {
      reset(surveyRun);
    }
  }, [surveyRun, reset]);

  const updateSurveyRun = async (data) => {
    try {
      await updateSurveyRunMutation.mutateAsync({
        accountId,
        surveyRunId: surveyRun.id,
        data,
      });
      queryClient.invalidateQueries([
        "fetchSurveyRuns",
        accountId,
        surveyRun.surveyId,
      ]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating survey run" });
    }
  };

  const downloadSurveyRunResponses = async (surveyRunId) => {
    try {
      const respData = await getAllSurveyRunResponsesAsCsvMutation.mutateAsync({
        contentType: "text/csv",
        accountId,
        surveyRunId,
      });
      // dowload the file as a csv
      const element = document.createElement("a");
      const file = new Blob([respData], { type: "text/csv" });
      element.href = URL.createObjectURL(file);
      element.download = `${surveyRun.label}.csv`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error downloading survey run results" });
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateSurveyRun)(event);
    } else {
      console.log("errors", errors);
    }
  };

  const archiveSurveyRun = async () => {
    updateSurveyRun({ isArchived: true });
  };

  const unArchiveSurveyRun = async () => {
    updateSurveyRun({ isArchived: false });
  };

  return (
    <>
      <DeleteSomethingModal
        isOpen={isDeleteSurveyRunModalOpen}
        setIsOpen={setIsDeleteSurveyRunModalOpen}
        headerText={"Archive Survey Run"}
        bodyText={`Are you sure you want to archive this survey run? When archived, this run cannot be viewed in reports.`}
        deleteFunction={archiveSurveyRun}
        isDeleting={updateSurveyRunMutation.isLoading}
        buttonText={"Archive"}
      />
      <FormProvider {...form} key={index}>
        <form onChange={debouncedSubmit}>
          <Box py={2} borderTop={"1px solid"} borderColor={"gray.200"}>
            <Grid
              templateColumns="repeat(12, 1fr)"
              gap={6}
              alignItems="center"
              borderColor="gray.200"
              p={2}
            >
              <GridItem colSpan={3} maxW={"300px"}>
                <HStack>
                  <InputField
                    field={{
                      id: `label`,
                      placeholder: "Label",
                      validation: { required: true },
                    }}
                  />
                  {surveyRun.isArchived && (
                    <Box>
                      <Tag colorScheme="red">Archived</Tag>
                    </Box>
                  )}
                </HStack>
              </GridItem>

              <GridItem colSpan={2} minW={"130px"}>
                <Center h={"100%"}>
                  <DateField
                    field={{
                      id: `dueDate`,
                    }}
                    onDateChange={submitForm}
                  />
                </Center>
              </GridItem>

              <GridItem colSpan={1}>
                <Center h={"100%"}>
                  <Text>
                    {surveyRun.isEveryoneInvited && "Everyone"}
                    {!surveyRun.isEveryoneInvited && surveyRun._count.invitees}
                  </Text>
                </Center>
              </GridItem>

              <GridItem colSpan={1}>
                <Center h={"100%"}>
                  <Text>{surveyRun.surveyRunResponsesCount}</Text>
                </Center>
              </GridItem>

              <GridItem colSpan={1}>
                <Center h={"100%"}>
                  <Text>{surveyRun.submittedResponsesCount}</Text>
                </Center>
              </GridItem>

              <GridItem colSpan={1} justifyContent="center" display="flex">
                <SwitchField
                  field={{
                    id: `isActive`,
                  }}
                />
                {isSubmitting ? (
                  <Spinner size="sm" mr={2} alignSelf={"center"} />
                ) : (
                  <Box mr={2} w={"16px"} />
                )}
              </GridItem>

              <GridItem colSpan={1} justifyContent="center" display="flex">
                <SwitchField
                  field={{
                    id: `doMeasureEngagement`,
                  }}
                />
                {isSubmitting ? (
                  <Spinner size="sm" mr={2} alignSelf={"center"} />
                ) : (
                  <Box mr={2} w={"16px"} />
                )}
              </GridItem>

              <GridItem colSpan={2}>
                <HStack spacing={1}>
                  {surveyRun.isAnonymous ? (
                    <Tooltip label="Anonymous survey">
                      <Center w={"16px"} alignContent={"center"}>
                        <Icon
                          as={BsIncognito}
                          color={"textSecondary"}
                          fontSize={18}
                        />
                      </Center>
                    </Tooltip>
                  ) : (
                    <Box w={"16px"} />
                  )}
                  <Box>
                    <IconButton
                      icon={<BiDownload />}
                      color={"textSecondary"}
                      fontSize={18}
                      variant="ghost"
                      isLoading={
                        getAllSurveyRunResponsesAsCsvMutation.isLoading
                      }
                      onClick={() => {
                        downloadSurveyRunResponses(surveyRun.id);
                      }}
                    />
                  </Box>
                  {!surveyRun.isArchived ? (
                    <Box>
                      <IconButton
                        icon={<BiTrashAlt />}
                        color={"textSecondary"}
                        fontSize={18}
                        variant="ghost"
                        isLoading={updateSurveyRunMutation.isLoading}
                        onClick={() => setIsDeleteSurveyRunModalOpen(true)}
                      />
                    </Box>
                  ) : (
                    <Box>
                      <IconButton
                        icon={<MdUnarchive />}
                        color={"textSecondary"}
                        fontSize={18}
                        variant="ghost"
                        isLoading={updateSurveyRunMutation.isLoading}
                        onClick={() => unArchiveSurveyRun()}
                      />
                    </Box>
                  )}
                </HStack>
              </GridItem>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default EditSurveyRuns;
