import { useQuery, useMutation } from "@tanstack/react-query";
import api from "./config";

export const useCreateAccount = (props) =>
  useMutation({
    mutationKey: ["createAccount"],
    mutationFn: async (newAccount) => {
      const response = await api.post("/accounts", newAccount);
      return response.data;
    },
    ...props,
  });

export const useFetchAccount = (accountId) => {
  return useQuery({
    queryKey: ["fetchAccount", accountId],
    queryFn: async () => {
      const response = await api.get(`/accounts/${accountId}`);
      return response.data;
    },
    enabled: !!accountId,
    refetchInterval: 300000, // 5 minutes
  });
};

export const useUpdateAccount = (props) =>
  useMutation({
    mutationKey: ["updateAccount"],
    mutationFn: async ({ accountData, accountId }) => {
      const response = await api.put(`/accounts/${accountId}`, accountData);
      return response.data;
    },
    ...props,
  });

export const useCheckAccountIdExists = (accountId) => {
  return useQuery({
    queryKey: ["checkAccountId"],
    queryFn: async () => {
      const response = await api.get(
        `/accounts/checkIfAccountExists/${accountId}`
      );
      return response.data;
    },
    enabled: !!accountId,
  });
};
