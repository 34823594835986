import { useCallback, useContext } from "react";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../../hooks/useToastMessage";
import TipTapEditor from "../../../components/common/Editor/TipTapEditor";
import useAccountId from "../../../hooks/customDomainHooks";
import { AccountContext } from "../../../context/AccountContextComponent";
import { FormattedDate, FormattedTime } from "react-intl";
import { useUpdateTemplate } from "../../../api/oneOnOne/oneOnOneTemplate";
import HtmlViewer from "../../../components/common/Editor/HtmlViewer";

export const Template = ({ templateData }) => {
  const { accountId } = useAccountId();
  const updateTemplateMutation = useUpdateTemplate();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const { currentProfile } = useContext(AccountContext);

  const defaultValues = {
    content: templateData?.content,
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateSharedNote)(event);
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const updateSharedNote = async (data) => {
    try {
      await updateTemplateMutation.mutateAsync({
        accountId,
        data,
        templateId: templateData.id,
      });
      queryClient.invalidateQueries(["fetchOneOnOneTemplates"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating template" });
    }
  };

  const isOwnerAdmin = ["OWNER", "ADMIN", "PARTNER"].includes(
    currentProfile?.role
  );

  const canEdit =
    currentProfile.id == templateData.creatorId ||
    (isOwnerAdmin && templateData.type === "Account");

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(updateSharedNote)}
        onChange={debouncedSubmit}
        style={{
          width: "100%",
        }}
      >
        <VStack
          gap={4}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          // w={{ base: "100%", md: "70%" }}
        >
          <HStack
            gap={10}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
            w={"100%"}
          >
            <VStack gap={2} alignItems={"flex-start"} w={"100%"}>
              <HStack width={"100%"}>
                <Text fontSize={"sm"} fontWeight={600} color={"textSecondary"}>
                  Last edit by:
                </Text>
                <Text fontSize={"sm"} fontWeight={600} color={"textSecondary"}>
                  {templateData?.updatedBy?.name} on{" "}
                  <FormattedDate
                    value={templateData?.updatedAt}
                    month="short"
                    day="2-digit"
                    year="numeric"
                  />
                  {` at `}
                  <FormattedTime value={templateData?.updatedAt} />{" "}
                </Text>
              </HStack>
            </VStack>
          </HStack>
          <Box w={"100%"}>
            {canEdit ? (
              <TipTapEditor
                defaultValue={templateData?.content}
                onChange={(htmlContent) => {
                  setValue("content", htmlContent);
                  debouncedSubmit();
                }}
                minHeight={400}
                aiContext={{
                  context: "We are writing a template for a 1-on-1 meeting.",
                }}
              />
            ) : (
              <Box
                maxH={400}
                overflow={"auto"}
                borderColor={"gray.200"}
                borderWidth={1}
                borderRadius={10}
                p={4}
              >
                <HtmlViewer html={templateData?.content} minHeight={300} />
              </Box>
            )}
          </Box>
        </VStack>
      </form>
    </FormProvider>
  );
};
