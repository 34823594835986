import {
  Box,
  HStack,
  Link,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { KrLabel } from "../../../OKRV2/common";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../../hooks/customDomainHooks";
import { useFetchObjective } from "../../../../api/okr/objective";
import { ObjectiveLabel } from "../../../OKRV2/Objectives/ObjectiveLabel";
import KeyResultGoal, {
  KeyResultGoalReadOnly,
} from "../../../OKRV2/KeyResults/KeyResultGoal";

const ObjectiveWidget = ({ widgetData }) => {
  const { accountId } = useAccountId();
  const {
    data: objective,
    isLoading: isLoadingObjective,
    isError: isErrorObjective,
    error: errorObjective,
  } = useFetchObjective(accountId, widgetData?.data?.objective);
  const navigate = useCustomDomainNavigate();

  if (isLoadingObjective) return <Spinner />;
  if (isErrorObjective) {
    console.error(errorObjective);
    return <Text>Error loading Objective!</Text>;
  }

  // TODO use the objective set display order to get the index
  const index =
    (objective?.objectiveSet?.displayOrder?.indexOf(objective) || 0) + 1;

  const handleViewObjectiveClick = (objectiveId) => {
    navigate(`/${accountId}/okrs/?tab=All%20OKRs&objectiveId=${objectiveId}`);
  };

  if (!objective) {
    return <Text>Objective not found!</Text>;
  }

  return (
    <Box w={"100%"}>
      <VStack alignItems={"flex-start"}>
        <HStack spacing={6}>
          <ObjectiveLabel
            canExpand={false}
            objective={objective}
            onClick={() => {
              handleViewObjectiveClick(objective.id);
            }}
          />
          <Link
            textDecoration="none"
            onClick={() => {
              navigate(
                `/${accountId}/okrs/?tab=All%20OKRs&objectiveId=${objective.id}`
              );
            }}
          >
            <Text fontSize="md" fontWeight="bold" noOfLines={3}>
              {objective.title}
            </Text>
          </Link>
        </HStack>
        <VStack
          alignItems={"flex-start"}
          divider={<StackDivider borderColor="gray.200" />}
          w={"100%"}
          pl={4}
          spacing={0}
        >
          {objective.keyResults?.length === 0 && (
            <Text p={2}>No key results.</Text>
          )}
          {objective.keyResults.map((keyResult, index) => (
            <HStack
              key={keyResult.id}
              p={1}
              w={"100%"}
              onClick={() => {
                handleViewObjectiveClick(objective.id);
              }}
              cursor={"pointer"}
              _hover={{
                bg: "gray.100",
              }}
            >
              <KrLabel index={index} width={"50px"} />
              <HStack justifyContent={"space-between"} w={"100%"}>
                <Text fontSize="sm" noOfLines={3}>
                  {keyResult.title}
                </Text>
                <Box>
                  <KeyResultGoalReadOnly keyResult={keyResult} />
                </Box>
              </HStack>
            </HStack>
          ))}
        </VStack>
      </VStack>
    </Box>
  );
};

export default ObjectiveWidget;
