import PageShell from "../../layout/PageShell";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import ViewAccount from "./ViewAccount";
import LoadingBox from "../../components/common/LoadingBox";
import Modules from "./Modules/Modules";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";
import CurrentBilling from "../Billing/CurrentBilling";
import SubscriptionSettings from "../Billing/SubscriptionSettings";
import useTabNavigation from "../../hooks/useTabNavigation";
import IntegrationsTab from "./Integrations/IntegrationsTab";

const AccountPage = () => {
  const { accountId } = useAccountId();
  const {
    currentProfile,
    accountData,
    isLoading,
    errorAccountData,
    isErrorAccountData,
  } = useContext(AccountContext);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Company Settings" },
  ]);

  if (isLoading) {
    return <LoadingBox />;
  }
  if (isErrorAccountData) {
    return <Text>{errorAccountData.message}</Text>;
  }
  const profileRole = currentProfile?.role;

  const allTabs = [
    {
      name: "Company",
      rolesAllowed: ["EMPLOYEE", "ADMIN", "OWNER", "PARTNER"],
      content: <ViewAccount account={accountData} />,
    },
    {
      name: "Integrations",
      rolesAllowed: ["ADMIN", "OWNER", "PARTNER"],
      content: <IntegrationsTab />,
    },
    {
      name: "Modules",
      rolesAllowed: ["OWNER", "PARTNER", "ADMIN"],
      content: <Modules />,
    },
    {
      name: "Billing",
      isBillingRelated: true,
      rolesAllowed: ["OWNER", "PARTNER", "ADMIN"],
      content: <CurrentBilling />,
    },
    {
      name: "Subscription",
      isBillingRelated: true,
      rolesAllowed: ["OWNER", "PARTNER", "ADMIN"],
      content: <SubscriptionSettings />,
    },
  ];
  const allowedTabs = allTabs
    .filter((tab) => {
      return tab.rolesAllowed.includes(profileRole);
    })
    .filter((tab) => {
      if (!tab.isBillingRelated) return true;
      return currentProfile.hasBillingAccess;
    });

  const { tabIndex, handleTabsChange } = useTabNavigation(
    allowedTabs,
    "Account"
  );

  return (
    <PageShell breadcrumbs={breadcrumbs}>
      {isLoading && <LoadingBox />}
      {isErrorAccountData && <Text>{errorAccountData.message}</Text>}
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          {allowedTabs.map((tab) => (
            <Tab key={tab.name}>{tab.name}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {allowedTabs.map((tab, idx) => (
            <TabPanel key={tab.name}>
              {tabIndex === idx && tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </PageShell>
  );
};

export default AccountPage;
