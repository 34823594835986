import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../config";

export const useFetchAdminDashboardLayout = (accountId) => {
  return useQuery({
    queryKey: [accountId, "fetchAdminDashboardLayout"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/adminDashboardLayout`);
      return response.data;
    },
  });
};

export const useUpdateAdminDashboardLayout = (props) =>
  useMutation({
    mutationKey: ["updateAdminDashboardLayout"],
    mutationFn: async ({ accountId, adminDashboardLayoutId, data }) => {
      const response = await api.put(
        `/${accountId}/adminDashboardLayout/${adminDashboardLayoutId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
