import {
  Box,
  Button,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import OkrTree from "../../OrgChart/OkrTree";
import ArrowLeftIcon from "../../../assets/icons/arrow_left.svg?react";

export const OkrSetTreeModal = (props) => {
  const { isOpen, setIsOpen, objectiveId } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"full"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p={0}>
          <VStack alignItems={"flex-start"} w={"100%"}>
            <Text p={4}>OKR Tree</Text>
            <Box bg={"primary"} w={"100%"} h={"50px"}>
              <Link
                color={"white"}
                textDecoration={"none"}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <HStack p={2}>
                  <ArrowLeftIcon fill={"white"} />
                  <Text color={"white"}>Go Back</Text>
                </HStack>
              </Link>
            </Box>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={{ base: 0, md: 2 }}>
          <OkrTree
            objectiveId={objectiveId}
            onSuccess={() => {
              setIsOpen(false);
            }}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
